/* eslint-disable no-unused-expressions */

import React, {useState, useEffect} from 'react'
import { Drawer, Button } from 'rsuite';
import Form from './TrekForm';
import isEmpty from '../../validations/isEmpty';

function TreksDrawer(props) {
    const [open, setOpen] = useState(false);
    
  useEffect(()=>{
      if(!isEmpty(props.editData)){
          setOpen(true)
      }
  },[props.editData])

  const onClose = () => {
      props?.onEdit && props?.onEdit(null)
      setOpen(false);
  }

  const onOpen = () => {
    setOpen(true)
  }

  return (
    <div>
    <Button appearance="primary" onClick={onOpen}>Add Trek</Button>
    <Drawer
          show={open}
          onHide={onClose}
          backdrop="static"
          size="sm"
        >
          <Drawer.Header>
            <Drawer.Title>Add Trek</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <Form  {...props} />
          </Drawer.Body>
          <Drawer.Footer>
            {/* <Button onClick={onClose} appearance="primary">Confirm</Button> */}
            <Button onClick={onClose} appearance="subtle">Cancel</Button>
          </Drawer.Footer>
    </Drawer>   
    </div>
  )
}

export default TreksDrawer