import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUsers, deleteUser } from '../../actions/userActions';
import { logoutUser } from '../../actions/authActions';
import { Table,Checkbox,Dropdown,Popover,Whisper,Icon,IconButton,Divider } from 'rsuite';
import UserForm from './UserForm'
import Confirm from '../../components/common/Confirm'
import EditFormModal from './EditFormModal'
import { Loader } from 'rsuite';
import isEmpty from '../../validations/isEmpty'
import UpdateImage from './UpdateImage';
import {Link} from 'react-router-dom'

const { Column, HeaderCell, Cell, Pagination } = Table;
const NameCell = ({ rowData, dataKey, ...props }) => {
  const speaker = (
    <Popover title="Description">
      <p>
        <b>Name:</b> {`${rowData.name}`}{' '}
      </p>
      <p>
        <b>Email:</b> {rowData.email}{' '}
      </p>
      <p>
        <b>Phone:</b> {rowData.phone}{' '}
      </p>
      <p>
        <b>Role:</b> {rowData.role}{' '}
      </p>
    </Popover>
  );
  return (
    <Cell {...props}>
      <Whisper placement="top" speaker={speaker}>
        <Link to={`/users/${rowData.id}`}>{rowData[dataKey].toLocaleString()}</Link>
      </Whisper>
    </Cell>
  );
};

const ImageCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div
      style={{
        width: 40,
        height: 40,
        background: '#f5f5f5',
        borderRadius: 20,
        marginTop: 2,
        overflow: 'hidden',
        display: 'inline-block'
      }}
    >
    {/* photo */}
      <img src={rowData.photo} width="44" />
    </div>
  </Cell>
);

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: '46px' }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some(item => item === rowData[dataKey])}
      />
    </div>
  </Cell>
);

const Menu = ({ onSelect,id,delUser,logoutUser,currentUser }) => (
  <Dropdown.Menu onSelect={onSelect} placement="bottomEnd" >
    <Confirm func={()=>{
        if(id === currentUser.id ){
          console.log('here')
          logoutUser()
        }
        delUser(id)
      }
    }>
      <Dropdown.Item eventKey={3} >Delete User</Dropdown.Item>
    </Confirm>
    <Dropdown.Item  eventKey={4}>
      Export Pdf
    </Dropdown.Item>
    <Dropdown.Item eventKey={5}>Export HTML</Dropdown.Item>
    <Dropdown.Item eventKey={6}>Settings</Dropdown.Item>
    <Dropdown.Item eventKey={7}>About</Dropdown.Item>
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect,delUser,id,currentUser,logoutUser, ...rest }) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} delUser={delUser} logoutUser={logoutUser} currentUser={currentUser} id={id} />
  </Popover>
);

let tableBody;

class CustomWhisper extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectMenu = this.handleSelectMenu.bind(this);
  }
  handleSelectMenu(eventKey, event) {
    this.trigger.hide();
  }
  render() {
    return (
      <Whisper
        placement="autoVerticalStart"
        trigger="click"
        triggerRef={ref => {
          this.trigger = ref;
        }}
        container={() => {
          return tableBody;
        }}
        speaker={<MenuPopover delUser={this.props.delUser} 
                  currentUser={this.props.currentUser}
                  logoutUser={this.props.logoutUser}
                  id={this.props.id} onSelect={this.handleSelectMenu} />}
      >
        {this.props.children}
      </Whisper>
    );
  }
}

const ActionCell = ({ rowData,delUser,logoutUser,currentUser, dataKey, ...props }) => {
  function handleAction() {
    alert(`id:${rowData}`);
  }
  return (
    <Cell {...props} className="link-group">
      <EditFormModal datas={rowData}>
          {/* <IconButton
            appearance="subtle"
            // onClick={handleAction}
            icon={<Icon icon="edit2" />}
          /> */}
          <Icon icon="edit2" />
      </EditFormModal>
      <Divider vertical />

      <UpdateImage id={rowData.id}>
      <Icon icon="home" />
      </UpdateImage>
      
      <Divider vertical />
      <CustomWhisper delUser={delUser} logoutUser={logoutUser} currentUser={currentUser} id={rowData.id} >
        <IconButton appearance="subtle" icon={<Icon icon="more" />} />
      </CustomWhisper>
    </Cell>
  );
};

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedKeys: [],
      data:''
    };
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }
  handleCheckAll(value, checked) {
    const { data } = this.state;
    const checkedKeys = checked ? data.map(item => item.id) : [];
    this.setState({
      checkedKeys
    });
  }
  handleCheck(value, checked) {
    const { checkedKeys } = this.state;
    const nextCheckedKeys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter(item => item !== value);

    this.setState({
      checkedKeys: nextCheckedKeys
    });
  }
  componentDidMount() {
    this.props.getUsers();
  }
  // componentDidUpdate(prevProps,prevState){
  //   const {users}=this.props.userReducer;
  //   const prevUser=prevProps.userReducer.users;
  //   console.log('here')
  //   if(JSON.stringify(users) !==JSON.stringify(prevUser)){
  //     console.log('jer3')
  //     this.setState({data:users})
  //   }
  // }
  delUser(id){
    this.props.deleteUser(id)
    console.log(id)
  }

  renderUserTable(){
    const { data, checkedKeys } = this.state;
    const {users,loading}=this.props.userReducer;
    const {user}=this.props.auth;
    let checked = false;
    let indeterminate = false;

    if(!isEmpty(data)){
      if (checkedKeys.length === data.length) {
        checked = true;
      } else if (checkedKeys.length === 0) {
        checked = false;
      } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
        indeterminate = true;
      }

    }
    const newUsers = isEmpty(users) ? 
                        [] :
                        user.role === 'god' ? 
                        users : 
                        users.filter(item=>item.role !== 'god');
    return(
      <div>
        {
          loading ||users ===null?
          <Loader backdrop content="loading..." vertical />
          :
          <Table
          height={420}
          data={newUsers}
          id="table"
          bodyRef={ref => {
            tableBody = ref;
          }}
        >
        
          <Column width={50} align="center">
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ lineHeight: '40px' }}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={this.handleCheckAll}
                />
              </div>
            </HeaderCell>
            <CheckCell
              dataKey="id"
              checkedKeys={checkedKeys}
              onChange={this.handleCheck}
            />
          </Column>
          <Column width={80} align="center">
            <HeaderCell>Avatar</HeaderCell>
            <ImageCell dataKey="avartar" />
          </Column>

          <Column width={160}>
            <HeaderCell>Name</HeaderCell>
            <NameCell dataKey="name" />
          </Column>

          <Column width={300}>
            <HeaderCell>Email</HeaderCell>
            <Cell>
              {rowData => (
                <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
              )}
            </Cell>
          </Column>
          <Column width={300}>
            <HeaderCell>Phone</HeaderCell>
            <Cell>
              {rowData => (
                <span>{rowData.phone}</span>
              )}
            </Cell>
          </Column>
          <Column width={200}>
            <HeaderCell>Action</HeaderCell>
            <ActionCell dataKey="id" logoutUser={this.props.logoutUser} currentUser={user} delUser={this.delUser.bind(this)} />
          </Column>
        </Table>
          
        }
       
      </div>
    )

  }
  render() {
    // const {users}=this.props.userReducer;
    return (
      <div className="users">
        <div className="users-heading">
          <h2 className="primary-heading">Users</h2>
        </div>
        <UserForm />
        {this.renderUserTable()}
      </div>
    )
  }
}


Users.propTypes ={
  getUsers: PropTypes.func.isRequired,
  logoutUser:PropTypes.func.isRequired,
  deleteUser:PropTypes.func.isRequired,
  userReducer:PropTypes.object.isRequired,
  auth:PropTypes.object.isRequired,
  errorReducer: PropTypes.object.isRequired 
}

const mapStateToProps = (state) => ({
  userReducer:state.userReducer,
  auth:state.auth,
  errorReducer:state.errorReducer
})


export default connect(mapStateToProps, {getUsers,deleteUser,logoutUser})(Users);

