import axios from 'axios';
import { Alert } from 'rsuite';
import isEmpty from '../validations/isEmpty'
import {getError} from '../helpers/getUnauth'

import {
    GET_CUSTOMER,
    GET_CUSTOMERS,
    CUSTOMER_LOADING,
    ADD_CUSTOMER,
    DELETE_CUSTOMER,
    EDIT_CUSTOMER_STATUS,
    EDIT_CUSTOMER,
    CLEAR_ERRORS,
    GET_ERRORS
  } from './types';


const getPages = (pages)=>{
  if(isEmpty(pages)){
    return pages={
      pageNumber:0,
      pageSize:10
    }
  }
  return pages
}

// Get user by id
export const getCustomer = id => dispatch => {
    dispatch(setLoading());
    axios
      .get(`/api/v1/customers/${id}`)
      .then(res =>
        dispatch({
          type: GET_CUSTOMER,
          payload: res.data.data
        })
      )
      .catch(err =>{
        getError(err)
        dispatch({
          type: GET_CUSTOMER,
          payload: {}
        })
      }
      );
  };

  // Get all customers
export const getCustomers = (pages) => dispatch => {
    dispatch(setLoading());
    let page = getPages(pages);
    axios
      .get('/api/v1/customers',{ params:page,})
      .then(res =>{
        dispatch({
          type: GET_CUSTOMERS,
          payload: res.data.data,
          page: res.data.page,
        })
      }

      )
      .catch(err =>{
        getError(err)
        dispatch({
          type: GET_CUSTOMERS,
          payload: []
        })
      });
};

  // Get all customers by today
  export const getCustomersToday = (pages) => dispatch => {
    dispatch(setLoading());
    let page = getPages(pages);
    axios
      .get('/api/v1/customers/by/today',{ params:page,})
      .then(res =>{
        if(res.data.success){
          dispatch({
            type: GET_CUSTOMERS,
            payload: res.data.data,
            page: res.data.page,
          })
        }else{
          dispatch({
            type: GET_CUSTOMERS,
            payload: [],
          })
        }
      }
        
      )
      .catch(err =>{
        getError(err)
        dispatch({
          type: GET_CUSTOMERS,
          payload: []
        })

      })  
};

  // Get all customers by week
  export const getCustomersWeek = (pages) => dispatch => {
    dispatch(setLoading());
    let page = getPages(pages);
    axios
      .get('/api/v1/customers/by/week',{ params:page,})
      .then(res =>{
        if(res.data.success){
          dispatch({
            type: GET_CUSTOMERS,
            payload: res.data.data,
            page: res.data.page,
          })
        }else{
          dispatch({
            type: GET_CUSTOMERS,
            payload: [],
          })
          Alert.error(res.data.error, 5000)
        }
      }
      )
      .catch(err =>{
        getError(err)
        dispatch({
          type: GET_CUSTOMERS,
          payload: []
        })

        }
      );
};

  // Get all customers by month
  export const getCustomersMonth = (pages) => dispatch => {
    dispatch(setLoading());
    let page = getPages(pages);
    axios
      .get('/api/v1/customers/by/month',{ params:page,})
      .then(res =>{
        if(res.data.success){
          dispatch({
            type: GET_CUSTOMERS,
            payload: res.data.data,
            page: res.data.page,
          })
        }else{
          dispatch({
            type: GET_CUSTOMERS,
            payload: [],
          })
          Alert.error(res.data.error, 5000)
        }
      }
      )
      .catch(err =>{
        getError(err)
        dispatch({
          type: GET_CUSTOMERS,
          payload: []
        })

        }
      );
};

  // Get all customers by search
  export const getCustomersSearch = (data,pages) => dispatch => {
    dispatch(setLoading());
    let page = getPages(pages);
    axios
      .post('/api/v1/customers/by/search',data,{params:page,})
      .then(res =>{
        if(res.data.success){
          dispatch({
            type: GET_CUSTOMERS,
            payload: res.data.data,
            page: res.data.page,
          })
        }else{
          dispatch({
            type: GET_CUSTOMERS,
            payload: [],
          })
          Alert.error(res.data.error, 5000)
        }
      }
      )
      .catch(err =>{
        getError(err)
        dispatch({
          type: GET_CUSTOMERS,
          payload: []
        })

        }
      );
};
  // Add Customer
export const addCustomer = newCustomer => dispatch => {
    dispatch(clearErrors());
    axios
      .post('/api/v1/customers', newCustomer)
      .then(res =>{
        if(res.data.success){
          dispatch({
            type: ADD_CUSTOMER,
            payload: res.data.data
          })
          Alert.success(res.data.message, 5000)
        }else{
          Alert.error("Something went wrong", 5000)
        }
          
      }
      )
      .catch(err =>{
          getError(err)
          if(err){
            if(err.response){
              dispatch({
                type: GET_ERRORS,
                payload: err.response.data
              });
            }else{
              console.log(err)
            }
          }
        }
        
      );
};

// Edit Customer
export const editCustomer = (customerId,newCustomer) => dispatch => {
    dispatch(clearErrors());
    axios
      .put(`/api/v1/customers/${customerId}`, newCustomer)
      .then(res =>{
        if(res.data.success){
          dispatch({
            type: EDIT_CUSTOMER,
            payload: res.data.data[0],
            id:customerId,
            meta: {
              socket: {
                channel: 'update',
                namespace: 'customer',
                room: 'customers',
              },
            },
          })
          Alert.success(res.data.message, 5000)
        }else{
          Alert.error("Something went wrong", 5000)
        }
      })
      .catch(err =>{
        if(err){
          getError(err)
          if(err.response){
            dispatch({
              type: GET_ERRORS,
              payload: err.response.data
            })
          }else{
            console.log(err)
          }
        }
      }
      );
  };

// Delete Customer
export const deleteCustomer = id => dispatch => {
    axios
      .delete(`/api/v1/customers/${id}`)
      .then(res =>{
        if(res.data.success){
          dispatch({
            type: DELETE_CUSTOMER,
            payload: id
          })
          Alert.success(res.data.message, 5000)
        }else{
          Alert.error("Something went wrong", 5000)
        }

      }
      )
      .catch(err =>{
        getError(err)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      }
      );
  };

//Put Image
export const editCustomerStatus = (customerId,status) => dispatch => {
    dispatch(clearErrors());
    axios
      .post(`/api/v1/customers/status/${customerId}`, status)
      .then(res =>{
        if(res.data.success){
          dispatch({
            type: EDIT_CUSTOMER,
            payload: res.data.data[0],
            id:customerId,
          })
          Alert.success(res.data.message, 5000)
        }else{
          Alert.error("Something went wrong", 5000)
        }
        
      })
      .catch(err =>{
          getError(err)
          if(err){
            if(err.response){
              dispatch({
                type: GET_ERRORS,
                payload: err.response.data
              })
            }else{
              console.log(err)
            }
          }
        });
  };

// User loading
export const setLoading = () => {
  return {
    type: CUSTOMER_LOADING
  };
};

// Clear errors
export const clearErrors = () => {
    return {
      type: CLEAR_ERRORS
    };
  };