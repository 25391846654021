import React, {useState,useEffect} from 'react'
import { deleteTeam } from '../../actions/teamActions';
import { Modal,Button } from 'rsuite';
import {Link} from 'react-router-dom';
import {Icon, Uploader,Avatar} from 'rsuite';
import UploadImages from "../../components/common/UploadImages";
import {useDispatch, useSelector} from 'react-redux';
import {addFiles} from '../../actions/teamActions'
import isEmpty from '../../validations/isEmpty';

function TeamItem({ onEdit, team }) {
    const [ show, setShow] = useState(false);

    const dispatch = useDispatch();
    const close = () => {
        setShow( false );
    }

    const open = () =>  {
        setShow( true );
    }
    return (
        <div className="trek-item trek-grid">
            <EmpModal data={team} />
            <div className="trek-item__days text-center">
                {team.gender}
            </div>
            <div className="trek-item__days text-left">
                {team.designation}
            </div>
            {/* <div className="trek-item__days text-center">
                {team.details}
            </div> */}
            <div 
                onClick={()=>onEdit(team)}
                className="trek-item__edit text-center">
                <Icon icon="edit" size="lg" color="red"  />
            </div>
            <div 
                onClick={open}
                className="trek-item__delete text-center">
                <Icon icon="minus-square" size="lg" color="blue"  />
            </div>
            
            <Modal backdrop="static" show={show} onHide={close} size="xs">
                <Modal.Body>
                    <Icon
                    icon="remind"
                    style={{
                        color: '#ffb300',
                        fontSize: 24
                    }}
                    />
                    {'  '}
                    Are you sure, You want to delete this team member ?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>{
                        dispatch(deleteTeam(team.id));
                        close()
                    }} appearance="primary">
                    Ok
                    </Button>
                    <Button onClick={close} appearance="subtle">
                    Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const EmpModal = ({data}) =>{
    const [ mod, setMod] = useState(false);
    const [ team, setTeam] = useState({});
    const [ pic, setPic] = useState([]);
    useEffect(()=>{
        if(!isEmpty(data)){
            setTeam(data)
        }
    },[data])
    const closeMod = () => {
        setMod( false );
    }
    const dispatch = useDispatch();
    const getChar = (name) =>{
        const half = name.split(" ");
        let initial = "";
        half && half.map(item=>{
            if(item !== 'undefined'){
                initial += item.charAt(0);
            }
            
        })
        
        return initial.toUpperCase();
    }
    const styles = {
        width: 100,
        height: 100
      };
    const uploadPic = () =>{
        if(isEmpty(pic)){
            return false;
        }
        const img = new FormData();
        const asd = pic[pic.length -1].blobFile;
        img.append('pic',asd)
        dispatch(addFiles(data.id , img));
        setPic([])
        // debugger
    }
    return(
        <>
            <div onClick={()=>setMod(true)} className="trek-item__name">
                {/* <Link to={`/teams/${team.id}`}>{team.name}</Link> */}
                <a>{data.name}</a>
            </div>
            <Modal backdrop={true} show={mod} onHide={closeMod}>
                <Modal.Header>
                    <Modal.Title>Team Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="team-details">
                        <div className="team-image">
                            {
                                data.pic ?
                                <Avatar src={data.pic} />
                                :
                                <Avatar size="lg">{getChar(data.name)}</Avatar>
                            }
                            <div className="team-upload">
                                {/* <UploadImages
                                    UploadImages={dispatch(addFiles)}
                                    images={data.pic}
                                    id={data.id}
                                    name="pic"
                                    // onImageChange={onImageChange}
                                /> */}
                                <Uploader
                                    multiple={false}
                                    fileListVisible={false}
                                    defaultFileList={pic}
                                    listType="picture"
                                    autoUpload={false}
                                    onChange={image => {setPic(image)}}
                                    >
                                    <button style={styles}>
                                        {!isEmpty(pic) ? (
                                        <img src={URL.createObjectURL(pic[pic.length - 1].blobFile)} width="100%" height="100%" />
                                        ) : (
                                        <Icon icon="avatar" size="5x" />
                                        )}
                                    </button>
                                </Uploader>
                                <Button onClick={()=>uploadPic()} appearance="primary">
                                    Upload Image
                                </Button>   
                            </div>
                        </div>
                        <div className="team-list">
                            <div className="team-name">
                                <h2>{data.name}</h2>
                                <span>{data.gender}</span>
                            </div>
                            <div className="team-designation">
                                {data.designation}
                            </div>
                            <div className="team-detail">
                                {data.details}
                            </div>
                            
                        </div>
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeMod} appearance="primary">
                    Ok
                    </Button>
                    <Button onClick={closeMod} appearance="subtle">
                    Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TeamItem
