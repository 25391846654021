import React, { Component } from "react";
import { connect } from "react-redux";
import { updateIcons,editSettings } from "../../actions/settingActions";
import { Uploader, Icon, Button } from "rsuite";
import { Form, FormGroup, FormControl, ControlLabel } from "rsuite";
import isEmpty from "../../validations/isEmpty";

export class UploadIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      image: [],
      name: "",
      errors: {}
    };
    this.onUpload = this.onUpload.bind(this);
    this.onError = this.onError.bind(this);
  }
  onUpload() {
    let formData = new FormData();
    const { image, name } = this.state;
    const { error, isValid } = this.onError();
    if (!isValid) {
      this.setState({ errors: error });
      return false
    }
    formData.append("icon", image[0].blobFile);
    formData.append("name", name);
    this.props.updateIcons(this.props.settings.id,formData);
    this.setState({image:[],name:''})
    this.onSettingsUpdate=this.onSettingsUpdate.bind(this);
  }
  onError() {
    const error = {};
    if (isEmpty(this.state.name)) {
      error.name = "Name is Required";
    }
    if (isEmpty(this.state.image)) {
      error.image = "Image is required";
    }

    return { error, isValid: isEmpty(error) };
  }
  onSettingsUpdate(item){
      const {settings,editSettings}= this.props;
      const a = settings.roomIcons.filter(icon=>{
          // let parsed = JSON.parse(icon);
          let parsed = icon;
          if(parsed.id !==item.id){
              return parsed
          }
      });
      let data = settings;
      // data.roomIcons= a.map(item=>JSON.parse(item));
      data.roomIcons= a.map(item=>item);
      editSettings(data.id,data);
  }
  render() {
    const { settings } = this.props;
    const { errors, name } = this.state;
    // console.log(settings);
    return (
      <div className="settings-upload">
        <div className="settings-upload-form">
          <div className="settings-upload-image">
            <Uploader
              multiple={false}
              autoUpload={false}
              listType="picture"
              fileList={this.state.image}
              onChange={image => {
                console.log(image);
                this.setState({image:image});
              }}
            >
              <button>
                <Icon icon="camera-retro" size="lg" />
              </button>
            </Uploader>
            {
                !isEmpty(errors.image) ? <span className="error-message">{errors.image}</span> : null
            }
          </div>
          <div>
            <Form>
              <FormGroup>
                <ControlLabel>Icon Name</ControlLabel>
                <FormControl
                  name="name"
                  type="text"
                  value={name}
                  errorMessage={
                    !isEmpty(errors.name) ? "Name name Required" : ""
                  }
                  onChange={value => this.setState({ name: value })}
                />
              </FormGroup>
            </Form>
          </div>
          <Button onClick={() => this.onUpload()} appearance="primary">
            Upload
          </Button>
        </div>
 
        <div className="settings-upload-container">
            <ul className="settings-upload-list">
                {
                    settings && settings.roomIcons && settings.roomIcons.map(item=>{
                        // const parsed = JSON.parse(item);
                        const parsed = item;
                        return(
                           <li className="settings-upload-list__item">
                               <img src={parsed.icon} alt="Room Icon"/>
                               <span>{parsed.name}</span>
                               <span 
                                    onClick={()=>this.onSettingsUpdate(parsed)}
                                    className="close" >
                                    <span class="iconify" data-icon="ic:round-close" data-inline="false"></span>
                               </span>
                           </li> 
                        )
                    })
                }
            </ul>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateIcons,
  editSettings
};

const mapStateToProps = state => ({
  settings: state.settingReducer.settings
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadIcons);
