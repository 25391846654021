import React, { Component } from 'react'
import CKEditor from "../../components/common/CKEditor";

class Dashboard extends Component {
  constructor(props){
    super(props)
    this.state = {
      price_details: '',
    }
    this.onNotCkChange=this.onNotCkChange.bind(this)
  }
  onNotCkChange(name , data) {
    this.setState({ [name]: data });
  }
  render() {
    return (
      <>
        <h2>Dashboard</h2>
        {/* <CKEditor 
          id="price_details" 
          value={this.state.price_details} 
          datas={this.state.price_details} 
          onChange={this.onNotCkChange}/> */}
      </>
    )
  }
}

export default Dashboard
