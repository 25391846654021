import {
    GET_ADVENTURE,
    GET_ADVENTURES,
    ADVENTURE_LOADING,
    ADD_ADVENTURE,
    EDIT_ADVENTURE,
    EDIT_ADVENTURE_UPLOAD,
    DELETE_ADVENTURE
  } from '../actions/types';
  
  const initialState = {
    adventures: [],
    adventure: {},
    loading: false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case ADVENTURE_LOADING:
        return {
          ...state,
          loading: true
        };
      case GET_ADVENTURE:
        return {
          ...state,
          adventure: action.payload,
          loading: false
        };
      case GET_ADVENTURES:
        return {
          ...state,
          adventures: action.payload,
          loading: false
        };
    case ADD_ADVENTURE:
        return {
          ...state,
          adventures: [action.payload, ...state.adventures]
        };
    case EDIT_ADVENTURE_UPLOAD:
      return{
        ...state,
        adventure:action.payload
      }
    case EDIT_ADVENTURE:
        return {
          ...state,
          adventures:state.adventures.map(adventure=>
            adventure.id === action.id ?
            // transform the one with a matching id
            action.payload  : 
            // otherwise return original todo
            adventure
            )
        };
      case DELETE_ADVENTURE:
        return {
          ...state,
          adventures: state.adventures.filter(adventure => adventure.id !== action.payload)
        };
      default:
        return state;
    }
  }
  