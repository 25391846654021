import React, { Component, useEffect } from "react";
import { Button, Modal, Icon, ButtonToolbar } from "rsuite";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    Input
} from "rsuite";
import { DatePicker } from 'rsuite';
import { SelectPicker } from "rsuite";
import useFormValidation from "../../validations/formValidations";
import validateCustomer from "../../validations/validateCustomer";
import isEmpty from "../../validations/isEmpty";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editCustomer } from "../../actions/customerActions";
import { Loader } from "rsuite";
import countryList from "../../components/common/countryList";
import {room_type} from '../../helpers/enum';
import {useSelector} from 'react-redux'

class EditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }
    close() {
        this.setState({ show: false });
    }
    open() {
        this.setState({ show: true });
    }

    render() {
        const { backdrop, show } = this.state;
        const { errorReducer, editCustomer, auth, datas,statuses,rooms } = this.props;
        return (
            <span>
                {/* <Button onClick={this.open} appearance="primary" loading={false}>
            <Icon icon="plus-circle" style={{fontSize:18}}  /> Add User
        </Button> */}
                {React.cloneElement(this.props.children, {
                    key: 0,
                    onClick: e => this.open()
                })}
                <Modal backdrop={true} show={show} onHide={this.close}>
                    <Modal.Header>
                        <Modal.Title>Edit Customer Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formed
                            errorReducer={errorReducer}
                            close={this.close}
                            auth={auth}
                            datas={datas}
                            editCustomer={editCustomer}
                            statuses={statuses}
                            rooms={rooms}
                            settings={this.props.settings}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </span>
        );
    }
}

export const Formed = ({ errorReducer, editCustomer,statuses,rooms, auth,settings, datas, close }) => {
    const INITIAL_STATE = {
        email: !isEmpty(datas.email) ? datas.email : "",
        name: !isEmpty(datas.name) ? datas.name : "",
        status: !isEmpty(datas.status) ? datas.status : "",
        wifi: !isEmpty(datas.wifi) ? datas.wifi : "",
        phone: !isEmpty(datas.phone) ? datas.phone : "",
        country: !isEmpty(datas.country) ? datas.country : "",
        checkedInDate: !isEmpty(datas.checkedInDate) ? datas.checkedInDate : "",
        checkedOutDate: !isEmpty(datas.checkedOutDate) ? datas.checkedOutDate : "",
        checkedStatus: !isEmpty(datas.checkedStatus) ? datas.checkedStatus : "",
        roomType: !isEmpty(datas.roomType) ? datas.roomType : "",
        checkedStatus: !isEmpty(datas.checkedStatus) ? datas.checkedStatus : "",
    };
    const data = [
        { label: room_type.in, value: room_type.in, name: "checkedStatus" },
        { label: room_type.out, value: room_type.out, name: "checkedStatus" },
        // { label: "Partial Paid", value: "partial_paid", name: "status" }
    ];
    const statusOptions = !statuses?[]:statuses.map(item=>{
        return{
            label: item.name, value: item.id, name: "status" 
        }
    });
    const roomOptions = !rooms?[]:rooms.map(item=>{
        return{
            label: item.type, value: item.type, name: "roomType" 
        }
    });
    const {
        handleSubmit,
        handleChange2,
        handleSelect,
        handleBlur,
        handleDateChange,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, validateCustomer);
    const [errs, setErrs] = React.useState({});

    if (isSubmitting) {
        editCustomer(datas.id, values);
        close();
    }
    // const {auth}=props;
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);
    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };
    const Arrays2 = (items,name)=>{
        let arrayed=[];
        arrayed = !!items && items.map(item=>{
            return {label:item,value:item,name}
        })
        return arrayed;
    }
    const wifiOption = (items,name)=>{
        let arrayed=[];
        arrayed = !!items && items.map(item=>{
            return {label:item.wifi,value:item,name}
        })
        return arrayed;
    }
    return (
        <Form>
            <FormGroup className={getError("name")}>
                <ControlLabel>Name</ControlLabel>
                <FormControl
                    name="name"
                    type="text"
                    value={values.name}
                    errorMessage={getErrorMessage("name")}
                    onChange={handleChange2}
                />
                <HelpBlock tooltip>Required</HelpBlock>
            </FormGroup>
            <FormGroup className={getError("email")}>
                <ControlLabel>Email</ControlLabel>
                <FormControl
                    name="email"
                    type="email"
                    errorMessage={getErrorMessage("email")}
                    value={values.email}
                    onChange={handleChange2}
                />
                <HelpBlock tooltip>Required</HelpBlock>
            </FormGroup>
            <FormGroup className={getError("phone")}>
                <ControlLabel>Phone</ControlLabel>
                <FormControl
                    name="phone"
                    type="number"
                    errorMessage={getErrorMessage("phone")}
                    value={values.phone}
                    onChange={handleChange2}
                />
                <HelpBlock tooltip>Required</HelpBlock>
            </FormGroup>
            <FormGroup className={getError("checkedStatus")}>
                <ControlLabel>Checked Status</ControlLabel>
                <SelectPicker
                    data={data}
                    name="checkedStatus"
                    errorMessage={getErrorMessage("checkedStatus")}
                    style={{ width: 250 }}
                    value={values.checkedStatus || errs.checkedStatus}
                    onSelect={handleSelect}
                />
            </FormGroup>
            {
                settings && settings.wifi && (
                    <FormGroup className={getError("wifi")}>
                                <ControlLabel>Wifi</ControlLabel>
                                <SelectPicker
                                    data={wifiOption(settings.wifi,'wifi')}
                                    name="wifi"
                                    onBlur={e => handleBlur(e)}
                                    errorMessage={getErrorMessage("wifi")}
                                    style={{ width: 250 }}
                                    value={values.wifi || errs.wifi}
                                    onSelect={handleSelect}
                                />
                    </FormGroup>
                )
            }
            {
                settings && settings.status && (
                    <FormGroup className={getError("status")}>
                        <ControlLabel>Status</ControlLabel>
                        <SelectPicker
                            data={Arrays2(settings.status)}
                            name="status"
                            errorMessage={getErrorMessage("status")}
                            style={{ width: 250 }}
                            value={values.status || errs.status}
                            onSelect={handleSelect}
                        />
                    </FormGroup>
                )
            }

            <FormGroup className={getError("status")}>
                <ControlLabel>Room Type</ControlLabel>
                <SelectPicker
                    data={roomOptions}
                    name="roomType"
                    onBlur={e => handleBlur(e)}
                    errorMessage={getErrorMessage("roomType")}
                    style={{ width: 250 }}
                    value={values.roomType || errs.roomType}
                    onSelect={handleSelect}
                />
            </FormGroup>
            <FormGroup className={getError("status")}>
                <ControlLabel>Country</ControlLabel>
                <SelectPicker
                    data={countryList}
                    name="country"
                    onBlur={e => handleBlur(e)}
                    errorMessage={getErrorMessage("country")}
                    style={{ width: 250 }}
                    value={values.country || errs.country}
                    onSelect={handleSelect}
                />
            </FormGroup>
            <FormGroup className={getError("checkedInDate")}>
                <ControlLabel>Checked In date</ControlLabel>
                <DatePicker
                    name="checkedInDate"
                    onBlur={e => handleBlur(e)}
                    errorMessage={getErrorMessage("checkedInDate")}
                    style={{ width: 250 }}
                    value={values.checkedInDate || errs.checkedInDate}
                    onChange={(value)=>handleDateChange(value,'checkedInDate')}
                />
            </FormGroup>
            <FormGroup className={getError("checkedOutDate")}>
                <ControlLabel>Checked Out</ControlLabel>
                <DatePicker
                    name="checkedOutDate"
                    onBlur={e => handleBlur(e)}
                    errorMessage={getErrorMessage("checkedOutDate")}
                    style={{ width: 250 }}
                    value={values.checkedOutDate || errs.checkedOutDate}
                    onChange={(value)=>handleDateChange(value,'checkedOutDate')}
                />
            </FormGroup>
            <FormGroup>
                <ButtonToolbar>
                    <Button
                        appearance="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                    >
                        {isSubmitting ? <Loader /> : null}
                        Submit
                    </Button>
                </ButtonToolbar>
            </FormGroup>
        </Form>
    );
};

EditForm.propTypes = {
    editCustomer: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errorReducer: state.errorReducer,
    statuses:state.statusReducer.statuses,
    settings:state.settingReducer.settings,
    rooms:state.roomReducer.rooms,
});

export default connect(mapStateToProps, { editCustomer })(EditForm);
