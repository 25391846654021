import React, {useEffect,useState} from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Input,
} from "rsuite";
import { Button, Modal, Loader, ButtonToolbar } from "rsuite";
import useFormValidation from "../../../validations/formValidations";
import {validateAltitude} from "../../../validations/validateItinerary";
import isEmpty from "../../../validations/isEmpty";
import {addTrekAltitude} from '../../../actions/trekActions'
import { useDispatch } from "react-redux";

function ItineraryForm(props) {
    const { errorReducer, editCustomer, auth, datas={},isEdit=false,close,trek } = props;
    const dispatch = useDispatch()
    const INITIAL_STATE = {
        number: !isEmpty(datas?.number) ? datas.number : "",
        altitude: !isEmpty(datas?.altitude) ? datas.altitude : "",
        name: !isEmpty(datas?.name) ? datas.name : "",
        latitude: !isEmpty(datas?.latitude) ? datas.latitude : "",
        longitude: !isEmpty(datas?.longitude) ? datas.longitude : "",
    };
  const {
    handleSubmit,
    handleChange2,
    handleChange3,
    handleSelect,
    checkErrors,
    handleBlur,
    handleDateChange,
    values,
    errors,
    isSubmitting,
    clearValues,
  } = useFormValidation(INITIAL_STATE, validateAltitude);
  const handleSubmits = (e) => {
    e.preventDefault();
    const {isValid} =  checkErrors();
    if(isValid){
        if(isEmpty(errors)){
            if(isEdit){
                // dispatch(editTeam(values.id,values));
                // clearValues();
                // onEdit({})
                dispatch(addTrekAltitude(trek.id, {...values,id:datas.id}));
                clearValues();
              close();
                return false;
            }
            dispatch(addTrekAltitude(trek.id, values));
            clearValues();
            close();
        }
    }
}
  const [errs, setErrs] = React.useState({});
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);
    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };
  return (
    <Form>
      <FormGroup className={getError("number")}>
        <ControlLabel>Number</ControlLabel>
        <FormControl
          name="number"
          type="number"
          value={values.number}
          errorMessage={getErrorMessage("number")}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("altitude")}>
        <ControlLabel>Altitude</ControlLabel>
        <FormControl
          name="altitude"
          type="number"
          errorMessage={getErrorMessage("altitude")}
          value={values.altitude}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("name")}>
        <ControlLabel>Name</ControlLabel>
        <FormControl
          name="name"
          type="text"
          errorMessage={getErrorMessage("name")}
          value={values.name}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("latitude")}>
        <ControlLabel>Latitude</ControlLabel>
        <FormControl
          name="latitude"
          type="number"
          errorMessage={getErrorMessage("latitude")}
          value={values.latitude}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("longitude")}>
        <ControlLabel>Longitude</ControlLabel>
        <FormControl
          name="longitude"
          type="text"
          errorMessage={getErrorMessage("longitude")}
          value={values.longitude}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ButtonToolbar>
          <Button
            appearance="primary"
            disabled={isSubmitting}
            onClick={handleSubmits}
          >
            {isSubmitting ? <Loader /> : null}
            Submit
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
}

export default ItineraryForm;
