import React, {useEffect,useState} from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Input,
} from "rsuite";
import { Button, Modal, Loader, ButtonToolbar } from "rsuite";
import useFormValidation from "../../../../../validations/formValidations";
import validateitinerary from "../../../../../validations/validateItinerary";
import isEmpty from "../../../../../validations/isEmpty";
import Tiny from '../../../../../components/common/TinyEmc';
import {addPackageItinerary} from '../../../../../actions/tourActions'
import { useDispatch } from "react-redux";

function ItineraryForm(props) {
    const { errorReducer, editCustomer, auth, datas={},isEdit=false,close,tourPackage } = props;
    const dispatch = useDispatch()
    const INITIAL_STATE = {
        day: !isEmpty(datas?.day) ? datas.day : "",
        details: !isEmpty(datas?.details) ? datas.details : "",
        title: !isEmpty(datas?.title) ? datas.title : "",
    };
  const {
    handleSubmit,
    handleChange2,
    handleChange3,
    handleSelect,
    checkErrors,
    handleBlur,
    handleDateChange,
    values,
    errors,
    isSubmitting,
    clearValues,
  } = useFormValidation(INITIAL_STATE, validateitinerary);
  const handleSubmits = (e) => {
    e.preventDefault();
    const {isValid} =  checkErrors();
    if(isValid){
        console.log(values,isEdit)
        if(isEmpty(errors)){
            if(isEdit){
                // dispatch(editTeam(values.id,values));
                // clearValues();
                // onEdit({})
                dispatch(addPackageItinerary({...values,id:datas.id,packageId:tourPackage.id}));
                clearValues();
              close();
                return false;
            }
            dispatch(addPackageItinerary({...values,packageId:tourPackage.id}));
            clearValues();
            close();
        }
    }
}
  const [errs, setErrs] = React.useState({});
    useEffect(() => {
        if (errorReducer) {
            console.log(errorReducer);
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);
    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };
  return (
    <Form>
      <FormGroup className={getError("day")}>
        <ControlLabel>Day</ControlLabel>
        <FormControl
          name="day"
          type="text"
          value={values.day}
          errorMessage={getErrorMessage("day")}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("title")}>
        <ControlLabel>Title</ControlLabel>
        <FormControl
          name="title"
          type="text"
          errorMessage={getErrorMessage("title")}
          value={values.title}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("details")}>
        <ControlLabel>Details</ControlLabel>
        <Tiny 
        onChange={handleChange3}
        value={values.details}
        name="details"
        errorMessage={getErrorMessage("details")}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
     
      <FormGroup>
        <ButtonToolbar>
          <Button
            appearance="primary"
            disabled={isSubmitting}
            onClick={handleSubmits}
          >
            {isSubmitting ? <Loader /> : null}
            Submit
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
}

export default ItineraryForm;
