import {
	GET_CONTACTS,
	CONTACTS_LOADING,
	DELETE_CONTACT,
} from "../actions/types";

const initialState = {
	contacts: [],
	page: {},
	loading: false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case CONTACTS_LOADING:
			return {
				...state,
				loading: true
			};
		case GET_CONTACTS:
			return {
				...state,
				contacts: action.payload,
				page:action.page,
				loading: false
			};
		case DELETE_CONTACT:
			return {
				...state,
				contacts: state.contacts.filter(
					contact => contact.id !== action.payload
				)
			};
		default:
			return state;
	}
}
