import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCustomers, deleteCustomer,editCustomerStatus } from '../../actions/customerActions';
import { Table,Checkbox,Dropdown,Popover,Whisper,Icon,IconButton,Divider } from 'rsuite';
import Confirm from '../../components/common/Confirm'
import { Loader } from 'rsuite';
import isEmpty from '../../validations/isEmpty';
import Moment from 'react-moment';
import EditFormModal from './EditFormModal'
import {Link} from 'react-router-dom'
import openSocket from 'socket.io-client';
import {room_type} from '../../helpers/enum';
import { SelectPicker } from "rsuite";
import CustomPagination from '../../components/common/CustomPagination'

const { Column, HeaderCell, Cell, Pagination } = Table;
const NameCell = ({ rowData, dataKey, ...props }) => {
  const speaker = (
    <Popover title="Description">
      <p>
        <b>Name:</b> {`${rowData.name}`}{' '}
      </p>
      <p>
        <b>Added By:</b> {rowData.addedBy && rowData.addedBy.name}{' '}
      </p>
      <p>
        <b>Status:</b> {rowData.status}{' '}
      </p>
      <p>
        <b>Edited By:</b> {rowData.editedBy && rowData.editedBy.name}{' '}
      </p>
    </Popover>
  );
  return (
    <Cell {...props}>
      <Whisper placement="top" speaker={speaker}>
        <Link to={"/customer/"+rowData.id}>{rowData[dataKey].toLocaleString()}</Link>
      </Whisper>
    </Cell>
  );
};


const DateCell = ({ rowData, dataKey, ...props }) => {
  const speaker = (
    <Popover title="Time">
      <p>
        <b>Created at:</b> {<Moment interval={30000} format="D MMM YYYY" withTitle>{rowData.createdAt}</Moment>}{' '}
        <span style={{display:'block'}}>{<Moment interval={30000} format="hh:mm A">{rowData.createdAt}</Moment>}</span>
      </p>
      <p>
        <b>Edited By:</b> {rowData.editedBy && rowData.editedBy.name}{' '}
      </p>
      <p>
        <b>Edited at:</b> {<Moment interval={30000} format="D MMM YYYY" withTitle>{rowData.updatedAt}</Moment>}{' '}
        <span style={{display:'block'}}>{<Moment interval={30000} format="hh:mm A">{rowData.updatedAt}</Moment>}</span>
      </p>
    </Popover>
  );
  return (
    <Cell {...props}>
      <Whisper placement="top" speaker={speaker}>
          <a>
            <Moment  fromNow ago>{rowData[dataKey]}</Moment> ago
          </a>
      </Whisper>
    </Cell>
  );
};

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: '46px' }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some(item => item === rowData[dataKey])}
      />
    </div>
  </Cell>
);

const Menu = ({ onSelect,id,delCustomer,currentUser }) => (
  <Dropdown.Menu onSelect={onSelect} placement="bottomEnd" >
    <Confirm func={()=>{
        delCustomer(id)
      }
    }>
      <Dropdown.Item eventKey={3} >Delete Customer</Dropdown.Item>
    </Confirm>
    {/* <Dropdown.Item eventKey={4}>Export PDF</Dropdown.Item>
    <Dropdown.Item eventKey={5}>Export HTML</Dropdown.Item>
    <Dropdown.Item eventKey={6}>Settings</Dropdown.Item>
    <Dropdown.Item eventKey={7}>About</Dropdown.Item> */}
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect,delCustomer,id,currentUser, ...rest }) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} delCustomer={delCustomer} currentUser={currentUser} id={id} />
  </Popover>
);

let tableBody;

class CustomWhisper extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectMenu = this.handleSelectMenu.bind(this);
  }
  handleSelectMenu(eventKey, event) {
    this.trigger.hide();
  }
  render() {
    return (
      <Whisper
        placement="autoVerticalStart"
        trigger="click"
        triggerRef={ref => {
          this.trigger = ref;
        }}
        container={() => {
          return tableBody;
        }}
        speaker={<MenuPopover delCustomer={this.props.delCustomer} 
                  currentUser={this.props.currentUser}
                  id={this.props.id} onSelect={this.handleSelectMenu} />}
      >
        {this.props.children}
      </Whisper>
    );
  }
}

const ActionCell = ({ rowData,delCustomer,currentUser, dataKey, ...props }) => {
  function handleAction() {
    alert(`id:${rowData}`);
  }
  return (
    <Cell {...props} className="link-group">
      <EditFormModal datas={rowData}>
          <Icon icon="edit2" />
      </EditFormModal>
      
      <Divider vertical />
      <CustomWhisper delCustomer={delCustomer} currentUser={currentUser} id={rowData.id} >
        <IconButton appearance="subtle" icon={<Icon icon="more" />} />
      </CustomWhisper>
    </Cell>
  );
};

const Speaker = ({ id,editStatus,status,statuses, ...props }) => {
  const statusOptions = !statuses?[]:statuses.map(item=>{
    return{
        label: item, value: item, name: "status" 
    }
});
  return (
    <Popover title="Change Status" {...props}>
      <SelectPicker
          data={statusOptions}
          name="status"
          // errorMessage={getErrorMessage("status")}
          style={{ width: 250 }}
          value={status}
          onSelect={(name)=>editStatus(id,name)}
      />
    </Popover>
  );
};

class CustomersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedKeys: [],
      data:'',
      // pageSize: 10,
      customers:''
    };
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    // this.handleChangePage = this.handleChangePage.bind(this);
    // this.handleChangeLength = this.handleChangeLength.bind(this);
    this.editStatus=this.editStatus.bind(this);
    this.updateCustomer= this.updateCustomer.bind(this)
  }
  handleCheckAll(value, checked) {
    const { data } = this.state;
    const checkedKeys = checked ? data.map(item => item.id) : [];
    this.setState({
      checkedKeys
    });
  }
  handleCheck(value, checked) {
    const { checkedKeys } = this.state;
    const nextCheckedKeys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter(item => item !== value);

    this.setState({
      checkedKeys: nextCheckedKeys
    });
  }
  componentDidMount() {
    this.props.getCustomers();
    // const socket = openSocket('http://localhost:4000');
    // socket.on('customers', data => {
    //   if (data.action === 'update') {
    //       this.updateCustomer(data.customer[0]);
    //   }
    //   // if (data.action === 'create') {
    //   //   this.addPost(data.post);
    //   // } else if (data.action === 'update') {
    //   //   this.updatePost(data.post);
    //   // } else if (data.action === 'delete') {
    //   //   this.loadPosts();
    //   // }
    // });
  }
  updateCustomer(customer){
    this.setState(prevState => {
      const updatedCustomers = [...prevState.customers];
      const cus = updatedCustomers.map(cust=>cust.id === customer.id?
        { ...cust, 
            status: customer.status
        }
        :cust)
      // const updatedCustomerIndex = updatedCustomers.findIndex(p => p.id === customer.id);
      // if (updatedCustomerIndex > -1) {
      //   updatedCustomers[updatedCustomerIndex] = customer;
      // }
      return {
        customers: cus
      };
    });
  }
componentDidUpdate(prevProps, prevState) {
  const{customers} = this.props.customerReducer;
  if(!isEmpty(customers)){
    // if(JSON.stringify(customers) !== JSON.stringify(prevProps.customerReducer.customers) || isEmpty(this.state.customers) ){
    if(JSON.stringify(customers) !== JSON.stringify(prevProps.customerReducer.customers) ){
      this.setState({customers:customers})
    }
  }
}

  delCustomer(id){
    this.props.deleteCustomer(id)
  }
  editStatus(id,status){
    this.props.editCustomerStatus(id,{status})
  }
  renderCustomerTable(){
    const { data, checkedKeys } = this.state;
    const {customers,loading}=this.props.customerReducer;
    const {user}=this.props.auth;
    let checked = false;
    let indeterminate = false;

    if(!isEmpty(data)){
      if (checkedKeys.length === data.length) {
        checked = true;
      } else if (checkedKeys.length === 0) {
        checked = false;
      } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
        indeterminate = true;
      }

    }
    
    const getStatus = (id)=>{
      const {statuses} = this.props;
      let found = statuses && statuses.find(item=>item.id ===id);
      if(found){
        return found.name;
      }
      return null
      // return found.name
    }
    const {settings} = this.props;
    return(
      <div>
        {
          loading ||customers ===null?
          <Loader backdrop content="loading..." vertical />
          :
          <Table
          height={420}
          // data={this.state.customers}
          data={customers}
          id="table"
          bodyRef={ref => {
            tableBody = ref;
          }}
        >
        
          <Column width={50} align="center">
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ lineHeight: '40px' }}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={this.handleCheckAll}
                />
              </div>
            </HeaderCell>
            <CheckCell
              dataKey="id"
              checkedKeys={checkedKeys}
              onChange={this.handleCheck}
            />
          </Column>

          <Column width={160}>
            <HeaderCell>Name</HeaderCell>
            <NameCell dataKey="name" />
          </Column>

          <Column width={240}>
            <HeaderCell>Email</HeaderCell>
            <Cell>
              {rowData => (
                <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
              )}
            </Cell>
          </Column>
          <Column width={180}>
            <HeaderCell>Phone</HeaderCell>
            <Cell>
              {rowData => (
                <span>{rowData.phone}</span>
              )}
            </Cell>
          </Column>
          <Column width={180}>
            <HeaderCell>Country</HeaderCell>
            <Cell>
              {rowData => (
                <span>{rowData.country}</span>
              )}
            </Cell>
          </Column>
          <Column width={180}>
            <HeaderCell>Checked Status</HeaderCell>
            <Cell>
              {rowData => (
                <span
                  // onClick={()=>this.editStatus(rowData.id,rowData.status==='paid'?'unpaid':'paid')}
                  className={`chips  + ${rowData.checkedStatus===room_type.in?'chips-green':'chips-red'}` }>
                  {rowData.checkedStatus}
                </span>
              )}
            </Cell>
          </Column>
          <Column width={180}>
            <HeaderCell>Checked In Date</HeaderCell>
            <Cell>
              {rowData => (
                <span><Moment format="D MMM YYYY" withTitle>{rowData.checkedInDate}</Moment></span>
              )}
            </Cell>
          </Column>
          <Column width={180}>
            <HeaderCell>Checked Out Date</HeaderCell>
            <Cell>
              {rowData => (
                <span><Moment format="D MMM YYYY" withTitle>{rowData.checkedOutDate}</Moment></span>
              )}
            </Cell>
          </Column>
          <Column width={150}>
            <HeaderCell>Status</HeaderCell>
            <Cell>
              {rowData => (
                
                <Whisper
                  trigger="click"
                  placement={'bottom'}
                  speaker={ <Speaker 
                              editStatus={this.editStatus}
                              id={rowData.id}
                              status={rowData.status}
                              statuses={settings && settings.status && settings.status}
                          />}
                >
                  <span
                    // onClick={()=>this.editStatus(rowData.id,rowData.status==='paid'?'unpaid':'paid')}
                    // className={`chips  + ${rowData.status==='paid'?'chips-green':'chips-red'}` }
                    className={`chips chips-green` }>
                    {/* {rowData.status} */}
                    {rowData.status}
                  </span>
                </Whisper>
              )}
            </Cell>
          </Column>
          <Column width={150}>
            <HeaderCell>Created Date</HeaderCell>
            <DateCell dataKey="createdAt" />
            {/* <Cell>
              {rowData => (
                <span>
                  <span style={{display:'block'}}><Moment  fromNow ago>{rowData.createdAt}</Moment></span>
                  <span><Moment format="D MMM YYYY" withTitle>{rowData.createdAt}</Moment></span>
                </span>
              )}
            </Cell> */}
          </Column>
          <Column width={200}>
            <HeaderCell>Action</HeaderCell>
            <ActionCell dataKey="id" currentUser={user} delCustomer={this.delCustomer.bind(this)} />
          </Column>
          {/* <Pagination
              prev
              last
              next
              first
              size="lg"
              pages={10}
              activePage={this.state.activePage}
              onSelect={this.handleSelect}
            /> */}
        </Table>
          
        }
      </div>
    )

  }
  render() {
    const {page,customers}=this.props.customerReducer;
    return (
      <div>
      <div className="users-heading">
        <h2 className="primary-heading">Customers</h2>
      </div>
      {this.renderCustomerTable()}
      {
        isEmpty(customers)?null:
        <CustomPagination 
            page={page}
            getFunc={(pages)=>this.props.getCustomers(pages)}
        />
      }
      
    </div>
    )
  }
}

CustomersTable.propTypes ={
  getCustomers: PropTypes.func.isRequired,
  deleteCustomer:PropTypes.func.isRequired,
  customerReducer:PropTypes.object.isRequired,
  editCustomerStatus:PropTypes.func.isRequired,
  auth:PropTypes.object.isRequired,
  errorReducer: PropTypes.object.isRequired 
}

const mapStateToProps = (state) => ({
  customerReducer:state.customerReducer,
  auth:state.auth,
  errorReducer:state.errorReducer,
  statuses:state.statusReducer.statuses,
  settings:state.settingReducer.settings,
});

// const mapDispatchToProps = function (dispatch) {
//   return {
//     editCustomerStatuses: (customer) => dispatch(actionCreator.addUserRequest(user)),
//     }
// }


export default connect(mapStateToProps, {getCustomers,deleteCustomer,editCustomerStatus})(CustomersTable);
