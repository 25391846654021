import React, { Component } from "react";
import { Uploader, Icon, Button } from "rsuite";

export class UploadImages extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             image:[],
             map:[]
        }
        this.onUpload = this.onUpload.bind(this);
    }
    onUpload(){
        const {UploadImages,id} =this.props;
        const {image} = this.state;
        const data =new FormData();
        image.map(item=>{
            data.append('imag',item.blobFile)
        })
        data.append('id',id);
        // debugger
        UploadImages(id,data);
        this.setState({image:[]})
    }
    onMapUpload(){
      const {UploadImages,id} =this.props;
      const {map} = this.state;
      const data =new FormData();
      map.map(item=>{
          data.append('image',item.blobFile)
      })
      data.append('id',id);
      // debugger
      UploadImages(id,data);
      this.setState({map:[]})
  }
  render() {
    return (
      <>
      {/* <div className="single-upload">
          <div className="single-upload-header">
              <h3>Upload Trek Images</h3>
          </div>
          <div className="single-upload-body">
            <Uploader
                multiple={true}
                autoUpload={false}
                listType="picture"
                onChange={image => {
                    console.log(image);
                    this.setState({ image: image });
                }}
                >
                    <button>
                        <Icon icon="camera-retro" size="lg" />
                    </button>
            </Uploader>
          </div>
          <div className="single-upload-footer">
            <Button 
                    onClick={() => this.onUpload()} 
                    appearance="primary"
                >
                Upload
            </Button>
          </div>
      </div> */}
      <div className="single-upload">
          <div className="single-upload-header">
              <h3>Upload Map Image</h3>
          </div>
          <div className="single-upload-body">
            <Uploader
                multiple={false}
                autoUpload={false}
                listType="picture"
                onChange={image => {
                    this.setState({ map: image });
                }}
                >
                    <button>
                        <Icon icon="camera-retro" size="lg" />
                    </button>
            </Uploader>
          </div>
          
          <div className="single-upload-bottom">
            <div className="single-upload-title">Map Image</div>
            <div className="atimages">
              <div className="atimages-block">
                <div className="atimages-image">
                  {this.props.image && <img src={this.props.image} />}
                </div>
              </div>
            </div>
          </div>
          <div className="single-upload-footer">
            <Button 
                    onClick={() => this.onMapUpload()} 
                    appearance="primary"
                >
                Upload Map
            </Button>
          </div>
      </div>
      </>
      
    );
  }
}

export default UploadImages;