import { GET_ERRORS,CLEAR_ERRORS } from '../actions/types';
import { Alert } from 'rsuite';

const initialState ={
    
}

export default function(state= initialState,action){
    switch(action.type){
        case GET_ERRORS:
            if(typeof action.payload.error !=='object' && typeof action.payload.error !=='string'){
                Alert.error(action.payload.error, 5000)
            }
            return action.payload;
        case CLEAR_ERRORS:
            return{};
        default:
        return state;
    }
}