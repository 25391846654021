import React, { Component } from "react";
import { Button, Modal, Icon, Alert } from "rsuite";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    Input
} from "rsuite";
import { Uploader } from 'rsuite';
import isEmpty from "../../validations/isEmpty";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { uploadUserImage } from "../../actions/userActions";
import { Loader } from "rsuite";


function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }
  
  const styles = {
    width: 150,
    height: 150
  };

export class UpdateImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading:false,
            image:[]
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.onUpload=this.onUpload.bind(this)
    }
    close() {
        this.setState({ show: false });
    }
    open() {
        this.setState({ show: true });
    }
    onUpload(){
        let formData = new FormData();
        const {image}=this.state;
        
        const asd = image[image.length -1].blobFile;
        formData.append('photo',asd)

        this.props.uploadUserImage(this.props.id,formData);
        this.setState({image:[],show:false})
    }
    render() {
        const { backdrop, show,uploading,image } = this.state;
        const {id}=this.props;
        return (
            <span>
                {React.cloneElement(this.props.children, {
                    key: 0,
                    onClick: e => this.open()
                })}
                <Modal backdrop={true} show={show} siz="sm" onHide={this.close}>
                    <Modal.Header>
                        <Modal.Title>Edit User Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="users-upload">
                            <Uploader
                                autoUpload={false}
                                fileListVisible={false}
                                listType="picture"
                                defaultFileList={image}
                                onChange={(image)=>{
                                    this.setState({image:image});
                                }}
                                >
                                <button style={styles}>
                                    {uploading && <Loader backdrop center />}
                                    {!isEmpty(image) ? (
                                    <img src={URL.createObjectURL(image[image.length - 1].blobFile)} width="100%" height="100%" />
                                    ) : (
                                    <Icon icon="avatar" size="5x" />
                                    )}
                                </button>
                            </Uploader>
                            
                        </div>
                        
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button appearance="primary" onClick={()=>this.onUpload()}>Upload</Button>
                        <Button onClick={this.close} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </span>
        );
    }
}

UpdateImage.propTypes ={
    uploadUserImage: PropTypes.func.isRequired,
    auth:PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired 
  }
  
  const mapStateToProps = (state) => ({
    auth:state.auth,
    errorReducer:state.errorReducer
  })
  
  
export default connect(mapStateToProps, {uploadUserImage})(UpdateImage);

