import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { getBookingQR } from "../../actions/bookingActions";
import { useSelector, useDispatch } from "react-redux";
import "./bookings.styles.scss";
import isEmpty from "../../validations/isEmpty";
import dayjs from "dayjs";
import Pdf from "react-to-pdf";
import { Table,Button,Icon} from "rsuite";
import { saveAs } from 'file-saver'

function Booking() {
  let params = useParams();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { bookingqr, loading } = useSelector((state) => state.bookingReducer);
  const booking = bookingqr
    useEffect(() => {
        dispatch(getBookingQR(params.id));
    }, []);
    console.log({bookingqr});
    const options = {
        // orientation: 'landscape',
        // unit: 'in',
        // format: [4,2]
    };
    const onDownloadImage = () => {
        saveAs(booking.passport_img, booking.passport_no+'passport.jpg')
    }
  return (
    <>
      <div className="booking">
        <div className="booking-details">
          <div 
            onClick={()=>onDownloadImage()}
            className="booking-details__image">
            <img src={booking.passport_img} alt={booking.passport_no} />
          </div>
          <div className="booking-details__extra">
            <div className="booking-row">
              <Name
                name="Name"
                value={booking.first_name + " " + booking.last_name}
              />
              <Name name="Email" value={booking.email} />
              <Name name="Phone" value={booking.phone} />
              <Name name="Trip Name" value={booking.trip_name} />
              <Name
                name="Trip Date"
                value={dayjs(booking.trip_date).format("YYYY-MM-DD")}
              />
              <Name name="No. of people" value={booking.no_people} />
              <Name name="Mailing Address" value={booking.mailing_address} />
              <Name name="City" value={booking.city} />
              <Name name="Country" value={booking.country} />
              <Name name="Passport Number" value={booking.passport_no} />
              <Name
                name="Passport Expiration Date"
                value={booking.expiration_date}
              />
            </div>
            <div className="booking-link">
              <span>Go To </span>
              <Link to={"/treks/id/" + booking.trekId}>
                {booking.trip_name}
              </Link>
            </div>
          </div>
        </div>
        <div className="booking-flex">
          <div className="booking-emergency">
            <div className="booking-emergency__card">
              <h3 className="booking-emergency__title">Emergency Details</h3>
              <div className="booking-emergency__details">
                <Emergencydet name="Name" value={booking.emergency_name} />
                <Emergencydet name="Email" value={booking.emergency_email} />
                <Emergencydet name="Phone" value={booking.emergency_phone} />
                <Emergencydet
                  name="Relationship"
                  value={booking.emergency_relationship}
                />
              </div>
            </div>
          </div>
          {/* <div className='booking-additionsl-details'>

                </div> */}
          {isEmpty(booking.additional_details) ? null : (
            <div className="booking-aditional">
              <h3>Additional Details</h3>
              <div className="booking-aditional__container">
                {booking.additional_details.map(Additional)}
              </div>
            </div>
          )}
        </div>
      </div>
        <Pdf 
            options={options} x={.5} y={.5} scale={1}
            targetRef={ref} filename={booking?.passport_no ? `${booking?.passport_no}.pdf` : "trek-user.pdf"}>
            {({ toPdf }) =><Button bg="primary" 
            // type="button-outline" 
            size="small" onClick={toPdf}  >Download <Icon icon="download" size={14} /></Button>}
        </Pdf>
      <div ref={ref} className="cards-preview">
        <div className="cards-preview__body">
          <div className="cards-preview__header">
            <span>User information</span>
            <span>Alpwander Adventures</span>
          </div>
          <div className="cards-preview__content">
            <div className="cards-preview__qrs">
              <div className="cards-preview__card">
                <div className="cards-preview__card-image">
                  <div className="cards-preview__card-images">
                  <img src={booking?.customer_img} alt="Card Image" />
                  <img src={booking?.passport_img} alt="Card Image" />
                  </div>
                  
                  <div className="cards-preview__card-content">
                    {/* <div className="cards-preview__card-by">
                      <div>TIMS</div>
                      <span>SAARC</span>
                    </div> */}
                    <div className="cards-preview__card-to">
                      <div className="cards-preview__card-to-name">
                        {booking?.first_name} {booking?.last_name}
                      </div>
                      <div className="cards-preview__card-to-number">
                        {booking?.passport_no}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="cards-preview__qr">
                <div className="cards-preview__qr-image">
                  
                </div>
              </div> */}
            </div>
            <div className="cards-preview__details">
              <div className="cards-preview__detail">
                <div className="cards-preview__detail-header">
                  <div className="cards-preview__detail-title">
                    <span>Trek Details</span>
                  </div>
                </div>
                <div className="cards-preview__detail-body">
                  <div className="cards-preview__detail-row">
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Trekking Area:
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.trip_name}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Trek date:
                      </div>
                      <div className="cards-preview__detail-value">
                        {dayjs(booking?.trip_date).format("YYYY-MM-DD")}
                      </div>
                    </div>
                    {/* <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Trek Exit date:{" "}
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.TrekExitDate}
                      </div>
                    </div> */}
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Package Type:
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.package_type}
                      </div>
                    </div>
                  </div>
                  <div className="cards-preview__detail-row-2">
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Number of People Travelling
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.no_people}
                      </div>
                    </div>
                  </div>
                  <div className="cards-preview__detail-row-2">
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Total Amount
                      </div>
                      <div className="cards-preview__detail-value">
                        ${booking?.total_price}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cards-preview__detail">
                <div className="cards-preview__detail-header">
                  <div className="cards-preview__detail-title">
                    <span>User Details</span>
                  </div>
                </div>
                <div className="cards-preview__detail-body">
                  <div className="cards-preview__detail-row">
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Full Name:
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.first_name} {booking?.last_name}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Phone Number:
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.phone}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">Email: </div>
                      <div className="cards-preview__detail-value">
                        {booking?.email}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">Country</div>
                      <div className="cards-preview__detail-value">
                        {booking?.country} ({booking?.city})
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Mailing Address:
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.mailing_address}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Passport:
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.passport_no} ({dayjs(booking?.expiration_date).format("YYYY-MM-DD")})
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cards-preview__detail">
                <div className="cards-preview__detail-header">
                  <div className="cards-preview__detail-title">
                    <span>Emergency Details</span>
                  </div>
                </div>
                <div className="cards-preview__detail-body">
                  <div className="cards-preview__detail-row">
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Full Name:
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.emergency_name}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Phone number:
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.emergency_phone}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">Email: </div>
                      <div className="cards-preview__detail-value">
                        {booking?.emergency_email}
                      </div>
                    </div>
                    <div className="cards-preview__detail-col">
                      <div className="cards-preview__detail-label">
                        Relationship
                      </div>
                      <div className="cards-preview__detail-value">
                        {booking?.emergency_relationship}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isEmpty(booking.additional_details) ? null : (
                <div className="cards-preview__detail">
                  <div className="cards-preview__detail-header">
                    <div className="cards-preview__detail-title">
                      <span>Additional People Details</span>
                    </div>
                  </div>
                  {booking.additional_details &&
                    booking.additional_details.map((add) => {
                      return (
                        <div className="cards-preview__detail-body cards-preview__detail-flex">
                          <div className="cards-preview__detail-passport">
                            <img src={add?.passport_img} />
                          </div>
                          <div className="cards-preview__detail-row">
                            <div className="cards-preview__detail-col">
                              <div className="cards-preview__detail-label">
                                Full Name:
                              </div>
                              <div className="cards-preview__detail-value">
                                {add?.name}
                              </div>
                            </div>
                            <div className="cards-preview__detail-col">
                              <div className="cards-preview__detail-label">
                                Passport number:
                              </div>
                              <div className="cards-preview__detail-value">
                                {add?.passport_no}
                              </div>
                            </div>
                            <div className="cards-preview__detail-col">
                              <div className="cards-preview__detail-label">
                                Email:{" "}
                              </div>
                              <div className="cards-preview__detail-value">
                                {add?.expiration_date}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Name = ({ name, value }) => {
  return (
    <div className="booking-column">
      <label>{name}</label>
      <p className="booking-value">
        <span>{value}</span>
      </p>
    </div>
  );
};

const Additional = (props) => {
  const { expiration_date } = props;
  const onDownloadImage = () => {
    console.log( props.passport_no+'passport.jpeg')
    saveAs(props.passport_img, props.passport_no+'passport.jpeg')
}
  return (
    <div className="additional-card">
      <div 
        onClick={()=>onDownloadImage()}
        className="additional-card__image">
        <img src={props.passport_img} alt={props.passport_no} />
      </div>
      <div className="additional-card__details">
        <div className="additional-card__row">
          <div className="additional-card__label">Name</div>
          <div className="additional-card__value">{props.name}</div>
        </div>
        <div className="additional-card__row">
          <div className="additional-card__label">Passport Number</div>
          <div className="additional-card__value">{props.passport_no}</div>
        </div>
        <div className="additional-card__row">
          <div className="additional-card__label">Expiration Date</div>
          <div className="additional-card__value">{props.expiration_date}</div>
        </div>
      </div>
    </div>
  );
};

const Emergencydet = ({ name, value }) => {
  return (
    <div className="booking-emergency__row">
      <div className="booking-emergency__label">
        <span>{name}</span>
      </div>
      <div className="booking-emergency__value">
        <span>{value}</span>
      </div>
    </div>
  );
};

export default Booking;
