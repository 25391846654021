import React, { Component,useEffect } from 'react'
import { Button, Modal, Icon, ButtonToolbar } from 'rsuite';
import { Form, FormGroup, FormControl, ControlLabel, HelpBlock,Input } from 'rsuite';
import { SelectPicker } from 'rsuite';
import useFormValidation from "../../validations/formValidations";
import validateUser from "../../validations/validateUser";
import isEmpty from "../../validations/isEmpty"
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader } from 'rsuite';
import {addUser} from '../../actions/userActions'

class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show: false
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
      }
      close() {
        this.setState({ show: false });
      }
      open() {
        this.setState({ show: true });
      }
      
  render() {
    const { backdrop, show } = this.state;
    const {errorReducer,addUser,auth}=this.props;
    return (
      <div className="button-group button-group__right">
        <Button onClick={this.open} appearance="primary" loading={false}>
            <Icon icon="plus-circle" style={{fontSize:18}}  /> Add User
        </Button>

        <div>
            <Modal backdrop={true} show={show} onHide={this.close}>
                <Modal.Header>
                    <Modal.Title>User Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formed errorReducer={errorReducer} close={this.close} auth={auth} addUser={addUser} />
                </Modal.Body>
            </Modal>
        </div>
      </div>
    )
  }
}


const INITIAL_STATE = {
    email: "",
    password: "",
    password2:"",
    name:"",
    user_name:'',
    phone:null,
    role:''
  };


export const Formed = ({errorReducer,addUser,close,auth})=>{
    const data =[
        {label:"Super Admin",value:'super_admin',name:'role'},
        {label:"Admin",value:'admin',name:'role'},
        {label:"Guard",value:'guard',name:'role'},
    ];
    const {
        handleSubmit,
        handleChange2,
        handleSelect,
        handleBlur,
        values,
        errors,
        isSubmitting
      } = useFormValidation(INITIAL_STATE, validateUser);
      const [errs, setErrs] = React.useState({});
      if(isSubmitting){
        if(isEmpty(errors)){
          addUser(values);
          close()
        }
        
      }
    // const {auth}=props;
    useEffect(() => {
      if(errorReducer){
          console.log(errorReducer)
          if(!isEmpty(errorReducer.error)){
            setErrs(errorReducer.error);
          }
      }
    }, [errorReducer])
    const getError = (type)=>{
        let errClass = !isEmpty(errors[type]) && !isEmpty(errs[type]) ? 'has-error' : ''
        return errClass
    }
    const getErrorMessage=(type)=>{
        if(!isEmpty(errors[type])){
            return errors[type]
        }else if (!isEmpty(errs[type])){
            return errs[type]
        }
        return ''
    }
    return (
        <Form>
        <FormGroup className={getError('name')}>
          <ControlLabel>Name</ControlLabel>
          <FormControl name="name" type="text" value={values.name} errorMessage={getErrorMessage('name')} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        <FormGroup className={getError('user_name')}>
          <ControlLabel>Username</ControlLabel>
          <FormControl name="user_name" type="text"  errorMessage={getErrorMessage('user_name')} value={values.user_name} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        <FormGroup className={getError('email')}>
          <ControlLabel>Email</ControlLabel>
          <FormControl name="email" type="email"  errorMessage={getErrorMessage('email')} value={values.email} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        <FormGroup className={getError('phone')}>
          <ControlLabel>Phone</ControlLabel>
          <FormControl name="phone" type="number" errorMessage={getErrorMessage('phone')} value={values.phone} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        <FormGroup className={getError('password')}>
          <ControlLabel>Password</ControlLabel>
          <FormControl name="password" type="password" errorMessage={getErrorMessage('password')} value={values.password} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        <FormGroup className={getError('password2')}>
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl name="password2" type="password" errorMessage={getErrorMessage('password2')} value={values.password2} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        <FormGroup className={getError('role')}>
          <ControlLabel>Role</ControlLabel>
          <SelectPicker data={data} name="role"  errorMessage={getErrorMessage('role')} style={{ width: 250 }} value={values.role || errs.role} onSelect={handleSelect}  />
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
              <Button appearance="primary" disabled={isSubmitting} onClick={handleSubmit}>
                {isSubmitting?<Loader />:null}
                Submit
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    )
}


UserForm.propTypes ={
    addUser: PropTypes.func.isRequired,
    auth:PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired 
  }
  
  const mapStateToProps = (state) => ({
    auth:state.auth,
    errorReducer:state.errorReducer
  })
  
  
  export default connect(mapStateToProps, {addUser})(UserForm);
