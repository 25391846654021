import React, {useEffect,useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import isEmpty from '../../validations/isEmpty';
import { Table,IconButton,Icon} from "rsuite";
import { deleteActivity, getActivities } from "../../actions/activityActions";
import CustomPagination from '../../components/common/CustomPagination';
import { Modal,Button } from 'rsuite';
const { Column, HeaderCell, Cell, Pagination } = Table;

const ActionCell = ({ rowData,func, dataKey, ...props }) => {
  const [show,setShow] = useState(false)
  const onShow = () => {
    setShow(true)
  };
  const onShowClose = () => {
    setShow(false)
  };
    return (
      <Cell {...props} className="link-group">
        <IconButton onClick={()=>onShow()} appearance="subtle" icon={<Icon icon="trash" />} />
        {/* <IconButton onClick={()=>dispatch(deleteActivity(rowData.id))} appearance="subtle" icon={<Icon icon="trash" />} /> */}
        <Modal backdrop="static" show={show} onHide={onShowClose} size="xs">
                    <Modal.Body>
                        <Icon
                        icon="remind"
                        style={{
                            color: '#ffb300',
                            fontSize: 24
                        }}
                        />
                        {'  '}
                        Are you sure, You want to delete this activity ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>{
                            // this.props.deleteTrek(trek.id);
                            func(rowData.id)
                            onShowClose()
                        }} appearance="primary">
                        Ok
                        </Button>
                        <Button onClick={onShowClose} appearance="subtle">
                        Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
      </Cell>
    );
};

const Image = ({rowData,dataKey,...props})=>{
    return(
        <Cell {...props} className="link-group">
            <div
                style={{
                    width: 40,
                    height: 40,
                    background: '#f5f5f5',
                    borderRadius: 20,
                    marginTop: 2,
                    overflow: 'hidden',
                    display: 'inline-block'
                }}
                >
                {/* photo */}
                <img src={rowData.passport_image} width="44" />
            </div>
        </Cell>
    )
}


function Activities() {
    const {activities,loading,page} = useSelector(state=>state.activityReducer);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getActivities())
    },[]);

    const onActivityDelete = (id) => {
        dispatch(deleteActivity(id));
    }
    return (
        <div className="table-cont activities">
            <div className="activities-table">
            {/* <Table loading={loading} height={400} data={activities}> */}
            <Table loading={loading} height={500} data={activities}>
            <Column width={70} align="center">
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column width={70} align="center">
              <HeaderCell>Image</HeaderCell>
              <Image dataKey="passport_image" />
            </Column>
            
            <Column width={130}>
              <HeaderCell>Name</HeaderCell>
              <Cell dataKey="name" />
            </Column>

            <Column width={200}>
              <HeaderCell>Email</HeaderCell>
              <Cell dataKey="email" />
            </Column>

            <Column width={100}>
              <HeaderCell>Phone</HeaderCell>
              <Cell dataKey="phone" />
            </Column>
            <Column width={160}>
              <HeaderCell>Destination</HeaderCell>
              <Cell  dataKey="destination" />
            </Column>
            <Column width={150}>
              <HeaderCell>Group</HeaderCell>
              <Cell  dataKey="group" />
            </Column>
            <Column width={100}>
              <HeaderCell>Type</HeaderCell>
              <Cell dataKey="type" />
            </Column>
            
            <Column width={150}>
              <HeaderCell>Passport Number</HeaderCell>
              <Cell dataKey="passport_no" />
            </Column>
            
            
            <Column width={120}>
              <HeaderCell>Trip Date</HeaderCell>
              <Cell  dataKey="trip_date" />
            </Column>
            <Column width={120}>
              <HeaderCell>Price</HeaderCell>
              <Cell  dataKey="price" />
            </Column>
            <Column width={120}>
              <HeaderCell>Country</HeaderCell>
              {/* <Cell dataKey="popular" /> */}
              <Cell  dataKey="country" />
            </Column>

            <Column width={110}>
              <HeaderCell>Start date</HeaderCell>
              <Cell  dataKey="start_date" />
            </Column>
            <Column width={110}>
              <HeaderCell>End date</HeaderCell>
              <Cell  dataKey="end_date" />
            </Column>
            
            <Column width={110}>
              <HeaderCell>Booked at</HeaderCell>
              <Cell  dataKey="createdAt" />
            </Column>
            
            <Column width={100}>
              <HeaderCell>Delete</HeaderCell>
              <ActionCell dataKey="id" func={onActivityDelete} />
            </Column>
          </Table>
                {
                        isEmpty(activities)?null:
                        <CustomPagination 
                                page={page}
                                getFunc={(pages)=>dispatch(getActivities(pages))}
                        />
                }
            </div>
        </div>
    )
}

export default Activities
