import React, { Component } from "react";
import { Uploader, Icon, Button, Alert, } from "rsuite";
import isEmpty from '../../validations/isEmpty'

export class UploadImages extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             image:[]
        }
        this.onUpload = this.onUpload.bind(this);
    }
    onUpload(){
        const {UploadImages,id,images,name,isMultiple=true} =this.props;
        const {image} = this.state;
        console.log(image)
        // if(isEmpty(image)){
        //   Alert.error("Add Some Images", 5000);
        //   return
        // }
        const data =new FormData();
        image.map(item=>{
            data.append(name,item.blobFile)
        })
        if(!isMultiple){
          if(isEmpty(image)){
            Alert.error("Please choose a image before proceeding further", 5000);
            return;
          }
        }else{
          if(isEmpty(image) && isEmpty(images)){
            Alert.error("Please choose images before proceeding further", 5000);
            return;
          }
        }
        data.append('otherImages',images)
        data.append('id',id);
        UploadImages(id,data);
        this.setState({image:[]})
    }
  render() {
    const {images,onImageChange,title="Upload Room Images",isMultiple=true} = this.props;
    return (
      <div className="single-upload">
          <div className="single-upload-header">
              <h3>{title}</h3>
          </div>
          <div className="single-upload-body">
            <Uploader
                multiple={isMultiple}
                autoUpload={false}
                listType="picture"
                onChange={image => {
                    this.setState({ image: image });
                }}
                fileList={this.state.image}
                // onRemove={()=>this.setState({})}
                >
                    <button>
                        <Icon icon="camera-retro" size="lg" />
                    </button>
            </Uploader>
          </div>
          <div className="single-upload-bottom">
            <div className="single-upload-title">
              <span>Already there images</span>
            </div>
            <div className="atimages">
              {
                images && isMultiple ? images.map((image)=>ImageMap(image,onImageChange)) : ImageMap(images,onImageChange)
              }
            </div>
          </div>
          <div className="single-upload-footer">
            <Button 
                    onClick={() => this.onUpload()} 
                    appearance="primary"
                >
                Upload
            </Button>
          </div>
      </div>
    );
  }
}

const ImageMap = (image,onImageChange)=>{
  return(
    <div className="atimages-block">
      <div className="atimages-image">
        <img src={image} alt="AltImage"/>
      </div>
      <div className="atimages-link">
        <a onClick={()=>onImageChange(image)} >
          <Icon icon="close" />
        </a>
      </div>
    </div>
  )
}

export default UploadImages;