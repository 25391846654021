import {
	GET_TRIP_PLANS,
	TRIP_PLAN_LOADING,
	DELETE_TRIP_PLAN,
} from "../actions/types";

const initialState = {
	tripplans: [],
	page: {},
	loading: false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case TRIP_PLAN_LOADING:
			return {
				...state,
				loading: true
			};
		case GET_TRIP_PLANS:
			return {
				...state,
				tripplans: action.payload,
				page:action.page,
				loading: false
			};
		case DELETE_TRIP_PLAN:
			return {
				...state,
				tripplans: state.tripplans.filter(
					trip => trip.id !== action.payload
				)
			};
		default:
			return state;
	}
}
