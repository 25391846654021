export default function validateitinerary(values) {
    let errors = {};
    if (!values.day) {
        errors.day = "Day Field is Required";
    }
    if (!values.title) {
        errors.title = "Title Field is Required";
    }
    if (!values.details) {
        errors.details = "Details Field is Required";
    }

    return errors;
}

export function validateincludes(values) {
    let errors = {};
    if (!values.content) {
        errors.day = "Content Field is Required";
    }

    return errors;
}
export function validateAltitude(values) {
    let errors = {};
    if (!values.number) {
        errors.number = "Number Field is Required";
    }
    if (!values.altitude) {
        errors.altitude = "Altitude Field is Required";
    }

    return errors;
}
export function validatePrice(values) {
    let errors = {};
    if (!values.packageType) {
        errors.packageType = "Package Type Field is Required";
    }
    if (!values.details) {
        errors.details = "Details Field is Required";
    }
    if (!values.services_includes) {
        errors.services_includes = "Services Includes Field is Required";
    }
    if (!values.services_excludes) {
        errors.services_excludes = "Services Excludes Field is Required";
    }

    return errors;
}

export function validateGroup(values) {
    let errors = {};
    if (!values.group_size) {
        errors.group_size = "Group Size Field is Required";
    }
    if (!values.price) {
        errors.price = "Price Field is Required";
    }

    return errors;
}


export function validateGroupNew(values) {
    let errors = {};
    if (!values.group) {
        errors.group = "Group Size Field is Required";
    }
    if (!values.price) {
        errors.price = "Price Field is Required";
    }

    return errors;
}