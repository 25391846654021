import React, { Component,useState,useEffect } from 'react'
import { Sidenav,Nav,Dropdown,Icon } from 'rsuite';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import logo from '../../assets/Flock Final Logo.png'
import f from '../../assets/f.png'
import './layout.styles.scss'
import classnames from 'classnames'

const Sidebar =(props)=>{
    const [sideToggle,setSideToggle] = useState(true);
    const [activeKey,setActiveKey] = useState("1");
    const handleToggle = ()=>{
        setSideToggle(!sideToggle);
    }
    const handleActiveKey= (key)=>{
        setActiveKey(key)
    }
    const [expand,setExpand]=useState('');
    const onExpand =(item)=>{
        if(item===expand){
            setExpand('');
        }
        else{
            setExpand(item);
        }
    }
    const getClass = (item)=>{
      return classnames({
        "nav-list__item":true,
        "with-subMenu":true,
        "expand":expand===item,
        "collapse":expand!==item
      })
    }
    return(
    <div className={!props.toggle ? "sidebar sidebar-close":"sidebar"}>
        <div className="sidebar-header">
            <div className="sidebar-header-image">
              <img src={logo} alt="Flock Logo" className="bg-logo" />
              <img src={f} alt="Flock Logo" className="sm-logo" />
            </div>
        </div>
        <div className="sidebar-nav nav">
            <ul className="nav-list">
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                      <span className="iconify" data-icon="bx:bxs-dashboard" data-inline="false"></span>
                    </span>
                    <span className="name">Dashboard</span>
                  </div>
                </NavLink>
              </li>
              <li className={getClass('users')}>
                {/* <NavLink to="/users" activeClassName='active'> */}
                <div className="nav-list__link"  onClick={()=>onExpand('users')}>
                  <div className="nav-list__item-name">
                    <span className="icon">
                      <span className="iconify" data-icon="gridicons:multiple-users" data-inline="false"></span>
                    </span>
                    <span className="name">Users</span>
                  </div>
                  <span className="down-icon">
                      <span className="iconify" data-icon="bx:bx-chevron-down" data-inline="false"></span>
                  </span>
                </div>
                <ul className="sidebar-subLink ">
                    <li>
                        <NavLink exact className="sidebar-subLink__item" to="/users" activeClassName='active'>
                            <span className="iconify" data-icon="ant-design:table-outline"></span>
                            Users
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/users/add"  className="sidebar-subLink__item" activeClassName='active'>
                            <span className="iconify" data-icon="ant-design:usergroup-add-outline"></span>
                            Add User 
                        </NavLink>
                    </li>
                </ul>
              </li>
              {/* <li className={getClass('adventures')}>
                <div className="nav-list__link"  onClick={()=>onExpand('adventures')}>
                  <div className="nav-list__item-name">
                    <span className="icon">
                        <span className="iconify" data-icon="icons8:adventures" data-inline="false"></span>
                    </span>
                    <span className="name">Adventures</span>
                  </div>
                  <span className="down-icon">
                      <span className="iconify" data-icon="bx:bx-chevron-down" data-inline="false"></span>
                  </span>
                </div>
                <ul className="sidebar-subLink ">
                    <li>
                        <NavLink exact className="sidebar-subLink__item" to="/adventures" activeClassName='active'>
                            <span className="iconify" data-icon="icons8:adventures" data-inline="false"></span>
                            Adventures
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/adventures/add"  className="sidebar-subLink__item" activeClassName='active'>
                            <span className="iconify" data-icon="ant-design:appstore-add-outlined" data-inline="false"></span>
                            Add Adventure 
                        </NavLink>
                    </li>
                </ul>
              </li> */}
              
              <li className={getClass('customers')}>
                <div className="nav-list__link"  onClick={()=>onExpand('customers')}>
                  <div className="nav-list__item-name">
                    <span className="icon">
                      <span className="iconify" data-icon="bi:person-square" data-inline="false"></span>
                    </span>
                    <span className="name">Customers</span>
                  </div>
                  <span className="down-icon">
                      <span className="iconify" data-icon="bx:bx-chevron-down" data-inline="false"></span>
                  </span>
                </div>
                <ul className="sidebar-subLink ">
                    <li>
                        <NavLink exact className="sidebar-subLink__item" to="/customers" activeClassName='active'>
                            <span className="iconify" data-icon="bi:person" data-inline="false"></span>
                            Customers
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/customers/add"  className="sidebar-subLink__item" activeClassName='active'>
                            <span className="iconify" data-icon="bi:person-plus" data-inline="false"></span>
                            Add Customer 
                        </NavLink>
                    </li>
                </ul>
              </li>
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/teams" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                      <span className="iconify" data-icon="ri:team-fill" data-inline="false"></span>
                    </span>
                    <span className="name">Teams</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/adventures" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                        <span className="iconify" data-icon="icons8:adventures" data-inline="false"></span>
                    </span>
                    <span className="name">Adventures</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/treks" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                        <span className="iconify" data-icon="cryptocurrency:actn" data-inline="false"></span>
                    </span>
                    <span className="name">Treks</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/activities" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                        <span className="iconify" data-icon="ic:round-airplanemode-active" data-inline="false"></span>
                    </span>
                    <span className="name">Activities</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/reviews" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                        <span className="iconify" data-icon="ic:baseline-rate-review" data-inline="false"></span>
                    </span>
                    <span className="name">Reviews</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/tripplan" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                        <span className="iconify" data-icon="bx:bxs-plane-take-off" data-inline="false"></span>
                    </span>
                    <span className="name">Trip Plan</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/bookings" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                      <span className="iconify" data-icon="el:address-book-alt" data-inline="false"></span>
                    </span>
                    <span className="name">Bookings</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/bookingsqr" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                      <span className="iconify" data-icon="el:address-book-alt" data-inline="false"></span>
                    </span>
                    <span className="name">Bookings New</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/contacts" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                        <span className="iconify" data-icon="ic:outline-contacts" data-inline="false"></span>
                    </span>
                    <span className="name">Contacts</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-list__item">
                <NavLink exact className="nav-list__link" to="/rooms" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                      <span className="iconify" data-icon="ic:round-hotel" data-inline="false"></span>
                    </span>
                    <span className="name">Rooms</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-list__item settings-link">
                <NavLink exact className="nav-list__link" to="/settings" activeClassName='active'>
                  <div className="nav-list__item-name">
                    <span className="icon">
                        <span className="iconify" data-icon="feather:settings" data-inline="false"></span>
                    </span>
                    <span className="name">Settings</span>
                  </div>
                </NavLink>
              </li>
            </ul>
        </div>
  </div>
    )
}

export default withRouter(Sidebar);