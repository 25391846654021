import {
	GET_STATUS,
	GET_STATUSES,
	STATUS_LOADING,
	ADD_STATUS,
	DELETE_STATUS,
	STATUS_LOADING_SUBMIT,
	EDIT_STATUS
} from "../actions/types";

const initialState = {
	statuses: [],
	status: {},
	loading: false,
	loading2: false
};
export default function(state = initialState, action) {
	switch (action.type) {
		case STATUS_LOADING:
			return {
				...state,
				loading: true
			};
		case STATUS_LOADING_SUBMIT:
			return {
				...state,
				loading2: action.payload
			};
		case GET_STATUS:
			return {
				...state,
				status: action.payload,
				loading: false
			};
		case GET_STATUSES:
			return {
				...state,
				statuses: action.payload,
				loading: false
			};
		case ADD_STATUS:
			return {
				...state,
				statuses: [action.payload, ...state.statuses]
			};
		case EDIT_STATUS:
			return {
				...state,
				statuses: state.statuses.map(status =>
					status.id === action.id ? action.payload : status
				)
			};
		case DELETE_STATUS:
			return {
				...state,
				statuses: state.statuses.filter(
					status => status.id !== action.payload
				)
			};
		default:
			return state;
	}
}
