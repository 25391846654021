export default function validateCustomer(values) {
    let errors = {};
    if (!values.name) {
        errors.name = "Name Field is Required";
        }
        // Email Errors
        if (!values.email) {
        errors.email = "Email Field is Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Invalid email address";
        }
        // Password Errors
        if (!values.status) {
        errors.status = "Status Field is Required";
        }
        if (!values.country) {
            errors.country = "Country Field is Required";
            }
        if (!values.phone) {
            errors.phone = "Phone Field is Required";
            }
        else if (values.phone.length < 10) {
        errors.phone = "Phone Field must be at least 10 character long";
        }
    return errors;
}