import axios from "axios";
import { Alert } from "rsuite";
import {getError} from '../helpers/getUnauth'
import {
	GET_USER,
	GET_USERS,
	USER_LOADING,
	ADD_USER,
	DELETE_USER,
	UPDATE_PHOTO,
	EDIT_USER,
	UPDATE_ACCESS,
	CLEAR_ERRORS,
	GET_ERRORS
} from "./types";

// Get user by id
export const getUser = id => dispatch => {
	dispatch(setLoading());
	axios
		.get(`/api/v1/users/${id}`)
		.then(res =>
			dispatch({
				type: GET_USER,
				payload: res.data.data
			})
		)
		.catch(err =>{
			getError(err);
			dispatch({
				type: GET_USER,
				payload: {}
			})
		});
};

// Get all users
export const getUsers = () => dispatch => {
	dispatch(setLoading());
	axios
		.get("/api/v1/users/")
		.then(res =>{
			dispatch({
				type: GET_USERS,
				payload: res.data.data
			})
		})
		.catch(err =>{
			getError(err);
			dispatch({
				type: GET_USERS,
				payload: []
			})
		});
};

export const resetForgotPassword = ({ token, password }) => dispatch => {
	dispatch(clearErrors());
	axios
		.post(`/api/v1/users/change-forgot-password/${token}`, { password })
		.then(res => {
			if (res.data.success) {
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			if (err) {
				getError(err);
				if (err.response) {
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data
					});
				}
			}
		});
};

// Forgot Password
export const forgotPassword = email => dispatch => {
	dispatch(clearErrors());
	axios
		.post("/api/v1/users/forgot-password", { email })
		.then(res => {
			if (res.data.success) {
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			if (err) {
				
				if (err.response) {
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data
					});
				}
				getError(err);
			}
		});
};

// Change Password
export const changePassword = (id,pass) => dispatch => {
	dispatch(clearErrors());
	axios
		.put(`/api/v1/users/${id}/change-password`, pass)
		.then(res => {
			if (res.data.success) {
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			if (err) {
				
				if (err.response) {
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data
					});
				}
				getError(err);
			}
		});
};

// Add User
export const addUser = newUser => dispatch => {
	dispatch(clearErrors());
	axios
		.post("/api/v1/users/register", newUser)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: ADD_USER,
					payload: res.data.data
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			getError(err);
			if (err) {
				if (err.response) {
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data
					});
					
				}
				getError(err);
			}
		});
};

export const editAccess = (id, access, role) => dispatch => {
	dispatch(clearErrors());
	axios
		.post("/api/v1/users/access/" + id, { access, role })
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: UPDATE_ACCESS,
					payload: res.data.data[0]
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			if (err) {
				if (err.response) {
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data
					});
				} 
				getError(err);
			}
		});
};

// Edit User
export const editUser = (userId, newUser) => dispatch => {
	dispatch(clearErrors());
	axios
		.put(`/api/v1/users/${userId}`, newUser)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: EDIT_USER,
					payload: res.data.data[1][0],
					id: userId
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			if (err) {
				if (err.response) {
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data
					});
				}
				getError(err);
			}
		});
};

// Delete Post
export const deleteUser = id => dispatch => {
	axios
		.delete(`/api/v1/users/${id}`)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: DELETE_USER,
					payload: id
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			getError(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};

//Put Image
export const uploadUserImage = (userId, image) => dispatch => {
	dispatch(clearErrors());
	axios
		.post(`/api/v1/users/upload/${userId}`, image)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: UPDATE_PHOTO,
					payload: res.data,
					id: userId
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			getError(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};

// User loading
export const setLoading = () => {
	return {
		type: USER_LOADING
	};
};

// Clear errors
export const clearErrors = () => {
	return {
		type: CLEAR_ERRORS
	};
};


