import React, { Component } from 'react';
import { connect } from "react-redux";
import { getAdventureByHandle, addFiles } from "../../actions/adventuresActions";
import Slider from 'react-slick';
import { Loader, Button, Icon } from "rsuite";
import "./adventure.styles.scss";
import classnames from "classnames";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import renderHTML from 'react-render-html';
import UploadImages from "../../components/common/UploadImages";
import isEmpty from '../../validations/isEmpty'

export class Adventure extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          active: "Content",
          adv:{}
        };
        this._renderImages = this._renderImages.bind(this);
        this.getActiveClass = this.getActiveClass.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.Tab = this.Tab.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
      }
    async componentDidMount() {
        const {
          match: {
            params: { handle }
          },
          getAdventureByHandle,
          adventure
        } = this.props;
        await getAdventureByHandle(handle);
        if(!isEmpty(adventure)){
          this.setState({adv:adventure})
      }
    }
    componentDidUpdate(prevProps){
      const {adventure} = this.props;
      if(!isEmpty(adventure) && adventure !== prevProps.adventure){
          this.setState({adv:adventure})
      }
    }
    onImageChange(name){
        this.setState(prevState=>({
                ...prevState,
                adv:{
                    ...prevState.adv,
                    images:prevState.adv.images.filter(image=>image!==name)
                }
            })
        )
    }
    _renderImages() {
        const settings = {
          dots: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 3000,
          lazyLoad: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          // nextArrow: <SampleNextArrow />,
          // prevArrow: <SamplePrevArrow />,
          cssEase: "linear",
          arrow: false
        };
        const {adventure:{images,name}} = this.props;
        return (
          <Slider ref={c => (this.slider = c)} {...settings}>
            {images.map(image => {
              return (
                <div className="slide-image">
                  <img src={image} className="slider-image" alt={name} />
                </div>
              );
            })}
          </Slider>
        );
    }
    onTabClick(active) {
        this.setState({ active });
    }

    getActiveClass = name => {
        return classnames({
          "trek-tab": true,
          active: this.state.active === name
        });
    };
    Tab(name) {
        return (
          <div
            onClick={() => this.onTabClick(name)}
            className={this.getActiveClass(name)}
          >
            <span>{name}</span>
          </div>
        );
      }
    render() {
        const { adventure, loading } = this.props;
        const { active,adv } = this.state;
        if (loading && adventure === null) {
          return <Loader backdrop content="loading..." vertical />;
        }
        return (
            <div className="trek">
            <div className="trek-container">
              <div className="trek-block">
                <div className="trek-block__header">
                  <h2>{adventure.name}</h2>
                  <span>{adventure.destination}</span>
                </div>
                <div className="trek-block__chips">
                  <div className="trek-block__chip">
                    <h4>Properties</h4>
                    <div className="flex">
                      <span className="chips chips-color-1">$ {adventure.base_price}</span>
                      <span className="chips chips-color-4">{adventure.duration} Days</span>
                      <span className="chips chips-color-5">{adventure.difficulty}</span>
                    </div>
                  </div>
                </div>
                <div className="trek-block__body">
                  <div className="trek-block__slider">
                    {adventure.images ? (
                      this._renderImages()
                    ) : (
                      <span className="no-images">
                        No Images Found. Upload images down below inside the tab
                      </span>
                    )}
                  </div>
                  <div className="trek-block__tabs">
                    <div className="trek-block__tabs-tab">
                      {this.Tab("Content")}
                      {this.Tab("Pricing")}
                      {this.Tab("Upload Images")}
                    </div>
                    <div className="trek-block__tabs-body">
                      {active === "Content" ? (
                        adventure.content ? (
                            <div className="trek-body">
                                <div className="content">
                                    {renderHTML(renderHTML(adventure.content))}
                                </div>
                            </div>
                        ) : (
                          <span className="no-tab">Content Not Found</span>
                        )
                      ) : null}
                      {active === "Pricing" ? (
                        adventure.price_details ? (
                            <div className="trek-body">
                                <div className="pricing">
                                    {renderHTML(renderHTML(adventure.price_details))}
                                </div>
                            </div>
                        ) : (
                          <span className="no-tab">Prvice Details Not Found</span>
                        )
                      ) : null}
                      {active === "Upload Images" ? (
                        <div className="trek-body">
                          {/* <UploadImages
                            UploadImages={this.props.addFiles}
                            id={adventure.id}
                          /> */}
                          <UploadImages
                              UploadImages={this.props.addFiles}
                              images={adv.images}
                              id={adv.id}
                              name="images"
                              onImageChange={this.onImageChange}
                            />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

const mapDispatchToProps = {
    getAdventureByHandle,
    addFiles
  };
  
const mapStateToProps = state => ({
    adventure: state.adventureReducer.adventure,
    loading: state.adventureReducer.loading
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Adventure);