import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  CheckPicker,
  Radio,
  Input,
} from "rsuite";
import { Button, Modal, Loader, ButtonToolbar } from "rsuite";
import useFormValidation from "../../../validations/formValidations";
import {validatePrice} from "../../../validations/validateItinerary";
import isEmpty from "../../../validations/isEmpty";
import Tiny from "../../../components/common/TinyEmc";
import { addTrekPriceDetails } from "../../../actions/trekActions";
import { useDispatch, useSelector } from "react-redux";

const getLabel = (value) =>{
  if(!isEmpty(value)){
      return value.map(item=>{return {label:item.charAt(0).toUpperCase() + item.slice(1),value:item}})
  }
  return []
}

function ItineraryForm(props) {
  const {
    errorReducer,
    editCustomer,
    auth,
    datas = {},
    isEdit = false,
    statuses,
    close,
    trek,
  } = props;
  const dispatch = useDispatch();
  const settings = useSelector(state=>state.settingReducer.settings)
  const INITIAL_STATE = {
    packageType: !isEmpty(datas?.packageType) ? datas.packageType : '',
    details: !isEmpty(datas?.details) ? datas.details : "",
    services_includes: !isEmpty(datas?.services_includes) ? datas.services_includes : [],
    services_excludes: !isEmpty(datas?.services_excludes) ? datas.services_excludes : [],
  };
  const {
    handleSubmit,
    handleChange2,
    handleChange3,
    handleSelect,
    checkErrors,
    handleBlur,
    handleDateChange,
    values,
    errors,
    isSubmitting,
    clearValues,
  } = useFormValidation(INITIAL_STATE, validatePrice);
  const handleSubmits = async (e) => {
    e.preventDefault();
    const { isValid } = checkErrors();
    
    if (isValid) {
      
      if (isEmpty(errors)) {
        if (isEdit) {
          // dispatch(editTeam(values.id,values));
          // clearValues();
          // onEdit({})
          await dispatch(addTrekPriceDetails(trek.id, {...values,id:datas.id}));
          clearValues();
          close()
          return false;
        }
        await dispatch(addTrekPriceDetails(trek.id, values));
        clearValues();
        close()
      }
    }
  };
  const [errs, setErrs] = React.useState({});
  useEffect(() => {
    if (errorReducer) {
      if (!isEmpty(errorReducer.error)) {
        setErrs(errorReducer.error);
      }
    }
  }, [errorReducer]);
  const getError = (type) => {
    let errClass =
      !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
    return errClass;
  };
  const getErrorMessage = (type) => {
    if (!isEmpty(errors[type])) {
      return errors[type];
    } else if (!isEmpty(errs[type])) {
      return errs[type];
    }
    return "";
  };
  return (
    <Form>
      <FormGroup className={getError("packageType")}>
        <ControlLabel>Package Type</ControlLabel>
        <FormControl
          name="packageType"
          type=""
          value={values.packageType}
          errorMessage={getErrorMessage("packageType")}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("details")}>
        <ControlLabel>Details</ControlLabel>
        <FormControl
          name="details"
          type="text"
          errorMessage={getErrorMessage("details")}
          value={values.details}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("services_includes")}>
          <ControlLabel>Treks Provides</ControlLabel>
          <CheckPicker 
              data={getLabel(settings?.trekProvides ?? [])}
              name="services_includes"
              placement="topStart"
              // onBlur={e => this.handleBlur(e)}
              // errorMessage={errors.provides}
              style={{ width: 250 }}
              onClean={()=>handleChange3('services_includes',[])}
              value={values.services_includes}
              // onClean={()=>this.setState({provides:''})}
              defaultValue={values.services_includes}
              onSelect={(value,valueObj,e)=>handleChange3('services_includes',value)}
          />
      </FormGroup>
      <FormGroup className={getError("services_excludes")}>
          <ControlLabel>Treks Provides</ControlLabel>
          <CheckPicker 
              data={getLabel(settings?.trekProvides ?? [])}
              name="services_excludes"
              placement="topStart"
              // onBlur={e => this.handleBlur(e)}
              // errorMessage={errors.provides}
              style={{ width: 250 }}
              onClean={()=>handleChange3('services_excludes',[])}
              value={values.services_excludes}
              // onClean={()=>this.setState({provides:''})}
              defaultValue={values.services_excludes}
              onSelect={(value,valueObj,e)=>handleChange3('services_excludes',value)}
          />
      </FormGroup>

      <FormGroup>
        <ButtonToolbar>
          <Button
            appearance="primary"
            disabled={isSubmitting}
            onClick={handleSubmits}
          >
            {isSubmitting ? <Loader /> : null}
            Submit
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
}

export default ItineraryForm;
