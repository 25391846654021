import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addTrek, editTrek } from "../../actions/trekActions";
import isEmpty from "../../validations/isEmpty";
import { validateTreks } from "../../validations/validateTreks";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
  HelpBlock,
  ButtonToolbar,
  Button,
  Loader,
  RadioGroup,
  Radio,
  CheckPicker,
} from "rsuite";

const getLabel = (value) => {
  if (!isEmpty(value)) {
    return value.map((item) => {
      return {
        label: item.charAt(0).toUpperCase() + item.slice(1),
        value: item,
      };
    });
  }
  return [];
};

export class TrekForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      handle: "",
      trek_name: "",
      price: "",
      days: "",
      altitude: "",
      summary: "",
      provides: [],
      region: "",
      difficulty: "Easy",
      altitude: "",
      trek_type: "",
      trekRegionId: "",
      video: "",
      isEdit: false,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getError = this.getError.bind(this);
    this.handleEsc = this.handleEsc.bind(this);
    this.clearState = this.clearState.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    const { errors, editData } = this.props;
    if (!isEmpty(errors) && errors !== prevProps.errors) {
      this.setState({ errors });
    }
    if (editData !== prevProps.editData) {
      if (!isEmpty(editData)) {
        this.setState({
          handle: editData.handle,
          trek_name: editData.trek_name,
          price: editData.price,
          days: editData.days,
          trekRegionId: editData.trekRegionId + "",
          difficulty: editData.difficulty,
          provides: editData.provides,
          altitude: editData.altitude,
          region: editData.region,
          summary: editData.summary,
          trek_type: editData.trek_type,
          video: editData.video,
          // elementary_price:editData.elementary_price.join(","),
          // classic_price:editData.classic_price.join(","),
          // full_fledge_price:editData.full_fledge_price.join(","),
          id: editData.id,
          isEdit: true,
        });
        return;
      }
      this.clearState();
    }
  }
  clearState() {
    this.setState({
      id: "",
      handle: "",
      trek_name: "",
      price: "",
      days: "",
      region: "",
      difficulty: "Easy",
      provides: [],
      summary: "",
      trekRegionId: "",
      altitude: "",
      trek_type: "",
      elementary_price: "",
      classic_price: "",
      full_fledge_price: "",
      video: "",
      isEdit: false,
    });
  }
  componentDidMount() {
    document.addEventListener("keyup", this.handleEsc);
    const { editData } = this.props;
      if (!isEmpty(editData)) {
        this.setState({
          handle: editData.handle,
          trek_name: editData.trek_name,
          price: editData.price,
          days: editData.days,
          trekRegionId: editData.trekRegionId + "",
          difficulty: editData.difficulty,
          provides: editData.provides,
          altitude: editData.altitude,
          region: editData.region,
          summary: editData.summary,
          trek_type: editData.trek_type,
          video: editData.video,
          id: editData.id,
          isEdit: true,
        });
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEsc);
  }
  handleEsc(e, cancel = "") {
    if (e.key === "Escape" || cancel === "cancel") {
      this.props.onEdit("");
    }
  }
  async onSubmit(e) {
    e.preventDefault();
    // const commaVal = ['classic','elementary','full_fledge'];
    const newTrek = {
      id: this.state.id,
      handle: this.state.handle,
      trek_name: this.state.trek_name,
      price: this.state.price,
      days: this.state.days,
      provides: this.state.provides,
      trekRegionId: this.state.trekRegionId,
      region: this.state.region,
      difficulty: this.state.difficulty,
      summary: this.state.summary,
      altitude: this.state.altitude,
      trek_type: this.state.trek_type,
      video: this.state.video,
      // classic_price:this.state.classic_price,
      // elementary_price:this.state.elementary_price,
      // full_fledge_price:this.state.full_fledge_price,
    };
    const { errors, isValid } = validateTreks(newTrek);
    if (!isValid) {
      this.setState({ errors });
      return false;
    }
    if (this.state.isEdit) {
      this.props.editTrek(this.state.id, newTrek);
      this.handleEsc(e, "cancel");
      return;
    }
    const res = await this.props.addTrek({ ...newTrek });
    // this.props.addTrek({});
    // this.clearState();
  }

  onChange(value, e) {
    const { name } = e.target;
    this.setState({ [name]: value });
  }
  handleBlur(e) {
    const { errors, isValid } = validateTreks(this.state);
    if (!isValid) {
      this.setState({ errors });
      return false;
    }
  }
  getErrorMessage(type) {
    if (!isEmpty(this.state.errors[type])) {
      return this.state.errors[type];
    }
    return "";
  }
  onSelect(value, valueObj, name) {
    if (name === "trekRegionId") {
      this.setState({ region: valueObj.label });
    }
    this.setState({ [name]: value });
  }
  getError = (type) => {
    let errClass = !isEmpty(this.state.errors[type]) ? "has-error" : "";
    return errClass;
  };
  render() {
    const { errors } = this.state;
    const values = this.state;

    let trekTypes,
      trekRegion,
      trekProvides = [];
    if (!isEmpty(this.props.settings)) {
      trekTypes = this.props.settings.trekTypes;

      trekProvides = this.props.settings.trekProvides;
    }
    if (!isEmpty(this.props.trekRegions)) {
      trekRegion = this.props.trekRegions;
    }

    return (
      <div>
        <Form>
          <FormGroup className={this.getError("handle")}>
            <ControlLabel>Handle</ControlLabel>
            <FormControl
              name="handle"
              type="text"
              onBlur={(e) => this.handleBlur(e)}
              value={values.handle}
              errorMessage={errors.handle}
              onChange={this.onChange}
              style={{width:'400px'}}
            />
            <HelpBlock tooltip>Required</HelpBlock>
          </FormGroup>
          <FormGroup className={this.getError("trek_name")}>
            <ControlLabel>Trek Name</ControlLabel>
            <FormControl
              name="trek_name"
              type="text"
              onBlur={(e) => this.handleBlur(e)}
              value={values.trek_name}
              errorMessage={errors.trek_name}
              onChange={this.onChange}
              style={{width:'400px'}}
            />
            <HelpBlock tooltip>Required</HelpBlock>
          </FormGroup>
          <FormGroup className={this.getError("price")}>
            <ControlLabel>Base Price</ControlLabel>
            <FormControl
              name="price"
              type="text"
              onBlur={(e) => this.handleBlur(e)}
              value={values.price}
              errorMessage={errors.price}
              onChange={this.onChange}
              style={{width:'400px'}}
            />
            <HelpBlock tooltip>Required</HelpBlock>
          </FormGroup>
          <FormGroup className={this.getError("days")}>
            <ControlLabel>Days</ControlLabel>
            <FormControl
              name="days"
              type="number"
              onBlur={(e) => this.handleBlur(e)}
              value={values.days}
              style={{width:'400px'}}
              errorMessage={errors.days}
              onChange={this.onChange}
            />
            <HelpBlock tooltip>Required</HelpBlock>
          </FormGroup>
          <FormGroup className={this.getError("region")}>
            <ControlLabel>Treks Region</ControlLabel>
            <SelectPicker
              data={
                trekRegion &&
                trekRegion.map((item) => ({
                  label: item.name,
                  value: item.id + "",
                }))
              }
              name="trekRegionId"
              placement="topStart"
              onBlur={(e) => this.handleBlur(e)}
              // errorMessage={errors.region}
              style={{ width: 400 }}
              value={values.trekRegionId}
              onClean={() => this.setState({ trekRegionId: "" })}
              onSelect={(value, valueObj, e) =>
                this.onSelect(value, valueObj, "trekRegionId")
              }
            />
          </FormGroup>
          <FormGroup className={this.getError("trek_type")}>
            <ControlLabel>Treks Type</ControlLabel>
            <SelectPicker
              data={getLabel(trekTypes)}
              name="trek_type"
              placement="topStart"
              onBlur={(e) => this.handleBlur(e)}
              // errorMessage={errors.trek_type}
              style={{ width: 400 }}
              value={values.trek_type}
              onClean={() => this.setState({ trek_type: "" })}
              onSelect={(value, valueObj, e) =>
                this.onSelect(value, valueObj, "trek_type")
              }
            />
          </FormGroup>
          {/* <FormGroup className={this.getError("provides")}>
                        <ControlLabel>Treks Provides</ControlLabel>
                        <CheckPicker 
                            data={getLabel(trekProvides)}
                            name="provides"
                            placement="topStart"
                            onBlur={e => this.handleBlur(e)}
                            // errorMessage={errors.provides}
                            style={{ width: 250 }}
                            onClean={()=>this.setState({provides:[]})}
                            value={values.provides}
                            // onClean={()=>this.setState({provides:''})}
                            defaultValue={values.provides}
                            onSelect={(value,valueObj,e)=>this.onSelect(value,valueObj,'provides')}
                        />
                    </FormGroup> */}
          <RadioGroup
            className="radio-container"
            onChange={(value) => this.setState({ difficulty: value })}
            name="difficulty"
            inline
            value={values.difficulty}
            appearance="picker"
            defaultValue="Easy"
          >
            <span className="radio-header">Difficulty: </span>
            <Radio value="Easy">Easy</Radio>
            <Radio value="Medium">Medium</Radio>
            <Radio value="Difficult">Difficult</Radio>
          </RadioGroup>
          <FormGroup className={this.getError("altitude")}>
            <ControlLabel>Altitude</ControlLabel>
            <FormControl
              name="altitude"
              type="number"
              onBlur={(e) => this.handleBlur(e)}
              value={values.altitude}
              errorMessage={errors.altitude}
              onChange={this.onChange}
              style={{width:'400px'}}
            />
            <HelpBlock tooltip>Required</HelpBlock>
          </FormGroup>
          <FormGroup className={this.getError("video")}>
              <ControlLabel>Tourradar Booking Link</ControlLabel>
              <FormControl
                  name="video"
                  type="text"
                  width="400px"
                  onBlur={e => this.handleBlur(e)}
                  value={values.video}
                  errorMessage={errors.video}
                  onChange={this.onChange}
                  style={{width:'400px'}}
              />
              <HelpBlock tooltip>Required</HelpBlock>
          </FormGroup>
          {/* <FormGroup className={this.getError("full_fledge_price")}>
                        <ControlLabel>Full Fledge</ControlLabel>
                        <FormControl
                            name="full_fledge_price"
                            type="text"
                            onBlur={e => this.handleBlur(e)}
                            value={values.full_fledge_price}
                            errorMessage={errors.full_fledge_price}
                            onChange={this.onChange}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup> */}
          {/* <FormGroup className={this.getError("elementary_price")}>
                        <ControlLabel>Elementary</ControlLabel>
                        <FormControl
                            name="elementary_price"
                            type="text"
                            onBlur={e => this.handleBlur(e)}
                            value={values.elementary_price}
                            errorMessage={errors.elementary_price}
                            onChange={this.onChange}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup> */}
          <FormGroup className={this.getError("summary")}>
            <ControlLabel>Summary</ControlLabel>
            <FormControl
              name="summary"
              type="textarea"
              componentClass="textarea" 
              rows={5}
              onBlur={(e) => this.handleBlur(e)}
              value={values.summary}
              errorMessage={errors.summary}
              onChange={this.onChange}
              style={{width:'400px'}}
            />
            <HelpBlock tooltip>Required</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ButtonToolbar>
              <Button
                appearance="primary"
                disabled={false}
                onClick={(e) => this.onSubmit(e)}
              >
                {false ? <Loader /> : null}
                {!this.state.isEdit ? "Add Trek" : "Update Trek"}
              </Button>
              {this.state.isEdit ? (
                <Button onClick={(e) => this.handleEsc(e, "cancel")}>
                  Cancle Edit
                </Button>
              ) : null}
            </ButtonToolbar>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

TrekForm.propTypes = {
  addTrek: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errorReducer,
  settings: state.settingReducer.settings,
  trekRegions: state.settingReducer.trekRegions,
});

export default connect(mapStateToProps, { addTrek, editTrek })(TrekForm);
