
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import TeamItem from './TeamItem';
import { Icon, Uploader, Avatar, IconButton } from "rsuite";
import { Modal, Button } from "rsuite";
import { Link } from "react-router-dom";
import {
  deleteBlog,
  addBlogFiles,
  editBlog,
} from "../../../actions/settingActions";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../validations/isEmpty";
import renderHTML from "react-render-html";



const styles = {
    width: 100,
    marginBottom: 10,
  };

function BlogItem(props) {
    const { blog, setBlog } = props;
    const [pic, setPic] = useState(null);
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch();
    const onClose = () => {
        // setOpen(false);
        setBlog(null)
    }
    useEffect(()=>{
        if(blog){
            setOpen(true);
        }
    },[blog])

    const getChar = (name) => {
        const half = name.split(" ");
        let initial = "";
        half &&
          half.map((item) => {
            if (item !== "undefined") {
              initial += item.charAt(0);
            }
          });
    
        return initial.toUpperCase();
    };

    const uploadPic = () => {
        if (isEmpty(pic)) {
          return false;
        }
        const img = new FormData();
        const asd = pic[pic.length - 1].blobFile;
        img.append("image", asd);
        dispatch(addBlogFiles(blog.id, img));
        setPic([]);
        // debugger
    };
    if(!blog){
        return null;
    }
  return (
    <Modal size="lg" backdrop={true} show={open} onHide={onClose}>
        <Modal.Header>
          <Modal.Title>Blog Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="team-details" style={{gridTemplateColumns:'120px 1fr'}} >
            <div 
                style={{
                    height:400,
                    position:'sticky',
                    top:0
                }}
                className="team-image">
              {blog?.image ? (
                <Avatar size="lg" src={blog?.image} />
              ) : (
                <Avatar size="lg">{getChar(blog.title)}</Avatar>
              )}
              <div className="team-upload">
                <Uploader
                  multiple={false}
                  fileListVisible={false}
                  // defaultFileList={team.image}
                  listType="picture"
                  autoUpload={false}
                  onChange={(image) => {
                    setPic(image);
                  }}
                >
                  <button style={styles}>
                    {!isEmpty(pic) ? (
                      <img
                        src={URL.createObjectURL(pic[pic.length - 1].blobFile)}
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <Icon icon="avatar" size="5px" />
                    )}
                  </button>
                </Uploader>
                <Button onClick={() => uploadPic()} appearance="primary">
                  Upload Image
                </Button>
              </div>
            </div>
            <div className="team-list">
              <div className="team-name">
                <h2>{blog.title}</h2>
                {/* <span>{team.sub_title}</span> */}
              </div>
              <div className="team-designation">{blog.sub_title}</div>
              <div className="team-designation">{blog.summary}</div>
              <div className="team-detail">
                {renderHTML(renderHTML(blog.body))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={onClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default BlogItem