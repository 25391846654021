import isEmpty from './isEmpty'
export const validateAdventures = (data)=>{
    let errors ={};
    if(isEmpty(data.name)){
		errors.name = 'Name Field is required';
	}
	if(isEmpty(data.handle)){
		errors.handle = 'Handle Field is required';
	}
	if(isEmpty(data.base_price)){
		errors.base_price = 'Base Price Field is required';
	}
	if(isEmpty(data.duration)){
		errors.duration = 'Durations Field is required';
	}
	if(isEmpty(data.destination)){
		errors.destination = 'Destination Field is required';
	}
	if(isEmpty(data.summary)){
		errors.summary = 'Summary Field is required';
	}
	if(isEmpty(data.content)){
		errors.content = 'Content Field is required';
	}
	// if(isEmpty(data.trek_type)){
	// 	errors.trek_type = 'Type Field is required';
	// }
	if(isEmpty(data.price_details)){
		errors.price_details = 'Price Details Field is required';
	}
	return{
		errors,
		isValid: isEmpty(errors)
	}
}