import React, { useState } from 'react'
import {Link} from 'react-router-dom';
import classnames from 'classnames';
// import Icons from 'components/icons'
import './styles.scss'

function AllSettings(props) {
    return (
        <div className="contain">
            <div className="settings-expands">
                <ExpandContainer title="Settings">
                    <ExpandLink link={"/extras/trek-region"} title="Trek Regions" />
                    <ExpandLink link={"/extras/services"} title="Services" />
                    <ExpandLink link={"/extras/services/types"} title="Services Type" />
                    <ExpandLink link={"/extras/alpsettings"} title="Alp Settings" />
                    <ExpandLink link={"/extras/things"} title="Things" />
                </ExpandContainer>
                <ExpandContainer title="Blogs">
                    <ExpandLink link={"/extras/blogs"} title="Blogs" />
                </ExpandContainer>
                <ExpandContainer title="Tours">
                    <ExpandLink link={"/extras/tours"} title="Tours" />
                    <ExpandLink link={"/extras/tours/city"} title="Tours City" />
                    <ExpandLink link={"/extras/tours/package"} title="ToursPackage" />
                    <ExpandLink link={"/extras/tours/package"} title="ToursPackage Includes" />
                </ExpandContainer>
            </div>
        </div>
    )
}

const ExpandContainer = (props) => {
    const {title,children} = props;
    const [open,setOpen] = useState(true);
    const expandClass = classnames("settings-expand",{"settings-expand__open":open});
    return(
        <div className={expandClass}>
            <div 
                onClick={()=>setOpen(prev=>!prev)}
                className="settings-expand__header">
                <span>{title}</span>
                {/* <Icons type='iconify' dataIcon="akar-icons:chevron-down" /> */}
            </div>
            <div className="settings-expand__body">
                <div className="settings-expand__list">
                    {children}
                </div>
            </div>
        </div>
    )
}

const ExpandLink = (props) => {
    const {link,title} = props;
    return(
        <Link to={link} >
            <div className="settings-expand__item">
                <span>{title}</span>
                {/* <Icons type='iconify' dataIcon="akar-icons:chevron-right" /> */}
            </div>
        </Link>
    )
}

export default AllSettings
