import React from "react";
import isEmpty from './isEmpty'

function useFormValidation(initialState, validate) {
  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        // console.log("authenticated!", values.email, values.password);
        setSubmitting(false);
      } else {
        setSubmitting(false);
      }
    }
  }, [errors]);

  function handleChange(event) {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }
  function handleSelect(value,valueObj,e) {
    setValues({
      ...values,
      [valueObj.name]: valueObj.value
    });
  }
  function handleChange2(value,event) {
    setValues({
      ...values,
      [event.target.name]: value
    });
  }
  function handleChange3(name,value,) {
    setValues({
      ...values,
      [name]: value
    });
  }
  function setInitial(val) {
    console.log(val)
    setValues({
      ...values,
      ...val
    });
  }
  function clearValues(){
    setValues(initialState)
  }
  function handleDateChange(value,name){
    setValues({
      ...values,
      [name]: value
    });
  }

  function handleBlur() {
    const validationErrors = validate(values);
    setErrors(validationErrors);
  }
  function checkErrors(){
    const validationErrors = validate(values);
    setErrors(validationErrors);
    return {isValid:isEmpty(validationErrors)}
  }
  function handleSubmit(event) {
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    setSubmitting(true);
  }

  return {
    handleSubmit,
    handleChange,
    handleChange2,
    handleChange3,
    handleSelect,
    handleBlur,
    clearValues,
    checkErrors,
    values,
    setInitial,
    errors,
    isSubmitting,
    handleDateChange
  };
}

export default useFormValidation;
