import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import TeamItem from './TeamItem';
import { InputGroup, Icon, Input, Avatar, Uploader } from "rsuite";
import { Modal, Button } from "rsuite";
import { Link } from "react-router-dom";
import {
  deleteTourPackage,
  addPackageFiles,
} from "../../../actions/tourActions";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../validations/isEmpty";
import renderHTML from "react-render-html";

const styles = {
  width: 300,
  marginBottom: 10,
};

function TeamsFeed({ trekRegions, onEdit }) {
  return (
    <div className="treks-list__block">
      {/* <div className="treks-list__search">
                <InputGroup inside style={styles}>
                    <Input 
                     onChange={(val)=>onChange(val)}    
                    />
                    <InputGroup.Button>
                        <Icon icon="search" />
                    </InputGroup.Button>
                </InputGroup>
            </div> */}
      <div className="treks-list__header trek-grid">
        <span className="name">Title</span>
        {/* <span className="price text-center">Number</span> */}
        <span className="edit text-center">View</span>
        <span className="edit text-center">Edit</span>
        <span className="delete text-center">Delete</span>
      </div>
      <div className="treks-list__items">
        {trekRegions &&
          trekRegions.map((team) => <TourItem team={team} onEdit={onEdit} />)}
      </div>
    </div>
  );
}

const TourItem = (props) => {
  const { team, onEdit } = props;
  const [op, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [pic, setPic] = useState(null);

  const close = () => {
    setShow(false);
  };

  const open = () => {
    setShow(true);
  };

  const closeMod = () => {
    setOpen(false);
  };

  const openmod = () => {
    setOpen(true);
  };
  const getChar = (name) => {
    const half = name.split(" ");
    let initial = "";
    half &&
      half.map((item) => {
        if (item !== "undefined") {
          initial += item.charAt(0);
        }
      });

    return initial.toUpperCase();
  };
  const styles = {
    width: 100,
    height: 100,
  };
  const uploadPic = () => {
    if (isEmpty(pic)) {
      return false;
    }
    const img = new FormData();
    const asd = pic[pic.length - 1].blobFile;
    img.append("image", asd);
    dispatch(addPackageFiles(team.id, img));
    setPic([]);
    // debugger
  };

  const dispatch = useDispatch();
  return (
    <div className="trek-item trek-grid">
      <div onClick={() => openmod()} className="trek-item__days text-link">
        {team.title}
      </div>
      <div className="text-center">
        <Link to={"/extras/tours/package/" + team.id}>View Details</Link>
      </div>
      <Modal backdrop={true} size="lg" show={op} onHide={closeMod}>
        <Modal.Header>
          <Modal.Title>Tour Package Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="team-details" style={{gridTemplateColumns:'120px calc(100% - 120px)',gap:12}}>
            <div className="team-image">
              {team.image ? (
                <Avatar src={team.image} />
              ) : (
                <Avatar size="lg">{getChar(team.title)}</Avatar>
              )}
              <div className="team-upload">
                <Uploader
                  multiple={false}
                  fileListVisible={false}
                  // defaultFileList={team.image}
                  listType="picture"
                  autoUpload={false}
                  style={{width:100}}
                  onChange={(image) => {
                    setPic(image);
                  }}
                >
                  <button style={styles}>
                    {!isEmpty(pic) ? (
                      <img
                        src={URL.createObjectURL(pic[pic.length - 1].blobFile)}
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <Icon icon="avatar" size="5x" />
                    )}
                  </button>
                </Uploader>
              </div>
              <Button onClick={() => uploadPic()} appearance="primary">
                  Upload Image
                </Button>
            </div>
            <div className="team-list">
              <div className="team-name">
                <h2>{team?.title}</h2>
                {/* <span>{team?.number}</span> */}
              </div>
              {/* <div className="team-designation">
                                {team.summary}
                            </div> */}
              <div className="team-detail">
                {/* {team.details && renderHTML(team.details) } */}
                {team.details && renderHTML(renderHTML(team.details))}
                {team.price_details &&
                  renderHTML(renderHTML(team.price_details))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeMod} appearance="primary">
            Ok
          </Button>
          <Button onClick={closeMod} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="trek-item__days text-left">
                {team.short}
            </div> */}
      {/* <div className="trek-item__days text-center">
                {team.details}
            </div> */}
      <div onClick={() => onEdit(team)} className="trek-item__edit text-center">
        <Icon icon="edit" size="lg" color="red" />
      </div>
      <div onClick={open} className="trek-item__delete text-center">
        <Icon icon="minus-square" size="lg" color="blue" />
      </div>

      <Modal backdrop="static" show={show} onHide={close} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure, You want to delete this trek region?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(deleteTourPackage(team.id));
              close();
            }}
            appearance="primary"
          >
            Ok
          </Button>
          <Button onClick={close} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

TeamsFeed.propTypes = {
  teams: PropTypes.array.isRequired,
};

export default TeamsFeed;
