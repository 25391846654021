import React , {useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import { getThings } from '../../../actions/settingActions';
import {Loader} from 'rsuite'
import TeamsFeed from './TeamsFeed';
import TeamForm from './TeamForm';
import './team.styles.scss'
import isEmpty from '../../../validations/isEmpty';

// teamReducer
function TrekRegion() {
    const [ editData,setEditData ] = useState({});

    const dispatch = useDispatch();
    const { things,loading } = useSelector(state => state.settingReducer);

    useEffect(()=>{
        dispatch(getThings());
    },[]);

    const onEdit = (data) => {
        setEditData(data)
    }

    let teamsContent;
    if(loading){
        teamsContent = <Loader />
    }else{
        teamsContent =things && <TeamsFeed 
                                    onEdit = {onEdit}
                                    serviceTypes={things} />;
    }

    return (
        <div className="treks">
            <div className="treks-list">
                {teamsContent}
            </div>
            <div className="treks-form">
                <TeamForm 
                    onEdit={onEdit}
                    editData={editData}
                    isEdit={!isEmpty(editData)}
                />
            </div>
        </div>
    )
}

export default TrekRegion;