import {
    GET_REVIEWS,
    REVIEWS_LOADING,
    EDIT_REVIEW,
	DELETE_REVIEW,
} from "../actions/types";

const initialState = {
	reviews: [],
	page: {},
	loading: false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case REVIEWS_LOADING:
			return {
				...state,
				loading: true
			};
		case GET_REVIEWS:
			return {
				...state,
				reviews: action.payload,
				page:action.page,
				loading: false
			};
		case DELETE_REVIEW:
			return {
				...state,
				reviews: state.reviews.filter(
					review => review.id !== action.payload
				)
            };
        case EDIT_REVIEW:
            return {
                ...state,
                reviews: state.reviews.map(review =>
                    review.id === action.id
                    ? // transform the one with a matching id
                    action.payload
                    : // otherwise return original todo
                    review
                )
            };
		default:
			return state;
	}
}
