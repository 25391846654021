import {
    GET_BOOKINGS,
	BOOKINGS_LOADING,
    DELETE_BOOKING,
    GET_BOOKING,
    GET_BOOKINGS_QR,
    GET_BOOKING_QR,
    EDIT_BOOKING
} from "../actions/types";

const initialState = {
    bookings: [],
    booking:{},
    bookingsqr: [],
    bookingqr:{},
	page: {},
	loading: false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case BOOKINGS_LOADING:
			return {
				...state,
				loading: true
			};
		case GET_BOOKINGS:
			return {
				...state,
				bookings: action.payload,
				page:action.page,
				loading: false
            };
        case GET_BOOKING:
            return {
                ...state,
                booking:action.payload
            }
        case GET_BOOKINGS_QR:
            return {
                ...state,
                bookingsqr: action.payload,
                page:action.page,
                loading: false
            };
        case GET_BOOKING_QR:
            return {
                ...state,
                bookingqr:action.payload
            }

            
		case DELETE_BOOKING:
			return {
				...state,
				bookings: state.bookings.filter(
					booking => booking.id !== action.payload
				)
            };
        case EDIT_BOOKING:
            return {
                ...state,
                bookings: state.bookings.map(booking =>
                    booking.id === action.id
                    ? // transform the one with a matching id
                    action.payload
                    : // otherwise return original todo
                    booking
                )
            };
		default:
			return state;
	}
}
