import React , {useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import { getTourCities } from '../../../actions/tourActions';
import {Loader} from 'rsuite'
import TeamsFeed from './ToursCityFeed';
import TourCityForm from './TourCityForm';
import './team.styles.scss'
import isEmpty from '../../../validations/isEmpty';

// teamReducer
function TrekRegion() {
    const [ editData,setEditData ] = useState({});

    const dispatch = useDispatch();
    const { tourCities,tourCityLoading } = useSelector(state => state.tourReducer);

    useEffect(()=>{
        dispatch(getTourCities());
    },[]);

    const onEdit = (data) => {
        setEditData(data)
    }

    let teamsContent;

    if(tourCityLoading){
        teamsContent = <Loader />
    }else{
        teamsContent =tourCities && <TeamsFeed 
                                    onEdit = {onEdit}
                                    trekRegions={tourCities} />;
    }
    return (
        <div className="treks">
            <div className="treks-list">
                {teamsContent}
            </div>
            <div className="treks-form">
                <TourCityForm 
                    onEdit={onEdit}
                    editData={editData}
                    isEdit={!isEmpty(editData)}
                />
            </div>
        </div>
    )
}

export default TrekRegion;