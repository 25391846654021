import React, { Component } from "react";
import { connect } from "react-redux";
import { getUser,editAccess } from "../../actions/userActions";
import isEmpty from '../../validations/isEmpty';
import Avatar from 'react-avatar';
import Access from './Access'
import Change from './Change'
import './user.styles.scss'

class User extends Component {
	constructor(props) {
		super(props);

		this.state = {
            user: {},
        };
	}

	async componentDidMount() {
		const { id } = this.props.match.params;
		await this.props.getUser(id);
	}
	componentDidUpdate(prevProps, prevState) {
		const { user } = this.props;
		if (!isEmpty(user) && user !== prevProps.user) {
			this.setState({ user });
		}
    }
	render() {
        const { user } = this.state;
        const {loading} = this.props;
        if(loading){
            return <h2>Loading...</h2>
        }
		return (
            <div className="user">
                <div className="user-details">
                    <div className="user-imag">
                        <Avatar name={user.name} src={user.photo} round="5" size="150px" />
                    </div>
                    <div className="user-list">
                        <div className="user-name">
                            <h2>{user.name}</h2>
                            <span>{user.user_name}</span>
                        </div>
                        <div className="user-email">
                            <span>{user.email}</span>
                        </div>
                        <div className="user-phone">
                            <span>{user.phone}</span>
                        </div>
                    </div>
                </div>
                {
                    isEmpty(user)?
                    null :
                    <Change user={user} />
                }
                
                {
                    isEmpty(user) ? null : user.role === 'god' ? null : <Access user={user} />
                }
            </div>
        )
        
	}
}

const mapDispatchToProps = {
    getUser,
    editAccess
};

const mapStatToProps = state => ({
	user: state.userReducer.user,
	loading: state.userReducer.loading,
});

export default connect(mapStatToProps, mapDispatchToProps)(User);
