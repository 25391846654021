import isEmpty from '../validations/isEmpty'

export const getPages = (pages)=>{
    if(isEmpty(pages)){
      return pages={
        pageNumber:0,
        pageSize:10
      }
    }
    return pages
  }