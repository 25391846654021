import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "../../validations/isEmpty";
import { getCustomer } from "../../actions/customerActions";
import { Loader } from "rsuite";
import avatar from "../../assets/user.png";
import { Icon } from "rsuite";
import Moment from "react-moment";
import boarding from "../../assets/boarding pass.PNG";
import { getStatus } from "../../helpers/getStatus";
import ReactToPrint from "react-to-print";

class CustomerInfo extends Component {
    render(){
		const {customer} = this.props;
		const a= customer.roomType ? customer.roomType : '';
		const roomType = a.split(' ').map(item=>item[0]).join('')
        return (
            <div className="paper">
                <div className="boarding-container">
                    <div className="boarding-image">
                        <img src={boarding} alt="Boarding Pass" />
                    </div>
					<div className="boarding-wifi">
						<span className="wifi-name">{customer.wifi && customer.wifi.wifi}</span>
						<span className="wifi-pass">{customer.wifi && customer.wifi.password}</span>
					</div>
                    <div className="boarding-details" id="boardingPrint">
                        <span className="position name">{customer.name}</span>
                        <span className="position country">{customer.country}</span>
                        <span className="position checked-in">
                            <Moment format="D MMM YYYY" withTitle>
                                {customer.checkedInDate}
                            </Moment>
                        </span>
                        <span className="position checked-out">
                            <Moment format="D MMM YYYY" withTitle>
                                {customer.checkedInDate}
                            </Moment>
                        </span>
                        <span className="position email">{customer.email}</span>
                        <span className="position roomType">{roomType}</span>
                        <span className="position status">
                            {customer.status}
                        </span>
                    </div>
					<div className="boarding-code">
						<img className="code-image" src={customer.code} alt={customer.name}/>
					</div>
            </div>
        </div>
            
        );
    }
};

class Customer extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            
        }
        this.componentRef="";
        this.onPrint=this.onPrint.bind(this);
    }
    
	componentDidMount() {
		const { id } = this.props.match.params;
		this.props.getCustomer(id);
	}
	onPrint() {
		const content = document.getElementById("boardingPrint");
		debugger;
		var pri = document.getElementById("ifmcontentstoprint").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
		// el.print()
	}
	render() {
		const { id } = this.props.match.params;
		const { customer, loading } = this.props.customerReducer;
		if (loading || customer === null) {
			return <Loader backdrop content="loading..." vertical />;
		} else {
			return (
				<div className="customers">
					<iframe
						id="ifmcontentstoprint"
						style={{ height: 0, width: 0, position: "absolute" }}
					></iframe>
					<div className="space-between">
						<h2 className="primary-heading">Customer Details</h2>
						<div className="button-group">
							<button className="info btn-rounded">Edit</button>
						</div>
					</div>
                    <ReactToPrint
                        trigger={() => <a href="#" className="btn btn-primary">Print this out!</a>}
                        content={() => this.componentRef}
                    />
                    <CustomerInfo ref={ref=>this.componentRef=ref} customer={customer} />
                    
					{/* <button onClick={() => this.onPrint()}>Print</button> */}
					<section className="section-customer">
						{/* <div className="customer-left">
							<div className="customers-details">
								<div className="details-row">
									<span className="details-title">
										Name :{" "}
									</span>
									<span className="details-name">
										{customer.name}
									</span>
								</div>
								<div className="details-row">
									<span className="details-title">
										Email :{" "}
									</span>
									<span className="details-name">
										{customer.email}
									</span>
								</div>
								<div className="details-row">
									<span className="details-title">
										Phone :{" "}
									</span>
									<span className="details-name">
										{customer.phone}
									</span>
								</div>
								<div className="details-row">
									<span className="details-title">
										Country :{" "}
									</span>
									<span className="details-name">
										{customer.country}
									</span>
								</div>
								<div className="details-row">
									<span className="details-title">
										Status :{" "}
									</span>
									<span className="details-name">
										<span
											className={
												customer.status === "paid"
													? "chips chips-green"
													: "chips chips-red"
											}
										>
											{customer.status}
										</span>
									</span>
								</div>
							</div>
						</div> */}
						<div className="customer-right">
							<UserBox
								title="Added By"
								name={customer.addedBy && customer.addedBy.name}
								photo={
									customer.addedBy && customer.addedBy.photo
								}
								history={this.props.history}
								id={customer.addedBy && customer.addedBy.id}
								date={customer.createdAt}
							/>
							{isEmpty(customer.editedBy) ? null : (
								<UserBox
									title="Edited By"
									name={
										customer.editedBy &&
										customer.editedBy.name
									}
									photo={
										customer.editedBy &&
										customer.editedBy.photo
									}
									history={this.props.history}
									id={
										customer.editedBy && customer.addedBy.id
									}
									date={customer.updatedAt}
								/>
							)}
						</div>
					</section>
				</div>
			);
		}
	}
}

const UserBox = ({ history, title, name, id, date, photo }) => {
	return (
		<div className="user-box">
			<h3 className="user-box__heading">{title}</h3>
			<div className="user-box__details">
				<div className="user-box__images">
					<img src={isEmpty(photo) ? avatar : photo} alt="" />
				</div>
				<div className="user-details">
					<h4>{name}</h4>
					<div className="flex">
						<span className="time">
							<Moment format="D MMM YYYY" withTitle>
								{date}
							</Moment>
							{/* {date} */}
						</span>
						<span className="time">
							<Moment format="hh:mm A">{date}</Moment>
						</span>
					</div>
					<div className="button-group">
						<button
							onClick={() => history.push("/users/" + id)}
							className="btn right-icon"
						>
							Profile
							<Icon icon="angle-right" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

Customer.propTypes = {
	getCustomer: PropTypes.func.isRequired,
	customerReducer: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	customerReducer: state.customerReducer,
	auth: state.auth,
	errorReducer: state.errorReducer
});

export default connect(mapStateToProps, { getCustomer })(Customer);

// export default Customer;
