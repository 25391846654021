import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTreks } from '../../actions/trekActions';
import { getSettings } from '../../actions/settingActions';
import {Loader} from 'rsuite'
import TreksFeed from './TreksFeed';
import TrekForm from './TrekForm';
import TrekDrawer from './treksDrawer'
import './treks.styles.scss'

export class Treks extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             editData:{}
        }
        this.onEdit=this.onEdit.bind(this);
    }
    
    componentDidMount() {
        this.props.getTreks();
        if(!this.props.settings){
            this.props.getSettings()
        }
    }
    onEdit(editData){
        this.setState({editData})
    }
    render() {
        const {loading,treks} = this.props;
        const { editData } = this.state;
        let treksContent;
        
        if(loading){
            treksContent = <Loader />
        }else{
            treksContent =treks && <TreksFeed 
                                        onEdit = {this.onEdit}
                                        treks={treks} />;
        }
        return (
            <div style={{padding:12, display:'flex', flexDirection: 'column', gap:12}}>
                <TrekDrawer 
                    onEdit={this.onEdit}
                    editData={editData}
                />
                <div className="treks-list">
                    {treksContent}
                </div>
            </div>
        )
    }
}

Treks.propTypes = {
    getTreks: PropTypes.func.isRequired,
    treks: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
  };
  
  const mapStateToProps = state => ({
    treks: state.trekReducer.treks,
    settings: state.settingReducer.settings,
    loading: state.trekReducer.loading,
  });
  
  export default connect(mapStateToProps, { getTreks,getSettings })(Treks);
