import React from 'react'
import {  Switch  } from "react-router-dom";
import PrivateRoute from "../components/common/PrivateRoute";
import Treks from '../screens/treks'
import Trek from '../screens/trek'
import Details from '../screens/trek/details'

function AuthLinks() {
    return (
        <>
            
                <PrivateRoute exact path="/treks" component={Treks} />
            
            
                <PrivateRoute exact path="/treks/:handle" component={Trek} />
                <PrivateRoute exact path="/treks/id/:id" component={Trek} />
            
            
                <PrivateRoute exact path="/treks/:handle/details" component={Details} />
            
        </>
    )
}

export default AuthLinks
