import isEmpty from './isEmpty'
export const validateTeams = (data)=>{
	let errors ={};
    if(isEmpty(data.name)){
		errors.name = 'Name Field is required';
	}
	if(isEmpty(data.designation)){
		errors.designation = 'Designation Field is required';
	}
	if(isEmpty(data.details)){
		errors.details = 'Details Field is required';
	}
	if(isEmpty(data.age)){
		errors.age = 'Age Field is required';
	}
	if(isEmpty(data.gender)){
		errors.gender = 'Gender Field is required';
	}
	// return{
	// 	errors,
	// 	isValid: isEmpty(errors)
	// }
	return errors
}


export const validateService = (data)=>{
	let errors ={};
    if(isEmpty(data.name)){
		errors.name = 'Name Field is required';
	}
	if(isEmpty(data.note)){
		errors.note = 'Note Field is required';
	}
	if(isEmpty(data.details)){
		errors.details = 'Details Field is required';
	}
	if(isEmpty(data.serviceTypeId)){
		errors.serviceTypeId = 'Service Type Field is required';
	}
	
	// return{
	// 	errors,
	// 	isValid: isEmpty(errors)
	// }
	return errors
}

export const validateBloge = (data)=>{
	let errors ={};
    if(isEmpty(data.title)){
		errors.title = 'Title Field is required';
	}
	if(isEmpty(data.handle)){
		errors.handle = 'Handle Field is required';
	}
	if(isEmpty(data.summary)){
		errors.summary = 'Summary Field is required';
	}
	if(isEmpty(data.body)){
		errors.body = 'Body Field is required';
	}
	if(isEmpty(data.tags)){
		errors.tags = 'Tgas Field is required';
	}
	if(isEmpty(data.sub_title)){
		errors.sub_title = 'Sub Title Field is required';
	}
	
	// return{
	// 	errors,
	// 	isValid: isEmpty(errors)
	// }
	return errors
}