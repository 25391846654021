import React, { Component } from 'react'
import RoomForm from './RoomForm'
import List from './List'
import {connect} from 'react-redux'
import {getRooms,addRoom,editRoom,deleteRoom} from '../../actions/roomActions';
import { Loader } from "rsuite";
import './room.styles.scss'

export class Rooms extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            room:null,
            isEdit:false
        }
        this.onEditComplete=this.onEditComplete.bind(this);
        this.onEdit=this.onEdit.bind(this);
    }
    componentDidMount(){
        this.props.getRooms();
    }
    onEdit(room){
        this.setState({isEdit:true,room})
    }
    onEditComplete(){
        this.setState({isEdit:false,room:''})
    }
    render() {
        const {roomReducer,rooms,addRoom,editRoom,deleteRoom} = this.props;
        return (
            <div className="treks">
                {
                    roomReducer.loading?
                    <Loader backdrop content="Loading..." />
                    :null
                }
                <div className="treks-list">
                    <List 
                        rooms={rooms}
                        deleteRoom={deleteRoom}
                        onEdit={this.onEdit}
                    />
                </div>
                <div className="treks-form">
                    <RoomForm
                        // addRoom={addRoom}
                        editRoom={editRoom}
                        roomReducer={roomReducer}
                        onEditComplete={this.onEditComplete}
                        editState={this.state}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    rooms:state.roomReducer.rooms,
    roomReducer:state.roomReducer,
})

const mapDispatchToProps = {
    getRooms,
    addRoom,
    editRoom,
    deleteRoom
}

export default connect(mapStateToProps,mapDispatchToProps)(Rooms)