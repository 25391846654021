import React, { useState } from "react";
import { Button, Modal, Icon, ButtonToolbar } from "rsuite";
import Formed from "./form";
import GroupForm from "./groupForm";
import { Animation } from "rsuite";
import renderHTML from "react-render-html";
import {
  deleteTrekPriceGroupSize,
  deleteTrekPriceDetails,
} from "../../../actions/trekActions";
import { useDispatch } from "react-redux";

const { Fade, Collapse, Transition } = Animation;

function Includes(props) {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState(null);
  const onModalOpen = () => setOpen(true);
  const onModalClose = () => setOpen(false);
  const { errorReducer, editCustomer, auth, statuses, trek } = props;
  const onData = (dt) => {
    setDatas(dt);
    setOpen(true);
  };
  const onClear = (dt) => {
    setDatas(null);
    setOpen(false);
  };
  return (
    <div className="">
      <Button onClick={onModalOpen}>Add Price</Button>
      <Modal full backdrop='static' show={open} onHide={onModalClose}>
        <Modal.Header>
          <Modal.Title>Trek Price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formed
            errorReducer={errorReducer}
            close={onClear}
            auth={auth}
            datas={datas}
            isEdit={datas != null}
            editCustomer={editCustomer}
            statuses={statuses}
            trek={trek}
            settings={props.settings}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onModalClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="pricing">
        <div className="pricing-main">Pricing</div>
        <div className="pricing-content">
          {trek?.priceDetails &&
            trek?.priceDetails.map((it) => {
              return <Iti includes={it} onData={onData} />;
            })}
        </div>
      </div>
    </div>
  );
}

const Iti = (props) => {
  const { includes, onData } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [datas, setDatas] = useState(null);
  const onModalOpen = () => setShow(true);
  const onModalClose = () => setShow(false);
  const {} = props;
  const onFormData = (dt) => {
    setDatas(dt);
    setShow(true);
  };
  const onClear = (dt) => {
    setDatas(null);
    setShow(false);
  };
  return (
    <>
      <div className="pricing-single">
        <div className="pricing-container">
          <div className="pricing-title">{includes.packageType}</div>

          <div>
            <div
              onClick={() => onData(includes)}
              className="includes__edit text-center"
            >
              <Icon icon="edit" size="lg" color="red" />
            </div>
            <div
              onClick={() => setOpen(!open)}
              className="includes__delete text-center"
            >
              <Icon icon="minus-square" size="lg" color="blue" />
            </div>
            <Modal
              backdrop="static"
              show={open}
              onHide={() => setOpen(false)}
              size="xs"
            >
              <Modal.Body>
                <Icon
                  icon="remind"
                  style={{
                    color: "#ffb300",
                    fontSize: 24,
                  }}
                />
                {"  "}
                Are you sure, You want to delete this includes ?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    dispatch(deleteTrekPriceDetails(includes.id));
                    setOpen(false);
                  }}
                  appearance="primary"
                >
                  Ok
                </Button>
                <Button onClick={() => setOpen(false)} appearance="subtle">
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div className="pricing-accom">
          <h5>Includes</h5>
          <div className="pricing-includes">
            {includes.services_includes.map((item) => (
              <div className="pricing-accom-item">{item}</div>
            ))}
          </div>
          <h5>Excludes</h5>
          <div className="pricing-includes">
            {includes.services_excludes.map((item) => (
              <div className="pricing-accom-item">{item}</div>
            ))}
          </div>
        </div>
        <Button onClick={onModalOpen}>Add Group Size</Button>
        <Modal full backdrop='static' show={show} onHide={onModalClose}>
          <Modal.Header>
            <Modal.Title>Trek Group Size</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GroupForm
              close={onClear}
              datas={datas}
              isEdit={datas != null}
              trek={includes}
              // editCustomer={editCustomer}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onModalClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <div>
          <div className="group-size__title">Group Sizes</div>
          {includes.group_sizes &&
            includes.group_sizes.map((group) => (
              <GroupSize onData={onFormData} group={group} />
            ))}
        </div>
      </div>
    </>
  );
};

const GroupSize = (props) => {
  const { group, onData } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className="group-size">
      <div className="group-size-details">
        <div className="group-size-group">{group.group_size}</div>
        <div className="group-size-price">Rs. {group.price}</div>
      </div>
      <div className="group-size-buttons">
        <div
          onClick={() => onData(group)}
          className="includes__edit text-center"
        >
          <Icon icon="edit" size="lg" color="red" />
        </div>
        <div
          onClick={() => setOpen(!open)}
          className="includes__delete text-center"
        >
          <Icon icon="minus-square" size="lg" color="blue" />
        </div>
        <Modal
          backdrop="static"
          show={open}
          onHide={() => setOpen(false)}
          size="xs"
        >
          <Modal.Body>
            <Icon
              icon="remind"
              style={{
                color: "#ffb300",
                fontSize: 24,
              }}
            />
            {"  "}
            Are you sure, You want to delete this group size ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(deleteTrekPriceGroupSize(group.id));
                setOpen(false);
              }}
              appearance="primary"
            >
              Ok
            </Button>
            <Button onClick={() => setOpen(false)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Includes;
