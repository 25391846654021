import React, {useState, useEffect} from 'react'
import isEmpty from '../../validations/isEmpty';
import {validateTeams} from '../../validations/validateTeams';
import PropTypes from 'prop-types';
import { addTeam,editTeam } from '../../actions/teamActions';
import useFormValidation from "../../validations/formValidations";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    ButtonToolbar,
    Button,
    Loader,
    RadioGroup,
    Alert,
    Radio,
} from "rsuite";
import {useDispatch, useSelector} from 'react-redux';

const INITIAL_STATE = {
    name: "",
    designation: "",
    details: "",
    age: null,
    gender:'Male',
};

function TeamForm({onEdit,editData,isEdit}) {
    const {
        handleSubmit,
        handleChange2,
        handleDateChange,
        handleBlur,
        checkErrors,
        values,
        setInitial,
        errors,
        isSubmitting,
        clearValues
    } = useFormValidation(INITIAL_STATE, validateTeams);
    const errorReducer = useSelector(state=>state.errorReducer)
    const load = useSelector(state=>state.teamReducer.load); 
    
    const [errs, setErrs] = React.useState({});
    const dispatch = useDispatch();
    if (isSubmitting) {
        if(isEmpty(errors)){
            
        }
    }
    const handleSubmits = (e) => {
        e.preventDefault();
        const {isValid} =  checkErrors();
        if(isValid){
            console.log(values,isEdit)
            if(isEmpty(errors)){
                if(isEdit){
                    dispatch(editTeam(values.id,values));
                    clearValues();
                    onEdit({})
                    return false;
                }
                dispatch(addTeam(values));
                clearValues();
            }
        }
    }
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);

    useEffect(()=>{
        if(!isEmpty(editData)){
            const {age,...rest} = editData;
            let newAge = parseInt(age);
            let newData = {age:newAge,...rest}
            setInitial(newData)
        }
    },[editData]);

    useEffect(() => {
        window.addEventListener('keydown',(e)=>{handleEsc(e)})
    }, [isEdit])

    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const handleEsc = (e,cancel='') => {
        if(e.key === 'Escape' || cancel==='cancel'){
            clearValues();
            onEdit({})
        }
    }
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };
    console.log(editData)
    return (
        <div>
            <Form>
                <FormGroup className={getError("name")}>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl
                        name="name"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.name}
                        errorMessage={getErrorMessage('name')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("designation")}>
                    <ControlLabel>Designation</ControlLabel>
                    <FormControl
                        name="designation"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.designation}
                        errorMessage={getErrorMessage('designation')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("details")}>
                    <ControlLabel>Details</ControlLabel>
                    <FormControl
                        name="details"
                        type="text"
                        componentClass="textarea" 
                        rows={6}
                        style={{ resize: 'auto' }}
                        onBlur={e => handleBlur(e)}
                        value={values.details}
                        errorMessage={getErrorMessage('details')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("age")}>
                    <ControlLabel>Age</ControlLabel>
                    <FormControl
                        name="age"
                        type="number"
                        onBlur={e => handleBlur(e)}
                        value={values.age}
                        errorMessage={getErrorMessage('age')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <RadioGroup 
                        className="radio-container"
                        onChange={(value)=>handleDateChange(value,'gender')}
                        name="gender" 
                        inline 
                        value={values.gender}
                        appearance="picker" 
                        defaultValue="Easy">
                            <span className="radio-header">Gender: </span>
                            <Radio value="Male">Male</Radio>
                            <Radio value="Female">Female</Radio>
                            <Radio value="Others">Others</Radio>
                </RadioGroup>
                {/* <Uploader
                    fileListVisible={false}
                    listType="picture"
                    autoUpload={false}
                    onChange={image => {
                       this.setState({ image: image });
                           console.log(image);
                  }}
                    >
                    <button style={styles}>
                        {fileInfo ? (
                        <img src={fileInfo} width="100%" height="100%" />
                        ) : (
                        <Icon icon="avatar" size="5x" />
                        )}
                    </button>
                </Uploader> */}
                <FormGroup>
                    <ButtonToolbar>
                        <Button
                            appearance="primary"
                            disabled={load}
                            onClick={(e)=>handleSubmits(e)}
                        >
                            {load ? <Loader /> : null}
                            {isEdit ? "Update Team" : "Add Team"}
                        </Button>
                        {isEdit?(
                                <Button
                                onClick={(e)=>handleEsc(e,'cancel')}
                            >
                                Cancle Edit
                            </Button>
                        ):null}
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    )
}

export default TeamForm
