import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Icon, IconButton} from 'rsuite';
import {connect} from 'react-redux';
import { deleteAdventure } from '../../actions/adventuresActions';
import { Modal,Button } from 'rsuite';

export class AdventureItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show: false
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }
    close() {
        this.setState({ show: false });
    }
    open() {
        this.setState({ show: true });
    }
    render() {
        const {adventure,onEdit} = this.props;
        return (
            <div className="adventure-item adventure-grid">
                <div className="adventure-item__name">
                    <Link to={`/adventures/${adventure.handle}`}>{adventure.name}</Link>
                </div>
                <div className="adventure-item__price text-center">
                    {adventure.base_price}
                </div>
                <div className="adventure-item__days text-center">
                    {adventure.duration}
                </div>
                <div className="adventure-item__summary text-left">
                    {adventure.summary}
                </div>
                <div 
                    onClick={()=>onEdit(adventure)}
                    className="adventure-item__edit text-center">
                    <Icon icon="edit" size="lg" color="red"  />
                </div>
                <div 
                     onClick={this.open}
                    className="adventure-item__delete text-center">
                    <Icon icon="minus-square" size="lg" color="blue"  />
                </div>
                <Modal backdrop="static" show={this.state.show} onHide={this.close} size="xs">
                    <Modal.Body>
                        <Icon
                        icon="remind"
                        style={{
                            color: '#ffb300',
                            fontSize: 24
                        }}
                        />
                        {'  '}
                        Are you sure, You want to delete this adventure ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>{
                            this.props.deleteAdventure(adventure.id);
                            this.close()
                        }} appearance="primary">
                        Ok
                        </Button>
                        <Button onClick={this.close} appearance="subtle">
                        Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = {
    deleteAdventure,
}

export default connect(mapStateToProps,mapDispatchToProps)(AdventureItem);