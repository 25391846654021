import {
  CLEAR_ERRORS,
  GET_ERRORS,
  TOUR_LOADING,
  ADD_TOUR,
  EDIT_TOUR,
  GET_TOUR,
  GET_TOURS,
  DELETE_TOUR,
  TOUR_CITY_LOADING,
  ADD_TOUR_CITY,
  EDIT_TOUR_CITY,
  GET_TOUR_CITY,
  GET_TOUR_CITIES,
  DELETE_TOUR_CITY,
  TOUR_PACKAGE_LOADING,
  ADD_TOUR_PACKAGE,
  EDIT_TOUR_PACKAGE,
  GET_TOUR_PACKAGE,
  GET_TOUR_PACKAGES,
  DELETE_TOUR_PACKAGE,
  TOUR_PACKAGE_ITINERARY_LOADING,
  ADD_TOUR_PACKAGE_ITINERARY,
  EDIT_TOUR_PACKAGE_ITINERARY,
  GET_TOUR_PACKAGE_ITINERARY,
  GET_TOUR_PACKAGE_ITINERARIES,
  DELETE_TOUR_PACKAGE_ITINERARY,

  TOUR_PACKAGE_INCLUDE_LOADING,
  ADD_TOUR_PACKAGE_INCLUDE,
  EDIT_TOUR_PACKAGE_INCLUDE,
  GET_TOUR_PACKAGE_INCLUDE,
  GET_TOUR_PACKAGE_INCLUDES,
  DELETE_TOUR_PACKAGE_INCLUDE
} from "../actions/types";

const initialState = {
  tours: [],
  tourPackages: [],
  tourPackage: {},
  tourCities: [],
  tour: {},
  tourLoading: false,
  tourCityLoading: false,
  tourPackageLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOUR_LOADING:
      return {
        ...state,
        tourLoading: action.payload,
      };
    case TOUR_CITY_LOADING:
      return {
        ...state,
        tourCityLoading: action.payload,
      };
    case TOUR_PACKAGE_LOADING:
      return {
        ...state,
        tourPackageLoading: action.payload,
      };
    case ADD_TOUR_PACKAGE_ITINERARY:
      return {
        ...state,
        tourPackage: state.tourPackage && {
          ...state.tourPackage,
          itineraries:[...state.tourPackage.itineraries,action.payload],
        },
        loading: false
      };
    case EDIT_TOUR_PACKAGE_ITINERARY:
      return {
        ...state,
        tourPackage: state.tourPackage && {
          ...state.tourPackage,
          itineraries:state.tourPackage.itineraries.map(item=>item.id === action.payload.id ? action.payload : item),
        },
        loading: false
      };
    case DELETE_TOUR_PACKAGE_ITINERARY:
      return {
        ...state,
        tourPackage: state.tourPackage && {
          ...state.tourPackage,
          itineraries:state.tourPackage.itineraries.filter(trek => trek.id !== action.payload),
        },
        loading: false
      };
    case ADD_TOUR_PACKAGE_INCLUDE:
      return {
        ...state,
        tourPackage: state.tourPackage && {
          ...state.tourPackage,
          includes:[...state.tourPackage.includes,action.payload],
        },
        loading: false
      };
    case EDIT_TOUR_PACKAGE_INCLUDE:
      return {
        ...state,
        tourPackage: state.tourPackage && {
          ...state.tourPackage,
          includes:state.tourPackage.includes.map(item=>item.id === action.payload.id ? action.payload : item),
        },
        loading: false
      };
    case DELETE_TOUR_PACKAGE_INCLUDE:
      return {
        ...state,
        tourPackage: state.tourPackage && {
          ...state.tourPackage,
          includes:state.tourPackage.includes.filter(trek => trek.id !== action.payload),
        },
        loading: false
      };
    case GET_TOURS:
      return {
        ...state,
        tours: action.payload,
        tourLoading: false,
      };
    case ADD_TOUR:
      return {
        ...state,
        tours: [action.payload, ...state.tours],
      };
    case EDIT_TOUR:
      return {
        ...state,
        tours: state.tours.map((room) =>
          room.id === action.id ? action.payload : room
        ),
      };
    case DELETE_TOUR:
      return {
        ...state,
        tours: state.tours.filter((room) => room.id !== action.payload),
      };
    case GET_TOUR_CITIES:
      return {
        ...state,
        tourCities: action.payload,
        tourCityLoading: false,
      };
    case ADD_TOUR_CITY:
      return {
        ...state,
        tourCities: [action.payload, ...state.tourCities],
      };
    case EDIT_TOUR_CITY:
      return {
        ...state,
        tourCities: state.tourCities.map((room) =>
          room.id === action.id ? action.payload : room
        ),
      };
    case DELETE_TOUR_CITY:
      return {
        ...state,
        tourCities: state.tourCities.filter(
          (room) => room.id !== action.payload
        ),
      };

    case GET_TOUR_PACKAGES:
      return {
        ...state,
        tourPackages: action.payload,
        tourPackageLoading: false,
      };
    case GET_TOUR_PACKAGE:
      return {
        ...state,
        tourPackage: action.payload,
      };
    case ADD_TOUR_PACKAGE:
      return {
        ...state,
        tourPackages: [action.payload, ...state.tourPackages],
      };
    case EDIT_TOUR_PACKAGE:
      return {
        ...state,
        tourPackages: state.tourPackages.map((room) =>
          room.id === action.id ? action.payload : room
        ),
      };
    case DELETE_TOUR_PACKAGE:
      return {
        ...state,
        tourPackages: state.tourPackages.filter(
          (room) => room.id !== action.payload
        ),
      };
    default:
      return state;
    
    }
    
}
