import React, { Component } from 'react'
import {connect} from 'react-redux';
import {getRoom,uploadRooms} from '../../actions/roomActions';
import { Loader } from "rsuite";
import UploadImages from './UploadImages'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import isEmpty from '../../validations/isEmpty'

const SampleNextArrow = (props) =>{
    const { className, style, onClick } = props;
    return (
      <div
        className={className + ' custom-icon'}
        style={{ ...style}}
        onClick={onClick}
      />
    );
}

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + ' custom-icon'}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  }
  


export class Room extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             room:{}
        }
        this._renderImage=this._renderImage.bind(this);
        this.onImageChange = this.onImageChange.bind(this)
    }
    
    async componentDidMount(){
        const {id} = this.props.match.params;
        await this.props.getRoom(id);
        const {room} = this.props;
        if(!isEmpty(room)){
            this.setState({room})
        }
    }
    componentDidUpdate = (prevProps)=>{
        const {room} = this.props;
        if(!isEmpty(room) && room !== prevProps.room){
            this.setState({room})
        }
    } 
    onImageChange(name){
        this.setState(prevState=>({
                ...prevState,
                room:{
                    ...prevState.room,
                    images:prevState.room.images.filter(image=>image!==name)
                }
            })
        )
    }
    _renderImage(){
        const {room} = this.props;
        const settings = {
            // customPaging: function(i) {
            //     console.log(i);
            //     return (
            //         <a>
            //           <img src={`/abstract0${i + 1}.jpg`} />
            //         </a>
            //       );
            // },
            dots: false,
            infinite: true,
            autoplay:false,
            autoplaySpeed:3000,
            lazyLoad: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            // autoplaySpeed: 2000,
            cssEase: "linear",
            arrow:false
          };
        return(
            <Slider ref={c => (this.slider = c)} {...settings}>
                {room.images.map(image=>{
                    return(
                        <div className="slide-image">
                            <img src={image} className="slider-image" alt={room.type} />
                        </div>
                    )
                })}
            </Slider>
        )
    }

    render() {
        const {loading,room} = this.props;
        return (
            <div className="single">
                 {
                    loading?
                    <Loader backdrop content="Loading..." vertical />
                    :null
                }
                {
                    room && <RoomHead room={room} />
                }
                <UploadImages
                    uploadRooms={this.props.uploadRooms}
                    id={this.props.room.id}
                    images={this.state.room.images}
                    onImageChange={this.onImageChange}
                />
                <div className="single-slider">
                    {
                        room.images && this._renderImage()
                    }
                </div>
            </div>
        )
    }
}

export const RoomHead  = ({room}) =>{
    return(
        <div className="single-room">
            <div className="single-room__type">
                <h1>{room.type}</h1>
            </div>
            <div className="single-room__heading">
                <span>{room.heading}</span>
            </div>
            <div className="single-room__desc">
                <span>{room.description}</span>
            </div>
            <div className="single-room__icons">
                {
                    room.icons && room.icons.map(RoomIcon)
                }
            </div>
        </div>
    )
}

const RoomIcon = (icon)=>{
    return(
        <div className="single-room__icon">
            <div className="icon-img">
                <img src={icon.icon} alt={icon.label} />
            </div>
            <div className="icon-label">
                <span>{icon.label}</span>
            </div>
        </div>
    )
}

const mapStateToProps = state =>({
    room:state.roomReducer.room,
    loading:state.roomReducer.loading,
})

const mapDispatchToProps = {
    getRoom,
    uploadRooms
}

export default connect(mapStateToProps,mapDispatchToProps)(Room)
