import React, {useState, useEffect} from 'react'
import isEmpty from '../../../validations/isEmpty';
import {validateTeams} from '../../../validations/validateTeams';
import PropTypes from 'prop-types';
import { addTourCity,editTrekRegions } from '../../../actions/tourActions';
import useFormValidation from "../../../validations/formValidations";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    ButtonToolbar,
    Button,
    Loader,
    RadioGroup,
    Alert,
    Radio,
} from "rsuite";
import {useDispatch, useSelector} from 'react-redux';

const INITIAL_STATE = {
    name: "",
    short: "",
};

export const validateRegions = (data)=>{
	let errors ={};
    if(isEmpty(data.name)){
		errors.name = 'Name Field is required';
	}
	if(isEmpty(data.handle)){
		errors.handle = 'Handle Field is required';
	}
	return errors
}

function TeamForm({onEdit,editData,isEdit}) {
    const {
        handleSubmit,
        handleChange2,
        handleDateChange,
        handleBlur,
        checkErrors,
        values,
        setInitial,
        errors,
        isSubmitting,
        clearValues
    } = useFormValidation(INITIAL_STATE, validateRegions);
    const errorReducer = useSelector(state=>state.errorReducer)
    const load = useSelector(state=>state.teamReducer.load); 
    
    const [errs, setErrs] = React.useState({});
    const dispatch = useDispatch();
    if (isSubmitting) {
        if(isEmpty(errors)){
            
        }
    }
    const handleSubmits = (e) => {
        
        e.preventDefault();
        const {isValid} =  checkErrors();
        console.log({values,isValid})
        if(isValid){
            console.log(values,isEdit)
            if(isEmpty(errors)){
                if(isEdit){
                    dispatch(addTourCity({id:values.id,...values},true));
                    // dispatch(editTrekRegions(values.id,values));
                    clearValues();
                    onEdit({})
                    return false;
                }
                dispatch(addTourCity(values));
                clearValues();
            }
        }
    }
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);

    useEffect(()=>{
        if(!isEmpty(editData)){
            const {age,...rest} = editData;
            let newAge = parseInt(age);
            let newData = {age:newAge,...rest}
            setInitial(newData)
        }
    },[editData]);

    useEffect(() => {
        window.addEventListener('keydown',(e)=>{handleEsc(e)})
    }, [isEdit])

    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const handleEsc = (e,cancel='') => {
        if(e.key === 'Escape' || cancel==='cancel'){
            clearValues();
            onEdit({})
        }
    }
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };
    console.log(editData)
    return (
        <div>
            <Form>
                <FormGroup className={getError("name")}>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl
                        name="name"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.name}
                        errorMessage={getErrorMessage('name')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("handle")}>
                    <ControlLabel>Handle</ControlLabel>
                    <FormControl
                        name="handle"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.handle}
                        errorMessage={getErrorMessage('handle')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                
                <FormGroup>
                    <ButtonToolbar>
                        <Button
                            appearance="primary"
                            disabled={load}
                            onClick={(e)=>handleSubmits(e)}
                        >
                            {load ? <Loader /> : null}
                            {isEdit ? "Update Tour City" : "Add Tour City"}
                        </Button>
                        {isEdit?(
                                <Button
                                onClick={(e)=>handleEsc(e,'cancel')}
                            >
                                Cancle Edit
                            </Button>
                        ):null}
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    )
}

export default TeamForm
