import React, { Component } from "react";
import {connect} from 'react-redux';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import {forgotPassword} from '../../actions/userActions';
import isEmpty from '../../validations/isEmpty'
import logo from '../../assets/FlockFinal.svg';
import {Link} from 'react-router-dom'

export class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
            email: "",
            errors:{}
        };
        this.onSubmit=this.onSubmit.bind(this);
    }
    componentWillUpdate(prevProps,prevState){
        const {errors} =this.props;
        if(!isEmpty(errors) && errors !== prevProps.errors){
            this.setState({errors})
        }
    }
    onSubmit(e){
        e.preventDefault();
        const {email}=this.state;
        this.props.forgotPassword(email)
    }
	render() {
        const {errors} = this.state;
		return (
			<div className="forgot">
				<div className="forgot-left">
					<div className="forgot-top-logo">
						<img src={logo} alt="Flock Logo "/>
					</div>
				</div>
				<div className="forgot-right">
					<form
                        onSubmit={this.onSubmit}
                    >
						<div className="forgot-status">
							<span>
								Forgot password ? Enter your Registered email
								below to reset password
							</span>
						</div>
						<div className="forgot-input">
							<TextFieldGroup
								placeholder="email"
								name="email"
								type="email"
								value={this.state.email}
								onChange={e =>
									this.setState({ email: e.target.value })
								}
								error={errors.email}
							/>
						</div>
						<Link className="forgot-password-link" to="/login">
                        <span>Login</span>
						</Link>
						<div className="forgot-submit">
							<button className="login-button">
								Reset-password
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state=>({
    errors:state.errors
})

export default connect(mapStateToProps,{forgotPassword})(ForgotPassword);
