import { GET_SCAN, CLEAR_SCAN, SCAN_LOADING } from "../actions/types";
import {getCustomer} from './customerActions'
import {getError} from '../helpers/getUnauth'

export const scanned=(data,isScanned)=>dispatch=>{
    dispatch(setLoading());
    // getCustomer(data);
    dispatch({
        type:GET_SCAN,
        payload:{data,isScanned}
    });
}

export const clearScan=()=>dispatch=>{
    // dispatch(setLoading());
    dispatch({
        type:CLEAR_SCAN,
        payload:''
    });
}

// Scan loading
export const setLoading = () => {
    return {
        type: SCAN_LOADING
    };
};

// // Clear errors
// export const clearErrors = () => {
//     return {
//         type: CLEAR_ERRORS
//     };
// };
