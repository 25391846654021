import React, { useEffect, useState } from 'react'
import Tiny from '../../components/common/TinyEmc';
import renderHTML from 'react-render-html';
import { Button, } from "rsuite";
import { useDispatch } from 'react-redux';
import {addDetails} from '../../actions/trekActions'

function Overview(props) {
    const {trek} = props;
    const [overview,setOverview] = useState("")
    const dispatch = useDispatch()
    useEffect(()=>{
        if(trek.overview !=null){
            setOverview(renderHTML(trek.overview))
        }
    },[trek]);
    const handleSubmits = () => {
        dispatch(addDetails(trek.id,{overview}))
    }
    return (
        <div>
             <Tiny 
                onChange={(name,value)=>setOverview(value)}
                value={overview}
                name="overview"
                // errorMessage={getErrorMessage("details")}
            />
            <Button
                appearance="primary"
                onClick={handleSubmits}
            >
                Submit
            </Button>
            {
                trek.overview ? renderHTML(renderHTML(trek.overview)) : null
            }
        </div>
    )
}

export default Overview