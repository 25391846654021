import React, { Component,useEffect } from 'react'
import { Button, Modal, Icon, ButtonToolbar } from 'rsuite';
import { Form, FormGroup, FormControl, ControlLabel, HelpBlock,Input } from 'rsuite';
import { SelectPicker } from 'rsuite';
import useFormValidation from "../../validations/formValidations";
import validateUser from "../../validations/validateUser";
import isEmpty from "../../validations/isEmpty"
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {editUser} from '../../actions/userActions'
import { Loader } from 'rsuite';

class EditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show: false
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
      }
      close() {
        this.setState({ show: false });
      }
      open() {
        this.setState({ show: true });
      }
      
  render() {
    const { backdrop, show } = this.state;
    const {errorReducer,editUser,auth,datas}=this.props;
    return (
      <span>
        {/* <Button onClick={this.open} appearance="primary" loading={false}>
            <Icon icon="plus-circle" style={{fontSize:18}}  /> Add User
        </Button> */}
        {
            React.cloneElement(this.props.children, {
                key: 0,
                onClick: (e)=>this.open()
            })
        }
            <Modal backdrop={false} show={show} onHide={this.close}>
                <Modal.Header>
                    <Modal.Title>Edit User Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formed errorReducer={errorReducer} close={this.close} auth={auth} datas={datas} editUser={editUser} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.close} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
      </span>
    )
  }
}





export const Formed = ({errorReducer,editUser,auth,datas,close})=>{
    const INITIAL_STATE = {
        email: !isEmpty(datas.email)?datas.email:'',
        // password:'',
        name:!isEmpty(datas.name)?datas.name:'',
        user_name:!isEmpty(datas.user_name)?datas.user_name:'',
        phone:!isEmpty(datas.phone)?datas.phone:'',
        role:!isEmpty(datas.role)?datas.role:'',
      };
    const data =[
        {label:"Super Admin",value:'super_admin',name:'role'},
        {label:"Admin",value:'admin',name:'role'},
        {label:"Guard",value:'guard',name:'role'},
    ];
    const {
        handleSubmit,
        handleChange2,
        handleSelect,
        handleBlur,
        values,
        errors,
        isSubmitting
      } = useFormValidation(INITIAL_STATE, validateUser);
      const [errs, setErrs] = React.useState({});
      console.log(datas)
      if(isSubmitting){
        editUser(datas.id,values)
        close()
      }
    // const {auth}=props;
    useEffect(() => {
      if(errorReducer){
          console.log(errorReducer)
          if(!isEmpty(errorReducer.error)){
            setErrs(errorReducer.error);
          }
      }
    }, [errorReducer])
    const getError = (type)=>{
        let errClass = !isEmpty(errors[type]) && !isEmpty(errs[type]) ? 'has-error' : ''
        return errClass
    }
    const getErrorMessage=(type)=>{
        if(!isEmpty(errors[type])){
            return errors[type]
        }else if (!isEmpty(errs[type])){
            return errs[type]
        }
        return ''
    }
    return (
        <Form>
        <FormGroup className={getError('name')}>
          <ControlLabel>Name</ControlLabel>
          <FormControl name="name" type="text" value={values.name} errorMessage={getErrorMessage('name')} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        <FormGroup className={getError('user_name')}>
          <ControlLabel>Username</ControlLabel>
          <FormControl name="user_name" type="text"  errorMessage={getErrorMessage('user_name')} value={values.user_name} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        <FormGroup className={getError('email')}>
          <ControlLabel>Email</ControlLabel>
          <FormControl name="email" type="email"  errorMessage={getErrorMessage('email')} value={values.email} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        <FormGroup className={getError('phone')}>
          <ControlLabel>Phone</ControlLabel>
          <FormControl name="phone" type="number" errorMessage={getErrorMessage('phone')} value={values.phone} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        {/* <FormGroup className={getError('password')}>
          <ControlLabel>Password</ControlLabel>
          <FormControl name="password" type="password" errorMessage={getErrorMessage('password')} value={values.password} onChange={handleChange2} />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup> */}
        <FormGroup className={getError('role')}>
          <ControlLabel>Role</ControlLabel>
          <SelectPicker data={data} name="role"  errorMessage={getErrorMessage('role')} style={{ width: 250 }} value={values.role || errs.role} onSelect={handleSelect}  />
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Button appearance="primary" disabled={isSubmitting} onClick={handleSubmit}>
                {isSubmitting?<Loader />:null}
                Submit
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    )
}


EditForm.propTypes ={
    editUser: PropTypes.func.isRequired,
    auth:PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired 
  }
  
  const mapStateToProps = (state) => ({
    auth:state.auth,
    errorReducer:state.errorReducer
  })
  
  
  export default connect(mapStateToProps, {editUser})(EditForm);
