import { Alert } from "rsuite";
export const getError = (err)=>{
	if(err){
		if(err.response && err.response.data){
			let res = err.response.data;
			if(res && res.status === 403){
                Alert.error(res.error, 5000);
                return;
            }
            if(res && res.status === 500){
                if( typeof res.error === "string"){
                    Alert.error(res.error, 5000);
                    return false;
                }
				Alert.error("Something went wrong", 5000);
                return false;
            }
            if(res && res.error &&  typeof res.error === "string"){
                Alert.error(res.error, 5000);
                return;
            }
		}
	}
	
}