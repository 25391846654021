import axios from "axios";
import { Alert } from "rsuite";
import isEmpty from "../validations/isEmpty";
import {getError} from '../helpers/getUnauth'

import {
	GET_BOOKINGS,
	BOOKINGS_LOADING,
	DELETE_BOOKING,
    CLEAR_ERRORS,
    EDIT_BOOKING,
	GET_BOOKINGS_QR,
	GET_BOOKING_QR,
    GET_BOOKING,
	GET_ERRORS
} from "./types";

import {getPages} from '../helpers/getPages';

// Get all contacts
export const getBookings = (pages) => async dispatch => {
    dispatch(setLoading());
    let page = getPages(pages);
	await axios
		.get("/api/v1/bookings",{ params:page})
		.then(res =>{
			dispatch({
				type: GET_BOOKINGS,
                payload: res.data.data,
                page:res.data.page
			})
		}
		)
		.catch(err =>{
			getError(err)
            dispatch({
				type: GET_BOOKINGS,
				payload: []
			})
        }
			
		);
};

// Get all contacts
export const getBookingsQR = (pages) => async dispatch => {
    dispatch(setLoading());
    let page = getPages(pages);
	await axios
		.get("/api/v1/bookings/qr",{ params:page})
		.then(res =>{
			dispatch({
				type: GET_BOOKINGS_QR,
                payload: res.data.data,
                page:res.data.page
			})
		}
		)
		.catch(err =>{
			getError(err)
            dispatch({
				type: GET_BOOKINGS_QR,
				payload: []
			})
        }
			
		);
};

// Get booking by id
export const getBooking = (id) => async dispatch => {
    dispatch(setLoading());
	await axios
		.get("/api/v1/bookings/"+id )
		.then(res =>{
			dispatch({
				type: GET_BOOKING,
                payload: res.data.data,
			})
		}
		)
		.catch(err =>{
			getError(err)
            dispatch({
				type: GET_BOOKING,
				payload: []
			})
        }
			
		);
};

// Get booking by id
export const getBookingQR = (id) => async dispatch => {
    dispatch(setLoading());
	await axios
		.get("/api/v1/bookings/qr/"+id )
		.then(res =>{
			dispatch({
				type: GET_BOOKING_QR,
                payload: res.data.data,
			})
		}
		)
		.catch(err =>{
			getError(err)
            dispatch({
				type: GET_BOOKING_QR,
				payload: []
			})
        }
			
		);
};

// Delete Review
export const deleteBooking = id => dispatch => {
	axios
		.delete(`/api/v1/bookings/${id}`)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: DELETE_BOOKING,
					payload: id
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			getError(err)
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};

// Delete Booking QR
export const deleteBookingQr = id => dispatch => {
	axios
		.delete(`/api/v1/bookings/qr/${id}`)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: DELETE_BOOKING,
					payload: id
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			getError(err)
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};

// User loading
export const setLoading = () => {
	return {
		type: BOOKINGS_LOADING
	};
};

// Clear errors
export const clearErrors = () => {
	return {
		type: CLEAR_ERRORS
	};
};