import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import TeamItem from './TeamItem';
import { InputGroup, Icon, Input } from "rsuite";
import { Modal, Button } from "rsuite";
import { Link } from "react-router-dom";
import { deleteTourCity } from "../../../actions/tourActions";
import { useDispatch, useSelector } from "react-redux";
const styles = {
  width: 300,
  marginBottom: 10,
};

function TeamsFeed({ trekRegions, onEdit }) {
  const newTeam = trekRegions;
  // const [newTeam, setNewTeam] = useState(trekRegions);
  const [show, setShow] = useState(null);

  const dispatch = useDispatch();
  const close = () => {
    setShow(null);
  };

  const open = (id) => {
    setShow(id);
  };
  return (
    <div className="treks-list__block">
      {/* <div className="treks-list__search">
                <InputGroup inside style={styles}>
                    <Input 
                     onChange={(val)=>onChange(val)}    
                    />
                    <InputGroup.Button>
                        <Icon icon="search" />
                    </InputGroup.Button>
                </InputGroup>
            </div> */}
      <div className="treks-list__header trek-grid">
        <span className="name">Name</span>
        <span className="price text-center">Handle</span>
        <span className="edit text-center">Edit</span>
        <span className="delete text-center">Delete</span>
      </div>
      <div className="treks-list__items">
        {newTeam &&
          newTeam.map((team) => (
            <div className="trek-item trek-grid">
              <div className="trek-item__days text-center">{team.name}</div>
              <div className="trek-item__days text-left">{team.handle}</div>
              {/* <div className="trek-item__days text-center">
                {team.details}
            </div> */}
              <div
                onClick={() => onEdit(team)}
                className="trek-item__edit text-center"
              >
                <Icon icon="edit" size="lg" color="red" />
              </div>
              <div
                onClick={() => open(team.id)}
                className="trek-item__delete text-center"
              >
                <Icon icon="minus-square" size="lg" color="blue" />
              </div>
            </div>
          ))}
      </div>
      <Modal backdrop="static" show={Boolean(show)} onHide={close} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure, You want to delete this trek region?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(deleteTourCity(show));
              close();
            }}
            appearance="primary"
          >
            Ok
          </Button>
          <Button onClick={close} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

TeamsFeed.propTypes = {
  teams: PropTypes.array.isRequired,
};

export default TeamsFeed;
