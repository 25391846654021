import axios from "axios";
import { Alert } from "rsuite";
import isEmpty from "../validations/isEmpty";
import {getError} from '../helpers/getUnauth'

import {
	GET_REVIEWS,
	REVIEWS_LOADING,
	DELETE_REVIEW,
    CLEAR_ERRORS,
    EDIT_REVIEW,
	GET_ERRORS
} from "./types";

import {getPages} from '../helpers/getPages'

// Get all contacts
export const getReviews = (pages) => async dispatch => {
    dispatch(setLoading());
    let page = getPages(pages);
	await axios
		.get("/api/v1/reviews",{ params:page})
		.then(res =>{
			dispatch({
				type: GET_REVIEWS,
                payload: res.data.data,
                page:res.data.page
			})
		}
		)
		.catch(err =>{
            getError(err)
            dispatch({
				type: GET_REVIEWS,
				payload: []
			})
        }
			
		);
};

// edit review popular
export const editPopularReview = (id, popular) => dispatch => {
    axios
      .put(`/api/v1/reviews/${id}/`, popular)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: EDIT_REVIEW,
            payload: res.data.data[0],
            id: id
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.success(res.data.error, 5000);
  
      })
      .catch(err => {
        getError(err)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  };

// Delete Review
export const deleteReview = id => dispatch => {
	axios
		.delete(`/api/v1/reviews/${id}`)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: DELETE_REVIEW,
					payload: id
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			getError(err)
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};


// User loading
export const setLoading = () => {
	return {
		type: REVIEWS_LOADING
	};
};

// Clear errors
export const clearErrors = () => {
	return {
		type: CLEAR_ERRORS
	};
};
