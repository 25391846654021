import React , {useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import { getAlpSettings } from '../../../actions/settingActions';
import {Loader} from 'rsuite'
// import TeamsFeed from './TeamsFeed';
// import TeamForm from './TeamForm';
// import './team.styles.scss'
import isEmpty from '../../../validations/isEmpty';
import SettingsForm from './settingsForm'
import SettingsImages from './settingsImages';


// teamReducer
function TrekRegion() {
    const [ editData,setEditData ] = useState({});

    const dispatch = useDispatch();
    const { alpSettings,loading } = useSelector(state => state.settingReducer);

    useEffect(()=>{
        dispatch(getAlpSettings());
    },[]);

    const onEdit = (data) => {
        setEditData(data)
    }

    let teamsContent;
    return (
        <div className="">
            <div className="treks-form">
                {/* <TeamForm 
                    onEdit={onEdit}
                    editData={editData}
                    isEdit={!isEmpty(editData)}
                /> */}
                <SettingsForm 
                    alpSettings={alpSettings}
                />
                <SettingsImages alpSettings={alpSettings} />
            </div>
        </div>
    )
}

export default TrekRegion;