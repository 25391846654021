import React, { Component } from "react";
import { Route, Switch,withRouter  } from "react-router-dom";
import PrivateRoute from "../components/common/PrivateRoute";
import store from "../store";
import jwt_decode from "jwt-decode";
import setAuthToken from "../utils/setAuthToken";
import { setCurrentUser } from "../actions/authActions";
import { logoutUser } from "../actions/authActions";
import BarcodeReader from 'react-barcode-reader'
import { BrowserRouter, Outlet, Link,   } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {scanned} from '../actions/scanAction'
import {getCustomer} from '../actions/customerActions'
import {getRooms} from '../actions/roomActions'
import {getSettings,getTrekRegions,getServiceTypes} from '../actions/settingActions'
import {getStatuses} from '../actions/statusActions'
import {getTourCities} from '../actions/tourActions'

import { Alert } from 'rsuite';
import isEmpty from '../validations/isEmpty'

import Sidebar from "../components/layout/Sidebar";
import Header from "../components/layout/Header";

import AuthLinks from './AuthLinks'
import Customers from "../screens/customers/Customers";
import Users from "../screens/users/Users";
import User from "../screens/user";
import NotFound from "../components/common/NotFound";
import Dashboard from "../screens/dashboard/Dashboard";
import AddCustomer from "../screens/customers/AddCustomer";
import Customer from '../screens/customers/Customer'
import Scan from '../screens/scan/Scan'
import Rooms from '../screens/room'
// import Status from '../screens/status'
import Settings from '../screens/settings';
import Contacts from '../screens/contacts';
import TripPlan from '../screens/tripplan';
import Room from '../screens/room/Room'
import TreksLinks from './TreksLinks'
import AdventureLinks from './AdventureLinks';

import Reviews from '../screens/reviews';
import Bookings from '../screens/bookings'
import BookingsQr from '../screens/bookingsqr'
import Booking from '../screens/bookings/Booking'
import BookingQr from '../screens/bookingsqr/Booking'
import Activiteis from '../screens/activities'
import Teams from '../screens/teams'
import Extras from '../screens/extras'

import TrekRegion from '../screens/extras/trekRegions'
import Services from '../screens/extras/services'
import ServiceTypes from '../screens/extras/services_type'
import Blogs from '../screens/extras/blogs'
import TourCity from '../screens/extras/tourCity'
import Tour from '../screens/extras/tour'
import Packages from '../screens/extras/packages'
import Package from '../screens/extras/packages/package'
import AlpSettings from '../screens/extras/alpSettings'
import Things from '../screens/extras/things'

// Check for Token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode Token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set User and isAuth
  store.dispatch(setCurrentUser(decoded));

  // Check for expire token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout User
    store.dispatch(logoutUser());
    // Clear current Profile
    // store.dispatch(clearCurrentProfile());
    //Redirect to login
    window.location.href = "/login";
  }
}

class Links extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggle: true
    };
    this.onClick = this.onClick.bind(this);
    this.handleError=this.handleError.bind(this);
    this.handleScan=this.handleScan.bind(this);
  }
  async componentDidMount(){
    const { isAuthenticated } = this.props.auth;
    if(isAuthenticated){
      await this.props.getRooms()
      // await this.props.getStatuses()
      await this.props.getSettings()
      await this.props.getTrekRegions()
      await this.props.getServiceTypes()
      await this.props.getTourCities()
    }
  }
  onClick(e) {
    this.setState({ toggle: !this.state.toggle });
  }
  handleError(err){
    // console.log(err)
    // Alert.error("Something went wrong", 5000)
  }
  handleScan(data){
    console.log(data)
    const { isAuthenticated } = this.props.auth;
    if(isAuthenticated){
      
      this.props.scanned(data,true);
      // this.props.getCustomer(data)
    }else{
      Alert.error("Please Login To Scan ", 5000)
    }
    
  }
  componentDidUpdate(prevProps, prevState) {
    const { auth:{isAuthenticated},rooms,statuses,settings } = this.props;
    const {scanData,isScan}=this.props.scanReducer.scan;
    const {scanData:prevScan}=prevProps.scanReducer.scan;
    if(isAuthenticated){
      // if(isEmpty(rooms) && rooms !==null){
      //   this.props.getRooms()
      // }
      // if(isEmpty(statuses) && statuses !==null){
      //   this.props.getStatuses()
      // }
      if(isEmpty(settings) && settings !==null && !this.props.settingsLoading){
        this.props.getSettings()
      }
    }
    if(!isEmpty(scanData)){
      // if(prevScan !==scanData){
      //   this.props.history.push(`/customer/${scanData}`)
      //   // this.props.history.push('/scans')
      // }
      if(isScan){
        this.props.scanned(scanData,false);
        this.props.history.push(`/customer/${scanData}`)
      }
    }
    // if(isAuthenticated){
    //   if(isEmpty(rooms)){
    //     this.props.getRooms();
    //   }
    //   if(isEmpty(statuses)){
    //     this.props.getStatuses();
    //   }
    // }
  }
  
  render() {
    const { isAuthenticated } = this.props.auth;
    return (
      <BrowserRouter>
      {/* <Routes> */}
      <div className="admin">
        <AuthLinks />
        {isAuthenticated ? <Sidebar toggle={this.state.toggle} /> : null}
        <>
          {/* <BarcodeReader
            onError={this.handleError}
            onScan={this.handleScan}
            /> */}
          <div className="admin-body">
            {isAuthenticated && (
              <Header onClick={this.onClick} toggle={this.state.toggle} />
            )}
            
              <PrivateRoute exact path={["/", "/dashboard"]} component={Dashboard} />
            
              <PrivateRoute exact path="/customers" component={Customers} />
            
              <PrivateRoute exact path="/customer/:id" component={Customer} />
            
              <PrivateRoute
                exact
                path="/customers/add"
                component={AddCustomer}
              />
            
              <PrivateRoute exact path="/scans" component={Scan} />
            
              <PrivateRoute exact path="/teams" component={Teams} />
            
              <PrivateRoute exact path="/rooms" component={Rooms} />
            
              <PrivateRoute exact path="/rooms/:id" component={Room} />
            {/* 
              <PrivateRoute exact path="/status" component={Status} /> */}
            
              <PrivateRoute exact path="/settings" component={Settings} />
            
              <PrivateRoute exact path="/reviews" component={Reviews} />
            
              <PrivateRoute exact path="/bookings" component={Bookings} />
              <PrivateRoute exact path="/bookingsqr" component={BookingsQr} />
              
              <PrivateRoute exact path="/activities" component={Activiteis} />
            
              <PrivateRoute exact path="/bookings/:id" component={Booking} />
              <PrivateRoute exact path="/bookingsqr/:id" component={BookingQr} />
            
              <PrivateRoute exact path="/tripplan" component={TripPlan} />
            
              <PrivateRoute exact path="/users" component={Users} />
            
              <PrivateRoute exact path="/users/:id" component={User} />
            
              <PrivateRoute exact path="/contacts" component={Contacts} />
              <PrivateRoute exact path="/extras" component={Extras} />
              <PrivateRoute exact path="/extras/trek-region" component={TrekRegion} />
              <PrivateRoute exact path="/extras/services" component={Services} />
              <PrivateRoute exact path="/extras/services/types" component={ServiceTypes} />
              <PrivateRoute exact path="/extras/blogs" component={Blogs} />
              <PrivateRoute exact path="/extras/tours/city" component={TourCity} />
              <PrivateRoute exact path="/extras/tours" component={Tour} />
              <PrivateRoute exact path="/extras/alpsettings" component={AlpSettings} />
              <PrivateRoute exact path="/extras/things" component={Things} />
              <PrivateRoute exact path="/extras/tours/package" component={Packages} />
              <PrivateRoute exact path="/extras/tours/package/:id" component={Package} />
            <TreksLinks />
            <AdventureLinks />
          </div>
        </>
        <Route exact path="/not-found" component={NotFound} />
      </div>
      {/* </Routes> */}
      </BrowserRouter>
    );
  }
}


Links.propTypes = {
  scanned: PropTypes.func.isRequired,
  getCustomer: PropTypes.func.isRequired,
  getSettings: PropTypes.func.isRequired,
  getStatuses: PropTypes.func.isRequired,
  getTrekRegions:PropTypes.func.isRequired,
  getRooms: PropTypes.func.isRequired,
  getTourCities: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  scanReducer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  scanReducer:state.scanReducer,
  statuses:state.statusReducer.statuses,
  settings: state.settingReducer.settings,
  rooms:state.roomReducer.rooms,
  loading:state.roomReducer.loading && state.settingReducer.loading && state.statusReducer.loading,
  settingsLoading:state.settingReducer.loading
});

const mapDispatchToProps = {
  scanned,
  getCustomer,
  getSettings,
  getStatuses,
  getRooms,
  getTrekRegions,
  getTourCities,
  getServiceTypes
}


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Links));
