import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import TeamItem from './TeamItem';
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  CheckPicker,
  Radio,
  Input,
  Icon,
  RadioGroup,
  InputGroup,
} from "rsuite";
import { Button, Modal, Loader, ButtonToolbar } from "rsuite";
import { Link } from "react-router-dom";
import {
  deleteService,
  addActivityPriceGroup,
  deleteActivityPriceGroup,
  getActivityPrice,
} from "../../../actions/settingActions";
import { useDispatch, useSelector } from "react-redux";
import renderHTML from "react-render-html";
import useFormValidation from "../../../validations/formValidations";
import { validateGroupNew } from "../../../validations/validateItinerary";
import isEmpty from "../../../validations/isEmpty";

const styles = {
  width: 300,
  marginBottom: 10,
};

function TeamsFeed({ trekRegions, onEdit }) {
  return (
    <div className="treks-list__block">
      {/* <div className="treks-list__search">
                <InputGroup inside style={styles}>
                    <Input 
                     onChange={(val)=>onChange(val)}    
                    />
                    <InputGroup.Button>
                        <Icon icon="search" />
                    </InputGroup.Button>
                </InputGroup>
            </div> */}
      <div className="treks-list__header trek-grid">
        <span className="name">Name</span>
        <span className="price text-center">Service type</span>
        <span className="edit text-center">Edit</span>
        <span className="delete text-center">Delete</span>
      </div>
      <div className="treks-list__items">
        {trekRegions &&
          trekRegions.map((team) => (
            <ServiceItem team={team} onEdit={onEdit} />
          ))}
      </div>
    </div>
  );
}

const ServiceItem = (props) => {
  const { team, onEdit } = props;
  const [op, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { serviceTypes } = useSelector(state => state.settingReducer);
  const service = serviceTypes?.find(item=>item.id === team?.serviceTypeId)

  const close = () => {
    setShow(false);
  };

  const open = () => {
    setShow(true);
  };

  const closeMod = () => {
    setOpen(false);
  };

  const openmod = () => {
    console.log("Herer");
    setOpen(true);
  };
  const styles = {
    width: 100,
    height: 100,
  };
  return (
    <div className="trek-item trek-grid">
      <div onClick={() => openmod()} className="trek-item__days text-link">
        {team?.name}
      </div>
      <div className="trek-item__days text-center">{service?.name}</div>
      <Modal backdrop={true} show={op} onHide={closeMod}>
        <Modal.Header>
          <Modal.Title>Service Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="team-detailss">
            <div className="team-list">
              <div className="team-name">
                <h2>{team?.name}</h2>
                {/* <span>{team?.handle}</span> */}
              </div>
              <TeamPrice service={team} />
              <div className="team-detail">
                {team?.details && renderHTML(renderHTML(team?.details))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeMod} appearance="primary">
            Ok
          </Button>
          <Button onClick={closeMod} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="trek-item__days text-left">
                {team.short}
            </div> */}
      {/* <div className="trek-item__days text-center">
                {team.details}
            </div> */}
      <div onClick={() => onEdit(team)} className="trek-item__edit text-center">
        <Icon icon="edit" size="lg" color="red" />
      </div>
      <div onClick={open} className="trek-item__delete text-center">
        <Icon icon="minus-square" size="lg" color="blue" />
      </div>

      <Modal backdrop="static" show={show} onHide={close} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure, You want to delete this trek region?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(deleteService(team.id));
              close();
            }}
            appearance="primary"
          >
            Ok
          </Button>
          <Button onClick={close} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const TeamPrice = (props) => {
  const [prices, setPrices] = useState([]);
  const { service, onData } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getActs();
  }, []);

  const getActs = async () => {
    const res = await dispatch(getActivityPrice("?serviceId=" + service.id));
    if (!isEmpty(res)) {
      setPrices(res);
    }
  };

  const [show, setShow] = useState(false);
  const [datas, setDatas] = useState(null);
  const onModalOpen = () => setShow(true);
  const onModalClose = () => setShow(false);
  const {} = props;
  const onFormData = (dt) => {
    setDatas(dt);
    setShow(true);
  };
  const onClear = (dt) => {
    setDatas(null);
    setShow(false);
  };
  return (
    <div className="trip-price service-price">
      <Button onClick={onModalOpen}>Add Price</Button>
      <Modal full backdrop={true} show={show} onHide={onModalClose}>
        <Modal.Header>
          <Modal.Title>Trek Group Size</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PriceForm
            close={onClear}
            datas={datas}
            isEdit={datas != null}
            trek={service}
            getActs={getActs}
            // editCustomer={editCustomer}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onModalClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {prices &&
        prices.map((item) => {
          return (
            <div className="">
              <GroupSize onData={onFormData} getActs={getActs} group={item} />
            </div>
          );
        })}
    </div>
  );
};

const GroupSize = (props) => {
  const { group, onData, getActs } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className="group-size">
      <div className="group-size-details">
        <div className="group-size-group">{group.group}</div>
        <div className="group-size-price">
          {group.is_dollar ? "$" : "Rs."} {group.price}
        </div>
      </div>
      <div className="group-size-buttons">
        <div
          onClick={() => onData(group)}
          className="includes__edit text-center"
        >
          <Icon icon="edit" size="lg" color="red" />
        </div>
        <div
          onClick={() => setOpen(!open)}
          className="includes__delete text-center"
        >
          <Icon icon="minus-square" size="lg" color="blue" />
        </div>
        <Modal
          backdrop="static"
          show={open}
          onHide={() => setOpen(false)}
          size="xs"
        >
          <Modal.Body>
            <Icon
              icon="remind"
              style={{
                color: "#ffb300",
                fontSize: 24,
              }}
            />
            {"  "}
            Are you sure, You want to delete this group size ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(deleteActivityPriceGroup(group.id));
                setOpen(false);
                getActs();
              }}
              appearance="primary"
            >
              Ok
            </Button>
            <Button onClick={() => setOpen(false)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

function PriceForm(props) {
  const {
    errorReducer,
    editCustomer,
    auth,
    datas = {},
    isEdit = false,
    statuses,
    close,
    trek,
    getActs,
  } = props;
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settingReducer.settings);
  const INITIAL_STATE = {
    group: !isEmpty(datas?.group) ? datas.group : "",
    price: !isEmpty(datas?.price) ? datas.price : "",
    is_dollar: !isEmpty(datas?.is_dollar) ? datas.is_dollar : true,
  };
  const {
    handleSubmit,
    handleChange2,
    handleChange3,
    handleSelect,
    checkErrors,
    handleBlur,
    handleDateChange,
    values,
    errors,
    isSubmitting,
    clearValues,
  } = useFormValidation(INITIAL_STATE, validateGroupNew);
  const handleSubmits = async (e) => {
    e.preventDefault();
    const { isValid } = checkErrors();

    if (isValid) {
      if (isEmpty(errors)) {
        values.price = parseInt(values.price);
        if (isEdit) {
          // dispatch(editTeam(values.id,values));
          // clearValues();
          // onEdit({})
          await dispatch(
            addActivityPriceGroup({
              ...values,
              id: datas.id,
              serviceId: trek.id,
            })
          );
          clearValues();
          getActs();
          close();

          return false;
        }
        await dispatch(
          addActivityPriceGroup({ ...values, serviceId: trek.id })
        );
        clearValues();
        getActs();
        close();
      }
    }
  };
  const [errs, setErrs] = React.useState({});
  useEffect(() => {
    if (errorReducer) {
      console.log(errorReducer);
      if (!isEmpty(errorReducer.error)) {
        setErrs(errorReducer.error);
      }
    }
  }, [errorReducer]);
  const getError = (type) => {
    let errClass =
      !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
    return errClass;
  };
  const getErrorMessage = (type) => {
    if (!isEmpty(errors[type])) {
      return errors[type];
    } else if (!isEmpty(errs[type])) {
      return errs[type];
    }
    return "";
  };
  return (
    <Form>
      <FormGroup className={getError("group")}>
        <ControlLabel>Group Size</ControlLabel>
        <FormControl
          name="group"
          type=""
          value={values.group}
          errorMessage={getErrorMessage("group")}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("price")}>
        <ControlLabel>Price</ControlLabel>
        <FormControl
          name="price"
          type="number"
          errorMessage={getErrorMessage("price")}
          value={values.price}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("is_dollar")}>
        <ControlLabel>Is Dollar</ControlLabel>
        <RadioGroup
          errorMessage={getErrorMessage("is_dollar")}
          onChange={handleChange2}
          value={values.is_dollar}
          name="is_dollar"
        >
          <Radio value={true}>Dollar</Radio>
          <Radio value={false}>Ruppees</Radio>
        </RadioGroup>
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>

      <FormGroup>
        <ButtonToolbar>
          <Button
            appearance="primary"
            disabled={isSubmitting}
            onClick={handleSubmits}
          >
            {isSubmitting ? <Loader /> : null}
            Submit
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
}

TeamsFeed.propTypes = {
  teams: PropTypes.array.isRequired,
};

export default TeamsFeed;
