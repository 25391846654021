import React from 'react'
import { Route, Switch,withRouter  } from "react-router-dom";
import PrivateRoute from "../components/common/PrivateRoute";
import Login from "../screens/auth/Login";
import ForgotPassword from '../screens/auth/ForgotPassword'
import ResetPassword from '../screens/auth/ResetPassword'

function AuthLinks() {
    return (
        <>
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset/token/:token" component={ResetPassword} />
        </>
    )
}

export default AuthLinks
