import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TeamItem from './TeamItem';
import {
    InputGroup,
    Icon,
    Input,
} from 'rsuite';

const styles = {
    width: 300,
    marginBottom: 10
};

function TeamsFeed({ teams,onEdit } ) {
    const [newTeam, setNewTeam] = useState(teams);
    useEffect(()=>{
        setNewTeam(teams)
    },[teams])
    const onChange = (value) =>{
        if(newTeam){
            if(value){
                const a = newTeam.filter(team=>team.name.toLowerCase().includes(value));
                setNewTeam(a)
            }else{
                setNewTeam(teams)
            }
        }
    }
    
    return (
        <div className="treks-list__block">
            <div className="treks-list__search">
                <InputGroup inside style={styles}>
                    <Input 
                     onChange={(val)=>onChange(val)}    
                    />
                    <InputGroup.Button>
                        <Icon icon="search" />
                    </InputGroup.Button>
                </InputGroup>
            </div>
            <div className="treks-list__header trek-grid">
                <span className="name">Name</span>
                <span className="price text-center">Gender</span>
                <span className="days text-left">Designation</span>
                {/* <span className="popular text-center">Details</span> */}
                <span className="edit text-center">Edit</span>
                <span className="delete text-center">Delete</span>
            </div>
            <div className="treks-list__items">
            {
                newTeam && newTeam.map(team => <TeamItem onEdit={onEdit} key={team.id} team={team} />)
            }
            </div>
        </div>
    )
}

TeamsFeed.propTypes = {
    teams: PropTypes.array.isRequired
};

export default TeamsFeed
