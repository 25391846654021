import React , {useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import { getTeams } from '../../actions/teamActions';
import {Loader} from 'rsuite'
import TeamsFeed from './TeamsFeed';
import TeamForm from './TeamForm';
import './team.styles.scss'
import isEmpty from '../../validations/isEmpty';

// teamReducer
function Teams() {
    const [ editData,setEditData ] = useState({});

    const dispatch = useDispatch();
    const { teams,loading } = useSelector(state => state.teamReducer);

    useEffect(()=>{
        dispatch(getTeams());
    },[]);

    const onEdit = (data) => {
        setEditData(data)
    }

    let teamsContent;

    if(loading){
        teamsContent = <Loader />
    }else{
        teamsContent =teams && <TeamsFeed 
                                    onEdit = {onEdit}
                                    teams={teams} />;
    }

    return (
        <div className="treks">
            <div className="treks-list">
                {teamsContent}
            </div>
            <div className="treks-form">
                <TeamForm 
                    onEdit={onEdit}
                    editData={editData}
                    isEdit={!isEmpty(editData)}
                />
            </div>
        </div>
    )
}

export default Teams;