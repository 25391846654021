import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AdventureFeed from './AdventureFeed';
import AdventureForm from './AdventureForm';
import {Loader} from 'rsuite'
import { getAdventures } from '../../actions/adventuresActions';
import './adventures.styles.scss';
import isEmpty from '../../validations/isEmpty'

export class Adventures extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             editData:{}
        }
        this.onEdit=this.onEdit.bind(this);
    }
    componentDidMount() {
        this.props.getAdventures();
    }
    onEdit(editData){
        this.setState({editData})
    }
    render() {
        const {loading,adventures} = this.props;
        const { editData } = this.state;
        let adventuresContent;
        
        if(loading){
            adventuresContent = <Loader />
        }else{
            adventuresContent =adventures && <AdventureFeed 
                                        onEdit = {this.onEdit}
                                        adventures={adventures} />;
        }
        return (
            <div className="adventures">
                <div className="adventures-list">
                    {adventuresContent}
                </div>
                <div className="adventures-form">
                    <AdventureForm 
                        onEdit={this.onEdit}
                        editData={editData}
                    />
                </div>
            </div>
        )
    }
}

Adventures.propTypes = {
    getAdventures: PropTypes.func.isRequired,
    adventure: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    adventures: state.adventureReducer.adventures,
    loading: state.adventureReducer.loading,
  });

const mapDispatchToProps = {
    getAdventures
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Adventures)
