import React, {useState, useEffect} from 'react'
import isEmpty from '../../../validations/isEmpty';
import {validateTeams} from '../../../validations/validateTeams';
import PropTypes from 'prop-types';
import { addTours,editTrekRegions } from '../../../actions/tourActions';
import useFormValidation from "../../../validations/formValidations";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    ButtonToolbar,
    Button,
    Loader,
    RadioGroup,
    Alert,
    SelectPicker,
    Radio,
} from "rsuite";
import {useDispatch, useSelector} from 'react-redux';
import Tiny from '../../../components/common/TinyEmc';

const INITIAL_STATE = {
    name: "",
    number: "",
    details: "",
    tourCityId:'',

};

export const validateRegions = (data)=>{
	let errors ={};
    if(isEmpty(data.name)){
		errors.name = 'Name Field is required';
	}
	if(isEmpty(data.number)){
		errors.number = 'Number Field is required';
	}
    if(isEmpty(data.tourCityId)){
		errors.tourCityId = 'Tour City Field is required';
	}
    // if(isEmpty(data.details)){
	// 	errors.details = 'Details Field is required';
	// }
	return errors
}

function TeamForm({onEdit,editData,isEdit}) {
    const {
        handleSubmit,
        handleChange2,
        handleChange3,
        handleSelect,
        handleBlur,
        checkErrors,
        values,
        setInitial,
        errors,
        isSubmitting,
        clearValues
    } = useFormValidation(INITIAL_STATE, validateRegions);
    const errorReducer = useSelector(state=>state.errorReducer)
    const load = useSelector(state=>state.teamReducer.load); 
    const { tourCities,tourCityLoading } = useSelector(state => state.tourReducer);
    const [errs, setErrs] = React.useState({});
    const dispatch = useDispatch();
    if (isSubmitting) {
        if(isEmpty(errors)){
            
        }
    }
    const handleSubmits = (e) => {
        
        e.preventDefault();
        const {isValid} =  checkErrors();
        if(isValid){
            // if(isEmpty(errors)){
                if(isEdit){
                    dispatch(addTours({id:values.id,...values},true));
                    // dispatch(editTrekRegions(values.id,values));
                    clearValues();
                    onEdit({})
                    return false;
                }
                dispatch(addTours(values));
                clearValues();
            // }
        }
    }
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);

    useEffect(()=>{
        if(!isEmpty(editData)){
            const {...rest} = editData;
            // let newAge = parseInt(age);
            let newData = {...rest, tourCityId: (rest.tourCityId ?? '') + ''}
            setInitial(newData)
        }
    },[editData]);

    useEffect(() => {
        window.addEventListener('keydown',(e)=>{handleEsc(e)})
    }, [isEdit])

    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const handleEsc = (e,cancel='') => {
        if(e.key === 'Escape' || cancel==='cancel'){
            clearValues();
            onEdit({})
        }
    }
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };

    return (
        <div>
            <Form>
                <FormGroup className={getError("name")}>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl
                        name="name"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.name}
                        errorMessage={getErrorMessage('name')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("number")}>
                    <ControlLabel>Number</ControlLabel>
                    <FormControl
                        name="number"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.number}
                        errorMessage={getErrorMessage('number')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("tourCityId")}>
                    <ControlLabel>Service Type</ControlLabel>
                    <SelectPicker
                        data={tourCities && tourCities.map(item=>({label:item.name,value:item.id+''}))}
                        name="tourCityId"
                        placement="topStart"
                        onBlur={e => handleBlur(e)}
                        // errorMessage={errors.region}
                        style={{ width: 250 }}
                        value={values.tourCityId}
                        onClean={()=>handleChange3('tourCityId','')}
                        onSelect={(value,valueObj,e)=>handleSelect(value,{name:'tourCityId',...valueObj},'tourCityId')}
                    />
                </FormGroup>
                <FormGroup className={getError("details")}>
                    <ControlLabel>Details</ControlLabel>
                    <Tiny 
                        onChange={(name,value)=>handleChange3(name,value)}
                        value={values.details}
                        name="details"
                        errorMessage={getErrorMessage("details")}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup>
                    <ButtonToolbar>
                        <Button
                            appearance="primary"
                            disabled={load}
                            onClick={(e)=>handleSubmits(e)}
                        >
                            {load ? <Loader /> : null}
                            {isEdit ? "Update Tour City" : "Add Tour City"}
                        </Button>
                        {isEdit?(
                                <Button
                                onClick={(e)=>handleEsc(e,'cancel')}
                            >
                                Cancle Edit
                            </Button>
                        ):null}
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    )
}

export default TeamForm
