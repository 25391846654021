import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import customerReducer from './customerReducer';
import errorReducer from './errorReducer';
import scanReducer from './scanReducer';
import roomReducer from './roomReducer';
import settingReducer from './settingReducer';
import statusReducer from './statusReducer';
import contactReducer from './contactReducer';
import reviewsReducer from './reviewsReducer';
import trekReducer from './trekReducer';
import adventureReducer from './adventureReducer';
import bookingReducer from './bookingReducer';
import activityReducer from './activityReducer';
import tripplanReducer from './tripplanReducer';
import teamReducer from './teamReducer';
import tourReducer from './tourReducer';

export default combineReducers({
    auth: authReducer,
    userReducer,
    customerReducer,
    errorReducer,
    scanReducer,
    roomReducer,
    settingReducer,
    statusReducer,
    contactReducer,
    trekReducer,
    adventureReducer,
    reviewsReducer,
    bookingReducer,
    activityReducer,
    tripplanReducer,
    tourReducer,
    teamReducer,
});