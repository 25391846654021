import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTourPackage } from '../../../../actions/tourActions'
import renderHTML from 'react-render-html';
import classnames from 'classnames';
import Includes from './includes'
import Itinerary from './itinerary'

function Package(props) {
    const {params} = props.match;
    const dispatch = useDispatch()
    const [tab,setTab] = useState("Overview")
    const {tourPackage} = useSelector(state=>state.tourReducer)
    useEffect(()=>{
        if(params.id){
            dispatch(getTourPackage(params.id))
        }
    },[])
    const Tab = (name) =>{
        const classNames = classnames({
            "trek-tab": true,
            active: tab === name
          })
        return (
          <div
            onClick={() => setTab(name)}
            className={classNames}
          >
            <span>{name}</span>
          </div>
        );
      }
    return (
        <div className="trek">
        <div className="trek-container">
          <div className="trek-block">
            <div className="trek-block__header">
              <h2>{tourPackage.title}</h2>
              {/* <span>{trek.region}</span> */}
            </div>
            <div className="trek-block__chips">
              {/* <div className="trek-block__chip">
                <h4>Properties</h4>
                <div className="flex">
                  <span className="chips chips-color-1">$ {trek.price}</span>
                  <span className="chips chips-color-2">
                    {trek.popular ? "Popular" : "Unpopular"}
                  </span>
                  <span className="chips chips-color-3">{trek.days} Days</span>
                  <span className="chips chips-color-4">{trek.altitude} m</span>
                  <span className="chips chips-color-5">{trek.difficulty}</span>
                </div>
              </div> */}
            </div>
            <div className="trek-block__body">
            <div className="trek-block__slider">
                {tourPackage.image ? (
                 <img src={tourPackage?.image} />
                ) : (
                  <span className="no-images">
                    No Images Found. Upload images down below inside the tab
                  </span>
                )}
              </div>
              <div className="trek-block__tabs">
                <div className="trek-block__tabs-tab">
                  {Tab("Overview")}
                  {Tab("Itinerary")}
                  {Tab("Includes")}
                </div>
                <div className="trek-block__tabs-body">
                  {tab === "Overview" ? <div>
                    {
                        tourPackage.details ? renderHTML(renderHTML(tourPackage.details)) : null
                    }
                    {
                        tourPackage.price_details ? renderHTML(renderHTML(tourPackage.price_details)) : null
                    }
                  </div>:null}
                  {tab === "Itinerary" ? (
                    <Itinerary tourPackage={tourPackage} />
                  ) : null}
                  {tab === "Includes" ? (
                    tourPackage.includes ? (
                      <div className="trek-body">
                          <Includes tourPackage={tourPackage} />
                        </div>
                    ) : (
                      <span className="no-tab">Includes Not Found</span>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Package