import axios from "axios";
import {getError} from '../helpers/getUnauth'

import {
  GET_ADVENTURE,
  GET_ADVENTURES,
  ADVENTURE_LOADING,
  ADD_ADVENTURE,
  EDIT_ADVENTURE,
  DELETE_ADVENTURE,
  EDIT_ADVENTURE_UPLOAD,
  GET_ERRORS,
  CLEAR_ERRORS
} from "./types";
import { Alert } from "rsuite";
import isEmpty from "../validations/isEmpty";

// Get current adventure
export const getAdventure = id => dispatch => {
  dispatch(setAdventureLoading());
  axios
    .get(`/api/v1/adventures/${id}`)
    .then(res =>
      dispatch({
        type: GET_ADVENTURE,
        payload: res.data
      })
    )
    .catch(err =>{
      getError(err)
      dispatch({
        type: GET_ADVENTURE,
        payload: {}
      })
    });
};

// Get adventure by handle
export const getAdventureByHandle = handle => async dispatch => {
  dispatch(setAdventureLoading());
  await axios
    .get(`/api/v1/adventures/handle/${handle}`)
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: GET_ADVENTURE,
          payload: res.data.data
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.error(res.data.error, 5000);
    })
    .catch(err => {
      getError(err)
      dispatch({
        type: GET_ADVENTURE,
        payload: null
      });
    });
};

// Add adventure
export const addAdventure = newAdventure => dispatch => {
  dispatch(clearErrors());
  axios
    .post("/api/v1/adventures", newAdventure)
    .then(res => {
      // debugger
      if (res.data.success) {
        dispatch({
          type: ADD_ADVENTURE,
          payload: res.data.data
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch(err => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        return false;
      }
      getError(err)
    });
};

// Edit adventure
export const editAdventure = (adventureId, newAdventure) => dispatch => {
  dispatch(clearErrors());
  axios
    .put(`/api/v1/adventures/${adventureId}`, newAdventure)
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: EDIT_ADVENTURE,
          payload: res.data.data[0],
          id: adventureId
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.error(res.data.error, 5000);
    })
    .catch(err => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        return false;
      }
      getError(err)
    });
};

// Add adventure details
export const addFiles = (adventureHandle, uploadData) => dispatch => {
  axios
    .put(`/api/v1/adventures/upload/${adventureHandle}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then(res =>
      dispatch({
        type: EDIT_ADVENTURE_UPLOAD,
        payload: res.data.data
      })
    )
    .catch(err => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        return false;
      }
      getError(err)
    });
};

// Get all adventures
export const getAdventures = () => dispatch => {
  dispatch(setAdventureLoading());
  axios
    .get("/api/v1/adventures/")
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: GET_ADVENTURES,
          payload: res.data.data
        });
      } else {
        dispatch({
          type: GET_ADVENTURES,
          payload: null
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch(err => {
      getError(err)
      dispatch({
        type: GET_ADVENTURES,
        payload: null
      });
    });
};

// Delete Post
export const deleteAdventure = id => dispatch => {
  axios
    .delete(`/api/v1/adventures/${id}`)
    .then(res => {
      debugger;
      dispatch({
        type: DELETE_ADVENTURE,
        payload: id
      });
      Alert.success(res.data.message, 5000);
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      getError(err)
    });
};

// adventure loading
export const setAdventureLoading = () => {
  return {
    type: ADVENTURE_LOADING
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
