import axios from "axios";
import { Alert } from "rsuite";
import isEmpty from "../validations/isEmpty";
import {getError} from '../helpers/getUnauth'

import {
	GET_CONTACTS,
	CONTACTS_LOADING,
	DELETE_CONTACT,
	CLEAR_ERRORS,
	GET_ERRORS
} from "./types";
import {getPages} from '../helpers/getPages'

// Get all contacts
export const getContacts = (pages) => async dispatch => {
    dispatch(setLoading());
    let page = getPages(pages);
	await axios
		.get("/api/v1/contacts",{ params:page})
		.then(res =>{
			dispatch({
				type: GET_CONTACTS,
                payload: res.data.data,
                page:res.data.page
			})
		}
		)
		.catch(err =>{
			getError(err)
            dispatch({
				type: GET_CONTACTS,
				payload: []
			})
        }
			
		);
};

export const getWebsiteContacts = (pages,website) => async dispatch =>{
	dispatch(setLoading());
	let page = getPages(pages);
	const data = {website}
	await axios
		.post("/api/v1/contacts/website",data,{params:page,})
		.then(res =>{
			dispatch({
				type: GET_CONTACTS,
                payload: res.data.data,
                page:res.data.page
			})
		}
		)
		.catch(err =>{
			getError(err)
            dispatch({
				type: GET_CONTACTS,
				payload: []
			})
        }
			
		);
}

// Delete Contact
export const deleteContact = id => dispatch => {
	axios
		.delete(`/api/v1/contacts/${id}`)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: DELETE_CONTACT,
					payload: id
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			getError(err)
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};


// User loading
export const setLoading = () => {
	return {
		type: CONTACTS_LOADING
	};
};

// Clear errors
export const clearErrors = () => {
	return {
		type: CLEAR_ERRORS
	};
};
