import { SET_CURRENT_USER,LOGIN_LOADING} from '../actions/types';
import isEmpty from '../validations/isEmpty';

const initialState ={
    isAuthenticated:false,
    user:{},
    loading:false
}

export default function(state= initialState,action){
    switch(action.type){
        case LOGIN_LOADING:
			return {
				...state,
				loading: action.payload,
        };
        case SET_CURRENT_USER:
        return{
            ...state,
            isAuthenticated: !isEmpty(action.payload),
            user: action.payload
        }
        default:
        return state;
    }
}


