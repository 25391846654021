import React, { Component } from 'react'
import { Button, Modal, Icon, ButtonToolbar } from 'rsuite';
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {logoutUser} from '../../actions/authActions'

class Confirm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        show: false
      };
      this.close = this.close.bind(this);
      this.open = this.open.bind(this);
    }
    close() {
      this.setState({ show: false });
    }
    open() {
      this.setState({ show: true });
    }
    render() {
        const {func,auth:user}=this.props;
      return (
        <div className="modal-container">
          <ButtonToolbar>
            {/* <Button onClick={this.open}> Open</Button> */}
            {
                React.cloneElement(this.props.children, {
                    key: 0,
                    onClick: (e)=>{
                      this.open()
                    }
                })
            }
          </ButtonToolbar>
  
          <Modal backdrop="static" show={this.state.show} onHide={this.close} size="xs">
            <Modal.Body>
              <Icon
                icon="remind"
                style={{
                  color: '#ffb300',
                  fontSize: 24
                }}
              />
              {'  '}
              Once a project is disabled, there will be no update on project report, and project
              members can access history data only. Are you sure you want to proceed?
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={()=>func()} appearance="primary">
                Ok
              </Button>
              <Button onClick={this.close} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }

Confirm.propTypes={
  logoutUser: PropTypes.func.isRequired,
  auth:PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth:state.auth,
})


export default connect(mapStateToProps, {logoutUser})(Confirm);
