import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { getSettings, editSettings } from "../../actions/settingActions";
import "./settings.styles.scss";
import classnames from "classnames";
import { code } from "../../helpers/enum";
import isEmpty from "../../validations/isEmpty";
import { Form, FormGroup, FormControl, ControlLabel } from "rsuite";
import { Loader } from "rsuite";
import { Button, Icon, ButtonToolbar } from "rsuite";
import UploadIcons from './UploadIcons'

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {}
    };
    this.onCodeChange = this.onCodeChange.bind(this);
    this._renderCode = this._renderCode.bind(this);
    this._renderFlockEmail = this._renderFlockEmail.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeEmailRemove = this.changeEmailRemove.bind(this);
    this._renderAlpEmail = this._renderAlpEmail.bind(this);
    this._renderWifi = this._renderWifi.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.changeWifi=this.changeWifi.bind(this);
    this._renderTreksType=this._renderTreksType.bind(this);
    this._renderTreksRegion=this._renderTreksRegion.bind(this);
    this._renderTreksProvides=this._renderTreksProvides.bind(this);
    this._renderStatus=this._renderStatus.bind(this);
  }

  async componentDidMount() {
    await this.props.getSettings();
    const { settings } = this.props;
    this.setState({ settings });
  }
  componentDidUpdate(prevProps,prevState){
    const { settings } = this.props;
    if(!isEmpty(settings) && prevProps.settings !== settings){
      this.setState({settings})
    }
  }
  onCodeChange(item) {
    this.setState(prevState => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        codeType: item
      }
    }));
  }
  updateSettings() {
    const { settings } = this.state;
    const data = {
      customerFilter: settings.customerFilter,
      codeType: settings.codeType,
      flockToEmail: settings.flockToEmail,
      alpToEmail: settings.alpToEmail,
      wifi: settings.wifi,
      trekTypes: settings.trekTypes,
      trekRegion:settings.trekRegion,
      trekProvides:settings.trekProvides,
      status:settings.status,
      roomIcons: settings.roomIcons
    };
    this.props.editSettings(1, data);
  }
  _renderCode() {
    const {
      settings,
    } = this.state;
    if (isEmpty(settings)) {
      return null;
    }
    const getCodeClass = item => {
      return classnames({
        "code-type": true,
        "active-code": item === settings.codeType
      });
    };
    return (
      <div className="code">
        <div
          onClick={() => this.onCodeChange(code.bar)}
          className={getCodeClass(code.bar)}
        >
          <span>{code.bar}</span>
        </div>
        <div
          onClick={() => this.onCodeChange(code.qr)}
          className={getCodeClass(code.qr)}
        >
          <span>{code.qr}</span>
        </div>
      </div>
    );
  }
  changeEmail(name, value) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      this.setState(prevState => ({
        ...prevState,
        settings: {
          ...prevState.settings,
          [name]: [...prevState.settings[name], value]
        }
      }));
      // this.updateSettings()
      return true;
    }
    alert("Invalid Email");
    return false;
  }
  changeWifi(name,value){
	this.setState(prevState => ({
        ...prevState,
        settings: {
          ...prevState.settings,
          [name]: prevState.settings[name]?[...prevState.settings[name], value]:[value]
        }
      }));
  }
  changeEmailRemove(name, value) {
    this.setState(prevState => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        [name]: prevState.settings[name].filter(item => item !== value)
      }
    }));
  }
  _renderTreksType(){
    const {
      settings,
      // settings: { trekTypes }
    } = this.state;
    let trekTypes = '';
    if (settings){
      trekTypes = settings.trekTypes;
    }
    return (
      <SettingsEmails
        name="trekTypes"
        title="Treks Types"
        type="text"
        vals={trekTypes}
        changeWifi={this.changeWifi}
        changeEmailRemove={this.changeEmailRemove}
      />
    );
  }
  _renderStatus(){
    const {
      settings,
      // settings: { status }
    } = this.state;
    let status = '';
    if (settings){
      status = settings.status;
    }
    return (
      <SettingsEmails
        name="status"
        title="Status"
        type="text"
        vals={status}
        changeWifi={this.changeWifi}
        changeEmailRemove={this.changeEmailRemove}
      />
    );
  }
  _renderTreksRegion(){
    const {
      settings,
      // settings: { trekRegion }
    } = this.state;
    let trekRegion = '';
    if (settings){
      trekRegion = settings.trekRegion;
    }
    return (
      <SettingsEmails
        name="trekRegion"
        title="Treks Region"
        type="text"
        vals={trekRegion}
        changeWifi={this.changeWifi}
        changeEmailRemove={this.changeEmailRemove}
      />
    );
  }
  _renderTreksProvides(){
    const {
      settings,
      // settings: { trekProvides }
    } = this.state;
    let trekProvides = '';
    if (settings){
      trekProvides = settings.trekProvides;
    }
    return (
      <SettingsEmails
        name="trekProvides"
        title="Treks Provides"
        type="text"
        vals={trekProvides}
        changeWifi={this.changeWifi}
        changeEmailRemove={this.changeEmailRemove}
      />
    );
  }
  _renderFlockEmail() {
    const {
      settings,
      // settings: { flockToEmail }
    } = this.state;
    let flockToEmail = ''
    if(settings){
      flockToEmail = settings.flockToEmail
    }
    return (
      <SettingsEmails
        name="flockToEmail"
        title="Flock"
        type="email"
        vals={flockToEmail}
        changeEmail={this.changeEmail}
        changeEmailRemove={this.changeEmailRemove}
      />
    );
  }
  _renderAlpEmail() {
    const {
      settings,
      // settings: { alpToEmail }
    } = this.state;
    let alpToEmail =''
    if(settings){
      alpToEmail = settings.alpToEmail
    }
    return (
      <SettingsEmails
        name="alpToEmail"
        title="Alpwander"
        type="email"
        vals={alpToEmail}
        changeEmail={this.changeEmail}
        changeEmailRemove={this.changeEmailRemove}
      />
    );
  }
  _renderWifi() {
    const {
      settings,
      // settings: { wifi }
  } = this.state;
  let wifi =''
    if(settings){
      wifi = settings.wifi
    }
    return <CustomWifi 
		name="wifi"
        vals={wifi}
        changeWifi={this.changeWifi}
        changeEmailRemove={this.changeEmailRemove}
	/>;
  }
  render() {
    const { settings, loading } = this.props;
    // if (loading) {
    //   return <Loader backdrop content="loading..." vertical />;
    // }
    if(!settings){
      return(
        <div className="settings-no">
          <span>No Settings Found</span>
        </div>
      )
    }
    return (
      <div className="settings">
        {
          loading?<Loader backdrop content="loading..." vertical />:null
        }
        <h2>Settings here</h2>
        {this._renderCode()}
        {this._renderFlockEmail()}
        {this._renderAlpEmail()}
        {this._renderWifi()}
        {this._renderTreksType()}
        {this._renderTreksRegion()}
        {this._renderTreksProvides()}
        {this._renderStatus()}
		<UploadIcons />
        <button
          onClick={() => this.updateSettings()}
          className="floating btn"
          disabled={loading}
        >
          Update Settings
        </button>
      </div>
    );
  }
}

export const CustomWifi = ({ name, vals, changeWifi, changeEmailRemove }) => {
  const [wifi, setWifi] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErors] = useState({});
  const onSubmit=()=>{
	const {error,isValid}= getErrors();
	
	setErors(error);
	if(isValid){
		changeWifi(name, {wifi,password});
		setWifi("");
		setPassword("");
	}

  }
  const getErrors = ()=>{
	  const error={}
	  if(isEmpty(wifi)){
		error.wifi="Wifi name is required";
	  }
	  if(isEmpty(password)){
		error.password="Password is required";
	  }
	  return {error,isValid:isEmpty(error)}
  }
  return (
    <div className="settings-email">
      <Form
        onSubmit={() => onSubmit()}
      >
        <FormGroup>
          <ControlLabel>Wifi Name</ControlLabel>
          <FormControl
            name="wifi"
            type="text"
			value={wifi}
			errorMessage={!isEmpty(errors.wifi)?"Wifi name Required":""}
            onChange={value => setWifi(value)}
          />
        </FormGroup>
		<FormGroup>
          <ControlLabel>Password</ControlLabel>
          <FormControl
            name="password"
            type="text"
			value={password}
			errorMessage={!isEmpty(errors.password)?"Password Required":""}
            onChange={value => setPassword(value)}
          />
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Button
              appearance="primary"
              // onClick={() => changeEmail(name,email)}
              onClick={() => onSubmit()}
            >
              Add Wifi
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
      <div className="status-list">
        {vals ? (
          vals.map(item => {
            return (
              <div className="status-list__item">
                <span className="name">{item.wifi}</span>
                <span className="password">{item.password}</span>
                <Icon
                  onClick={() => changeEmailRemove(name, item)}
                  icon="close"
                  size="12px"
                />
              </div>
            );
          })
        ) : (
          <span>No Wifi Added</span>
        )}
      </div>
    </div>
  );
};

export const SettingsEmails = ({
  name,
  vals,
  title,
  type,
  changeEmail,
  changeWifi,
  changeEmailRemove
}) => {
  const [email, setEmail] = useState("");
  return (
    <div className="settings-email">
      <Form
        onSubmit={() => {
          setEmail("");
          type ==='email'?changeEmail(name, email):changeWifi(name,email);
        }}
      >
        <FormGroup
        // className={getError("flockToEmail")}
        >
          <ControlLabel>{title}</ControlLabel>
          <FormControl
            name="email"
            type={type}
            value={email}
            onChange={value => setEmail(value)}
          />
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Button
              appearance="primary"
              onClick={() =>{
                setEmail("");
                type ==='email'?changeEmail(name, email):changeWifi(name,email);
              }}
            >
              Add
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
      <div className="status-list">
        {vals ? (
          vals.map(item => {
            return (
              <div className="status-list__item">
                <span className="name">{item}</span>
                <Icon
                  onClick={() => changeEmailRemove(name, item)}
                  icon="close"
                  size="12px"
                />
              </div>
            );
          })
        ) : (
          <span>Nothing Added</span>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  getSettings,
  editSettings
};

const mapStateToProps = state => ({
  settings: state.settingReducer.settings,
  loading: state.settingReducer.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
