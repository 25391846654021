import React, { Component, useEffect } from "react";
import { Button, Icon, ButtonToolbar } from "rsuite";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    Input
} from "rsuite";
import { DatePicker } from 'rsuite';
import { SelectPicker } from "rsuite";
import useFormValidation from "../../validations/formValidations";
import validateCustomer from "../../validations/validateCustomer";
import isEmpty from "../../validations/isEmpty";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Loader } from "rsuite";
import { addCustomer } from "../../actions/customerActions";
import countryList from '../../components/common/countryList'

const INITIAL_STATE = {
    email: "",
    status: "",
    name: "",
    country:'',
    phone: null,
    checkedStatus:'Checked In',
    checkedOutDate:'',
    checkedInDate:'',
    roomType:'',
    wifi:""
};

function AddCustomer({ errorReducer, auth, addCustomer,statuses,rooms,settings }) {
    const statusOptions = !settings ? [] : !settings.status?[]:settings.status.map(item=>{
        return{
            label: item, value: item, name: "status" 
        }
    });
    const wifiOptions = !settings ? [] : !settings.wifi?[]:settings.wifi.map(item=>{
        return{
            label: item.wifi, value: item, name: "wifi" 
        }
    });
    const roomOptions = !rooms?[]:rooms.map(item=>{
        return{
            label: item.type, value: item.type, name: "roomType" 
        }
    });
   
    const {
        handleSubmit,
        handleChange2,
        handleSelect,
        handleDateChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
        clearValues
    } = useFormValidation(INITIAL_STATE, validateCustomer);
    const [errs, setErrs] = React.useState({});
    if (isSubmitting) {
        addCustomer(values);
        // clearValues()
    }
    // const {auth}=props;
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);
    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };

    return (
        <section className="section-addCustomer container">
            <div className="users-heading">
                <h2 className="primary-heading">Add Customer</h2>
            </div>
            <div className="form-contain">
                <Form>
                    <FormGroup className={getError("name")}>
                        <ControlLabel>Name</ControlLabel>
                        <FormControl
                            name="name"
                            type="text"
                            onBlur={e => handleBlur(e)}
                            value={values.name}
                            errorMessage={getErrorMessage("name")}
                            onChange={handleChange2}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <FormGroup className={getError("email")}>
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                            name="email"
                            type="email"
                            onBlur={e => handleBlur(e)}
                            errorMessage={getErrorMessage("email")}
                            value={values.email}
                            onChange={handleChange2}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <FormGroup className={getError("phone")}>
                        <ControlLabel>Phone</ControlLabel>
                        <FormControl
                            name="phone"
                            type="number"
                            onBlur={e => handleBlur(e)}
                            errorMessage={getErrorMessage("phone")}
                            value={values.phone}
                            onChange={handleChange2}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <FormGroup className={getError("status")}>
                        <ControlLabel>Status</ControlLabel>
                        <SelectPicker
                            data={statusOptions}
                            name="status"
                            onBlur={e => handleBlur(e)}
                            errorMessage={getErrorMessage("status")}
                            style={{ width: 250 }}
                            value={values.status || errs.status}
                            onSelect={handleSelect}
                        />
                    </FormGroup>
                    <FormGroup className={getError("wifi")}>
                        <ControlLabel>Wifi</ControlLabel>
                        <SelectPicker
                            data={wifiOptions}
                            name="wifi"
                            onBlur={e => handleBlur(e)}
                            errorMessage={getErrorMessage("wifi")}
                            style={{ width: 250 }}
                            value={values.wifi || errs.wifi}
                            onSelect={handleSelect}
                        />
                    </FormGroup>
                    
                    <FormGroup className={getError("status")}>
                        <ControlLabel>Room Type</ControlLabel>
                        <SelectPicker
                            data={roomOptions}
                            name="roomType"
                            onBlur={e => handleBlur(e)}
                            errorMessage={getErrorMessage("roomType")}
                            style={{ width: 250 }}
                            value={values.roomType || errs.roomType}
                            onSelect={handleSelect}
                        />
                    </FormGroup>
                    <FormGroup className={getError("status")}>
                        <ControlLabel>Country</ControlLabel>
                        <SelectPicker
                            data={countryList}
                            name="country"
                            onBlur={e => handleBlur(e)}
                            errorMessage={getErrorMessage("country")}
                            style={{ width: 250 }}
                            value={values.country || errs.country}
                            onSelect={handleSelect}
                        />
                    </FormGroup>
                    <FormGroup className={getError("checkedInDate")}>
                        <ControlLabel>Checked In date</ControlLabel>
                        <DatePicker
                            name="checkedInDate"
                            oneTap
                            placement="topStart"
                            onBlur={e => handleBlur(e)}
                            errorMessage={getErrorMessage("checkedInDate")}
                            style={{ width: 250 }}
                            value={values.checkedInDate || errs.checkedInDate}
                            onChange={(value)=>handleDateChange(value,'checkedInDate')}
                        />
                    </FormGroup>
                    <FormGroup className={getError("checkedOutDate")}>
                        <ControlLabel>Checked Out</ControlLabel>
                        <DatePicker
                            name="checkedOutDate"
                            oneTap
                            placement="topStart"
                            onBlur={e => handleBlur(e)}
                            errorMessage={getErrorMessage("checkedOutDate")}
                            style={{ width: 250 }}
                            value={values.checkedOutDate || errs.checkedOutDate}
                            onChange={(value)=>handleDateChange(value,'checkedOutDate')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ButtonToolbar>
                            <Button
                                appearance="primary"
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                            >
                                {isSubmitting ? <Loader /> : null}
                                Submit
                            </Button>
                        </ButtonToolbar>
                    </FormGroup>
                </Form>
            </div>
        </section>
    );
}

AddCustomer.propTypes = {
    addCustomer: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errorReducer: state.errorReducer,
    statuses:state.statusReducer.statuses,
    settings:state.settingReducer.settings,
    rooms:state.roomReducer.rooms,
});

export default connect(mapStateToProps, { addCustomer })(AddCustomer);
