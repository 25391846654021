import React , {useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import { getBlogss } from '../../../actions/settingActions';
import {Loader} from 'rsuite'
import TeamsFeed from './TeamsFeed';
import './team.styles.scss'
import isEmpty from '../../../validations/isEmpty';
import BlogDrawer from './blog-drawer'

// teamReducer
function TrekRegion() {
    const [ editData,setEditData ] = useState({});

    const dispatch = useDispatch();
    const { blogs,loading } = useSelector(state => state.settingReducer);

    useEffect(()=>{
        dispatch(getBlogss());
    },[]);

    const onEdit = (data) => {
        setEditData(data)
    }

    let teamsContent;

    if(loading){
        teamsContent = <Loader />
    }else{
        teamsContent =blogs && <TeamsFeed 
                                    onEdit = {onEdit}
                                    trekRegions={blogs} />;
    }

    return (
        <div className="blogsser">
            <BlogDrawer 
                    onEdit={onEdit}
                    editData={editData}
                    isEdit={!isEmpty(editData)}
                />
            <div className="treks-list">
                {teamsContent}
            </div>
        </div>
    )
}

export default TrekRegion;