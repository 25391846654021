import React, { Component } from "react";
import { Uploader, Icon, Button, Alert } from "rsuite";
import isEmpty from '../../validations/isEmpty'

export class UploadImages extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             image:[]
        }
        this.onUpload = this.onUpload.bind(this);
    }
    onUpload(){
        const {uploadRooms,id,images} =this.props;
        const {image} = this.state;
        // console.log(images)
        // if(isEmpty(image)){
        //   Alert.error("Add Some Images", 5000);
        //   return
        // }
        const data =new FormData();
        image.map(item=>{
            data.append('images',item.blobFile)
        })
        data.append('otherImages',images)
        data.append('id',id);
        uploadRooms(id,data);
        this.setState({image:[]})
    }
  render() {
    const {images,onImageChange} = this.props;
    return (
      <div className="single-upload">
          <div className="single-upload-header">
              <h3>Upload Room Images</h3>
          </div>
          <div className="single-upload-body">
            <Uploader
                multiple={true}
                autoUpload={false}
                listType="picture"
                fileList={this.state.image}
                onChange={image => {
                    console.log(image);
                    this.setState({ image: image });
                }}
                >
                    <button>
                        <Icon icon="camera-retro" size="lg" />
                    </button>
            </Uploader>
          </div>
          <div className="single-upload-bottom">
            <div className="single-upload-title">
              <span>Already there images</span>
            </div>
            <div className="atimages">
              {
                images && images.map((image)=>ImageMap(image,onImageChange))
              }
            </div>
          </div>
          <div className="single-upload-footer">
            <Button 
                    onClick={() => this.onUpload()} 
                    appearance="primary"
                >
                Upload
            </Button>
          </div>
      </div>
    );
  }
}

const ImageMap = (image,onImageChange)=>{
  return(
    <div className="atimages-block">
      <div className="atimages-image">
        <img src={image} alt="AltImage"/>
      </div>
      <div className="atimages-link">
        <a onClick={()=>onImageChange(image)} >
          <Icon icon="close" />
        </a>
      </div>
    </div>
  )
}

export default UploadImages;