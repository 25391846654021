import React, {useEffect, useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import './reviews.styles.scss';
import CustomPagination from '../../components/common/CustomPagination';
import isEmpty from '../../validations/isEmpty'
import { deleteReview, getReviews, editPopularReview } from "../../actions/reviewsActions";
import { Table,IconButton,Icon} from "rsuite";
import ReviewModal from './Modal'
import { Modal,Button } from 'rsuite';

const { Column, HeaderCell, Cell, Pagination } = Table;

const ActionCell = ({ rowData,func, dataKey, ...props }) => {
    const dispatch = useDispatch();
    return (
      <Cell {...props} className="link-group">
        <IconButton 
            // onClick={()=>dispatch(deleteReview(rowData.id))} 
            onClick={()=>func(rowData.id)}
            appearance="subtle" 
            icon={<Icon icon="trash" />} 
        />
      </Cell>
    );
};

const Chips = ({ rowData, func, dataKey, ...props }) => {
    let data = '';
    if(dataKey === 'approved'){
        data = rowData[dataKey] ? 'Approved' : 'Disapproved';
    }
    if(dataKey === 'popular'){
        data = rowData[dataKey] ? 'Popular' : 'Unpopular';
    }
    return(
        <Cell {...props} className="link-group">
            <div 
                onClick={()=>func(rowData.id,!rowData[dataKey])}
                className="trek-item__popular text-center">
                    <span className={`chips ${rowData[dataKey] ? "chips-green" : "chips-red"} `}>{data}</span>
            </div>
        </Cell>
    )
}

const Name = ({ rowData, dataKey, ...props }) =>{
    
    return(
        <Cell {...props} className="link-group">
            <ReviewModal {...props} dataKey={dataKey} data={rowData} />
        </Cell>
    )
}

const Image = ({rowData,dataKey,...props})=>{
    return(
        <Cell {...props} className="link-group">
            <div
                style={{
                    width: 40,
                    height: 40,
                    background: '#f5f5f5',
                    borderRadius: 20,
                    marginTop: 2,
                    overflow: 'hidden',
                    display: 'inline-block'
                }}
                >
                {/* photo */}
                <img src={rowData.image} width="44" />
            </div>
        </Cell>
    )
}


function Reviews() {

    const {reviews,loading,page} = useSelector(state=>state.reviewsReducer);
    const [show,setShow] = useState(false)
    const [id,setId] = useState(null)
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getReviews())
    },[]);
    const onShow = (id) => {
      setId(id)
      setShow(true)
    };
    const onShowClose = () => {
      setId(null)
      setShow(false)
    };
    const onReviewDelete = () => {
        dispatch(deleteReview(id));
    }
    return (
        <div className="reviews">
            <div className="reviews-table">
            <Table 
              wordWrap
              loading={loading} height={800} data={reviews}>
            <Column width={70} align="center">
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column width={70} align="center">
              <HeaderCell>Image</HeaderCell>
              <Image dataKey="image" />
            </Column>
            
            <Column width={130}>
              <HeaderCell>Name</HeaderCell>
              <Name dataKey="name" />
            </Column>

            <Column width={120}>
              <HeaderCell>Email</HeaderCell>
              <Cell dataKey="email" />
            </Column>

            <Column width={100}>
              <HeaderCell>Trek Name</HeaderCell>
              <Cell dataKey="trek_name" />
            </Column>
            <Column width={100}>
              <HeaderCell>Ratings</HeaderCell>
              <Cell dataKey="ratings" />
            </Column>
            <Column width={150} resizable>
              <HeaderCell>Summary</HeaderCell>
              <Cell dataKey="summary" />
            </Column>
            <Column width={200} resizable>
              <HeaderCell>Details</HeaderCell>
              <Cell dataKey="details" />
            </Column>
            
            {/* <Column width={200}>
              <HeaderCell>Summary</HeaderCell>
              <Cell dataKey="summary" />
            </Column> */}
            
            <Column width={120}>
              <HeaderCell>Approved</HeaderCell>
              <Chips  dataKey="approved"  func={(id,data)=>dispatch(editPopularReview(id,{approved:data}))} />
            </Column>
            <Column width={120}>
              <HeaderCell>Popular</HeaderCell>
              {/* <Cell dataKey="popular" /> */}
              <Chips  dataKey="popular" func={(id,data)=>dispatch(editPopularReview(id,{popular:data}))} />
            </Column>
            <Column width={100}>
              <HeaderCell>Delete</HeaderCell>
              <ActionCell dataKey="id" func={onShow} />
            </Column>
          </Table>
                {
                        isEmpty(reviews)?null:
                        <CustomPagination 
                                page={page}
                                getFunc={(pages)=>dispatch(getReviews(pages))}
                        />
                }
            </div>
            <Modal backdrop="static" show={show} onHide={onShowClose} size="xs">
                    <Modal.Body>
                        <Icon
                        icon="remind"
                        style={{
                            color: '#ffb300',
                            fontSize: 24
                        }}
                        />
                        {'  '}
                        Are you sure, You want to delete this trek ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>{
                            // this.props.deleteTrek(trek.id);
                            onReviewDelete()
                            onShowClose()
                        }} appearance="primary">
                        Ok
                        </Button>
                        <Button onClick={onShowClose} appearance="subtle">
                        Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
        </div>
    )
}

export default Reviews
