import React, {useState, useEffect} from 'react'
import isEmpty from '../../../validations/isEmpty';
import {validateService} from '../../../validations/validateTeams';
import PropTypes from 'prop-types';
import { addService,editService } from '../../../actions/settingActions';
import useFormValidation from "../../../validations/formValidations";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    ButtonToolbar,
    Button,
    Loader,
    RadioGroup,
    SelectPicker,
    Alert,
    Radio,
    Uploader,
    Icon
} from "rsuite";
import {useDispatch, useSelector} from 'react-redux';
import Tiny from '../../../components/common/TinyEmc';


const INITIAL_STATE = {
    name: "",
    note: null,
    duration: null,
    details:'',
    serviceTypeId:'',
    is_both_ways:true,
    // image:null
};


function TeamForm({onEdit,editData,isEdit}) {
    const {
        handleSubmit,
        handleChange2,
        handleDateChange,
        handleChange3,
        handleBlur,
        checkErrors,
        values,
        setInitial,
        handleSelect,
        errors,
        isSubmitting,
        clearValues
    } = useFormValidation(INITIAL_STATE, validateService);
    const errorReducer = useSelector(state=>state.errorReducer);
    const { serviceTypes } = useSelector(state => state.settingReducer);
    const load = useSelector(state=>state.teamReducer.load); 
    
    const [errs, setErrs] = React.useState({});
    const dispatch = useDispatch();
    if (isSubmitting) {
        if(isEmpty(errors)){
            
        }
    }
    const handleSubmits = async (e) => {
        
        e.preventDefault();
        const {isValid} =  checkErrors();
        console.log({values,isValid,isEdit,errors})
        if(isValid){
            if(isEdit){
                await dispatch(addService({id:values.id,...values},true));
                // dispatch(editTrekRegions(values.id,values));
                clearValues();
                onEdit({})
                return false;
            }
            await dispatch(addService(values));
            clearValues();
        }
    }
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);

    useEffect(()=>{
        if(!isEmpty(editData)){
            const {serviceTypeId,...rest} = editData;
            let sID = serviceTypeId ?? ""
            let newData = {serviceTypeId:sID + '',...rest}
            setInitial(newData)
        }
    },[editData]);

    useEffect(() => {
        window.addEventListener('keydown',(e)=>{handleEsc(e)})
    }, [isEdit])

    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const handleEsc = (e,cancel='') => {
        if(e.key === 'Escape' || cancel==='cancel'){
            clearValues();
            onEdit({})
        }
    }
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };
    return (
        <div>
            <Form>
                <FormGroup className={getError("name")}>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl
                        name="name"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.name}
                        errorMessage={getErrorMessage('name')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("note")}>
                    <ControlLabel>Note</ControlLabel>
                    <FormControl
                        name="note"
                        type="number"
                        onBlur={e => handleBlur(e)}
                        value={values.note}
                        errorMessage={getErrorMessage('note')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("duration")}>
                    <ControlLabel>Duration</ControlLabel>
                    <FormControl
                        name="duration"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.duration}
                        errorMessage={getErrorMessage('duration')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("serviceTypeId")}>
                        <ControlLabel>Service Type</ControlLabel>
                        <SelectPicker
                            data={serviceTypes && serviceTypes.map(item=>({label:item.name,value:item.id+''}))}
                            name="serviceTypeId"
                            placement="topStart"
                            onBlur={e => handleBlur(e)}
                            // errorMessage={errors.region}
                            style={{ width: 250 }}
                            value={values.serviceTypeId}
                            onClean={()=>handleChange3('serviceTypeId','')}
                            onSelect={(value,valueObj,e)=>handleSelect(value,{name:'serviceTypeId',...valueObj},'serviceTypeId')}
                        />
                    </FormGroup>
                <FormGroup className={getError("details")}>
                    <ControlLabel>Details</ControlLabel>
                    <Tiny 
                        onChange={(name,value)=>handleChange3(name,value)}
                        value={values.details}
                        name="details"
                        errorMessage={getErrorMessage("details")}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("is_both_ways")}>
          <ControlLabel>Way?</ControlLabel>
          <RadioGroup errorMessage={getErrorMessage("is_both_ways")} onChange={handleChange2} value={values.is_both_ways} name="is_both_ways">
            <Radio value={false}>One Way</Radio>
            <Radio value={true}>Both Way</Radio>
            </RadioGroup>
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
                {/* <FormGroup className={getError("image")}>
                    <ControlLabel>Image</ControlLabel>
                    <Uploader
                        multiple={false}
                        autoUpload={false}
                        listType="picture"
                        onChange={image => {
                            handleChange3("image",image)
                        }}
                        >
                            <button>
                                <Icon icon="camera-retro" size="lg" />
                            </button>
                    </Uploader>
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup> */}

                
                
                <FormGroup>
                    <ButtonToolbar>
                        <Button
                            appearance="primary"
                            disabled={load}
                            onClick={(e)=>handleSubmits(e)}
                        >
                            {load ? <Loader /> : null}
                            {isEdit ? "Update Service" : "Add Service"}
                        </Button>
                        {isEdit?(
                                <Button
                                onClick={(e)=>handleEsc(e,'cancel')}
                            >
                                Cancle Edit
                            </Button>
                        ):null}
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    )
}

export default TeamForm
