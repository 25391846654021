import axios from "axios";
import { Alert } from "rsuite";
import isEmpty from "../validations/isEmpty";

import {
	GET_STATUS,
	GET_STATUSES,
	STATUS_LOADING,
	ADD_STATUS,
	DELETE_STATUS,
	EDIT_STATUS,
	CLEAR_ERRORS,
	STATUS_LOADING_SUBMIT,
	GET_ERRORS
} from "./types";
import {getError} from '../helpers/getUnauth'

// Get STATUS by id
export const getStatus = id => dispatch => {
	dispatch(setLoading());
	axios
		.get(`/api/v1/status/${id}`)
		.then(res =>
			dispatch({
				type: GET_STATUS,
				payload: res.data.data
			})
		)
		.catch(err => {
			getError(err);
			dispatch({
				type: GET_STATUS,
				payload: {}
			});
		});
};

// Get all statuses
export const getStatuses = () => dispatch => {
	dispatch(setLoading());
	axios
		.get("/api/v1/status")
		.then(res =>
			dispatch({
				type: GET_STATUSES,
				payload: res.data.data?res.data.data:[],
			})
		)
		.catch(err =>{
			getError(err);
			dispatch({
				type: GET_STATUSES,
				payload: null
			})
		});
};

// Add STATUS
export const addStatus = newStatus => dispatch => {
	dispatch(clearErrors());
	dispatch(setLoading2(true));
	axios
		.post("/api/v1/status", newStatus)
		.then(res => {
			dispatch(setLoading2(false));
			if (res.data.success) {
				dispatch({
					type: ADD_STATUS,
					payload: res.data.data
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			dispatch(setLoading2(false));
			if (err) {
				getError(err);
				if (err.response) {
					const error=err.response.data.error.name
					dispatch({
						type: GET_ERRORS,
						payload: {error}
					});
				} else {
					console.log(err);
				}
			}
		});
};

// Edit STATUS
export const editStatus = (statusId, newStatus) => dispatch => {
	dispatch(clearErrors());
	dispatch(setLoading2(true));
	axios
		.put(`/api/v1/status/${statusId}`, newStatus)
		.then(res => {
			dispatch(setLoading2(false));
			if (res.data.success) {
				dispatch({
					type: EDIT_STATUS,
					payload: res.data.data[0],
					id: statusId,
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			dispatch(setLoading2(false));
			if (err) {
				getError(err);
				if (err.response) {
					const error=err.response.data.error.name
					dispatch({
						type: GET_ERRORS,
						payload:{error}
					});
				} else {
					console.log(err);
				}
			}
		});
};

// Delete STATUS
export const deleteStatus = id => dispatch => {
	axios
		.delete(`/api/v1/status/${id}`)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: DELETE_STATUS,
					payload: id
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			getError(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};


export const setLoading = () => {
	return {
		type: STATUS_LOADING
	};
};
export const setLoading2 = (payload) => {
	return {
		type: STATUS_LOADING_SUBMIT,
		payload
	};
};



// Clear errors
export const clearErrors = () => {
	return {
		type: CLEAR_ERRORS
	};
};
