import React, { useState, useEffect } from "react";
import { Tooltip, Whisper } from "rsuite";
import { Icon } from "rsuite";
import { Link } from "react-router-dom";
import { InputGroup, Input } from "rsuite";
import isEmpty from "../../validations/isEmpty";

const styles = {
  width: 300,
  marginBottom: 10,
};

function List({ deleteRoom, rooms, onEdit }) {
  const [newRoom, setNewRoom] = useState(rooms);
  const onChange = (value) => {
    if (newRoom) {
      if (value) {
        const a = newRoom.filter((room) =>
          room.type.toLowerCase().includes(value)
        );
        setNewRoom(a);
      } else {
        setNewRoom(rooms);
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(rooms)) {
      setNewRoom(rooms);
    }
  }, [rooms]);
  return (
    <div className="treks-list__block">
      <div className="room-list status-list">
        <div className="treks-list__search">
          <InputGroup inside style={styles}>
            <Input onChange={(val) => onChange(val)} />
            <InputGroup.Button>
              <Icon icon="search" />
            </InputGroup.Button>
          </InputGroup>
        </div>
        {newRoom ? (
          newRoom.map((item) => {
            return (
              <div className="status-list__item room">
                <Link className="type" to={`/rooms/${item.id}`}>
                  <span>{item.type}</span>
                </Link>
                <span className="heading">{item.heading}</span>
                <span className="desc">{item.description}</span>
                <span className="rooms-icon">
                  {item.icons &&
                    item.icons.map((icon) => {
                      return (
                        <span className="rooms-icon__item">
                          <img
                            src={icon.icon}
                            alt={icon.name}
                            className="icon-img"
                          />
                          <span className="icon-name">{icon.label}</span>
                        </span>
                      );
                    })}
                </span>
                <span className="update-icons">
                  <Icon
                    className="icon-edit"
                    onClick={() => onEdit(item)}
                    icon="edit"
                    size="12px"
                  />
                  <Icon
                    className="icon-del"
                    onClick={() => deleteRoom(item.id)}
                    icon="close"
                    size="12px"
                  />
                </span>
              </div>
            );
          })
        ) : (
          <span>No Room Added</span>
        )}
      </div>
    </div>
  );
}

export default List;
