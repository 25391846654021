import React, {useEffect, useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import CustomPagination from '../../components/common/CustomPagination';
import isEmpty from '../../validations/isEmpty'
import { deleteBooking, getBookings } from "../../actions/bookingActions";
import { Table,IconButton,Icon} from "rsuite";
import ExpandData from './ExpandData';
import {Link} from 'react-router-dom'
import { saveAs } from 'file-saver'
import { Modal,Button } from 'rsuite';
const { Column, HeaderCell, Cell, Pagination } = Table;

// const ActionCell = ({ rowData,onReviewDelete, dataKey, ...props }) => {
//     const dispatch = useDispatch();
    // const onDownloadImage = () => {
    //     saveAs(rowData.passport_img, rowData.passport_no+'passport.jpg')
    // }
//     return (
//       <Cell {...props} className="link-group">
//         <IconButton onClick={()=>dispatch(deleteBooking(rowData.id))} appearance="subtle" icon={<Icon icon="trash" />} />
//         <IconButton onClick={()=>onDownloadImage()} appearance="subtle" icon={<Icon icon="download" />} />
//       </Cell>
//     );
// };

const ActionCell = ({ rowData,func, dataKey, ...props }) => {
    const [show,setShow] = useState(false)
    const onShow = () => {
      setShow(true)
    };
    const onShowClose = () => {
      setShow(false)
    };
    const onDownloadImage = () => {
        saveAs(rowData.passport_img, rowData.passport_no+'passport.jpg')
    }
      return (
        <Cell {...props} className="link-group">
          <IconButton onClick={()=>onShow()} appearance="subtle" icon={<Icon icon="trash" />} />
          <IconButton onClick={()=>onDownloadImage()} appearance="subtle" icon={<Icon icon="download" />} />
          {/* <IconButton onClick={()=>dispatch(deleteActivity(rowData.id))} appearance="subtle" icon={<Icon icon="trash" />} /> */}
          <Modal backdrop="static" show={show} onHide={onShowClose} size="xs">
                      <Modal.Body>
                          <Icon
                          icon="remind"
                          style={{
                              color: '#ffb300',
                              fontSize: 24
                          }}
                          />
                          {'  '}
                          Are you sure, You want to delete this activity ?
                      </Modal.Body>
                      <Modal.Footer>
                          <Button onClick={()=>{
                              // this.props.deleteTrek(trek.id);
                              func(rowData.id)
                              onShowClose()
                          }} appearance="primary">
                          Ok
                          </Button>
                          <Button onClick={onShowClose} appearance="subtle">
                          Cancel
                          </Button>
                      </Modal.Footer>
                  </Modal>
        </Cell>
      );
  };

const Name = ({ rowData,dataKey,...props }) =>{
    return(
        <Cell {...props} className="link-group">
            <Link to={`/bookings/${rowData.id}`}>
                {rowData.first_name} {rowData.last_name}
            </Link>
        </Cell>
    )
}

const Country = ({ rowData,dataKey,...props }) =>{
    return(
        <Cell {...props} className="link-group">
            <span>
                {rowData.city}, {rowData.country}
            </span>
        </Cell>
    )
}
const rowKey = 'id';
const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props}>
      <IconButton
        size="xs"
        appearance="subtle"
        onClick={() => {
          onChange(rowData);
        }}
        icon={
          <Icon
            icon={
              expandedRowKeys.some(key => key === rowData[rowKey])
                ? 'minus-square-o'
                : 'plus-square-o'
            }
          />
        }
      />
    </Cell>
  );

function Booking() {
    const {bookings,loading,page} = useSelector(state=>state.bookingReducer);
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getBookings())
    },[]);

    const onBookingDelete = (id) => {
        dispatch(deleteBooking(id));
    }
    const handleExpanded = (rowData, dataKey) => {
        let open = false;
        const nextExpandedRowKeys = [];
    
        expandedRowKeys.forEach(key => {
          if (key === rowData[rowKey]) {
            open = true;
          } else {
            nextExpandedRowKeys.push(key);
          }
        });
    
        if (!open) {
          nextExpandedRowKeys.push(rowData[rowKey]);
        }
        setExpandedRowKeys(nextExpandedRowKeys)
      }
    return (
        <div className="table-cont bookings">
            <div className="bookings-table">
            <Table 
                rowKey={rowKey}
                expandedRowKeys={expandedRowKeys}
                onRowClick={data => {
                }}
                renderRowExpanded={rowData =><ExpandData data={rowData} />}
                loading={loading} height={400} data={bookings}>
                <Column width={70} align="center" fixed>
                    <HeaderCell>#</HeaderCell>
                    <ExpandCell
                        dataKey="id"
                        expandedRowKeys={expandedRowKeys}
                        onChange={handleExpanded}
                    />
                </Column>
                <Column width={70} align="center" fixed>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey="id" />
                </Column>
                <Column width={130} fixed >
                    <HeaderCell>Name</HeaderCell>
                    <Name dataKey="first_name" />
                    {/* <Cell dataKey="last_name" /> */}
                </Column>

                <Column width={200}>
                    <HeaderCell>Email</HeaderCell>
                    <Cell dataKey="email" />
                </Column>
                <Column width={150}>
                    <HeaderCell>Country</HeaderCell>
                    <Country dataKey="country" />
                </Column>
                <Column width={200} resizable>
                    <HeaderCell>Trip Name</HeaderCell>
                    <Cell dataKey="trip_name" />
                </Column>
                <Column width={120}>
                    <HeaderCell>Trip Date</HeaderCell>
                    <Cell dataKey="trip_date" />
                </Column>
                <Column width={120} resizable>
                    <HeaderCell>Number of People</HeaderCell>
                    <Cell dataKey="no_people" />
                </Column>
                <Column width={120} resizable>
                    <HeaderCell>Passport number</HeaderCell>
                    <Cell dataKey="passport_no" />
                </Column>
                <Column 
                    width={100} 
                    // fixed="right"
                >
                    <HeaderCell>Delete</HeaderCell>
                    <ActionCell dataKey="id" func={onBookingDelete} />
                </Column>
            </Table>
                {   
                    isEmpty(bookings)?null:
                    <CustomPagination 
                            page={page}
                            getFunc={(pages)=>dispatch(getBookings(pages))}
                    />
                }
            </div>
        </div>
    )
}

export default Booking
