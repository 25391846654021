import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Icon, IconButton} from 'rsuite';
import {connect} from 'react-redux';
import { deleteTrek,editPopularTrek } from '../../actions/trekActions';
import { Modal,Button } from 'rsuite';

export class TreksItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show: false
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }
    close() {
        this.setState({ show: false });
    }
    open() {
        this.setState({ show: true });
    }
    render() {
        // console.log(this.props);
        const {trek,onEdit} = this.props
        return (
            <div 
            style={{gridTemplateColumns:'40% 15% 10% 15% 10% 10%'}}
                className="trek-item trek-grid">
                <div className="trek-item__name">
                    <Link to={`/treks/${trek.handle}`}>{trek.trek_name}</Link>
                </div>
                <div className="trek-item__price text-center">
                    {trek.price}
                </div>
                <div className="trek-item__days text-center">
                    {trek.days}
                </div>
                <div 
                    onClick={()=>this.props.editPopularTrek(trek.id,{popular:!trek.popular})}
                    className="trek-item__popular text-center">
                    {trek.popular?
                        <span className="chips chips-green">Popular</span>: 
                        <span className="chips chips-red">Unpopular</span>}
                </div>
                <div 
                    onClick={()=>onEdit(trek)}
                    className="trek-item__edit text-center">
                    <Icon icon="edit" size="lg" color="red"  />
                </div>
                <div 
                     onClick={this.open}
                    className="trek-item__delete text-center">
                    <Icon icon="minus-square" size="lg" color="blue"  />
                </div>
                <Modal backdrop="static" show={this.state.show} onHide={this.close} size="xs">
                    <Modal.Body>
                        <Icon
                        icon="remind"
                        style={{
                            color: '#ffb300',
                            fontSize: 24
                        }}
                        />
                        {'  '}
                        Are you sure, You want to delete this trek ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>{
                            this.props.deleteTrek(trek.id);
                            this.close()
                        }} appearance="primary">
                        Ok
                        </Button>
                        <Button onClick={this.close} appearance="subtle">
                        Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = {
    deleteTrek,
    editPopularTrek
}

export default connect(mapStateToProps,mapDispatchToProps)(TreksItem)
