import {
	GET_USER,
	GET_USERS,
	USER_LOADING,
	ADD_USER,
	DELETE_USER,
	EDIT_USER,
	UPDATE_PHOTO,
	UPDATE_ACCESS
} from "../actions/types";

const initialState = {
	users: [],
	user: {},
	loading: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case USER_LOADING:
			return {
				...state,
				loading: true
			};
		case GET_USER:
			return {
				...state,
				user: action.payload,
				loading: false
			};
		case GET_USERS:
			return {
				...state,
				users: action.payload,
				loading: false
			};
		case UPDATE_ACCESS:
			return {
        ...state,
        user:action.payload
      };
		case ADD_USER:
			return {
				...state,
				users: [action.payload, ...state.users]
			};
		case EDIT_USER:
			return {
				...state,
				users: state.users.map(user =>
					user.id === action.id ? action.payload : user
				)
			};
		case UPDATE_PHOTO:
			return {
				...state,
				users: state.users.map(user =>
					user.id === action.id
						? { ...user, photo: action.payload.photo }
						: user
				)
			};
		case DELETE_USER:
			return {
				...state,
				users: state.users.filter(user => user.id !== action.payload)
			};
		default:
			return state;
	}
}
