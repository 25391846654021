import axios from "axios";
import { Alert } from "rsuite";
import isEmpty from "../validations/isEmpty";

import {
  GET_SETTINGS,
  SETTINGS_LOADING,
  EDIT_SETTINGS,
  CLEAR_ERRORS,
  GET_ERRORS,
  ADD_TREK_REGION,
  EDIT_TREK_REGION,
  GET_TREK_REGION,
  TREK_REGION_LOADING,
  GET_TREK_REGIONS,
  DELETE_TREK_REGION,
  GET_SERVICES,
  DELETE_SERVICE,
  ADD_SERVICE,
  EDIT_SERVICE,
  GET_SERVICE_TYPES,
  DELETE_SERVICE_TYPE,
  EDIT_SERVICE_TYPE,
  ADD_SERVICE_TYPE,
  GET_BLOGS,
  DELETE_BLOG,
  EDIT_BLOG,
  ADD_BLOG,
  UPDATE_ALP_SETTINGS,
  UPDATE_ALP_SETTINGS_IMAGES,
  GET_ALP_SETTINGS,
  ADD_THINGS_TO,
  EDIT_THINGS_TO,
  GET_THINGS_TOS,
  DELETE_THINGS_TO,
  ADD_ACTIVITY_PRICE,
  EDIT_ACTIVITY_PRICE,
  DELETE_ACTIVITY_PRICE,
  GET_ACTIVITY_PRICES,
  EDIT_TREK_ITINERARY,
} from "./types";
import { getError } from "../helpers/getUnauth";

// Get all rooms
export const getSettings = () => async (dispatch) => {
  dispatch(setLoading(true));
  await axios
    .get("/api/v1/settings")
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({
        type: GET_SETTINGS,
        payload: res.data.data[0],
      });
    })
    .catch((err) => {
      getError(err);
      dispatch(setLoading(false));
      dispatch({
        type: GET_SETTINGS,
        payload: null,
      });
    });
};

// Edit Room
export const editSettings = (id, data) => (dispatch) => {
  dispatch(clearErrors());
  // debugger
  dispatch(setLoading(true));
  axios
    .put(`/api/v1/settings/${id}`, data)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: res.data.data[0],
          id: id,
        });
        Alert.success(res.data.message, 5000);
      } else {
        Alert.error("Something went wrong", 5000);
      }
    })
    .catch((err) => {
      dispatch(setLoading(false));
      getError(err);
      if (err) {
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        } else {
          console.log(err);
        }
      }
    });
};

// Update Rooms Icon
export const updateIcons = (id, data) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setLoading(true));
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  axios
    .post(`/api/v1/settings/uploads/${id}`, data, config)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: res.data.data[0],
          id: id,
        });
        Alert.success(res.data.message, 5000);
      } else {
        Alert.error("Something went wrong", 5000);
      }
    })
    .catch((err) => {
      dispatch(setLoading(false));
      getError(err);
      Alert.error("Something went wrong", 5000);
      if (err) {
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        } else {
          console.log(err);
        }
      }
    });
};

// Get all Teams
export const getTrekRegions = () => (dispatch) => {
  dispatch(setTeamLoading());
  axios
    .get("/api/v1/treks/regions/")
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_TREK_REGIONS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_TREK_REGIONS,
          payload: null,
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      dispatch({
        type: GET_TREK_REGIONS,
        payload: null,
      });
    });
};

// Delete Post
export const deleteTrekRegion = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/treks/regions/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TREK_REGION,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addTrekRegions =
  (newTeam, isEdit = false) =>
  (dispatch) => {
    dispatch(clearErrors());
    dispatch(setLoading(true));
    axios
      .post("/api/v1/treks/regions", newTeam)
      .then((res) => {
        dispatch(setLoading(false));
        console.log({ res });
        if (res.data.success) {
          console.log("HERERERER");
          dispatch({
            type: isEdit ? EDIT_TREK_REGION : ADD_TREK_REGION,
            payload: res.data.data,
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.success(res.data.error, 5000);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        getError(err);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
          return false;
        }
      });
  };

// Edit adventure
export const editTrekRegions = (teamId, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setLoading(true));
  axios
    .put(`/api/v1/treks/regions/${teamId}`, newTeam)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: EDIT_TREK_REGION,
          payload: res.data.data[0],
          id: teamId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.error(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

// Alp Settings
export const getAlpSettings = () => (dispatch) => {
  dispatch(setTeamLoading());
  axios
    .get("/api/v1/settings/alp/1")
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_ALP_SETTINGS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_ALP_SETTINGS,
          payload: null,
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      dispatch({
        type: GET_ALP_SETTINGS,
        payload: null,
      });
    });
};
export const updateAlpSettings = (blogId, uploadData) => (dispatch) => {
  setLoading(true);
  axios
    .put(`/api/v1/settings/alp/${blogId}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({
        type: UPDATE_ALP_SETTINGS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

export const addAlpFiles = (blogId, uploadData) => (dispatch) => {
  setLoading(true);
  axios
    .put(`/api/v1/settings/alp/upload/${blogId}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({
        type: UPDATE_ALP_SETTINGS_IMAGES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

export const addItineraryFiles = (blogId, uploadData) => (dispatch) => {
  setLoading(true);
  axios
    .post(`/api/v1/treks/itinerary/${blogId}/upload/`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({
        type: EDIT_TREK_ITINERARY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

export const addAlpLogo = (blogId, uploadData) => (dispatch) => {
  setLoading(true);
  axios
    .put(`/api/v1/settings/alp/logo/${blogId}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({
        type: UPDATE_ALP_SETTINGS_IMAGES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

// Blogs
export const getBlogss = () => (dispatch) => {
  dispatch(setTeamLoading());
  axios
    .get("/api/v1/blogs")
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_BLOGS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_BLOGS,
          payload: null,
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      dispatch({
        type: GET_BLOGS,
        payload: null,
      });
    });
};

// Delete Post
export const deleteBlog = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/blogs/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_BLOG,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addBlog =
  (newTeam, isEdit = false) =>
  (dispatch) => {
    dispatch(clearErrors());
    dispatch(setLoading(true));
    return axios
      .post("/api/v1/blogs", newTeam)
      .then((res) => {
        console.log({ res });
        dispatch(setLoading(false));
        if (res.data.success) {
          dispatch({
            type: isEdit ? EDIT_BLOG : ADD_BLOG,
            payload: isEdit ? res.data.data[0] : res.data.data,
          });
          Alert.success(res.data.message, 5000);
          return res.data.data;
        }
        Alert.success(res.data.error, 5000);
        return null
      })
      .catch((err) => {
        dispatch(setLoading(false));
        getError(err);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
          return null;
        }
      });
  };

// Edit adventure
export const editBlog = (teamId, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .put(`/api/v1/blogs/${teamId}`, newTeam)
    .then((res) => {
      // dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: EDIT_BLOG,
          payload: res.data.data[0],
          id: teamId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.error(res.data.error, 5000);
    })
    .catch((err) => {
      // dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

export const addBlogFiles = (blogId, uploadData) => (dispatch) => {
  setLoading(true);
  axios
    .put(`/api/v1/blogs/upload/${blogId}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({
        type: EDIT_BLOG,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

// Services
// Get all Teams
export const getServices = () => (dispatch) => {
  dispatch(setTeamLoading());
  axios
    .get("/api/v1/services")
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_SERVICES,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_SERVICES,
          payload: null,
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      dispatch({
        type: GET_SERVICES,
        payload: null,
      });
    });
};

// Delete Post
export const deleteService = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/services/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_SERVICE,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addService =
  (newTeam, isEdit = false) =>
  (dispatch) => {
    dispatch(clearErrors());
    dispatch(setLoading(true));
    axios
      .post("/api/v1/services", newTeam)
      .then((res) => {
        console.log({ res });
        dispatch(setLoading(false));
        if (res.data.success) {
          dispatch({
            type: isEdit ? EDIT_SERVICE : ADD_SERVICE,
            payload: res.data.data,
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.success(res.data.error, 5000);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        getError(err);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
          return false;
        }
      });
  };

// Edit adventure
export const editService = (teamId, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setLoading(true));
  axios
    .put(`/api/v1/services/${teamId}`, newTeam)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: EDIT_SERVICE,
          payload: res.data.data[0],
          id: teamId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.error(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

export const addServiceTypeFiles = (serviceId, uploadData) => (dispatch) => {
  setLoading(true);
  axios
    .put(`/api/v1/services/types/upload/${serviceId}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({
        type: EDIT_SERVICE_TYPE,
        payload: [res.data.data],
      });
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

export const getServiceTypes = () => (dispatch) => {
  dispatch(setTeamLoading());
  axios
    .get("/api/v1/services/types")
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_SERVICE_TYPES,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_SERVICES,
          payload: null,
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      dispatch({
        type: GET_SERVICES,
        payload: null,
      });
    });
};

// Delete Post
export const deleteServiceType = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/services/types/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_SERVICE_TYPE,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addServiceType =
  (newTeam, isEdit = false) =>
  (dispatch) => {
    dispatch(clearErrors());
    dispatch(setLoading(true));
    axios
      .post("/api/v1/services/types", newTeam)
      .then((res) => {
        dispatch(setLoading(false));
        if (res.data.success) {
          dispatch({
            type: newTeam.id ? EDIT_SERVICE_TYPE : ADD_SERVICE_TYPE,
            payload: res.data.data,
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.success(res.data.error, 5000);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        getError(err);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
          return false;
        }
      });
  };

// Edit adventure
export const editServiceType = (teamId, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setLoading(true));
  axios
    .put(`/api/v1/services/types/${teamId}`, newTeam)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: EDIT_SERVICE_TYPE,
          payload: res.data.data[0],
          id: teamId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.error(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

export const addThingsFiles = (serviceId, uploadData) => (dispatch) => {
  setLoading(true);
  axios
    .put(`/api/v1/settings/things/upload/${serviceId}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({
        type: EDIT_THINGS_TO,
        payload: [res.data.data],
      });
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

export const getThings = () => (dispatch) => {
  dispatch(setTeamLoading());
  axios
    .get("/api/v1/settings/things")
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_THINGS_TOS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_SERVICES,
          payload: null,
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      dispatch({
        type: GET_SERVICES,
        payload: null,
      });
    });
};

// Delete Post
export const deleteThings = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/settings/things/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_THINGS_TO,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addThings = (newTeam) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setLoading(true));
  axios
    .post("/api/v1/settings/things", newTeam)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: newTeam.id ? EDIT_THINGS_TO : ADD_THINGS_TO,
          payload: res.data.data,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setLoading(false));
      getError(err);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
    });
};

export const getActivityPrice =
  (search = null) =>
  async (dispatch) => {
    return await axios
      .get(
        search
          ? "/api/v1/services/group_size" + search
          : "/api/v1/services/group_size"
      )
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: GET_ACTIVITY_PRICES,
            payload: res.data.data,
          });
          return res.data.data;
        } else {
          dispatch({
            type: GET_ACTIVITY_PRICES,
            payload: null,
          });
          Alert.error(res.data.error, 5000);
          return null;
        }
      })
      .catch((err) => {
        getError(err);
        dispatch({
          type: GET_ACTIVITY_PRICES,
          payload: null,
        });
        return null;
      });
  };

export const setTeamLoading = () => {
  return {
    type: TREK_REGION_LOADING,
  };
};

// User loading
export const setLoading = (load) => {
  return {
    type: SETTINGS_LOADING,
    payload: load,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const addActivityPriceGroup = (newTeam) => (dispatch) => {
  dispatch(clearErrors());
  setLoading(true);
  axios
    .post("/api/v1/services/group_size", newTeam)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: newTeam.id ? EDIT_ACTIVITY_PRICE : ADD_ACTIVITY_PRICE,
          payload: newTeam.id ? res.data.data[0] : res.data.data,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setLoading(false));
      getError(err);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
    });
};

export const deleteActivityPriceGroup = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/services/group_size/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_ACTIVITY_PRICE,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};
