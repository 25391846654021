export const room_type = {
    in:'Checked In',
    out:'Checked Out',
}

export const code = {
    bar:'Bar Code',
    qr:'Qr Code',
}

export const treksRegion =[
    {label:"Annapurna Region Trek",value:"Annapurna Region Trek"},
    {label:"Everest Region Trek",value:"Everest Region Trek"},
    {label:"Langtang Region Trek",value:"Langtang Region Trek"},
    {label:"Manaslu Region Trek",value:"Manaslu Region Trek"},
    {label:"Ganesh Region Trek",value:"Ganesh Region Trek"},
    {label:"Kanchenjunga Region Trek",value:"Kanchenjunga Region Trek"},
    {label:"Dolpa Region Trek",value:"Dolpa Region Trek"},
    {label:"Rara Region Trek",value:"Rara Region Trek"},
    {label:"Small One Day Trek",value:"Small One Day Trek"},
];

export const treksType = [
    { label: 'Treks', value: 'treks' },
    { label: 'Hikes', value: 'hikes' },
    { label: 'Offbeat', value: 'offbeat' },
    { label: 'Recreational', value: 'recreational' }
]