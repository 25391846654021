import axios from "axios";
import { Alert } from "rsuite";
import isEmpty from "../validations/isEmpty";

import {
  CLEAR_ERRORS,
  GET_ERRORS,
  TOUR_LOADING,
  ADD_TOUR,
  EDIT_TOUR,
  GET_TOUR,
  GET_TOURS,
  DELETE_TOUR,
  TOUR_CITY_LOADING,
  ADD_TOUR_CITY,
  EDIT_TOUR_CITY,
  GET_TOUR_CITY,
  GET_TOUR_CITIES,
  DELETE_TOUR_CITY,
  TOUR_PACKAGE_LOADING,
  ADD_TOUR_PACKAGE,
  EDIT_TOUR_PACKAGE,
  GET_TOUR_PACKAGE,
  GET_TOUR_PACKAGES,
  DELETE_TOUR_PACKAGE,
  TOUR_PACKAGE_ITINERARY_LOADING,
  ADD_TOUR_PACKAGE_ITINERARY,
  EDIT_TOUR_PACKAGE_ITINERARY,
  GET_TOUR_PACKAGE_ITINERARY,
  GET_TOUR_PACKAGE_ITINERARIES,
  DELETE_TOUR_PACKAGE_ITINERARY,
  DELETE_TOUR_PACKAGE_INCLUDE,
  EDIT_TOUR_PACKAGE_INCLUDE,
  ADD_TOUR_PACKAGE_INCLUDE,
} from "./types";
import { getError } from "../helpers/getUnauth";

// Get all Teams
export const getTours = () => (dispatch) => {
  dispatch(setTourLoading());
  axios
    .get("/api/v1/tours/")
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_TOURS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_TOURS,
          payload: null,
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      dispatch({
        type: GET_TOURS,
        payload: null,
      });
    });
};

// Delete Post
export const deleteTours = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/tours/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TOUR,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addTours =
  (newTeam, isEdit = false) =>
  (dispatch) => {
    dispatch(clearErrors());
    dispatch(setTourLoading(true));
    axios
      .post("/api/v1/tours", newTeam)
      .then((res) => {
        dispatch(setTourLoading(false));
        if (res.data.success) {
          dispatch({
            type: isEdit ? EDIT_TOUR : ADD_TOUR,
            payload: isEdit?res.data.data[0]:res.data.data,
            id:isEdit?res.data.data[0].id:null
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.success(res.data.error, 5000);
      })
      .catch((err) => {
        dispatch(setTourLoading(false));
        getError(err);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
          return false;
        }
      });
  };

// Edit adventure
export const editTours = (teamId, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setTourLoading(true));
  axios
    .put(`/api/v1/tours/${teamId}`, newTeam)
    .then((res) => {
      dispatch(setTourLoading(false));
      if (res.data.success) {
        dispatch({
          type: EDIT_TOUR,
          payload: res.data.data[0],
          id: teamId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.error(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setTourLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

// City
export const getTourCities = () => (dispatch) => {
  dispatch(setTourCityLoading());
  axios
    .get("/api/v1/tours/city")
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_TOUR_CITIES,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_TOUR_CITIES,
          payload: null,
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      dispatch({
        type: GET_TOUR_CITIES,
        payload: null,
      });
    });
};

// Delete Post
export const deleteTourCity = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/tours/city/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TOUR_CITY,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addTourCity =
  (newTeam, isEdit = false) =>
  (dispatch) => {
    dispatch(clearErrors());
    dispatch(setTourCityLoading(true));
    axios
      .post("/api/v1/tours/city", newTeam)
      .then((res) => {
        dispatch(setTourCityLoading(false));
        if (res.data.success) {
          dispatch({
            type: isEdit ? EDIT_TOUR_CITY : ADD_TOUR_CITY,
            payload: res.data.data,
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.success(res.data.error, 5000);
      })
      .catch((err) => {
        dispatch(setTourCityLoading(false));
        getError(err);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
          return false;
        }
      });
};

export const addPackageIncludes =
  (newTeam, isEdit = false) =>
  (dispatch) => {
    dispatch(clearErrors());
    dispatch(setTourCityLoading(true));
    axios
      .post("/api/v1/tours/packages/includes", newTeam)
      .then((res) => {
        dispatch(setTourCityLoading(false));
        if (res.data.success) {
          dispatch({
            type: isEdit ? EDIT_TOUR_PACKAGE_INCLUDE : ADD_TOUR_PACKAGE_INCLUDE,
            payload: res.data.data,
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.success(res.data.error, 5000);
      })
      .catch((err) => {
        dispatch(setTourCityLoading(false));
        getError(err);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
          return false;
        }
      });
};

export const addPackageItinerary =
  (newTeam, isEdit = false) =>
  (dispatch) => {
    dispatch(clearErrors());
    dispatch(setTourCityLoading(true));
    axios
      .post("/api/v1/tours/packages/itinerary", newTeam)
      .then((res) => {
        dispatch(setTourCityLoading(false));
        if (res.data.success) {
          dispatch({
            type: isEdit ? EDIT_TOUR_PACKAGE_ITINERARY : ADD_TOUR_PACKAGE_ITINERARY,
            payload: res.data.data,
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.success(res.data.error, 5000);
      })
      .catch((err) => {
        dispatch(setTourCityLoading(false));
        getError(err);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
          return false;
        }
      });
};

// Edit
export const editTourCity = (teamId, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setTourCityLoading(true));
  axios
    .put(`/api/v1/tours/city/${teamId}`, newTeam)
    .then((res) => {
      dispatch(setTourCityLoading(false));
      if (res.data.success) {
        dispatch({
          type: EDIT_TOUR_CITY,
          payload: res.data.data[0],
          id: teamId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.error(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setTourCityLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

// Get all tours
export const getTourPackages = () => (dispatch) => {
  dispatch(setTourPackageLoading());
  axios
    .get("/api/v1/tours/packages")
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_TOUR_PACKAGES,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_TOUR_PACKAGES,
          payload: null,
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      dispatch({
        type: GET_TOUR_PACKAGES,
        payload: null,
      });
    });
};

// Get all tours
export const getTourPackage = (id) => (dispatch) => {
  dispatch(setTourPackageLoading());
  axios
    .get("/api/v1/tours/packages/"+id)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_TOUR_PACKAGE,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_TOUR_PACKAGE,
          payload: null,
        });
        Alert.error(res.data.error, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      dispatch({
        type: GET_TOUR_PACKAGE,
        payload: null,
      });
    });
};

// Delete Post
export const deleteTourPackage = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/tours/packages/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TOUR_PACKAGE,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const deleteTourPackageIncludes = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/tours/packages/includes/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TOUR_PACKAGE_INCLUDE,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const deleteTourPackageItinerary = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/tours/packages/itinerary/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TOUR_PACKAGE_ITINERARY,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addTourPackage =
  (newTeam, isEdit = false) =>
  (dispatch) => {
    dispatch(clearErrors());
    dispatch(setTourPackageLoading(true));
    axios
      .post("/api/v1/tours/packages", newTeam)
      .then((res) => {
        dispatch(setTourPackageLoading(false));
        if (res.data.success) {
          dispatch({
            type: isEdit ? EDIT_TOUR_PACKAGE : ADD_TOUR_PACKAGE,
            payload: res.data.data,
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.success(res.data.error, 5000);
      })
      .catch((err) => {
        dispatch(setTourPackageLoading(false));
        getError(err);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
          return false;
        }
      });
  };

// Edit adventure
export const editTourPackage = (teamId, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setTourPackageLoading(true));
  axios
    .put(`/api/v1/tours/packages/${teamId}`, newTeam)
    .then((res) => {
      dispatch(setTourPackageLoading(false));
      if (res.data.success) {
        dispatch({
          type: EDIT_TOUR_PACKAGE,
          payload: res.data.data[0],
          id: teamId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.error(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setTourPackageLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};


export const addTourFiles = (blogId, uploadData) => dispatch => {
  setTourLoading(true);
  axios
    .put(`/api/v1/tours/upload/${blogId}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then(res =>{
          dispatch(setTourLoading(false));
          dispatch({
              type: EDIT_TOUR,
              payload: res.data.data,
              id:res.data.data.id
          })
      }
    )
    .catch(err => {
      dispatch(setTourLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        return false;
      }
      getError(err);
    });
};

export const addPackageFiles = (blogId, uploadData) => dispatch => {
  setTourLoading(true);
  axios
    .put(`/api/v1/tours/packages/upload/${blogId}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then(res =>{
          dispatch(setTourLoading(false));
          dispatch({
              type: EDIT_TOUR_PACKAGE,
              payload: res.data.data,
              id: blogId,
          })
      }
    )
    .catch(err => {
      dispatch(setTourLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        return false;
      }
      getError(err);
    });
};
export const setTourLoading = (load) => {
  return {
    type: TOUR_LOADING,
    payload: load,
  };
};

export const setTourCityLoading = (load) => {
  return {
    type: TOUR_CITY_LOADING,
    payload: load,
  };
};

export const setTourPackageLoading = (load) => {
  return {
    type: TOUR_PACKAGE_LOADING,
    payload: load,
  };
};

export const setTourPackageItineraryLoading = (load) => {
  return {
    type: TOUR_PACKAGE_ITINERARY_LOADING,
    payload: load,
  };
};
// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
