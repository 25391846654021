export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGIN_LOADING = 'LOGIN_LOADING'

export const USER_LOADING = 'USER_LOADING';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_PHOTO ='UPDATE_PHOTO';
export const UPDATE_ACCESS='UPDATE_ACCESS';

export const TREK_LOADING = 'TREK_LOADING';
export const TREK_ITINERARY_LOADING = 'TREK_ITINERARY_LOADING';
export const GET_TREKS = 'GET_TREKS';
export const GET_TREK = 'GET_TREK';
export const GET_TREKS_BY_TYPE = 'GET_TREKS_BY_TYPE';
export const ADD_TREK = 'ADD_TREK';
export const EDIT_TREK = 'EDIT_TREK';
export const ADD_POPULAR_TREK = 'ADD_POPULAR_TREK';
export const DELETE_TREK = 'DELETE_TREK';
export const SINGLE_EDIT_TREK = 'SINGLE_EDIT_TREK';
export const SINGLE_EDIT_TREK_OVERVIEW = 'SINGLE_EDIT_TREK_OVERVIEW';

export const ADD_TREK_ITINERARY = 'ADD_TREK_ITINERARY';
export const EDIT_TREK_ITINERARY = 'EDIT_TREK_ITINERARY';
export const DELETE_TREK_ITINERARY = 'DELETE_TREK_ITINERARY';

export const ADD_TREK_ALTITUDE = 'ADD_TREK_ALTITUDE';
export const EDIT_TREK_ALTITUDE = 'EDIT_TREK_ALTITUDE';
export const DELETE_TREK_ALTITUDE = 'DELETE_TREK_ALTITUDE';

export const ADD_TREK_INCLUDES = 'ADD_TREK_INCLUDES';
export const EDIT_TREK_INCLUDES = 'EDIT_TREK_INCLUDES';
export const DELETE_TREK_INCLUDES = 'DELETE_TREK_INCLUDES';

export const ADD_TREK_PRICE_DETAILS = 'ADD_TREK_PRICE_DETAILS';
export const EDIT_TREK_PRICE_DETAILS = 'EDIT_TREK_PRICE_DETAILS';
export const DELETE_TREK_PRICE_DETAILS = 'DELETE_TREK_PRICE_DETAILS';
export const ADD_TREK_PRICE_GROUP_SIZE = 'ADD_TREK_PRICE_GROUP_SIZE';
export const EDIT_TREK_PRICE_GROUP_SIZE = 'EDIT_TREK_PRICE_GROUP_SIZE';
export const DELETE_TREK_PRICE_GROUP_SIZE = 'DELETE_TREK_PRICE_GROUP_SIZE';

export const SCAN_LOADING ='SCAN_LOADING';
export const CLEAR_SCAN ='CLEAR_SCAN';
export const GET_SCAN ='GET_SCAN';

export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const EDIT_CUSTOMER_STATUS = 'EDIT_CUSTOMER_STATUS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

export const ROOM_LOADING = 'ROOM_LOADING';
export const GET_ROOMS = 'GET_ROOMS';
export const GET_ROOM = 'GET_ROOM';
export const ADD_ROOM = 'ADD_ROOM';
export const EDIT_ROOM = 'EDIT_ROOM';
export const DELETE_ROOM = 'DELETE_ROOM';
export const ROOM_LOADING_SUBMIT = 'ROOM_LOADING_SUBMIT';
export const EDIT_ROOM_IMAGES ='EDIT_ROOM_IMAGES';

export const CONTACT_LOADING = 'CONTACT_LOADING';
export const GET_CONTACTS = 'GET_CONTACTS';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const CONTACTS_LOADING="CONTACTS_LOADING";

// Trip Plan
export const TRIP_PLAN_LOADING = 'TRIP_PLAN_LOADING';
export const GET_TRIP_PLANS = 'GET_TRIP_PLANS';
export const DELETE_TRIP_PLAN = 'DELETE_TRIP_PLAN';

// Reviews
export const GET_REVIEWS = 'GET_REVIEWS';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const REVIEWS_LOADING="REVIEWS_LOADING";
export const EDIT_REVIEW = "EDIT_REVIEW";

// Reviews
export const GET_BOOKINGS_QR = 'GET_BOOKINGS_QR';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const BOOKINGS_LOADING="BOOKINGS_LOADING";
export const EDIT_BOOKING = "EDIT_BOOKING";
export const GET_BOOKING = "GET_BOOKING";
export const GET_BOOKING_QR = "GET_BOOKING_QR";

// Activities
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const ACTIVITY_LOADING="ACTIVITY_LOADING";

//Adventures
export const ADVENTURE_LOADING = 'ADVENTURE_LOADING';
export const ADD_ADVENTURE = 'ADD_ADVENTURE';
export const EDIT_ADVENTURE = 'EDIT_ADVENTURE';
export const GET_ADVENTURE = 'GET_ADVENTURE';
export const GET_ADVENTURES = 'GET_ADVENTURES';
export const DELETE_ADVENTURE = 'DELETE_ADVENTURE';
export const EDIT_ADVENTURE_UPLOAD = "EDIT_ADVENTURE_UPLOAD"

//Teams
export const TEAM_LOADING = 'TEAM_LOADING';
export const ADD_TEAM = 'ADD_TEAM';
export const EDIT_TEAM = 'EDIT_TEAM';
export const GET_TEAM = 'GET_TEAM';
export const GET_TEAMS = 'GET_TEAMS';
export const DELETE_TEAM = 'DELETE_TEAM';
export const EDIT_TEAM_UPLOAD = "EDIT_TEAM_UPLOAD"
export const ADD_TEAM_LOAD = "ADD_TEAM_LOAD"

//Teams
export const TREK_REGION_LOADING = 'TREK_REGION_LOADING';
export const ADD_TREK_REGION = 'ADD_TREK_REGION';
export const EDIT_TREK_REGION = 'EDIT_TREK_REGION';
export const GET_TREK_REGION = 'GET_TREK_REGION';
export const GET_TREK_REGIONS = 'GET_TREK_REGIONS';
export const DELETE_TREK_REGION = 'DELETE_TREK_REGION';


// Tour Actions 
export const TOUR_LOADING = 'TOUR_LOADING';
export const ADD_TOUR = 'ADD_TOUR';
export const EDIT_TOUR = 'EDIT_TOUR';
export const GET_TOUR = 'GET_TOUR';
export const GET_TOURS = 'GET_TOURS';
export const DELETE_TOUR = 'DELETE_TOUR';

export const TOUR_CITY_LOADING = 'TOUR_CITY_LOADING';
export const ADD_TOUR_CITY = 'ADD_TOUR_CITY';
export const EDIT_TOUR_CITY = 'EDIT_TOUR_CITY';
export const GET_TOUR_CITY = 'GET_TOUR_CITY';
export const GET_TOUR_CITIES = 'GET_TOUR_CITIES';
export const DELETE_TOUR_CITY = 'DELETE_TOUR_CITY';

export const TOUR_PACKAGE_LOADING = 'TOUR_PACKAGE_LOADING';
export const ADD_TOUR_PACKAGE = 'ADD_TOUR_PACKAGE';
export const EDIT_TOUR_PACKAGE = 'EDIT_TOUR_PACKAGE';
export const GET_TOUR_PACKAGE = 'GET_TOUR_PACKAGE';
export const GET_TOUR_PACKAGES = 'GET_TOUR_PACKAGES';
export const DELETE_TOUR_PACKAGE = 'DELETE_TOUR_PACKAGE';

export const TOUR_PACKAGE_ITINERARY_LOADING = 'TOUR_PACKAGE_ITINERARY_LOADING';
export const ADD_TOUR_PACKAGE_ITINERARY = 'ADD_TOUR_PACKAGE_ITINERARY';
export const EDIT_TOUR_PACKAGE_ITINERARY = 'EDIT_TOUR_PACKAGE_ITINERARY';
export const GET_TOUR_PACKAGE_ITINERARY = 'GET_TOUR_PACKAGE_ITINERARY';
export const GET_TOUR_PACKAGE_ITINERARIES = 'GET_TOUR_PACKAGE_ITINERARIES';
export const DELETE_TOUR_PACKAGE_ITINERARY = 'DELETE_TOUR_PACKAGE_ITINERARY';

export const TOUR_PACKAGE_INCLUDE_LOADING = 'TOUR_PACKAGE_INCLUDE_LOADING';
export const ADD_TOUR_PACKAGE_INCLUDE = 'ADD_TOUR_PACKAGE_INCLUDE';
export const EDIT_TOUR_PACKAGE_INCLUDE = 'EDIT_TOUR_PACKAGE_INCLUDE';
export const GET_TOUR_PACKAGE_INCLUDE = 'GET_TOUR_PACKAGE_INCLUDE';
export const GET_TOUR_PACKAGE_INCLUDES = 'GET_TOUR_PACKAGE_INCLUDES';
export const DELETE_TOUR_PACKAGE_INCLUDE = 'DELETE_TOUR_PACKAGE_INCLUDE';

//Blogs
export const BLOG_LOADING = 'BLOG_LOADING';
export const ADD_BLOG = 'ADD_BLOG';
export const EDIT_BLOG = 'EDIT_BLOG';
export const GET_BLOG = 'GET_BLOG';
export const GET_BLOGS = 'GET_BLOGS';
export const DELETE_BLOG = 'DELETE_BLOG';

//ALP
export const GET_ALP_SETTINGS = 'GET_ALP_SETTINGS';
export const UPDATE_ALP_SETTINGS = 'UPDATE_ALP_SETTINGS';
export const UPDATE_ALP_SETTINGS_IMAGES = 'UPDATE_ALP_SETTINGS_IMAGES';
export const THINGS_TO_LOADING = 'THINGS_TO_LOADING';
export const ADD_THINGS_TO = 'ADD_THINGS_TO';
export const EDIT_THINGS_TO = 'EDIT_THINGS_TO';
export const GET_THINGS_TO = 'GET_THINGS_TO';
export const GET_THINGS_TOS = 'GET_THINGS_TOS';
export const DELETE_THINGS_TO = 'DELETE_THINGS_TO';


export const ADD_ACTIVITY_PRICE = 'ADD_ACTIVITY_PRICE';
export const EDIT_ACTIVITY_PRICE = 'EDIT_ACTIVITY_PRICE';
export const GET_ACTIVITY_PRICE = 'GET_ACTIVITY_PRICE';
export const GET_ACTIVITY_PRICES = 'GET_ACTIVITY_PRICES';
export const DELETE_ACTIVITY_PRICE = 'DELETE_ACTIVITY_PRICE';

//Services
export const SERVICE_LOADING = 'SERVICE_LOADING';
export const ADD_SERVICE = 'ADD_SERVICE';
export const EDIT_SERVICE = 'EDIT_SERVICE';
export const GET_SERVICE = 'GET_SERVICE';
export const GET_SERVICES = 'GET_SERVICES';
export const DELETE_SERVICE = 'DELETE_SERVICE';

export const SERVICE_TYPE_LOADING = 'SERVICE_TYPE_LOADING';
export const ADD_SERVICE_TYPE = 'ADD_SERVICE_TYPE';
export const EDIT_SERVICE_TYPE = 'EDIT_SERVICE_TYPE';
export const GET_SERVICE_TYPE = 'GET_SERVICE_TYPE';
export const GET_SERVICE_TYPES = 'GET_SERVICE_TYPES';
export const DELETE_SERVICE_TYPE = 'DELETE_SERVICE_TYPE';

// Status
export const STATUS_LOADING = 'STATUS_LOADING';
export const GET_STATUSES = 'GET_STATUSES';
export const GET_STATUS = 'GET_STATUS';
export const ADD_STATUS = 'ADD_STATUS';
export const EDIT_STATUS = 'EDIT_STATUS';
export const DELETE_STATUS = 'DELETE_STATUS';
export const STATUS_LOADING_SUBMIT ='STATUS_LOADING_SUBMIT';

export const SETTINGS_LOADING = 'SETTING_LOADING';
export const GET_SETTINGS = 'GET_SETTINGS';
export const EDIT_SETTINGS = 'EDIT_SETTINGS';

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
