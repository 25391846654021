import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteContact, getContacts, getWebsiteContacts } from "../../actions/contactActions";
import { Table,IconButton,Icon } from "rsuite";
import './contacts.styles.scss';
import CustomPagination from '../../components/common/CustomPagination';
import isEmpty from '../../validations/isEmpty'

const { Column, HeaderCell, Cell, Pagination,  } = Table;
const rowKey = 'id';
const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props}>
      <IconButton
        size="xs"
        appearance="subtle"
        onClick={() => {
          onChange(rowData);
        }}
        icon={
          <Icon
            icon={
              expandedRowKeys.some(key => key === rowData[rowKey])
                ? 'minus-square-o'
                : 'plus-square-o'
            }
          />
        }
      />
    </Cell>
  );

const ActionCell = ({ rowData,onContactDelete, dataKey, ...props }) => {
    return (
      <Cell {...props} className="link-group">
        <IconButton onClick={()=>onContactDelete(rowData.id)} appearance="subtle" icon={<Icon icon="trash" />} />
      </Cell>
    );
  };
const Website = ({ rowData, dataKey, ...props }) =>{
  return(
    <Cell {...props} className="website" dataKey="website" > 
      <span style={{textTransform:'capitalize'}}>{rowData[dataKey]}</span>
    </Cell>
  )
}
export class Contacts extends Component {
    constructor(props) {
        super(props)
        this.state = {
          type:'All',
          expandedRowKeys:[]
        }
        // this.state = {
        //     pageSize: 10,
        // }
        // this.handleChangePage = this.handleChangePage.bind(this);
        // this.handleChangeLength = this.handleChangeLength.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.renderTimeTab = this.renderTimeTab.bind(this);
    }
    
  componentDidMount() {
    this.props.getContacts();
  }
  onContactDelete(id){
    this.props.deleteContact(id)
  }
//   handleChangePage(dataKey) {
//     let pages={
//       pageNumber:dataKey-1,
//       pageSize:this.state.pageSize
//     }
//     this.props.getContacts(pages);

//   }
//   handleChangeLength(dataKey) {
//     let pages={
//       pageNumber:0,
//       pageSize:dataKey
//     }
//     this.props.getContacts(pages);
//     this.setState({
//       pageSize: dataKey
//     });
//   }
  changeTime(type) {
    this.setState({ type });
    if (type === "Flock" && this.state.type !== "Flock") {
        this.props.getWebsiteContacts(null,'flock');
    } else if (type === "Alpwander" && this.state.type !== "Alpwander") {
        this.props.getWebsiteContacts(null,'alpwander');
    } else if (this.state.type !== "All") {
        this.props.getContacts();
    }
  }
  handleExpanded = (rowData, dataKey) => {
    let open = false;
    const nextExpandedRowKeys = [];

    this.state.expandedRowKeys.forEach(key => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }
    this.setState({expandedRowKeys:nextExpandedRowKeys})
    // setExpandedRowKeys(nextExpandedRowKeys)
  }
  renderTimeTab() {
    const { type } = this.state;
    const time = ["All", "Alpwander", "Flock"];
    return (
        <ul className="time-tab-list">
            {time.map((item, key) => {
                return (
                    <li
                        className={
                            type === item
                                ? "time-tab-list__item active-tab"
                                : "time-tab-list__item"
                        }
                        key={key}
                        onClick={() => this.changeTime(item)}
                    >
                        <span>{item}</span>
                    </li>
                );
            })}
        </ul>
    );
  }
  render() {
    const { loading, contacts,page } = this.props;
    // console.log(loading, contacts);
    // if(loading){
    //     return <Loader backdrop content="loading..." vertical />
    // }
    const { type } = this.state;
    return (
      <div className="contacts">
        <div className="time-tab">{this.renderTimeTab()}</div>
        <div className="contacts-table">
          <Table 
            wordWrap
            expandedRowKeys={this.state.expandedRowKeys}
            renderRowExpanded={rowData =><div className="contact-message">
              {rowData?.message}
            </div>}
            on loading={loading} height={500} data={contacts}>
            <Column width={70} align="center">
              <HeaderCell>Id</HeaderCell>
              {/* <Cell dataKey="id" /> */}
              <ExpandCell
                    dataKey="id"
                    expandedRowKeys={this.state.expandedRowKeys}
                    onChange={this.handleExpanded}
                />
            </Column>

            <Column width={130}>
              <HeaderCell>Name</HeaderCell>
              <Cell dataKey="name" />
            </Column>

            <Column width={200}>
              <HeaderCell>Email</HeaderCell>
              <Cell dataKey="email" />
            </Column>

            <Column width={100}>
              <HeaderCell>Phone</HeaderCell>
              <Cell dataKey="phone" />
            </Column>
            <Column width={100}>
              <HeaderCell>Website</HeaderCell>
              <Website dataKey="website" />
              
            </Column>
            <Column width={350} resizable>
              <HeaderCell>Message</HeaderCell>
              <Cell dataKey="message" />
            </Column>
            <Column width={350} resizable>
              <HeaderCell>Contacted At</HeaderCell>
              <Cell dataKey="createdAt" />
            </Column>

            
            <Column width={100}>
              <HeaderCell>Delete</HeaderCell>
              <ActionCell dataKey="id" onContactDelete={this.onContactDelete.bind(this)} />
            </Column>
          </Table>
          {
                isEmpty(contacts)?null:
                <CustomPagination 
                        page={page}
                        getFunc={(pages)=>{
                          if (type === "Flock") {
                            this.props.getWebsiteContacts(pages,'flock');
                          } else if (type === "Alpwander") {
                              this.props.getWebsiteContacts(pages,'alpwander');
                          }else{
                            this.props.getContacts(pages)
                          }
                        }}
                />
        }
          
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getContacts,
  deleteContact,
  getWebsiteContacts
};

const mapStateToProps = state => ({
  contacts: state.contactReducer.contacts,
  page: state.contactReducer.page,
  loading: state.contactReducer.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
