import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import TeamItem from './TeamItem';
import { Icon, Uploader, Avatar, IconButton } from "rsuite";
import { Modal, Button } from "rsuite";
import { Link } from "react-router-dom";
import {
  deleteBlog,
  addBlogFiles,
  editBlog,
} from "../../../actions/settingActions";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../validations/isEmpty";
import renderHTML from "react-render-html";
import BlogItem from "./blog-item";
import { Table } from "rsuite";
const { Column, HeaderCell, Cell, Pagination } = Table;

const styles = {
  width: 300,
  marginBottom: 10,
};

const ImageCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div
      style={{
        width: 40,
        height: 40,
        background: "#f5f5f5",
        borderRadius: 20,
        marginTop: 2,
        overflow: "hidden",
        display: "inline-block",
      }}
    >
      <img src={rowData[dataKey]} width="44" />
    </div>
  </Cell>
);

const ActionCell = ({ rowData, dataKey, onEdit, ...props }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const id = rowData[dataKey];
  const close = () => {
    setShow(false);
  };
  const open = (e) => {
    e.stopPropagation();
    setShow(true);
  };
  return (
    <Cell {...props} style={{ padding: 0 }}>
      <div className="blogs-action">
        {/* <div onClick={() => onEdit(rowData)} className="blogs-action__item edit">
        <Icon icon="edit" size="sm" color="red" />
        
      </div> */}
        <IconButton
          appearance="subtle"
          className="blogs-action__item edit"
          onClick={(e) => {
            e.stopPropagation();
            onEdit(rowData)}}
          icon={<Icon icon="edit2" />}
        />
        <IconButton
          appearance="subtle"
          className="blogs-action__item delete"
          onClick={open}
          icon={<Icon icon="minus-square" />}
        />
      </div>
      <Modal backdrop="static" show={show} onHide={close} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure, You want to delete this blog?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              // e?.stopPropagation();
              dispatch(deleteBlog(id));
              close();
            }}
            appearance="primary"
          >
            Ok
          </Button>
          <Button onClick={close} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Cell>
  );
};

const BooleanCell = ({ rowData, dataKey, onEdit, ...props }) => {
  const dispatch = useDispatch();
  const isThere = rowData[dataKey];
  const notKey = rowData[props?.notKey];
  const onClick = async (e) => {
    e.stopPropagation();
    await dispatch(
      editBlog(rowData?.id, { [dataKey]: !isThere, [props?.notKey]: notKey })
    );
  };
  return (
    <Cell {...props} style={{ padding: 0 }}>
      <div className="blogs-action">
        <IconButton
          appearance="subtle"
          className="blogs-action__item green"
          onClick={onClick}
          icon={isThere ? <Icon icon="check" /> : <Icon icon="close" />}
        />
      </div>
    </Cell>
  );
};

function TeamsFeed({ trekRegions, onEdit }) {
  const [blog, setBlog] = useState(null);
  return (
    <>
      <BlogItem blog={blog} setBlog={setBlog} />
      <Table
        height={600}
        data={trekRegions}
        className="blogs-table"
        onRowClick={(data) => {
          setBlog(data);
        }}
      >
        <Column width={80} align="center">
          <HeaderCell>Image</HeaderCell>
          <ImageCell dataKey="image" />
        </Column>
        <Column width={500} align="center" fixed>
          <HeaderCell>Title</HeaderCell>
          <Cell dataKey="title" className="text-link" />
        </Column>
        <Column width={80} align="center" fixed>
          <HeaderCell>View count</HeaderCell>
          <Cell dataKey="view_count" />
        </Column>
        <Column width={80} align="center" fixed>
          <HeaderCell>Published ?</HeaderCell>
          <BooleanCell dataKey="is_published" notKey="is_published" />
        </Column>
        <Column width={80} align="center" fixed>
          <HeaderCell>Featured ?</HeaderCell>
          <BooleanCell dataKey="is_featured" notKey="is_featured" />
        </Column>
        <Column width={120} align="center" fixed>
          <HeaderCell>Actions</HeaderCell>
          <ActionCell onEdit={onEdit} dataKey="id" />
        </Column>
      </Table>
    </>
  );
}

TeamsFeed.propTypes = {
  teams: PropTypes.array.isRequired,
};

export default TeamsFeed;
