/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from 'react'
import { Drawer, Button } from 'rsuite';
import BlogForm from './blog-form'
import isEmpty from '../../../validations/isEmpty';
function BlogDrawer(props) {
    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false)
        props?.onEdit(null)
    }
    useEffect(()=>{
        if(!isEmpty(props.editData)){
            setOpen(true)
        }
    },[props.editData])
  return (
    <div>
    <Button appearance="primary" onClick={()=>setOpen(true)}>Add Blog</Button>
    <Drawer
          show={open}
          onHide={()=>onClose()}
          // full
          size="lg"
          backdrop="static"
          placement="right"
        >
          <Drawer.Header>
            <Drawer.Title>Add Blog</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <BlogForm 
                {...props}
                onClose={onClose}
            />
          </Drawer.Body>
          <Drawer.Footer>
            {/* <Button onClick={onClose} appearance="primary">Confirm</Button> */}
            <Button onClick={onClose} appearance="subtle">Cancel</Button>
          </Drawer.Footer>
    </Drawer>   
    </div>
  )
}

export default BlogDrawer