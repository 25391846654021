import {
    GET_CUSTOMER,
    GET_CUSTOMERS,
    CUSTOMER_LOADING,
    ADD_CUSTOMER,
    DELETE_CUSTOMER,
    EDIT_CUSTOMER_STATUS,
    EDIT_CUSTOMER
  } from '../actions/types';
  
  const initialState = {
    customers: [],
    customer: {},
    page: {},
    loading: false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case CUSTOMER_LOADING:
        return {
          ...state,
          loading: true
        };
      case GET_CUSTOMER:
        return {
          ...state,
          customer: action.payload,
          loading: false
        };
      case GET_CUSTOMERS:
        return {
          ...state,
          customers: action.payload,
          page:action.page,
          loading: false
        };
    case ADD_CUSTOMER:
        return {
          ...state,
          customers: [action.payload, ...state.customers]
        };
    case EDIT_CUSTOMER:
      return {
        ...state,
        customers:state.customers.map(customer=>customer.id === action.id?action.payload:customer)
      };

    case EDIT_CUSTOMER_STATUS:
        return {
          ...state,
          customers:state.customers.map(customer=>customer.id === action.id?
                    { ...customer, 
                        status: action.payload.status
                    }
                    :customer)
        };
    case DELETE_CUSTOMER:
        return {
          ...state,
          customers: state.customers.filter(customer => customer.id !== action.payload)
        };
      default:
        return state;
    }
  }
  