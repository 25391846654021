import React, { useEffect, useState } from 'react'
import UploadImages from "../../../components/common/UploadImages";
import { addAlpFiles,addAlpLogo} from '../../../actions/settingActions';
import { useDispatch } from 'react-redux';

function SettingsImages(props) {
    const {alpSettings} = props;
    const [images,setImages] = useState([]);
    const [logo,setLogo] = useState(null);
    useEffect(()=>{
        if(alpSettings){
            if(alpSettings?.images){
                setImages(alpSettings.images)
                setLogo(alpSettings.logo)
            }
        }
    },[alpSettings])
    const dispatch = useDispatch()
    const onImageChange = (name) => {
        console.log({name,images})
        setImages(prev=>{
            return prev.filter(image=>image!==name)
        })
    }
    const addFiles = (data,f) => {
        dispatch(addAlpFiles(data,f))
        console.log({data,f,images})
    }
    const addLogo = (data,f) => {
        dispatch(addAlpLogo(data,f))
        console.log({data,f,logo})
    }
    return (
        <div>
            <UploadImages
                UploadImages={addLogo}
                images={logo ?? null}
                id={alpSettings?.id ?? null}
                name="logo"
                title="Upload Alpwander Logo"
                isMultiple={false}
                onImageChange={onImageChange}
            />
            <UploadImages
                UploadImages={addFiles}
                images={images ?? []}
                id={alpSettings?.id ?? null}
                name="images"
                title="Upload Slider Images"
                onImageChange={onImageChange}
            />
        </div>
    )
}

export default SettingsImages