import React, {Component} from "react";

export default class CKEditor extends Component {
  constructor(props) {
    super(props);
    this.elementName =  this.props.id;
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  render() {
    return (
      <textarea name={this.elementName} className="sth" value={this.props.datas} defaultValue={this.props.value}></textarea>
    )
  }

  componentDidMount() {
    let configuration = {
      toolbar: "Basic"
    };
    window.CKEDITOR.replace(this.elementName, configuration);
    window.CKEDITOR.instances[this.elementName].on("change", function () {
      let data = window.CKEDITOR.instances[this.elementName].getData();
      const con = this.elementName.substring(0,3);
      if(con === 'ids'){
        this.props.onChange('content' ,data);
      }
      else if(con === 'idc'){
        this.props.onChange('price_details' ,data);
      }
      else{
        this.props.onChange(this.elementName ,data);
      }
      // this.props.onChange(this.elementName ,data);

      
    }.bind(this));
  }
}