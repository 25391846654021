import React, { useState } from "react";
import { Table } from "rsuite";

const { Pagination } = Table;

function CustomPagination({ page, getFunc }) {
  const [size, setSize] = useState(10);
  const handleChangePage = dataKey => {
    let pages = {
      pageNumber: dataKey - 1,
      pageSize: size
    };
    getFunc(pages);
  };
  const handleChangeLength = dataKey => {
    let pages = {
      pageNumber: 0,
      pageSize: dataKey
    };
    getFunc(pages);
    setSize(dataKey);
  };
  return (
    <Pagination
      lengthMenu={[
        {value: 5,label: 5},
        {value: 10,label: 10},
        {value: 20,label: 20},
        {value: 50,label: 50},
        {value: 100,label: 100}
      ]}
      activePage={parseInt(page.currentPage) + 1}
      displayLength={size}
      total={parseInt(page.total)}
      onChangePage={handleChangePage}
      onChangeLength={handleChangeLength}
    />
  );
}

export default CustomPagination;
