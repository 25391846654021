import React, { Component } from "react";
import PropTypes from "prop-types";
import TrekItem from "./TrekItem";

class TrekFeed extends Component {
  render() {
    const { treks, onEdit } = this.props;
    console.log({ treks });
    return (
      <div className="treks-list__block">
        <div className="treks-list__search"></div>
        <div
          style={{ gridTemplateColumns: "40% 15% 10% 15% 10% 10%" }}
          className="treks-list__header trek-grid"
        >
          <span className="name">Trek Name</span>
          <span className="price text-center">Price</span>
          <span className="days text-center">Days</span>
          <span className="popular text-center">Popular</span>
          <span className="edit text-center">Edit</span>
          <span className="delete text-center">Delete</span>
        </div>
        <div className="treks-list__items">
          {treks &&
            treks.map((trek) => (
              <TrekItem onEdit={onEdit} key={trek.id} trek={trek} />
            ))}
        </div>
      </div>
    );
  }
}

TrekFeed.propTypes = {
  treks: PropTypes.array.isRequired,
};

export default TrekFeed;
