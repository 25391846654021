import React, { Component } from 'react'
import CKEditor from "./CKEditor";
import { connect } from 'react-redux';
import {addDetails} from '../../../actions/trekActions'
import './details.styles.scss';
import isEmpty from '../../../validations/isEmpty';
import {Button} from 'rsuite';
import classnames from 'classnames';
import renderHTML from 'react-render-html';

export class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
          overview: '',
          trek_details: '',
          price_details: '',
          includes: '',
          itinerary: '',
          errors: {},
        };
    
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount(){
        const {state} = this.props.location;
        const {params}  = this.props.match;
        if(isEmpty(state.trek)){
            if(params.handle){
                this.props.history.push(`/treks/${params.handle}`);
                return
            }
            this.props.history.goBack();
            return;
        }else{
            const {trek} = state;
            this.setState({
                overview: trek.overview ? renderHTML(trek.overview) : '',
                price_details: trek.price_details ? renderHTML(trek.price_details) : '',
                includes: trek.includes ? renderHTML(trek.includes) : '',
                itinerary: trek.itinerary ? renderHTML(trek.itinerary) : '',
            })
        }
    }
    componentDidUpdate(prevProps,prevState){
        const {errorReducer} = this.props;
        if(!isEmpty(errorReducer) && errorReducer !== prevProps.errorReducer){
            this.setState({errors:errorReducer})
        }
    }
    onSubmit(e) {
        e.preventDefault();
        const {trek} = this.props.location.state;
        const detailsData = {
            overview: this.state.overview,
            price_details: this.state.price_details,
            includes: this.state.includes,
            itinerary: this.state.itinerary,
        };
        this.props.addDetails(trek.id, detailsData, this.props.history);
    }
    onChange(name , data) {
        this.setState({ [name]: data });
    }
    render() {
        const {errors} = this.state;
        const getDetailsClass = (name)=>{
            return classnames({
                "details-form__input" : true,
                "error-back": !isEmpty(errors[name])
            })
        }
        return (
            <div className="details">
                <div className="details-form">
                    <div className={getDetailsClass('overview')}>
                        <label htmlFor="Overview" className="details-form__label">Overview</label>
                        <CKEditor 
                            id="overview" 
                            value={this.state.overview} 
                            datas={this.state.overview} 
                            onChange={this.onChange}/>
                        {errors && errors.overview && <span className="error-message">{errors.overview}</span>}
                    </div>
                    <div className={getDetailsClass('price_details')}>
                        <label htmlFor="price_details" className="details-form__label">Price Details</label>
                        <CKEditor 
                            id="price_details" 
                            value={this.state.price_details} 
                            datas={this.state.price_details} 
                            onChange={this.onChange}/>
                        {errors && errors.price_details && <span className="error-message">{errors.price_details}</span>}
                    </div>
                    <div className={getDetailsClass('includes')}>
                        <label htmlFor="includes" className="details-form__label">Includes</label>
                        <CKEditor 
                            id="includes" 
                            value={this.state.includes} 
                            datas={this.state.includes} 
                            onChange={this.onChange}/>
                        {errors && errors.includes && <span className="error-message">{errors.includes}</span>}
                    </div>
                    <div className={getDetailsClass('itinerary')}>
                        <label htmlFor="itinerary" className="details-form__label">Itinerary</label>
                        <CKEditor 
                            id="itinerary" 
                            value={this.state.itinerary} 
                            datas={this.state.itinerary} 
                            onChange={this.onChange}/>
                        {errors && errors.itinerary && <span className="error-message">{errors.itinerary}</span>}
                    </div>
                    <div className="details-form__button">
                        <Button
                            color="blue"
                            onClick = {(e)=>this.onSubmit(e)}
                            >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    addDetails
}

const mapStateToProps = state => ({
    errorReducer : state.errorReducer
})



export default connect(mapStateToProps,mapDispatchToProps)(Details);