import axios from "axios";
import { Alert } from "rsuite";
import isEmpty from "../validations/isEmpty";
import {getError} from '../helpers/getUnauth'

import {
	GET_ROOM,
	GET_ROOMS,
	ROOM_LOADING,
	ADD_ROOM,
	DELETE_ROOM,
	EDIT_ROOM,
	EDIT_ROOM_IMAGES,
	CLEAR_ERRORS,
	GET_ERRORS,
	ROOM_LOADING_SUBMIT
} from "./types";
import {getPages} from '../helpers/getPages'

// Get room by id
export const getRoom = id => async dispatch => {
	dispatch(setLoading());
	await axios
		.get(`/api/v1/rooms/${id}`)
		.then(res =>{
			dispatch({
				type: GET_ROOM,
				payload: res.data.data
			})
		}
		)
		.catch(err => {
			getError(err)
			dispatch({
				type: GET_ROOM,
				payload: {}
			});
		});
};

// Get all rooms
export const getRooms = () => dispatch => {
	let page = getPages({pageNumber:0,pageSize:100});
	dispatch(setLoading());
	axios
		.get("/api/v1/rooms",{ params:page})
		.then(res =>
			dispatch({
				type: GET_ROOMS,
				payload: res.data.data,
			})
		)
		.catch(err =>{
			getError(err);
			dispatch({
				type: GET_ROOMS,
				payload: null
			})
		});
};

// Add ROOM
export const addRoom = newRoom => dispatch => {
	dispatch(clearErrors());
	dispatch(setLoading2(true));
	axios
		.post("/api/v1/rooms", newRoom)
		.then(res => {
			dispatch(setLoading2(false));
			if (res.data.success) {
				dispatch({
					type: ADD_ROOM,
					payload: res.data.data
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			dispatch(setLoading2(false));
			if (err) {
				getError(err);
				if (err.response) {
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data
					});
				} else {
					console.log(err);
				}
			}
		});
};

// Edit Room
export const editRoom = (roomId, newRoom) => dispatch => {
	dispatch(clearErrors());
	dispatch(setLoading2(true));
	axios
		.put(`/api/v1/rooms/${roomId}`, newRoom)
		.then(res => {
			dispatch(setLoading2(false));
			if (res.data.success) {
				dispatch({
					type: EDIT_ROOM,
					payload: res.data.data[0],
					id: roomId,
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			dispatch(setLoading2(false));
			if (err) {
				getError(err);
				if (err.response) {
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data
					});
				} else {
					console.log(err);
				}
			}
		});
};

// Upload Room
export const uploadRooms = (roomId, images ) => dispatch => {
	dispatch(clearErrors());
	dispatch(setLoading2(true));
	const config = { headers: { 'Content-Type': 'multipart/form-data' } };
	axios
		.put(`/api/v1/rooms/upload/${roomId}`, images, config)
		.then(res => {
			dispatch(setLoading2(false));
			if (res.data.success) {
				dispatch({
					type: EDIT_ROOM,
					payload: res.data.data[0],
					id: roomId,
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			dispatch(setLoading2(false));
			if (err) {
				getError(err);
				if (err.response) {
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data
					});
				} else {
					console.log(err);
				}
			}
		});
};

// Delete ROOM
export const deleteRoom = id => dispatch => {
	axios
		.delete(`/api/v1/rooms/${id}`)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: DELETE_ROOM,
					payload: id
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			getError(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};

export const setLoading = () => {
	return {
		type: ROOM_LOADING
	};
};

export const setLoading2 = (payload) => {
	return {
		type: ROOM_LOADING_SUBMIT,
		payload
	};
};

// Clear errors
export const clearErrors = () => {
	return {
		type: CLEAR_ERRORS
	};
};
