import axios from "axios";

import {
  GET_TEAM,
  GET_TEAMS,
  TEAM_LOADING,
  ADD_TEAM,
  EDIT_TEAM,
  DELETE_TEAM,
  EDIT_TEAM_UPLOAD,
  GET_ERRORS,
  ADD_TEAM_LOAD,
  CLEAR_ERRORS,
  
} from "./types";
import { Alert } from "rsuite";
import isEmpty from "../validations/isEmpty";
import {getError} from '../helpers/getUnauth'

// Get current team
export const geTeam = id => dispatch => {
    dispatch(setTeamLoading());
    axios
      .get(`/api/v1/teams/${id}`)
      .then(res =>
        dispatch({
          type: GET_TEAM,
          payload: res.data
        })
      )
      .catch(err =>{
        getError(err);
        dispatch({
          type: GET_TEAM,
          payload: {}
        })
      }
        
      );
};

// Add Team
export const addTeam = newTeam => dispatch => {
    dispatch(clearErrors());
    dispatch(setLoading(true));
    axios
      .post("/api/v1/teams", newTeam)
      .then(res => {
        dispatch(setLoading(false));
        if (res.data.success) {
          dispatch({
            type: ADD_TEAM,
            payload: res.data.data
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.success(res.data.error, 5000);
      })
      .catch(err => {
        dispatch(setLoading(false));
        getError(err);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          });
          return false;
        }
      });
  };

  // Edit adventure
export const editTeam = (teamId, newTeam) => dispatch => {
    dispatch(clearErrors());
    dispatch(setLoading(true));
    axios
      .put(`/api/v1/teams/${teamId}`, newTeam)
      .then(res => {
        dispatch(setLoading(false));
        if (res.data.success) {
          dispatch({
            type: EDIT_TEAM,
            payload: res.data.data[0],
            id: teamId
          });
          Alert.success(res.data.message, 5000);
          return;
        }
        Alert.error(res.data.error, 5000);
      })
      .catch(err => {
        dispatch(setLoading(false));
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          });
          return false;
        }
        getError(err);
      });
};

// Add team upload
export const addFiles = (teamID, uploadData) => dispatch => {
    dispatch(setLoading(true));
    axios
      .put(`/api/v1/teams/upload/${teamID}`, uploadData)
      // .then(res => history.push('/dashboard'))
      .then(res =>{
            dispatch(setLoading(false));
            dispatch({
                type: EDIT_TEAM_UPLOAD,
                payload: res.data.data
            })
        }
      )
      .catch(err => {
        dispatch(setLoading(false));
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          });
          return false;
        }
        getError(err);
      });
  };
  
  // Get all Teams
  export const getTeams = () => dispatch => {
    dispatch(setTeamLoading());
    axios
      .get("/api/v1/teams/")
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: GET_TEAMS,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: GET_TEAMS,
            payload: null
          });
          Alert.error(res.data.error, 5000);
        }
      })
      .catch(err => {
        getError(err);
        dispatch({
          type: GET_TEAMS,
          payload: null
        });
      });
  };
  
  // Delete Post
  export const deleteTeam = id => dispatch => {
    axios
      .delete(`/api/v1/teams/${id}`)
      .then(res => {
        if(res.data){
            dispatch({
                type: DELETE_TEAM,
                payload: id
            });
            Alert.success(res.data.message, 5000);
        }
        
      })
      .catch(err => {
        getError(err);
        if (err.response && err.response.data.error) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        }
      });
  };

// adventure loading
export const setTeamLoading = () => {
    return {
      type: TEAM_LOADING
    };
  };
export const setLoading = (load) => {
    return {
        type: ADD_TEAM_LOAD,
        payload:load
    };
};
  
  // Clear errors
  export const clearErrors = () => {
    return {
      type: CLEAR_ERRORS
    };
  };