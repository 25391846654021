import React, { Component } from 'react'
import { editAccess } from "../../actions/userActions";
import isEmpty from '../../validations/isEmpty';
import {Icon,Button} from 'rsuite';
import { connect } from "react-redux";

export class Access extends Component {
    constructor(props) {
		super(props);

		this.state = {
            user: {},
            access:{}
        };
        this._renderItem=this._renderItem.bind(this);
        this.onAccessChange=this.onAccessChange.bind(this);
        this.onAccessSave=this.onAccessSave.bind(this);
        this.onAllAccessChange=this.onAllAccessChange.bind(this);
    }
    async componentDidMount() {
        const { user } = this.props;
		if (!isEmpty(user)) {
			this.setState({ user,access:user.access });
		}
	}
	componentDidUpdate(prevProps, prevState) {
		const { user } = this.props;
		if (!isEmpty(user) && user !== prevProps.user) {
			this.setState({ user,access:user.access });
		}
    }
    onAccessChange(accessOf,accessItems,accessName){
        const {access} =this.state;
        this.setState(prevState=>({
            ...prevState,
            access:{
                ...prevState.access,
                [accessOf]:{
                    ...prevState.access[accessOf],
                    [accessName]:!prevState.access[accessOf][accessName]
                }
            }
        })
        )
    }
    onAccessSave(){
        const {access,user:{id,role}} = this.state;
        this.props.editAccess(id,access,role)
    }
    onAllAccessChange(item,val=true){
        const {access} =this.state;
        let a = {}
        const vals = Object.keys(access[item]);
        vals.map(s=>a[s]=val)
        // const vals = Object.keys(access[item])
        
        
        this.setState(prevState=>({
            ...prevState,
            access:{
                ...prevState.access,
                [item]:a
            }
        }),
        ()=>console.log(this.state.access)
        )
    }
    _renderItem(access){
        const { user,access:stateAccess } = this.state;
        let value = !isEmpty(stateAccess)? stateAccess[access]:[];
        
        return(
            <div className="access-body__item">
                <div className="access-content">
                    <div className="access-content-heading">
                        <h3><span>{access}</span></h3>
                    </div>
                    {
                        value && Object.keys(value).map(item=>{
                            // console.log(value[item],item)
                            return (
                                <button onClick={()=>this.onAccessChange(access,value,item)} className="access-content-value">
                                    <span>{ 
                                            value[item]?
                                                <Icon icon="check" /> :
                                                <Icon icon="close" />
                                        }</span>
                                </button>
                            )
                        })
                    }
                    <button className="access-content-value all">
                        <span  onClick={()=>this.onAllAccessChange(access,true)}>
                            <Icon icon="check" />
                        </span>
                        <span  onClick={()=>this.onAllAccessChange(access,false)}>
                            <Icon icon="close" />
                        </span>
                    </button>
                </div>
            </div>
        )
    }
    render() {
        const { user } = this.state;
        const access = !isEmpty(user) ?Object.keys(user.access):[];
        const access_point = ['own','read','write','update','delete']
        return (
            <div className="access">
                <div className="access-headers">
                    <div className="access-header">
                        <span>Name</span>
                    </div>
                    {
                        !isEmpty(access) ? Object.keys(user.access['auth']).map(item=>{
                            
                            return(
                                <div className="access-header">
                                    <span>{item}</span>
                                </div>
                            )
                        })
                        :null
                    }
                    <div className="access-header all">
                        <span>all</span>
                    </div>
                </div>
                <div className="access-body">
                    {access && access.map(this._renderItem)}
                </div>
                <div className="access-button">
                    <Button 
                        block
                        onClick={()=>this.onAccessSave()}
                        appearance="primary">Save Access</Button>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    editAccess
};

const mapStatToProps = state => ({
	user: state.userReducer.user,
	loading: state.userReducer.loading,
});

export default connect(mapStatToProps, mapDispatchToProps)(Access);