import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    getCustomersToday,
    getCustomersWeek,
    getCustomersMonth,
    getCustomersSearch,
    getCustomers
} from "../../actions/customerActions";
import {getUsers} from '../../actions/userActions'
import CustomersTable from "./CustomersTable";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    Button,
    Icon,
    ButtonToolbar,
    SelectPicker,
    Input,
    DateRangePicker
} from "rsuite";

class Customers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: "All",
            email: "",
            status: "",
            name: "",
            addedBy: "",
            editedBy: "",
            dateRange: ""
        };
        this.renderTimeTab = this.renderTimeTab.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.renderFilters = this.renderFilters.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onClear = this.onClear.bind(this);
    }
    componentDidMount() {
        this.props.getUsers();
    }
    
    changeTime(type) {
        this.setState({ type });
        if (type === "Today" && this.state.type !== "Today") {
            this.props.getCustomersToday();
        } else if (type === "Week" && this.state.type !== "Week") {
            this.props.getCustomersWeek();
        } else if (type === "Month" && this.state.type !== "Month") {
            this.props.getCustomersMonth();
        } else if (this.state.type !== "All") {
            this.props.getCustomers();
        }
    }
    renderTimeTab() {
        const { type } = this.state;
        const time = ["All", "Today", "Week", "Month"];
        return (
            <ul className="time-tab-list">
                {time.map((item, key) => {
                    return (
                        <li
                            className={
                                type === item
                                    ? "time-tab-list__item active-tab"
                                    : "time-tab-list__item"
                            }
                            key={key}
                            onClick={() => this.changeTime(item)}
                        >
                            <span>{item}</span>
                        </li>
                    );
                })}
            </ul>
        );
    }
    handleChange(value, e,name) {
        if(name ==='daterange'){
            this.setState({dateRange:value},()=>console.log(this.state))
        }else{
            this.setState({ [e.target.name]: value });
        }
    }
    handleSelect(value, valueObj, e) {
        this.setState({ [valueObj.name]: valueObj.value });
    }
    handleSubmit() {
        const {
            name,
            email,
            status,
            dateRange,
            editedBy,
            addedBy
        } = this.state;
        let fromDate = dateRange[0];
        let toDate = dateRange[1];

        const search = {
            name,
            email,
            status,
            fromDate,
            toDate,
            editedBy,
            addedBy
        };
        this.props.getCustomersSearch(search);
    }
    onClear() {
        this.setState({
            email: "",
            status: "",
            name: "",
            addedBy: "",
            editedBy: "",
            dateRange: ""
        });
        const search = {
            name: "",
            email: "",
            status: "",
            fromDate: "",
            toDate: "",
            editedBy: "",
            addedBy: ""
        };
        this.props.getCustomersSearch(search);
    }
    renderFilters() {
        const data = [
            { label: "UnPaid", value: "unpaid", name: "status" },
            { label: "Paid", value: "paid", name: "status" }
        ];
        const {users,loading}=this.props.userReducer;
        const { name, email, status, dateRange,addedBy,editedBy } = this.state;

        const Arrays = (items,name)=>{
            let arrayed=[];
            arrayed = !!items && items.map(item=>{
                return {label:item.name,value:item.name,name}
            })
            return arrayed;
        }
        const Arrays2 = (items,name)=>{
            let arrayed=[];
            arrayed = !!items && items.map(item=>{
                return {label:item,value:item,name}
            })
            return arrayed;
        }
        const {settings} = this.props;
        return (
            <Form>
                <div className="filters">
                    <FormGroup className={"filters-item"}>
                        <ControlLabel>Customer Name</ControlLabel>
                        <FormControl
                            name="name"
                            type="text"
                            value={name}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup className={"filters-item"}>
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                            name="email"
                            type="text"
                            value={email}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup className={"filters-item"}>
                        <ControlLabel>Date Range</ControlLabel>
                        <DateRangePicker
                            appearance="default"
                            placeholder="Daterange"
                            onChange={(val, e) =>
                                this.handleChange(val, e, "daterange")
                            }
                            style={{ width: 225 }}
                        />
                    </FormGroup>
                    {
                        settings && settings.status && (
                        <FormGroup className={"filters-item"}>
                            <ControlLabel>Status</ControlLabel>
                            <SelectPicker
                                data={Arrays2(settings.status,"status")}
                                name="status"
                                style={{ width: 225 }}
                                value={status}
                                searchable={false}
                                onSelect={this.handleSelect}
                                onClean={() => this.setState({ status: "" })}
                            />
                        </FormGroup>
                        )
                    }
                    
                    <FormGroup className={"filters-item"}>
                    {
                        loading?<h2>Loading...</h2>:
                        <>
                            <ControlLabel>Added By</ControlLabel>
                            <SelectPicker
                                data={Arrays(users,'addedBy')}
                                name="addedBy"
                                style={{ width: 225 }}
                                value={addedBy}
                                searchable={true}
                                onSelect={this.handleSelect}
                                onClean={() => this.setState({ addedBy: "" })}
                            />
                        </>
                    }
                        
                    </FormGroup>
                    <FormGroup className={"filters-item"}>
                    {
                        loading?<h2>Loading...</h2>:
                        <>
                            <ControlLabel>Edited By</ControlLabel>
                            <SelectPicker
                                data={Arrays(users,'editedBy')}
                                name="editedBy"
                                style={{ width: 225 }}
                                value={editedBy}
                                searchable={true}
                                onSelect={this.handleSelect}
                                onClean={() => this.setState({ editedBy: "" })}
                            />
                        </>
                    }
                        
                    </FormGroup>

                    <FormGroup className={"filters-item"}>
                        <ButtonToolbar>
                            <Button
                                appearance="primary"
                                disabled={false}
                                onClick={this.handleSubmit}
                            >
                                {/* {false ? <Loader /> : null} */}
                                Search
                            </Button>
                        </ButtonToolbar>
                    </FormGroup>
                    <FormGroup className={"filters-item"} style={{marginBottom:'24px'}}>
                        <ButtonToolbar>
                            <Button
                                appearance="default"
                                disabled={false}
                                onClick={this.onClear}
                            >
                                {/* {false ? <Loader /> : null} */}
                                Clear
                            </Button>
                        </ButtonToolbar>
                    </FormGroup>
                </div>
            </Form>
        );
    }
    render() {
        const { type } = this.state;
        return (
            <div className="users">
                <div className="filters-block">{this.renderFilters()}</div>
                <div className="time-tab">{this.renderTimeTab()}</div>
                <CustomersTable />
            </div>
        );
    }
}

Customers.propTypes = {
    getCustomersToday: PropTypes.func.isRequired,
    getCustomersWeek: PropTypes.func.isRequired,
    getCustomersMonth: PropTypes.func.isRequired,
    getCustomers: PropTypes.func.isRequired,
    getUsers:PropTypes.func.isRequired,
    getCustomersSearch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    userReducer:PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    customerReducer: state.customerReducer,
    userReducer: state.userReducer,
    auth: state.auth,
    settings:state.settingReducer.settings,
    errorReducer: state.errorReducer
});

export default connect(mapStateToProps, {
    getCustomersToday,
    getCustomersWeek,
    getCustomersMonth,
    getCustomersSearch,
    getCustomers,
    getUsers
})(Customers);
