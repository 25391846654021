import React, {useEffect} from 'react'
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    Toggle,
    ButtonToolbar,
    Button,
    Loader,
    RadioGroup,
    SelectPicker,
    Alert,
    Radio,
    Uploader,
    Icon
} from "rsuite";
import { updateAlpSettings, } from '../../../actions/settingActions';
import useFormValidation from "../../../validations/formValidations";
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from '../../../validations/isEmpty'

const INITIAL_STATE = {
    images: [],
    email: "",
    phone: "",
    address: "",
    copyright: "",
    hash_tag: "",
    lat: "",
    long: "",
    facebook: "",
    instagram: "",
    youtube: "",
    website: "",
    title: "",
    subtitle: "",
};

export const validateSettings = (data)=>{
	let errors ={};
    if(isEmpty(data.email)){
		errors.email = 'Email Field is required';
	}
	if(isEmpty(data.phone)){
		errors.phone = 'Phone Field is required';
	}
	if(isEmpty(data.address)){
		errors.address = 'Address Field is required';
	}
	if(isEmpty(data.copyright)){
		errors.copyright = 'Copyright Field is required';
	}
	if(isEmpty(data.hash_tag)){
		errors.hash_tag = 'Hash Tag Field is required';
	}
	if(isEmpty(data.lat)){
		errors.lat = 'Lat Field is required';
	}
    if(isEmpty(data.long)){
		errors.long = 'Long Field is required';
	}
	return errors
}

function SettingsForm(props) {
    const {alpSettings,onEdit} = props;
    const {
        handleSubmit,
        handleChange2,
        handleDateChange,
        handleChange3,
        handleBlur,
        checkErrors,
        values,
        setInitial,
        handleSelect,
        errors,
        isSubmitting,
        clearValues
    } = useFormValidation(INITIAL_STATE, validateSettings);
    const [errs, setErrs] = React.useState({});
    const errorReducer = useSelector(state=>state.errorReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);
    useEffect(()=>{
        if(!isEmpty(alpSettings)){
            setInitial(alpSettings)
        }
    },[alpSettings]);
    const handleSubmits = async (e) => {
        
        e.preventDefault();
        const {isValid} =  checkErrors();
        if(isValid){
            await dispatch(updateAlpSettings(values.id,values));
        }
    }
    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const handleEsc = (e,cancel='') => {
        if(e.key === 'Escape' || cancel==='cancel'){
            clearValues();
            onEdit({})
        }
    }
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };
    return (
        <div>
            <Form>
                <FormGroup className={getError("email")}>
                    <ControlLabel>email</ControlLabel>
                    <FormControl
                        name="email"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.email}
                        errorMessage={getErrorMessage('email')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("phone")}>
                    <ControlLabel>phone</ControlLabel>
                    <FormControl
                        name="phone"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.phone}
                        errorMessage={getErrorMessage('phone')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("address")}>
                    <ControlLabel>address</ControlLabel>
                    <FormControl
                        name="address"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.address}
                        errorMessage={getErrorMessage('address')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("copyright")}>
                    <ControlLabel>copyright</ControlLabel>
                    <FormControl
                        name="copyright"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.copyright}
                        errorMessage={getErrorMessage('copyright')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("hash_tag")}>
                    <ControlLabel>hash_tag</ControlLabel>
                    <FormControl
                        name="hash_tag"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.hash_tag}
                        errorMessage={getErrorMessage('hash_tag')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("facebook")}>
                    <ControlLabel>Facebook</ControlLabel>
                    <FormControl
                        name="facebook"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.facebook}
                        errorMessage={getErrorMessage('facebook')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("instagram")}>
                    <ControlLabel>Instagram</ControlLabel>
                    <FormControl
                        name="instagram"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.instagram}
                        errorMessage={getErrorMessage('instagram')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("trip")}>
                    <ControlLabel>Trip</ControlLabel>
                    <FormControl
                        name="trip"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.trip}
                        errorMessage={getErrorMessage('trip')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("youtube")}>
                    <ControlLabel>Youtube</ControlLabel>
                    <FormControl
                        name="youtube"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.youtube}
                        errorMessage={getErrorMessage('youtube')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("website")}>
                    <ControlLabel>Website</ControlLabel>
                    <FormControl
                        name="website"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.website}
                        errorMessage={getErrorMessage('website')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("title")}>
                    <ControlLabel>Title</ControlLabel>
                    <FormControl
                        name="title"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.title}
                        errorMessage={getErrorMessage('title')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("subtitle")}>
                    <ControlLabel>Subtitle</ControlLabel>
                    <FormControl
                        name="subtitle"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.subtitle}
                        errorMessage={getErrorMessage('subtitle')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("lat")}>
                    <ControlLabel>lat</ControlLabel>
                    <FormControl
                        name="lat"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.lat}
                        errorMessage={getErrorMessage('lat')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("long")}>
                    <ControlLabel>long</ControlLabel>
                    <FormControl
                        name="long"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.long}
                        errorMessage={getErrorMessage('long')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup>
                    <ButtonToolbar>
                        <Button
                            appearance="primary"
                            disabled={false}
                            onClick={(e)=>handleSubmits(e)}
                        >
                            {false ? <Loader /> : null}
                            {"Update Settings"}
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    )
}

export default SettingsForm