import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {getCustomer} from '../../actions/customerActions'
import { Loader } from 'rsuite';

class Scan extends Component {
    componentDidMount() {
        const {scanData,isScan}=this.props.scanReducer.scan;
        this.props.getCustomer(scanData)
    }
    
    render() {
        const {loading,customer}=this.props.customerReducer;
        console.log(customer)
        if(loading){
            return ( <Loader inverse center content="loading..." />)
        }
        return <div> {customer.name} </div>;
    }
}


Scan.propTypes = {
    getCustomer: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    scanReducer: PropTypes.object.isRequired,
    customerReducer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    customerReducer:state.customerReducer,
    scanReducer:state.scanReducer
});

export default connect(mapStateToProps,{getCustomer})(Scan);
