import {
    GET_ACTIVITIES,
    ACTIVITY_LOADING,
    DELETE_ACTIVITY,
} from "../actions/types";

const initialState = {
	activities: [],
	page: {},
	loading: false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case ACTIVITY_LOADING:
			return {
				...state,
				loading: true
			};
		case GET_ACTIVITIES:
			return {
				...state,
				activities: action.payload,
				page:action.page,
				loading: false
			};
		case DELETE_ACTIVITY:
			return {
				...state,
				activities: state.activities.filter(
					act => act.id !== action.payload
				)
            };
        // case EDIT_REVIEW:
        //     return {
        //         ...state,
        //         reviews: state.reviews.map(review =>
        //             review.id === action.id
        //             ? // transform the one with a matching id
        //             action.payload
        //             : // otherwise return original todo
        //             review
        //         )
        //     };
		default:
			return state;
	}
}
