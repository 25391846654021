import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import renderHTML from "react-render-html";
function TinyEmc(props) {
  const { name, value, onChange, disabled } = props;
  const editorRef = useRef(null);
  return (
    <Editor
      onEditorChange={(val) => onChange(name, val)}
      value={value}
      onInit={(evt, editor) => {
        editorRef.current = editor;
      }}
      initialValue={renderHTML(value)}
      init={{
        plugins:
          "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
        editimage_cors_hosts: ["picsum.photos"],
        menubar: "file edit view insert format tools table help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
        toolbar_sticky: true,
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        convert_urls: true,
        relative_urls: false,
        remove_script_host: false,
        autosave_retention: "2m",
        images_upload_url: "/api/v1/settings/upload/images/",
        image_advtab: true,
        document_base_url: "https://server.alpwander.com/",
        images_upload_handler: example_image_upload_handler,
        images_upload_base_path: "https://server.alpwander.com/",
        link_list: [
          { title: "Alpwander adventures", value: "https://www.alpwander.com" },
          { title: "Flock Hostels", value: "http://www.flockhostels.com" },
        ],
        image_list: [
          { title: "Alpwander Logo", value: "https://server.alpwander.com/upload/logo-1679230810214.webp" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        file_picker_callback: (callback, value, meta) => {
          /* Provide file and text for the link dialog */
          if (meta.filetype === "file") {
            callback("https://www.google.com/logos/google.jpg", {
              text: "My text",
            });
          }

          /* Provide image and alt text for the image dialog */
          if (meta.filetype === "image") {
            callback("https://www.google.com/logos/google.jpg", {
              alt: "My alt text",
            });
          }

          /* Provide alternative source and posted for the media dialog */
          if (meta.filetype === "media") {
            callback("movie.mp4", {
              source2: "alt.ogg",
              poster: "https://www.google.com/logos/google.jpg",
            });
          }
        },
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
          {
            title: "Add Padding Box",
            description: "padding box",
            content:
              '<div style="padding:6px 12px;background-color:blue;border-radius:1px;display:inline-block;">Text Here</div>',
          },
          {
            title: "Add FAQS",
            description: "FAQS",
            content:
              '<div class="faqs"><div class="faqs-question">Questions</div><div class="faqs-answer">Answers</div></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 600,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        contextmenu: "link image table",
        skin: "oxide-dark",
        content_css: "dark",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
      }}
    />
  );
}

export default TinyEmc;

const example_image_upload_handler = (blobInfo, progress) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", "/api/v1/settings/upload/images/");

    xhr.upload.onprogress = (e) => {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = () => {
      if (xhr.status === 403) {
        reject({ message: "HTTP Error: " + xhr.status, remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        reject("HTTP Error: " + xhr.status);
        return;
      }

      const json = JSON.parse(xhr.responseText);
      if (!json || typeof json.image != "string") {
        reject("Invalid JSON: " + xhr.responseText);
        return;
      }

      resolve(json.image);
    };

    xhr.onerror = () => {
      reject(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    const formData = new FormData();
    formData.append("upload", blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
  });
