export default function validateAuth(values) {
    let errors = {};
    if (!values.name) {
      errors.name = "Name Field is Required";
    }
    // Email Errors
    if (!values.email) {
      errors.email = "Email Field is Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    // Password Errors
    if (!values.password) {
      errors.password = "Password Field is Required";
    } else if (values.password.length < 5) {
      errors.password = "Password must be at least 5 characters";
    }
    if (!values.password2) {
      errors.password2 = "Confirm Password Field is Required";
    }
    else if (values.password2 !== values.password) {
      errors.password2 = "Confirm Password Field must match Password";
    }
    if (values.phone.length < 10) {
      errors.phone = "Phone Field must be at least 10 character long";
    }
    if(typeof values.phone === 'number'){
      errors.phone = "Phone Field must be a number";
    }
    if (!values.user_name) {
      errors.user_name = "User Name Field is Required";
    }
    return errors;
  }
  