import { GET_ERRORS, SET_CURRENT_USER, LOGIN_LOADING} from './types';
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import {getError} from '../helpers/getUnauth'

// //Register User 
// export const registerUser =(userData,history) => dispatch =>{
//     axios
//     .post('/api/v1/users/register', userData)
//     .then(res => history.push('/admin/login'))
//     .catch(err => 
//         dispatch({
//             type: GET_ERRORS,
//             payload: err.response.data 

//         })
//         );
// };

//Login Get User Token
export const loginUser =(userData)=>dispatch=>{
    dispatch(setLoading(true));
    axios.post('/api/v1/users/login',userData)
    .then(res => {
        dispatch(setLoading(false));
        //Save to local Storage 
        const { token } = res.data;
        //Set token to ls
        localStorage.setItem('jwtToken', token);
        //set token to Auth header 
        setAuthToken(token);
        //Decode token to get user data
        const decode = jwt_decode(token);
        //Set Current user 
        dispatch(setCurrentUser(decode));
    })
    .catch(err =>{
        dispatch(setLoading(false));
        console.log(err)
        getError(err)
        // dispatch({
        //     type: GET_ERRORS,
        //     payload: err.response.data.error

        // });
    })
};

//Set logged in user 
export const setCurrentUser = (decode) =>{
    return{
        type: SET_CURRENT_USER,
        payload :decode
    }
}


//Log user out 
export const logoutUser = () => dispatch =>{ 
    // Remove the token from local storage
    localStorage.removeItem('jwtToken');
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which wil set isAuthen to false
    dispatch(setCurrentUser({}));
}


// User loading
export const setLoading = (load) => {
    return {
      type: LOGIN_LOADING,
      payload:load
    };
  };