import React, { Component } from "react";
import { connect } from "react-redux";
import { resetForgotPassword } from "../../actions/userActions";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import { Link } from "react-router-dom";

export class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			password: "",
			token: ""
		};
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}
	componentDidMount() {
		const { token } = this.props.match.params;
		this.setState({ token });
	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	onSubmit(e, dispatch) {
        e.preventDefault();
        const {token,password} = this.state;
        this.props.resetForgotPassword({token,password})
	}
	render() {
		return (
			<section className="forgot-password">
				<div className="forgot-container">
					<div className="forgot-title">
						<Link to="/login" className="btn-text">
							<span>Flock</span>
						</Link>
					</div>
					<p>Enter the new Password</p>
					<form
						className="forgot-form"
						onSubmit={this.onSubmit}
					>
						<TextFieldGroup
							type="email"
							onChange={this.onChange}
							value={this.state.token}
							placeholder="Token..."
							disabled
							className="forgot-email"
						/>

						<TextFieldGroup
							type="password"
							name="password"
							onChange={this.onChange}
							value={this.state.password}
							placeholder="New Password"
							className="forgot-email"
						/>
						<input
							type="submit"
							value="Reset Password"
							className="forgot-btn"
						/>
					</form>
				</div>
			</section>
		);
	}
}

export default connect(null, { resetForgotPassword })(ResetPassword);
