export default function validatePassword(values) {
    let errors = {};
    // Password Errors
    if (!values.password) {
      errors.password = "Password Field is Required";
    } else if (values.password.length < 5) {
      errors.password = "Password must be at least 5 characters";
    }
    if (!values.password2) {
      errors.password2 = "Confirm Password Field is Required";
    }
    else if (values.password2 !== values.password) {
      errors.password2 = "Confirm Password Field must match Password";
    }
    if (!values.old_password) {
        errors.old_password = "Old Password Field is Required";
      }
    return errors;
  }
  