import {
	GET_ROOM,
	GET_ROOMS,
	ROOM_LOADING,
	ADD_ROOM,
	DELETE_ROOM,
	EDIT_ROOM,
	EDIT_ROOM_IMAGES,
	ROOM_LOADING_SUBMIT
} from "../actions/types";

const initialState = {
	rooms: [],
	room: {},
	loading: false,
	loading2: false
};
export default function(state = initialState, action) {
	switch (action.type) {
		case ROOM_LOADING:
			return {
				...state,
				loading: true
			};
		case ROOM_LOADING_SUBMIT:
				return {
					...state,
					loading2: action.payload
				};
		case GET_ROOM:
			return {
				...state,
				room: action.payload,
				loading: false
			};
		case GET_ROOMS:
			return {
				...state,
				rooms: action.payload,
				loading: false
			};
		case ADD_ROOM:
			return {
				...state,
				rooms: [action.payload, ...state.rooms]
			};
		case EDIT_ROOM:
			return {
				...state,
				rooms: state.rooms.map(room =>
					room.id === action.id ? action.payload : room
				),
				room:action.payload
			};
		case EDIT_ROOM_IMAGES:
			return{
				...state,
				room:{...state.room,images:action.payload}
			}
		case DELETE_ROOM:
			return {
				...state,
				rooms: state.rooms.filter(
					room => room.id !== action.payload
				)
			};
		default:
			return state;
	}
}
