import React, { useEffect } from 'react'
import { Button, Icon, ButtonToolbar } from 'rsuite';
import { Form, FormGroup, FormControl, ControlLabel, HelpBlock,Input } from 'rsuite';
import {changePassword} from '../../actions/userActions'
import useFormValidation from "../../validations/formValidations";
import validatePassword from "../../validations/validatePassword";
import {useDispatch,useSelector} from 'react-redux'
import isEmpty from '../../validations/isEmpty';
import { Loader } from 'rsuite';

const INITIAL_STATE = {
    old_password: "",
    password: "",
    password2:"",
  };

function Change({user}) {
    const {
        handleSubmit,
        handleChange2,
        handleBlur,
        values,
        clearValues,
        errors,
        checkErrors,
        isSubmitting
      } = useFormValidation(INITIAL_STATE, validatePassword);
      const [errs, setErrs] = React.useState({});
      const dispatch = useDispatch();
      const errorReducer = useSelector(state=>state.errorReducer);

    //   if(isSubmitting){
    //     if(isEmpty(errors)){
    //         dispatch(changePassword(user.id,values));
    //     }
    //   }
      const handleSubmits = (e) => {
            e.preventDefault();
            const {isValid} =  checkErrors();
            if(isValid){
                if(isEmpty(errors)){
                    dispatch(changePassword(user.id,values));
                    clearValues();
                }
            }
        }
      useEffect(() => {
        if(errorReducer){
            console.log(errorReducer)
            if(!isEmpty(errorReducer.error)){
              setErrs(errorReducer.error);
            }
        }
      }, [errorReducer]);

      const getError = (type)=>{
          let errClass = !isEmpty(errors[type]) && !isEmpty(errs[type]) ? 'has-error' : ''
          return errClass
      }
      const getErrorMessage=(type)=>{
          if(!isEmpty(errors[type])){
              return errors[type]
          }else if (!isEmpty(errs[type])){
              return errs[type]
          }
          return ''
      }
    return (
        <div className="change">
            <Form>
                <FormGroup className={getError('old_password')}>
                    <ControlLabel>Old Password</ControlLabel>
                    <FormControl 
                        name="old_password" 
                        type="password" 
                        value={values.old_password} 
                        errorMessage={getErrorMessage('old_password')} 
                        onChange={handleChange2} />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError('password')}>
                    <ControlLabel>Password</ControlLabel>
                    <FormControl 
                        name="password" 
                        type="password" 
                        value={values.password} 
                        errorMessage={getErrorMessage('password')} 
                        onChange={handleChange2} />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError('password2')}>
                    <ControlLabel>Password</ControlLabel>
                    <FormControl 
                        name="password2" 
                        type="password" 
                        value={values.password2} 
                        errorMessage={getErrorMessage('password2')} 
                        onChange={handleChange2} />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup>
                    <ButtonToolbar>
                        <Button appearance="primary" disabled={isSubmitting} onClick={(e)=>handleSubmits(e)} >
                            {isSubmitting?<Loader />:null}
                            Submit
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    )
}

export default Change
