import React from 'react'
import {  Switch  } from "react-router-dom";
import PrivateRoute from "../components/common/PrivateRoute";
import Adventures from '../screens/adventures';
import Adventure from '../screens/adventure';

function AuthLinks() {
    return (
        <>
            
                <PrivateRoute exact path="/adventures" component={Adventures} />
            
            
                <PrivateRoute exact path="/adventures/:handle" component={Adventure} />
            
        </>
    )
}

export default AuthLinks
