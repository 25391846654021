import React, {Component, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Input
} from "rsuite";
import { Loader } from "rsuite";
import { Button, Icon, ButtonToolbar } from "rsuite";
import { CheckPicker } from 'rsuite';
import { connect } from "react-redux";
import {getRooms,addRoom} from '../../actions/roomActions';
import isEmpty from '../../validations/isEmpty'

function RoomForm({
  editRoom,
  addRoom,
  roomReducer,
  onEditComplete,
  settings,
  editState
}) {
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [heading, setHeading] = useState("");
  const [icons, setIcons] = useState([]);
  const [errors,setErrors]=useState({});

  const getError = type => {
    // let errClass =
    //     !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
    return "";
  };
  useEffect(() => {
    if (editState.room) {
      setType(editState.room.type);
      setDescription(editState.room.description);
      setHeading(editState.room.heading);
      setIcons(editState.room.icons);
    } else {
      setType("");
    }
  }, [editState.room]);
  const onEdit = () => {
    editRoom(editState.room.id, { type,heading,description,icons });
    onCancel();
  };
  const onCancel = () =>{
    onEditComplete();
    setType("");
    setDescription("");
    setHeading("");
    setIcons([]);
  }
  useEffect(() => {
    window.addEventListener('keydown',(e)=>{
      if(e.key === 'Escape'){
        if(editState.isEdit){
          onCancel();
        }
      }
    })
  }, [editState.isEdit])
  const onAdd = () => {
    // 
    const {error,isValid}= onError();
    if(!isValid){
      setErrors(error)
      return false;
    }
    const data ={
      type,
      heading,
      description,
      icons
    }
    addRoom(data);
    setType("");
    setDescription("");
    setHeading("");
    setIcons([]);
  };
  const onError = () =>{
    const error = {};
    if(isEmpty(type)){
      error.type = 'Type is required'
    }
    if(isEmpty(description)){
      error.description = 'Description is required'
    }
    if(isEmpty(heading)){
      error.heading = 'Heading is required'
    }
    return {error,isValid:isEmpty(error)}
  }
  return (
    <div className="status-form">
      {/* <div className="status-form-title">
        <h3>Add Room Type</h3>
      </div> */}
      <Form>
        <FormGroup className={getError("type")}>
          <ControlLabel>Type</ControlLabel>
          <FormControl
            name="type"
            type="text"
            // onBlur={e => handleBlur(e)}
            value={type}
            errorMessage={errors.type}
            onChange={value => setType(value)}
          />
          {/* <HelpBlock tooltip>Required</HelpBlock> */}
        </FormGroup>
        <FormGroup className={getError("heading")}>
          <ControlLabel>Heading</ControlLabel>
          <FormControl
            name="heading"
            type="text"
            // onBlur={e => handleBlur(e)}
            value={heading}
            errorMessage={errors.heading}
            onChange={value => setHeading(value)}
          />
          {/* <HelpBlock tooltip>Required</HelpBlock> */}
        </FormGroup>
        <FormGroup className={getError("description")}>
          <ControlLabel>Description</ControlLabel>
          <FormControl
            rows={5}
            name="description"
            errorMessage={errors.description}
            value={description}
            onChange={value => setDescription(value)}
            componentClass="textarea"
          />
        </FormGroup>
        {
          settings && settings.roomIcons &&(
            <FormGroup className={getError("roomIcon")}>
              <ControlLabel>Room Icon</ControlLabel>
              <CheckMark 
                  onChange={(value)=>setIcons(value)}
                  value={icons}
                  icons={settings.roomIcons} />
            </FormGroup>
          )
        }
        <FormGroup>
          <ButtonToolbar>
            <Button
              appearance="primary"
              disabled={roomReducer.loading2}
              onClick={() => (editState.isEdit ? onEdit() : onAdd())}
            >
              {roomReducer.loading2 ? <Loader /> : null}
              {editState.isEdit ? "Update" : "Submit"}
            </Button>
            {
              editState.isEdit ?
              (
                <Button
                  appearance="secondary"
                  onClick={() => onCancel()}
                >
                  Cancel
                </Button>
              )
              :null
            }
          </ButtonToolbar>
        </FormGroup>
      </Form>
    </div>
  );
}

export class CheckMark extends Component {
  render() {
    const datas = this.props.icons.map(icon=>{
      // let icony = JSON.parse(icon)
      let icony = icon
      return {label:icony.name,value:icony.id,icon:icony.icon}
    })
    // console.log(datas)
    return (
      <CheckPicker
        data={datas}
        // groupBy="role"
        placeholder="Select User"
        // block
        style={{ width: 250 }}
        value={this.props.value.map(item=>item.value)}
        renderMenuItem={(label, item) => {
          // console.log(label,item)
          return (
            <div className="room-icon">
              <img src={item.icon} alt="Room Icon"/> 
              <span>{label}</span>
            </div>
          );
        }}
        onChange={(value)=>{
          var item=datas;
          let val = value.map(a=>item.filter(b=>b.value===a)[0]);
          this.props.onChange(val)
        }}
        // onChange={(items)=>this.props.onChange(items)}
        renderValue={(label, items) => {
          // console.log(label,items)
          const labels = items.map(item=>item.label);
          // this.props.onChange(items)
          return (
            <span>
              <span style={{ color: '#575757' }}>
                <i className="rs-icon rs-icon-user" /> Room Icon :
              </span>{' '}
              {labels.join(' , ')}
            </span>
          );
        }}
      />
    )
  }
}


const mapDispatchToProps ={
  getRooms,
  addRoom
}

const mapStateToProps = state=>({
  settings: state.settingReducer.settings,
})

export default connect(mapStateToProps,mapDispatchToProps)(RoomForm)