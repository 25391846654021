import React, { Component,useState,useEffect,useRef } from 'react'
import { Badge,Avatar,Nav,Icon,Dropdown,IconButton } from 'rsuite';
import {withRouter,Link} from 'react-router-dom'
import isEmpty from '../../validations/isEmpty'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {logoutUser} from '../../actions/authActions';
import usered from '../../assets/user.png'


// Hook
function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = event => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
  
          handler(event);
        };
  
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
  
        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }


const Header = ({onClick,toggle, ...props }) => {
    
   const [drop,setDrop]=useState(false);
   const onDrop=(e)=>{
    e.stopPropagation() 
       setDrop(!drop)
   } 
   const onLogoutClick = (e)=>{
        e.preventDefault();
        props.logoutUser();
    }
    const drops = useRef(null);
    const {user,isAuthenticated} = props.auth;
    useOnClickOutside(drops, () => setDrop(false));
    return (
        <header className="header">
        <div className='header-menu'>
            <span className="header-menu__icon " id="header-menu" onClick={onClick}>
                <span className="iconify" data-icon="ant-design:menu-fold-outlined" data-inline="false"></span>
            </span>
        </div>
        <div className="header-right">
            {!isAuthenticated?null:
            <span className="header-avatar" ref={drops}>
                <span className="avatar-image"  onClick={(e)=>onDrop(e)}>
                    {isEmpty(user.photo)?
                    <img src={usered} alt="Avatar" />:
                    <img src={user.photo} alt="Avatar" />}
                </span>
                <div className={drop?"header-dropdown active":"header-dropdown"} id="header-dropdown">
                    <span onClick={(e)=>onDrop(e)} >
                    <span className="iconify" data-icon="ant-design:down-outline"></span>
                    </span>
                    <div className="header-dropdown__content" >
                        <ul className="dropdown-list">
                            <li className="dropdown-list__item" onClick={()=>props.history.push("/users/"+user.id)}>
                                    <span className="dropdown-icon">
                                        <Icon icon="user" />
                                    </span>
                                    <span className="dropdown-name">
                                        Profile
                                    </span>
                            </li>
                            <li className="dropdown-list__item" onClick={()=>props.history.push("/extras")}>
                                    <span className="dropdown-icon">
                                        <Icon icon="user" />
                                    </span>
                                    <span className="dropdown-name">
                                        Settings
                                    </span>
                            </li>
                            {/* <li className="dropdown-list__item" onClick={()=>onSetting(dispatch)}> */}
                            <li className="dropdown-list__item with-hr" onClick={(e)=>onLogoutClick(e)}>
                                    <span className="dropdown-icon">
                                        <Icon icon="sign-out" />
                                    </span>
                                    <span className="dropdown-name">
                                        Logout
                                    </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </span>}
            {/* <span className="header-right__icon">
                <Dropdown
                    placement="bottomEnd"
                    renderTitle={()=>{
                        return <Icon icon="cog" size="2x" />
                    }}
                    >
                    <Dropdown.Item onClick={(e)=>onLogoutClick(e)} ><Icon icon="group" />Logout</Dropdown.Item>
                </Dropdown>
            </span> */}
            <span className="header-right__icon">
            </span>
            
            {/* <span className="header-search" id="header-search">
                <span className="iconify" data-icon="ant-design:search-outline"></span>
                <input type="search" autoFocus placeholder="search" />
            </span> */}
            {/* <span className="header-notification">
                <Badge content="20">
                    <Avatar>
                        <Icon icon="IconButton" />
                    </Avatar>
                </Badge>
            </span> */}
           
        </div>
        </header>
    );
  };


Header.propTypes ={
    logoutUser :PropTypes.func.isRequired,
    auth : PropTypes.object.isRequired
}
  
const mapStateToProps =(state) =>({
    auth: state.auth 
});
  
export default withRouter(connect(mapStateToProps,{logoutUser})(Header));

// export default withRouter(Header)