import {
    GET_TEAM,
    GET_TEAMS,
    TEAM_LOADING,
    ADD_TEAM,
    EDIT_TEAM,
    DELETE_TEAM,
    ADD_TEAM_LOAD,
    EDIT_TEAM_UPLOAD,
}from "../actions/types";

const initialState = {
    teams: [],
    team: {},
    loading: false,
    load:false
};

export default function(state = initialState, action) {
    switch (action.type) {
      case TEAM_LOADING:
        return {
          ...state,
          loading: true
        };
      case GET_TEAM:
        return {
          ...state,
          team: action.payload,
          loading: false
        };
      case ADD_TEAM_LOAD:
        return{
          ...state,
          load:action.payload
        }
      case GET_TEAMS:
        return {
          ...state,
          teams: action.payload,
          loading: false
        };
      case ADD_TEAM:
        return {
          ...state,
          teams: [action.payload, ...state.teams]
        };
      case EDIT_TEAM:
        return {
          ...state,
          teams: state.teams.map(team => team.id === action.id ? action.payload : team)
        };
      case EDIT_TEAM_UPLOAD:
        return {
          ...state,
          team: action.payload,
          teams: state.teams.map(team => team.id === action.payload.id ? action.payload : team)
        };
      case DELETE_TEAM:
        return {
          ...state,
          teams: state.teams.filter(team => team.id !== action.payload)
        };
      default:
        return state;
    }
  }
  