import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import './App.scss';
import Links from './Links/Links'
import 'rsuite/dist/styles/rsuite-default.css';

function App() {
  return (
    <Provider store= {store}>
        <Router>
          <div className="App">
            <Links />
          </div>
        </Router>
    </Provider>

  );
}

export default App;
