import isEmpty from "./isEmpty";
export const validateTreks = (data) => {
  let errors = {};
  if (isEmpty(data.trek_name)) {
    errors.trek_name = "Trek Name Field is required";
  }
  if (isEmpty(data.handle)) {
    errors.handle = "Handle Field is required";
  }
  if (isEmpty(data.price)) {
    errors.price = "Price Field is required";
  }
  if (isEmpty(data.days)) {
    errors.days = "Days Field is required";
  }
  // if(isEmpty(data.provides)){
  // 	errors.provides = 'Provides Field is required';
  // }
  if (isEmpty(data.trekRegionId)) {
    errors.trekRegionId = "Region Field is required";
  }
  if (isEmpty(data.trek_type)) {
    errors.trek_type = "Type Field is required";
  }
  // if(isEmpty(data.classic_price)){
  // 	errors.classic_price = 'Classic Field is required';
  // }
  // if(!isEmpty(data.classic_price) && data.classic_price.split(",").length !== 3){
  // 	errors.classic_price = 'Need comma seperated value (3 values)';
  // }
  // if(isEmpty(data.elementary_price)){
  // 	errors.elementary_price = 'Elementary Field is required';
  // }
  // if(!isEmpty(data.elementary_price) && data.elementary_price.split(",").length !== 3){
  // 	errors.elementary_price = 'Need comma seperated value (3 values)';
  // }
  // if(isEmpty(data.full_fledge_price)){
  // 	errors.full_fledge_price = 'Full Fledge Field is required';
  // }
  // if(!isEmpty(data.full_fledge_price) && data.full_fledge_price.split(",").length !== 3){
  // 	errors.full_fledge_price = 'Need comma seperated value (3 values)';
  // }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};
