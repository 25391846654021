import React, { Component } from "react";
import { connect } from "react-redux";
import { getTrekByHandle, getTrek, addFiles, addMapFile } from "../../actions/trekActions";
import { Loader, Button, Icon } from "rsuite";
import "./trek.styles.scss";
import classnames from "classnames";
import UploadImages from "../../components/common/UploadImages";
// import UploadImages from "./UploadImages";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import renderHTML from 'react-render-html';
import isEmpty from '../../validations/isEmpty'
import Itinerary from "./itinerary";
import Includes from "./includes";
import Pricing from "./pricing";
import Overview from './overview'
import MapImage from  './UploadImages'
import Altitude from  './altitude'
export class Trek extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trek:{},
      active: "Overview"
    };
    this._renderImages = this._renderImages.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.onTabClick = this.onTabClick.bind(this);
    this.Tab = this.Tab.bind(this);
    this.onCollapse = this.onCollapse.bind(this);
    this.onImageChange = this.onImageChange.bind(this)
  }

  componentDidMount = async () => {
    const {
      match: {
        params: { handle,id }
      },
      getTrekByHandle,
      getTrek,
      trek
    } = this.props;
    if(handle){
      await getTrekByHandle(handle);
    }
    if(id){
      await getTrek(id);
    }
  }
  _renderImages() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      // nextArrow: <SampleNextArrow />,
      // prevArrow: <SamplePrevArrow />,
      cssEase: "linear",
      arrow: false
    };
    const {trek:{imag,trek_name}} = this.props;
    return (
      <Slider ref={c => (this.slider = c)} {...settings}>
        {imag.map(image => {
          return (
            <div className="slide-image">
              <img src={image} className="slider-image" alt={trek_name} />
            </div>
          );
        })}
      </Slider>
    );
  }
  componentDidUpdate(prevProps,prevState){
        const {trek} = this.props;
        if(!isEmpty(trek) && trek !== prevProps.trek){
            this.setState({trek})
        }
        const collapse = document.querySelector('.collapsible')
        if(collapse){
            const header = document.querySelectorAll('.collapsible .collapsible-list .collapsible-header');
            if(header){
                for(var i=0; i<header.length; i++){
                    header[i].addEventListener('click',this.onHeaderClick.bind(this));
                    const active = header[i].parentNode;
                    if(active.classList.contains('active')){
                        active.classList.remove('active')
                    }
                }
            }
            const active = document.querySelectorAll('.collapsible .collapsible-list.active');
            if(active){
                for(var i=0; i<active.length; i++){
                    active[i].classList.remove('active')
                }
            }
            // header.addEventListener('onclick',this.onHeaderClick.bind(this))
            
        }
  }
  onHeaderClick(e){
    const active = document.querySelectorAll('.collapsible .collapsible-list.active');
    const num = e.currentTarget.firstElementChild.innerText;
    // debugger
    if(active){
        for(var i=0; i<active.length; i++){
            const newNum = active[i].firstElementChild.firstElementChild.innerText;
            const newHeader = active[i].firstElementChild.nextElementSibling;
            if(num !== newNum){
                active[i].classList.remove('active');
                newHeader.style.animation = 'slideOutHeight .5s linear';
                setTimeout(() => {
                    newHeader.removeAttribute('style');
                    newHeader.style.display = 'none';
                }, 500);
            }
        }
    }
    
    const body = e.currentTarget.nextElementSibling;
    // clientHeight
    const li = e.currentTarget.parentNode;
    li.classList.toggle('active');
    if(li.classList.contains('active')){
        body.style.display = 'block';
    }else{
        body.style.animation = 'slideOutHeight .5s linear'
        setTimeout(() => {
            body.removeAttribute('style');
            body.style.display = 'none';
        }, 500);
    }

    // debugger
  }
  onTabClick(active) {
    this.setState({ active });
    this.onCollapse()
  }
  getActiveClass = name => {
    return classnames({
      "trek-tab": true,
      active: this.state.active === name
    });
  };
  onCollapse(){

  }
  Tab(name) {
    return (
      <div
        onClick={() => this.onTabClick(name)}
        className={this.getActiveClass(name)}
      >
        <span>{name}</span>
      </div>
    );
  }
  onImageChange(name){
      this.setState(prevState=>({
              ...prevState,
              trek:{
                  ...prevState.trek,
                  imag:prevState.trek.imag.filter(image=>image!==name)
              }
          })
      )
  }
  render() {
    const { trek, loading } = this.props;
    const { trek:trekState } = this.state;
    const { active } = this.state;
    if (loading) {
      return <Loader backdrop content="loading..." vertical />;
    }
    return (
      <div className="trek">
        <div className="trek-container">
          <div className="trek-block">
            <div className="trek-block__header">
              <h2>{trek.trek_name}</h2>
              <span>{trek.region}</span>
            </div>
            <div className="trek-block__chips">
              <div className="trek-block__chip">
                <h4>Properties</h4>
                <div className="flex">
                  <span className="chips chips-color-1">$ {trek.price}</span>
                  <span className="chips chips-color-2">
                    {trek.popular ? "Popular" : "Unpopular"}
                  </span>
                  <span className="chips chips-color-3">{trek.days} Days</span>
                  <span className="chips chips-color-4">{trek.altitude} m</span>
                  <span className="chips chips-color-5">{trek.difficulty}</span>
                </div>
              </div>
              <div className="trek-block__chip">
                <h4>Provides</h4>
                <div className="flex">
                  {trek.provides &&
                    trek.provides.map(item => {
                      return (
                        <span className="chips chips-color-4 not-rounded">
                          {item}
                        </span>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="trek-block__body">
              <div className="trek-block__slider">
                {trek.imag ? (
                  this._renderImages()
                ) : (
                  <span className="no-images">
                    No Images Found. Upload images down below inside the tab
                  </span>
                )}
              </div>
              {/* <div className="trek-block__button">
                <Button
                    onClick = {()=>this.props.history.push({
                        pathname:`/treks/${trek.handle}/details`,
                        state:{trek}
                    })}
                    >
                  <Icon icon="plus" /> Add Details
                </Button>
              </div> */}
              <div className="trek-block__tabs">
                <div className="trek-block__tabs-tab">
                  {this.Tab("Overview")}
                  {this.Tab("Pricing")}
                  {this.Tab("Itinerary")}
                  {this.Tab("Includes")}
                  {this.Tab("Altitude")}
                  {this.Tab("Gallery")}
                  {this.Tab("Upload Images")}
                  {this.Tab("Map")}
                </div>
                <div className="trek-block__tabs-body">
                  {active === "Overview" ? <Overview trek={trek} />:null}
                  {active === "Pricing" ? (
                    <Pricing trek={trek} />
                  ) : null}
                  {active === "Itinerary" ? (
                    <Itinerary trek={trek} />
                  ) : null}
                  {active === "Altitude" ? (
                    <Altitude trek={trek} />
                  ) : null}

                  {active === "Includes" ? (
                    trek.includes ? (
                      <div className="trek-body">
                          <Includes trek={trek} />
                        </div>
                    ) : (
                      <span className="no-tab">Includes Not Found</span>
                    )
                  ) : null}
                  {active === "Gallery" ? (
                    trek.imag ? (
                      <div className="trek-body">
                          <div className="image-grid">
                              {
                                  trek.imag.map(item=>{
                                      return(
                                          <div className="image-item">
                                              <img src={item} alt={trek.trek_name} />
                                          </div>
                                      )
                                  })
                              }
                          </div>
                      </div>
                    ) : (
                      <span className="no-tab">Images Not Found</span>
                    )
                  ) : null}
                  {active === "Upload Images" ? (
                    <div className="trek-body">
                      <UploadImages
                        UploadImages={this.props.addFiles}
                        images={trekState.imag}
                        id={trek.id}
                        name="imag"
                        onImageChange={this.onImageChange}
                      />
                    </div>
                  ) : null}
                  {active === "Map" ? (
                    <div className="trek-body">
                      <MapImage
                        UploadImages={this.props.addMapFile}
                        image={trekState.mapImage}
                        id={trek.id}
                        name="image"
                        onImageChange={this.onImageChange}
                      />
                    </div>
                  ) : null}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getTrekByHandle,
  getTrek,
  addFiles,
  addMapFile
};

const mapStateToProps = state => ({
  trek: state.trekReducer.trek,
  loading: state.trekReducer.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Trek);
