import React, {useState, useEffect} from 'react'
import isEmpty from '../../../validations/isEmpty';
import {validateBloge} from '../../../validations/validateTeams';
import PropTypes from 'prop-types';
import { addBlog,editService } from '../../../actions/settingActions';
import useFormValidation from "../../../validations/formValidations";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    Toggle,
    ButtonToolbar,
    Button,
    Loader,
    RadioGroup,
    SelectPicker,
    Alert,
    Radio,
    Uploader,
    Icon
} from "rsuite";
import {useDispatch, useSelector} from 'react-redux';
import Tiny from '../../../components/common/TinyEmc';


const INITIAL_STATE = {
    title: "",
    sub_title: "",
    handle: "",
    summary: "",
    body: "",
    tags: [],
    is_published:false,
    is_featured:false,
    // image:null
};


function TeamForm({onEdit,editData,isEdit,onClose}) {
    const {
        handleSubmit,
        handleChange2,
        handleDateChange,
        handleChange3,
        handleBlur,
        checkErrors,
        values,
        setInitial,
        handleSelect,
        errors,
        isSubmitting,
        clearValues
    } = useFormValidation(INITIAL_STATE, validateBloge);
    const errorReducer = useSelector(state=>state.errorReducer);
    const { serviceTypes } = useSelector(state => state.settingReducer);
    const load = useSelector(state=>state.teamReducer.load); 
    
    const [errs, setErrs] = React.useState({});
    const dispatch = useDispatch();
    if (isSubmitting) {
        if(isEmpty(errors)){
            
        }
    }
    const handleSubmits = async (e) => {
        
        e.preventDefault();
        const {isValid} =  checkErrors();
        if(isValid){
            if(isEdit){
                const res = await dispatch(addBlog({id:values.id,...values},true));
                // dispatch(editTrekRegions(values.id,values));
                console.log({res})
                if(res){
                    onClose && onClose()
                }
                clearValues();
                onEdit({})
                
                return;
            }
            const res = await dispatch(addBlog(values));
            if(res){
                onClose && onClose()
            }
            clearValues();
        }
    }
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);

    useEffect(()=>{
        if(!isEmpty(editData)){
            const {age,...rest} = editData;
            let newAge = parseInt(age);
            let newData = {age:newAge,...rest}
            setInitial(newData)
        }
    },[editData]);

    useEffect(() => {
        window.addEventListener('keydown',(e)=>{handleEsc(e)})
    }, [isEdit])

    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const handleEsc = (e,cancel='') => {
        if(e.key === 'Escape' || cancel==='cancel'){
            clearValues();
            onEdit({})
        }
    }
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };
    return (
        <div className='blogs-form'>
            <Form>
                <FormGroup className={getError("handle")}>
                    <ControlLabel>Handle</ControlLabel>
                    <FormControl
                        name="handle"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.handle}
                        errorMessage={getErrorMessage('handle')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("title")}>
                    <ControlLabel>Title</ControlLabel>
                    <FormControl
                        name="title"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.title}
                        errorMessage={getErrorMessage('title')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("sub_title")}>
                    <ControlLabel>Sub Title</ControlLabel>
                    <FormControl
                        name="sub_title"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.sub_title}
                        errorMessage={getErrorMessage('sub_title')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("summary")}>
                    <ControlLabel>Summary</ControlLabel>
                    <FormControl
                        name="summary"
                        type="textarea" 
                        componentClass="textarea"
                        rows={6}
                        onBlur={e => handleBlur(e)}
                        value={values.summary}
                        errorMessage={getErrorMessage('summary')}
                        onChange={handleChange2}
                        style={{width:600}}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <div className="flex" style={{gap:24}} >
                    <FormGroup className={getError("is_published")}>
                        <ControlLabel>Is Published</ControlLabel>
                    
                        <Toggle 
                            checked={values.is_published}
                            errorMessage={getErrorMessage('is_published')}
                            onChange={(val,e)=>handleChange3("is_published",val)}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <FormGroup className={getError("is_featured")} style={{marginBottom:24}}>
                        <ControlLabel>Is Featured</ControlLabel>
                    
                        <Toggle 
                            checked={values.is_featured}
                            errorMessage={getErrorMessage('is_featured')}
                            onChange={(val,e)=>handleChange3("is_featured",val)}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                </div>
                <FormGroup className={getError("body")}>
                    <ControlLabel>Body</ControlLabel>
                    <Tiny 
                        onChange={(name,value)=>handleChange3("body",value)}
                        value={values.body}
                        name="body"
                        errorMessage={getErrorMessage("body")}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("tags")}>
                    <ControlLabel>Tags</ControlLabel>
                    <ArrayForm 
                        name="tags"
                        vals={values.tags}
                        title="Tags"
                        change={(name,val)=>{
                            handleChange3(name,[val,...values.tags])
                        }}
                        remove={(name,item)=>handleChange3(name,values.tags.filter(it=>it!==item))}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                
                {/* <FormGroup className={getError("image")}>
                    <ControlLabel>Image</ControlLabel>
                    <Uploader
                        multiple={false}
                        autoUpload={false}
                        listType="picture"
                        onChange={image => {
                            handleChange3("image",image)
                        }}
                        >
                            <button>
                                <Icon icon="camera-retro" size="lg" />
                            </button>
                    </Uploader>
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup> */}

                
                
                <FormGroup>
                    <ButtonToolbar>
                        <Button
                            appearance="primary"
                            disabled={load}
                            onClick={(e)=>handleSubmits(e)}
                        >
                            {load ? <Loader /> : null}
                            {isEdit ? "Update Service" : "Add Service"}
                        </Button>
                        {isEdit?(
                                <Button
                                onClick={(e)=>handleEsc(e,'cancel')}
                            >
                                Cancle Edit
                            </Button>
                        ):null}
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    )
}

export const ArrayForm = ({
    name,
    vals,
    title,
    change,
    remove
  }) => {
    const [email, setEmail] = useState("");
    return (
      <div className="settings-email">
        {/* <Form
          onSubmit={() => {
            change(name, email);
            setEmail("");
          }}
        > */}
          <FormGroup
          // className={getError("flockToEmail")}
          >
            <ControlLabel>{title}</ControlLabel>
            <FormControl
              name={name}
              type={"text"}
              
              value={email}
              onChange={value => setEmail(value)}
            />
          </FormGroup>
          <FormGroup>
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={() =>{
                    if(!email){
                        return;
                    }
                    change(name,email);
                    setEmail("");
                }}
              >
                Add
              </Button>
            </ButtonToolbar>
          </FormGroup>
        {/* </Form> */}
        <div className="status-list">
          {vals ? (
            vals.map(item => {
              return (
                <div className="status-list__item">
                  <span className="name">{item}</span>
                  <Icon
                    onClick={() => remove(name, item)}
                    icon="close"
                    size="12px"
                  />
                </div>
              );
            })
          ) : (
            <span>Nothing Added</span>
          )}
        </div>
      </div>
    );
};

export default TeamForm
