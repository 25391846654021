import {
  GET_TREK,
  GET_TREKS,
  TREK_LOADING,
  ADD_TREK,
  ADD_POPULAR_TREK,
  GET_TREKS_BY_TYPE,
  EDIT_TREK,
  SINGLE_EDIT_TREK,
  DELETE_TREK,
  SINGLE_EDIT_TREK_OVERVIEW,
  ADD_TREK_INCLUDES,
  EDIT_TREK_INCLUDES,
  DELETE_TREK_INCLUDES,

  ADD_TREK_ALTITUDE,
  DELETE_TREK_ALTITUDE,
  EDIT_TREK_ALTITUDE,

  ADD_TREK_ITINERARY,
  EDIT_TREK_ITINERARY,
  DELETE_TREK_ITINERARY,

  ADD_TREK_PRICE_DETAILS,
  EDIT_TREK_PRICE_DETAILS,
  DELETE_TREK_PRICE_DETAILS,

  ADD_TREK_PRICE_GROUP_SIZE,
  EDIT_TREK_PRICE_GROUP_SIZE,
  DELETE_TREK_PRICE_GROUP_SIZE,
} from "../actions/types";

const initialState = {
  treks: [],
  trek: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TREK_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_TREK:
      return {
        ...state,
        trek: action.payload,
        loading: false
      };
    case GET_TREKS:
      return {
        ...state,
        treks: action.payload,
        loading: false
      };
  case ADD_TREK_ALTITUDE:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          altitudes:[...state.trek.altitudes,action.payload],
        },
        loading: false
      };
    case EDIT_TREK_ALTITUDE:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          altitudes:state.trek.altitudes.map(item=>item.id === action.payload.id ? action.payload : item),
        },
        loading: false
      };
    case DELETE_TREK_ALTITUDE:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          altitudes:state.trek.altitudes.filter(trek => trek.id !== action.payload),
        },
        loading: false
      };
    case ADD_TREK_ITINERARY:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          itinerary:[...state.trek.itinerary,action.payload],
        },
        loading: false
      };
    case EDIT_TREK_ITINERARY:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          itinerary:state.trek.itinerary.map(item=>item.id === action.payload.id ? action.payload : item),
        },
        loading: false
      };
    case DELETE_TREK_ITINERARY:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          itinerary:state.trek.itinerary.filter(trek => trek.id !== action.payload),
        },
        loading: false
      };

    
      case ADD_TREK_PRICE_DETAILS:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          priceDetails:[...state.trek.priceDetails,action.payload],
        },
        loading: false
      };
    case EDIT_TREK_PRICE_DETAILS:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          priceDetails:state.trek.priceDetails.map(item=>item.id === action.payload.id ? action.payload : item),
        },
        loading: false
      };
    case DELETE_TREK_PRICE_DETAILS:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          priceDetails:state.trek.priceDetails.filter(trek => trek.id !== action.payload),
        },
        loading: false
      };

    case ADD_TREK_PRICE_GROUP_SIZE:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          priceDetails:state.trek.priceDetails.map(item=>{
            if(item.id === action.payload.trekPriceId){
              return {
                ...item,
                group_sizes:[...item.group_sizes,action.payload]
              }
            }
            return item
          }),
        },
        loading: false
      };
      case EDIT_TREK_PRICE_GROUP_SIZE:
        return {
          ...state,
          trek: state.trek && {
            ...state.trek,
            priceDetails:state.trek.priceDetails.map(item=>{
              if(item.id === action.payload.trekPriceId){
                return {
                  ...item,
                  group_sizes:item.group_sizes.map(group=>group.id === action.payload.id ? action.payload : group)
                }
              }
              return item
            }),
          },
          loading: false
      };
      case DELETE_TREK_PRICE_GROUP_SIZE:
        return {
          ...state,
          trek: state.trek && {
            ...state.trek,
            priceDetails:state.trek.priceDetails.map(item=>{
              return {
                ...item,
                group_sizes:item.group_sizes.filter(group=>group.id !== action.payload)
              }
            }),
          },
          loading: false
        };
      
    case ADD_TREK_INCLUDES:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          includes:action.payload.included ? [...state.trek.includes,action.payload] :state.trek.includes,
          excludes:!action.payload.included ? [...state.trek.excludes,action.payload] :state.trek.excludes
        },
        loading: false
      };
    case EDIT_TREK_INCLUDES:
      return {
        ...state,
        trek: state.trek && {
          ...state.trek,
          includes:action.payload.included ? state.trek.includes.map(item=>item.id === action.payload.id ? action.payload : item) :state.trek.includes,
          excludes:!action.payload.included ? state.trek.excludes.map(item=>item.id === action.payload.id ? action.payload : item)  :state.trek.excludes
        },
        loading: false
      };
      case DELETE_TREK_INCLUDES:
        return {
          ...state,
          trek: state.trek && {
            ...state.trek,
            includes:state.trek.includes.filter(trek => trek.id !== action.payload),
            excludes:state.trek.excludes.filter(trek => trek.id !== action.payload)
          },
          loading: false
        };

      
    case ADD_TREK:
      return {
        ...state,
        treks: [action.payload, ...state.treks]
      };
    case SINGLE_EDIT_TREK:
      return {
        ...state,
        trek:  {
                
          ...state.trek,
          ...action.payload,
        }
      };
    case SINGLE_EDIT_TREK_OVERVIEW:
      return {
        ...state,
        trek: {
          ...state.trek,
          overview:action.payload.overview
        }
      };
      
    case EDIT_TREK:
      return {
        ...state,
        treks: state.treks.map(trek =>
          trek.id === action.id
            ? // transform the one with a matching id
              action.payload
            : // otherwise return original todo
              trek
        )
      };
    case ADD_POPULAR_TREK:
      return {
        ...state,
        treks: state.treks.map(trek =>
          trek.id === action.id
            ? // transform the one with a matching id
              { ...trek, popular: action.payload.popular }
            : // otherwise return original todo
              trek
        )
      };

    case GET_TREKS_BY_TYPE:
      return {
        ...state,
        treks: action.payload,
        loading: false
      };
    case DELETE_TREK:
      return {
        ...state,
        treks: state.treks.filter(trek => trek.id !== action.payload)
      };
    default:
      return state;
  }
}
