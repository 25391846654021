import React, { Component } from 'react'
import isEmpty from '../../validations/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addAdventure,editAdventure } from '../../actions/adventuresActions';
import {validateAdventures} from '../../validations/validateAdventures';
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    SelectPicker,
    HelpBlock,
    ButtonToolbar,
    Button,
    Loader,
    RadioGroup,
    Radio,
    CheckPicker 
} from "rsuite";
import renderHTML from 'react-render-html';
import Tiny from '../../components/common/TinyEmc';
export class AdventureForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            handle: '',
            name: '',
            content: '',
            summary: '',
            base_price: '',
            destination: '',
            difficulty: 'Easy',
            duration: '',
            type: 'Adventure',
            price_details: '',
            images: '',
            errors: {}
          };
    
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onNotCkChange = this.onNotCkChange.bind(this);
        this.handleEsc = this.handleEsc.bind(this);
      }
    componentDidMount(){
        document.addEventListener('keyup', this.handleEsc);
    }
    componentWillUnmount(){
        document.removeEventListener('keyup', this.handleEsc);
    }
    handleEsc(e,cancel=''){
        if(e.key === 'Escape' || cancel==='cancel'){
            this.props.onEdit('')
        }
    }
    componentDidUpdate(prevProps,prevState){
        const {errors,editData} = this.props;
        if(!isEmpty(errors) && errors !==prevProps.errors){
            console.log(errors);
            this.setState({errors})
        }
        if(editData !== prevProps.editData){
            if(!isEmpty(editData)){
                this.setState({
                    handle:editData.handle,
                    name:editData.name,
                    base_price:editData.base_price,
                    duration:editData.duration,
                    content:renderHTML(editData.content),
                    difficulty:editData.difficulty,
                    summary:editData.summary,
                    price_details:renderHTML(editData.price_details),
                    destination:editData.destination,
                    type:editData.type,
                    id:editData.id,
                    isEdit:true,
                });
                return;
            }
            this.clearState()
        }
        
    }
    async onSubmit(e) {
        e.preventDefault();
        const data = {
            id:this.state.id,
            name:this.state.name,
            handle:this.state.handle,
            content:this.state.content,
            price_details:this.state.price_details,
            summary:this.state.summary,
            base_price:this.state.base_price,
            difficulty:this.state.difficulty,
            destination:this.state.destination,
            type:this.state.type,
            duration:this.state.duration,
        };
        const {errors,isValid} = validateAdventures(data);
        console.log(errors)
        if(!isValid){
            this.setState({errors});
            return false;
        }
        if(this.state.isEdit){
            debugger
            await this.props.editAdventure(this.state.id,data);
            this.clearState()
            return false
        }
        await this.props.addAdventure(data);
        this.clearState()
    }
    onNotCkChange(name , data) {
        this.setState({ [name]: data });
      }
    clearState(){
        this.setState({
            id:'',
            handle: '',
            name: '',
            content: '',
            summary: '',
            base_price: '',
            destination: '',
            difficulty: 'Easy',
            duration: '',
            type: 'Adventure',
            price_details: '',
            images: '',
            isEdit:false
        })
    }
    onChange(value,e) {
        const {name} = e.target;
        this.setState({ [name]:value });
    }
    handleBlur(e){
        const {errors,isValid} = validateAdventures(this.state);
        // if(!isValid){
        //     this.setState({errors});
        //     return false;
        // } 
    }
    getErrorMessage(type){
        if (!isEmpty(this.state.errors[type])) {
            return this.state.errors[type];
        } 
        return "";
    }
    onSelect(value,valueObj,name){
        this.setState({[name]:value})
    }
    getError = type => {
        let errClass =
            !isEmpty(this.state.errors[type]) ? "has-error" : "";
        return errClass;
    };
    render() {
        const { errors } = this.state;
        const values = this.state;
        return (
            <div>
                <Form>
                    <FormGroup className={this.getError("handle")}>
                        <ControlLabel>Handle</ControlLabel>
                        <FormControl
                            name="handle"
                            type="text"
                            onBlur={e => this.handleBlur(e)}
                            value={values.handle}
                            errorMessage={errors.handle}
                            onChange={this.onChange}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <FormGroup className={this.getError("name")}>
                        <ControlLabel>Name</ControlLabel>
                        <FormControl
                            name="name"
                            type="text"
                            onBlur={e => this.handleBlur(e)}
                            value={values.name}
                            errorMessage={errors.name}
                            onChange={this.onChange}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <FormGroup className={this.getError("base_price")}>
                        <ControlLabel>Base Price</ControlLabel>
                        <FormControl
                            name="base_price"
                            type="text"
                            onBlur={e => this.handleBlur(e)}
                            value={values.base_price}
                            errorMessage={errors.base_price}
                            onChange={this.onChange}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <FormGroup className={this.getError("duration")}>
                        <ControlLabel>Duration</ControlLabel>
                        <FormControl
                            name="duration"
                            type="number"
                            onBlur={e => this.handleBlur(e)}
                            value={values.duration}
                            errorMessage={errors.duration}
                            onChange={this.onChange}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <RadioGroup 
                        className="radio-container"
                        onChange={(value)=>this.setState({difficulty:value})}
                        name="difficulty" 
                        inline 
                        value={values.difficulty}
                        appearance="picker" 
                        defaultValue="Easy">
                            <span className="radio-header">Difficulty: </span>
                            <Radio value="Easy">Easy</Radio>
                            <Radio value="Medium">Medium</Radio>
                            <Radio value="Difficult">Difficult</Radio>
                    </RadioGroup>
                    <FormGroup className={this.getError("destination")}>
                        <ControlLabel>Destination</ControlLabel>
                        <FormControl
                            name="destination"
                            type="text"
                            onBlur={e => this.handleBlur(e)}
                            value={values.destination}
                            errorMessage={errors.destination}
                            onChange={this.onChange}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <FormGroup className={this.getError("summary")}>
                        <ControlLabel>Summary</ControlLabel>
                        <FormControl
                            name="summary"
                            type="text"
                            onBlur={e => this.handleBlur(e)}
                            value={values.summary}
                            errorMessage={errors.summary}
                            onChange={this.onChange}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <FormGroup className={this.getError("content")}>
                        <ControlLabel>Content</ControlLabel>
                        <Tiny 
                            onChange={(name,value)=>this.setState({ [name]:value })}
                            value={values.content}
                            name="content"
                            // errorMessage={getErrorMessage("details")}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    <FormGroup className={this.getError("price_details")}>
                        <ControlLabel>Price Details</ControlLabel>
                        <Tiny 
                            onChange={(name,value)=>this.setState({ [name]:value })}
                            value={values.price_details}
                            name="price_details"
                            // errorMessage={getErrorMessage("details")}
                        />
                        <HelpBlock tooltip>Required</HelpBlock>
                    </FormGroup>
                    
                    {/* <div className="ck-form">
                        <label htmlFor="price_details">Price Details</label>
                        <CKEditor 
                            id="price_details" 
                            value={this.state.price_details} 
                            datas={this.state.price_details} 
                            onChange={this.onNotCkChange}/>
                        {errors && errors.price_details && <span className="error-message">{errors.price_details}</span>}
                    </div>
                    <div className="ck-form">
                        <label htmlFor="content">Content</label>
                        <CKEditor 
                            id="content" 
                            value={this.state.content} 
                            datas={this.state.content} 
                            onChange={this.onNotCkChange}/>
                        {errors && errors.content && <span className="error-message">{errors.content}</span>}
                    </div> */}
                    <FormGroup>
                        <ButtonToolbar>
                            <Button
                                appearance="primary"
                                disabled={false}
                                onClick={(e)=>this.onSubmit(e)}
                            >
                                {false ? <Loader /> : null}
                                {!this.state.isEdit?"Add Adventure":"Update Adventure"}
                            </Button>
                            {this.state.isEdit?(
                                <Button
                                onClick={(e)=>this.handleEsc(e,'cancel')}
                            >
                                Cancle Edit
                            </Button>
                            ):null}
                        </ButtonToolbar>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

AdventureForm.propTypes = {
    addAdventure: PropTypes.func.isRequired,
    editAdventure: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errorReducer,
    settings: state.settingReducer.settings,
});

export default connect(mapStateToProps, { addAdventure,editAdventure })(AdventureForm);