import React, {useState, useEffect} from 'react'
import isEmpty from '../../../validations/isEmpty';
import { addThings } from '../../../actions/settingActions';
import useFormValidation from "../../../validations/formValidations";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    HelpBlock,
    ButtonToolbar,
    Button,
    Loader,
    Toggle,
    Icon
} from "rsuite";
import {useDispatch, useSelector} from 'react-redux';

const INITIAL_STATE = {
    title: "",
    subtitle: "",
    link: "",
    search: "",
    priority: 1,
    tags: [],
    isThings: false,
    image:null
};

export const validateRegions = (data)=>{
	let errors ={};
    if(isEmpty(data.title)){
		errors.title = 'Title Field is required';
	}
	if(isEmpty(data.subtitle)){
		errors.subtitle = 'Subtitle Field is required';
	}
    if(isEmpty(data.link)){
		errors.link = 'Link Field is required';
	}
    // if(isEmpty(data.tags)){
	// 	errors.tags = 'Tags Field is required';
	// }
	return errors
}

function TeamForm({onEdit,editData,isEdit}) {
    const {
        handleSubmit,
        handleChange2,
        handleDateChange,
        handleChange3,
        handleBlur,
        checkErrors,
        values,
        setInitial,
        errors,
        isSubmitting,
        clearValues
    } = useFormValidation(INITIAL_STATE, validateRegions);
    const errorReducer = useSelector(state=>state.errorReducer)
    const load = useSelector(state=>state.teamReducer.load); 
    const [errs, setErrs] = React.useState({});
    const dispatch = useDispatch();
    if (isSubmitting) {
        if(isEmpty(errors)){
            
        }
    }
    const handleSubmits = (e) => {
        e.preventDefault();
        const {isValid} =  checkErrors();
        if(isValid){
            console.log({values,isEdit,errors})
            let data=values;
            if(isEmpty(errors)){
                if(isEdit){
                    // if(values.image){
                    //     data.append("id", values.id)
                    // }else{
                        
                    // }
                    dispatch(addThings(data,true));
                    // dispatch(editTrekRegions(values.id,values));
                    clearValues();
                    onEdit({})
                    return false;
                }
                
                dispatch(addThings(data));
                clearValues();
            }
        }
    }
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);

    useEffect(()=>{
        if(!isEmpty(editData)){
            const {...rest} = editData;
            // let newAge = parseInt(age);
            // let newData = {age:newAge,...rest}
            setInitial(rest)
        }
    },[editData]);

    useEffect(() => {
        window.addEventListener('keydown',(e)=>{handleEsc(e)})
    }, [isEdit])

    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const handleEsc = (e,cancel='') => {
        if(e.key === 'Escape' || cancel==='cancel'){
            clearValues();
            onEdit({})
        }
    }
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };
    return (
        <div>
            <Form>
                <FormGroup className={getError("title")}>
                    <ControlLabel>Title</ControlLabel>
                    <FormControl
                        name="title"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.title}
                        errorMessage={getErrorMessage('title')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("subtitle")}>
                    <ControlLabel>Sub Title</ControlLabel>
                    <FormControl
                        name="subtitle"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.subtitle}
                        errorMessage={getErrorMessage('subtitle')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("link")}>
                    <ControlLabel>Link</ControlLabel>
                    <FormControl
                        name="link"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.link}
                        errorMessage={getErrorMessage('link')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("search")}>
                    <ControlLabel>Search</ControlLabel>
                    <FormControl
                        name="search"
                        type="text"
                        onBlur={e => handleBlur(e)}
                        value={values.search}
                        errorMessage={getErrorMessage('search')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("priority")}>
                    <ControlLabel>Priority</ControlLabel>
                    <FormControl
                        name="priority"
                        type="number"
                        onBlur={e => handleBlur(e)}
                        value={values.priority}
                        errorMessage={getErrorMessage('priority')}
                        onChange={handleChange2}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup className={getError("tags")}>
                    <ControlLabel>Tags</ControlLabel>
                    <ArrayForm 
                        name="tags"
                        vals={values.tags}
                        title="Tags"
                        change={(name,val)=>{
                            // console.log({name,val,as:[val,...values.tags]})
                            handleChange3(name,[val,...values.tags])
                        }}
                        remove={(name,item)=>handleChange3(name,values.tags.filter(it=>it!==item))}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                {/* <FormGroup className={getError("image")}>
                    <ControlLabel>Image</ControlLabel>
                    <Uploader
                        multiple={false}
                        autoUpload={false}
                        listType="picture"
                        onChange={image => {
                            handleChange3("image",image)
                        }}
                        >
                            <button>
                                <Icon icon="camera-retro" size="lg" />
                            </button>
                    </Uploader>
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup> */}                
                <FormGroup className={getError("isThings")}>
                    <ControlLabel>Is Things</ControlLabel>
                   
                    <Toggle 
                        checked={values.isThings}
                        errorMessage={getErrorMessage('isThings')}
                        onChange={(val,e)=>handleChange3("isThings",val)}
                    />
                    <HelpBlock tooltip>Required</HelpBlock>
                </FormGroup>
                <FormGroup>
                    <ButtonToolbar>
                        <Button
                            appearance="primary"
                            disabled={load}
                            onClick={(e)=>handleSubmits(e)}
                        >
                            {load ? <Loader /> : null}
                            {isEdit ? "Update Service" : "Add Service"}
                        </Button>
                        {isEdit?(
                                <Button
                                onClick={(e)=>handleEsc(e,'cancel')}
                            >
                                Cancle Edit
                            </Button>
                        ):null}
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    )
}

export const ArrayForm = ({
    name,
    vals,
    title,
    change,
    remove
  }) => {
    const [email, setEmail] = useState("");
    return (
      <div className="settings-email">
        {/* <Form
          onSubmit={() => {
            change(name, email);
            setEmail("");
          }}
        > */}
          <FormGroup
          // className={getError("flockToEmail")}
          >
            <ControlLabel>{title}</ControlLabel>
            <FormControl
              name={name}
              type={"text"}
              
              value={email}
              onChange={value => setEmail(value)}
            />
          </FormGroup>
          <FormGroup>
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={() =>{
                    if(!email){
                        return;
                    }
                    change(name,email);
                    setEmail("");
                }}
              >
                Add
              </Button>
            </ButtonToolbar>
          </FormGroup>
        {/* </Form> */}
        <div className="status-list">
          {vals ? (
            vals.map(item => {
              return (
                <div className="status-list__item">
                  <span className="name">{item}</span>
                  <Icon
                    onClick={() => remove(name, item)}
                    icon="close"
                    size="12px"
                  />
                </div>
              );
            })
          ) : (
            <span>Nothing Added</span>
          )}
        </div>
      </div>
    );
};

export default TeamForm
