import React, {useEffect,useState} from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Input,
} from "rsuite";
import { Button, Modal, Loader, ButtonToolbar } from "rsuite";
import useFormValidation from "../../../validations/formValidations";
import validateitinerary from "../../../validations/validateItinerary";
import isEmpty from "../../../validations/isEmpty";
import Tiny from '../../../components/common/TinyEmc';
import {addTrekItinerary} from '../../../actions/trekActions'
import { useDispatch } from "react-redux";

function ItineraryForm(props) {
    const { errorReducer, editCustomer, auth, datas={},isEdit=false,close,trek } = props;
    const dispatch = useDispatch()
    const INITIAL_STATE = {
        day: !isEmpty(datas?.day) ? datas.day : "",
        details: !isEmpty(datas?.details) ? datas.details : "",
        title: !isEmpty(datas?.title) ? datas.title : "",
        accomodation: !isEmpty(datas?.accomodation) ? datas.accomodation : "",
        time: !isEmpty(datas?.time) ? datas.time : "",
        food: !isEmpty(datas?.food) ? datas.food : "",
        altitude: !isEmpty(datas?.altitude) ? datas.altitude : "",
    };
  const {
    handleSubmit,
    handleChange2,
    handleChange3,
    handleSelect,
    checkErrors,
    handleBlur,
    handleDateChange,
    values,
    errors,
    isSubmitting,
    clearValues,
  } = useFormValidation(INITIAL_STATE, validateitinerary);
  const handleSubmits = (e) => {
    e.preventDefault();
    const {isValid} =  checkErrors();
    if(isValid){
        if(isEmpty(errors)){
            if(isEdit){
                // dispatch(editTeam(values.id,values));
                // clearValues();
                // onEdit({})
                dispatch(addTrekItinerary(trek.id, {...values,id:datas.id}));
                clearValues();
              close();
                return false;
            }
            dispatch(addTrekItinerary(trek.id, values));
            clearValues();
            close();
        }
    }
}
  const [errs, setErrs] = React.useState({});
    useEffect(() => {
        if (errorReducer) {
            if (!isEmpty(errorReducer.error)) {
                setErrs(errorReducer.error);
            }
        }
    }, [errorReducer]);
    const getError = type => {
        let errClass =
            !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
        return errClass;
    };
    const getErrorMessage = type => {
        if (!isEmpty(errors[type])) {
            return errors[type];
        } else if (!isEmpty(errs[type])) {
            return errs[type];
        }
        return "";
    };
  return (
    <Form>
      <FormGroup className={getError("day")}>
        <ControlLabel>Day</ControlLabel>
        <FormControl
          name="day"
          type="text"
          value={values.day}
          errorMessage={getErrorMessage("day")}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("title")}>
        <ControlLabel>Title</ControlLabel>
        <FormControl
          name="title"
          type="text"
          errorMessage={getErrorMessage("title")}
          value={values.title}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("accomodation")}>
        <ControlLabel>Accomodation</ControlLabel>
        <FormControl
          name="accomodation"
          type="text"
          errorMessage={getErrorMessage("accomodation")}
          value={values.accomodation}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("time")}>
        <ControlLabel>Time</ControlLabel>
        <FormControl
          name="time"
          type="text"
          errorMessage={getErrorMessage("time")}
          value={values.time}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("place")}>
        <ControlLabel>Place</ControlLabel>
        <FormControl
          name="place"
          type="text"
          errorMessage={getErrorMessage("place")}
          value={values.place}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("food")}>
        <ControlLabel>Food</ControlLabel>
        <FormControl
          name="food"
          type="text"
          errorMessage={getErrorMessage("food")}
          value={values.food}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("altitude")}>
        <ControlLabel>Altitude</ControlLabel>
        <FormControl
          name="altitude"
          type="text"
          errorMessage={getErrorMessage("altitude")}
          value={values.altitude}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("details")}>
        <ControlLabel>Details</ControlLabel>
        <Tiny 
        onChange={handleChange3}
        value={values.details}
        name="details"
        errorMessage={getErrorMessage("details")}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
     
      <FormGroup>
        <ButtonToolbar>
          <Button
            appearance="primary"
            disabled={isSubmitting}
            onClick={handleSubmits}
          >
            {isSubmitting ? <Loader /> : null}
            Submit
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
}

export default ItineraryForm;
