import React, {useEffect, useState} from 'react'
import { Modal, Button  } from "rsuite";
import { editPopularReview } from "../../actions/reviewsActions";
import { useDispatch } from 'react-redux';

function ReviewModal({dataKey,data,...props}) {
    const [show, setShow] = useState(false);
    const close = () => {
        setShow(false);
    }
    const open = () =>{
        setShow(true);
    }
    const dispatch = useDispatch();

    console.log(data)
    return (
        <div>
            <a onClick={()=>setShow(!show)}>
                {data.name}
            </a>
            <Modal show={show} onHide={close} full={false}>
                <Modal.Header>
                    <Modal.Title>Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="review-modal">
                        <div className="review-modal__name">
                            <h3>{data.name}</h3>
                            <p>{data.trek_name}</p>
                        </div>
                        <div className="review-modal__chips">
                            
                            <div 
                                onClick={()=>dispatch(editPopularReview(data.id,{popular:!data.popular}))}
                                className="trek-item__popular">
                                    { data.popular ? 
                                        ( <span className="chips chips-green" >Popular</span> ) :
                                        ( <span className="chips chips-red" >Unpopular</span> )
                                    }
                            </div>
                            <div 
                                onClick={()=>dispatch(editPopularReview(data.id,{approved:!data.approved}))}
                                className="trek-item__popular">
                                    { data.approved ? 
                                        ( <span className="chips chips-green" >Approved</span> ) :
                                        ( <span className="chips chips-red" >Disapproved</span> )
                                    }
                            </div>
                        </div>
                        <div className="review-modal__image">
                            <img src={data.image} alt={data.trek_name} />
                        </div>
                        <div className="review-modal__details">
                            <h4>{data.summary}</h4>
                            <p>{data.details}</p>
                        </div>
                    </div>               
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={close} appearance="subtle">
                    Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ReviewModal
