import axios from "axios";
import { Alert } from "rsuite";
import {
  GET_TREK,
  GET_TREKS,
  TREK_LOADING,
  ADD_TREK,
  EDIT_TREK,
  ADD_POPULAR_TREK,
  SINGLE_EDIT_TREK,
  GET_TREKS_BY_TYPE,
  DELETE_TREK,
  TREK_ITINERARY_LOADING,
  GET_ERRORS,
  DELETE_TREK_ITINERARY,
  EDIT_TREK_ITINERARY,
  ADD_TREK_ITINERARY,
  ADD_TREK_ALTITUDE,
  EDIT_TREK_ALTITUDE,
  DELETE_TREK_ALTITUDE,
  ADD_TREK_INCLUDES,
  EDIT_TREK_INCLUDES,
  DELETE_TREK_INCLUDES,
  SINGLE_EDIT_TREK_OVERVIEW,
  ADD_TREK_PRICE_DETAILS,
  EDIT_TREK_PRICE_DETAILS,
  DELETE_TREK_PRICE_DETAILS,
  ADD_TREK_PRICE_GROUP_SIZE,
  EDIT_TREK_PRICE_GROUP_SIZE,
  DELETE_TREK_PRICE_GROUP_SIZE,
  CLEAR_ERRORS,
} from "./types";
import { getError } from "../helpers/getUnauth";

// Get current trek
export const getTrek = (id) => (dispatch) => {
  dispatch(setTrekLoading());
  axios
    .get(`/api/v1/treks/${id}`)
    .then((res) =>
      dispatch({
        type: GET_TREK,
        payload: res.data.data,
      })
    )
    .catch((err) => {
      if (err.response) {
        Alert.error(err.response.data.error, 5000);
      }
      dispatch({
        type: GET_TREK,
        payload: {},
      });
    });
};

// Get trek by handle
export const getTrekByHandle = (handle) => async (dispatch) => {
  dispatch(setTrekLoading());
  await axios
    .get(`/api/v1/treks/handle/${handle}`)
    .then((res) =>
      dispatch({
        type: GET_TREK,
        payload: res.data.data,
      })
    )
    .catch((err) => {
      if (err.response) {
        Alert.error(err.response.data.error, 5000);
      }
      dispatch({
        type: GET_TREK,
        payload: null,
      });
    });
};

// Add Trek
export const addTrek = (newTrek) => (dispatch) => {
  // debugger
  dispatch(clearErrors());
  axios
    .post("/api/v1/treks", newTrek)
    .then((res) => {
      console.log({ res });
      if (res?.data?.success) {
        dispatch({
          type: ADD_TREK,
          payload: res?.data?.data,
        });
        Alert.success(res?.data?.message, 5000);
        return res?.data;
      }
      return res;
      Alert.success(res?.data?.error, 5000);
    })
    .catch((err) => {
      console.log({ err });
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return err;
      }
      Alert.error("Something went wrong", 5000);
      return err;
    });
};

// Edit Trek
export const editTrek = (trekId, newTrek) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .post(`/api/v1/treks`, newTrek)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: EDIT_TREK,
          payload: res.data.data[0],
          id: trekId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      Alert.error("Something went wrong", 5000);
    });
};

// Add trek details
export const addDetails = (trekId, detailsData) => (dispatch) => {
  axios
    .put(`/api/v1/treks/details/${trekId}/`, detailsData)
    .then((res) => {
      debugger;
      if (res.data.success) {
        dispatch({
          type: SINGLE_EDIT_TREK_OVERVIEW,
          payload: res.data.data[0],
          id: trekId,
        });
        // history.push(`/treks/${res.data.data[0].handle}`)
        Alert.success(res.data.message, 5000);
        return;
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error,
        });
        return false;
      }
      Alert.error("Something went wrong", 5000);
    });
};

// edit trek details
export const editDetails = (trekId, detailsData, history) => (dispatch) => {
  axios
    .put(`/api/v1/treks/details/${trekId}/`, detailsData)
    .then((res) => history.push("/treks"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// edit trek popular
export const editPopularTrek = (trekId, popularTrek) => (dispatch) => {
  axios
    .put(`/api/v1/treks/popular/${trekId}/`, popularTrek)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: EDIT_TREK,
          payload: res.data.data[0],
          id: trekId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add trek details
export const addFiles = (trekId, uploadData) => (dispatch) => {
  axios
    .put(`/api/v1/treks/upload/${trekId}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: SINGLE_EDIT_TREK,
          payload: res.data.data,
          id: trekId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      Alert.error("Something went wrong", 5000);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// Add trek details
export const addMapFile = (trekId, uploadData) => (dispatch) => {
  axios
    .put(`/api/v1/treks/map/${trekId}`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: SINGLE_EDIT_TREK,
          payload: res.data.data,
          id: trekId,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      Alert.error("Something went wrong", 5000);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// Get all treks
export const getTreks = () => (dispatch) => {
  dispatch(setTrekLoading());
  axios
    .get("/api/v1/treks/")
    .then((res) =>
      dispatch({
        type: GET_TREKS,
        payload: res.data.data,
      })
    )
    .catch((err) => {
      if (err.response) {
        Alert.error(err.response.data.error, 5000);
      }
      dispatch({
        type: GET_TREKS,
        payload: null,
      });
    });
};

// Get all treks by type
export const getTreksByType = (trek_type) => (dispatch) => {
  dispatch(setTrekLoading());
  axios
    .get(`/api/v1/treks/type/${trek_type}`)
    .then((res) =>
      dispatch({
        type: GET_TREKS_BY_TYPE,
        payload: res.data,
      })
    )
    .catch((err) => {
      if (err.response) {
        Alert.error(err.response.data.error, 5000);
      }
      dispatch({
        type: GET_TREKS_BY_TYPE,
        payload: null,
      });
    });
};

// Get all treks by region
export const getTreksByRegion = (region) => (dispatch) => {
  dispatch(setTrekLoading());
  axios
    .get(`/api/v1/treks/region/${region}`)
    .then((res) =>
      dispatch({
        type: GET_TREKS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_TREKS,
        payload: null,
      })
    );
};

// Delete Post
export const deleteTrek = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/treks/${id}`)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: DELETE_TREK,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const addTrekItinerary = (id, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  setLoading(true);
  axios
    .post("/api/v1/treks/" + id + "/itinerary", newTeam)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: newTeam.id ? EDIT_TREK_ITINERARY : ADD_TREK_ITINERARY,
          payload: newTeam.id ? res.data.data[0] : res.data.data,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setLoading(false));
      getError(err);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
    });
};

export const addTrekAltitude = (id, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  setLoading(true);
  axios
    .post("/api/v1/treks/" + id + "/altitude", newTeam)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: newTeam.id ? EDIT_TREK_ALTITUDE : ADD_TREK_ALTITUDE,
          payload: newTeam.id ? res.data.data[0] : res.data.data,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setLoading(false));
      getError(err);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
    });
};

export const addTrekItineraryFiles = (teamID, uploadData) => (dispatch) => {
  setLoading(true);
  axios
    .put(`/api/v1/treks/itinerary/${teamID}/upload`, uploadData)
    // .then(res => history.push('/dashboard'))
    .then((res) => {
      dispatch(setLoading(false));
      dispatch({
        type: EDIT_TREK_ITINERARY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
      getError(err);
    });
};

export const deleteTrekItinerary = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/treks/itinerary/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TREK_ALTITUDE,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const deleteTrekAltitude = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/treks/altitude/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TREK_ALTITUDE,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addTrekPriceDetails = (id, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  setLoading(true);
  axios
    .post("/api/v1/treks/" + id + "/price", newTeam)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: newTeam.id ? EDIT_TREK_PRICE_DETAILS : ADD_TREK_PRICE_DETAILS,
          payload: newTeam.id ? res.data.data[0] : res.data.data,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setLoading(false));
      getError(err);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
    });
};

export const deleteTrekPriceDetails = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/treks/price/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TREK_PRICE_DETAILS,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addTrekPriceGroupSize = (id, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  setLoading(true);
  axios
    .post("/api/v1/treks/" + id + "/group_size", newTeam)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: newTeam.id
            ? EDIT_TREK_PRICE_GROUP_SIZE
            : ADD_TREK_PRICE_GROUP_SIZE,
          payload: newTeam.id ? res.data.data[0] : res.data.data,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setLoading(false));
      getError(err);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
    });
};

export const deleteTrekPriceGroupSize = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/treks/group_size/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TREK_PRICE_GROUP_SIZE,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const addTrekIncludes = (id, newTeam) => (dispatch) => {
  dispatch(clearErrors());
  setLoading(true);
  axios
    .post("/api/v1/treks/" + id + "/includes", newTeam)
    .then((res) => {
      dispatch(setLoading(false));
      if (res.data.success) {
        dispatch({
          type: newTeam.id ? EDIT_TREK_INCLUDES : ADD_TREK_INCLUDES,
          payload: newTeam.id ? res.data.data[0] : res.data.data,
        });
        Alert.success(res.data.message, 5000);
        return;
      }
      Alert.success(res.data.error, 5000);
    })
    .catch((err) => {
      dispatch(setLoading(false));
      getError(err);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return false;
      }
    });
};

export const deleteTrekIncludes = (id) => (dispatch) => {
  axios
    .delete(`/api/v1/treks/includes/${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_TREK_INCLUDES,
          payload: id,
        });
        Alert.success(res.data.message, 5000);
      }
    })
    .catch((err) => {
      getError(err);
      if (err.response && err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// adventure loading
// export const setTeamLoading = () => {
//   return {
//     type: TEAM_LOADING
//   };
// };

// Trek loading
export const setTrekLoading = () => {
  return {
    type: TREK_LOADING,
  };
};

export const setLoading = (load) => {
  return {
    type: TREK_ITINERARY_LOADING,
    payload: load,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
