import React, { Component, useState } from 'react'
import { connect } from "react-redux";
import { deleteTripPlan, getTripPlans } from "../../actions/tripplanAction";
import { Table,IconButton,Icon } from "rsuite";
import './trip.styles.scss';
import CustomPagination from '../../components/common/CustomPagination';
import isEmpty from '../../validations/isEmpty'
import { Modal,Button } from 'rsuite';

const { Column, HeaderCell, Cell, Pagination } = Table;

// const ActionCell = ({ rowData,onTripDelete, dataKey, ...props }) => {
//     return (
//       <Cell {...props} className="link-group">
//         <IconButton onClick={()=>onTripDelete(rowData.id)} appearance="subtle" icon={<Icon icon="trash" />} />
//       </Cell>
//     );
// };
const ActionCell = ({ rowData,onTripDelete, dataKey, ...props }) => {
  const [show,setShow] = useState(false)
  const onShow = () => {
    setShow(true)
  };
  const onShowClose = () => {
    setShow(false)
  };
    return (
      <Cell {...props} className="link-group">
        <IconButton onClick={()=>onShow()} appearance="subtle" icon={<Icon icon="trash" />} />
        {/* <IconButton onClick={()=>dispatch(deleteActivity(rowData.id))} appearance="subtle" icon={<Icon icon="trash" />} /> */}
        <Modal backdrop="static" show={show} onHide={onShowClose} size="xs">
                    <Modal.Body>
                        <Icon
                        icon="remind"
                        style={{
                            color: '#ffb300',
                            fontSize: 24
                        }}
                        />
                        {'  '}
                        Are you sure, You want to delete this plan ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>{
                            // this.props.deleteTrek(trek.id);
                            onTripDelete(rowData.id)
                            onShowClose()
                        }} appearance="primary">
                        Ok
                        </Button>
                        <Button onClick={onShowClose} appearance="subtle">
                        Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
      </Cell>
    );
};

const Group = ({ rowData,dataType, dataKey,dataName, ...props }) => {
    
    const data = rowData[dataType][dataName];
    return (
      <Cell {...props} className="link-group">
          <span>{data}</span>
      </Cell>
    );
};
const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props}>
      <IconButton
        size="xs"
        appearance="subtle"
        onClick={() => {
          onChange(rowData);
        }}
        icon={
          <Icon
            icon={
              expandedRowKeys.some(key => key === rowData[rowKey])
                ? 'minus-square-o'
                : 'plus-square-o'
            }
          />
        }
      />
    </Cell>
  );
const rowKey = 'id';
export class TripPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
          expandedRowKeys: []
        };
        this.handleExpanded = this.handleExpanded.bind(this);
    }
    componentDidMount() {
        this.props.getTripPlans();
    }
    handleExpanded(rowData, dataKey) {
        const { expandedRowKeys } = this.state;
    
        let open = false;
        const nextExpandedRowKeys = [];
    
        expandedRowKeys.forEach(key => {
          if (key === rowData[rowKey]) {
            open = true;
          } else {
            nextExpandedRowKeys.push(key);
          }
        });
    
        if (!open) {
          nextExpandedRowKeys.push(rowData[rowKey]);
        }
        this.setState({
          expandedRowKeys: nextExpandedRowKeys
        });
      }

    onTripDelete(id){
        this.props.deleteTripPlan(id)
    }
    render() {
        const { loading, tripplans ,page } = this.props;
        const { expandedRowKeys, data } = this.state;
        return (
            <div className="contacts">
            <div className="contacts-table">
              <Table 
                headerHeight={80}
                loading={loading} 
                height={400} data={tripplans ?? []}
                rowKey={rowKey}
                expandedRowKeys={expandedRowKeys}
                renderRowExpanded={rowData=>{
                    // const {trip_details} = rowData;
                    return(
                        <div className="trip-details">
                            <div className="trip-type">
                                
                                <span className="activity">
                                    {rowData?.trip_activity}
                                </span>
                                (<span className="type">
                                    {rowData?.trip_type}
                                </span>)
                            </div>
                            <div className="trip-summary">
                                {rowData?.trip_summary}
                            </div>
                            <div className="trip-describe">
                                {rowData?.trip_describe}
                            </div>
                        </div> 
                    )
                }}
                >
                <Column width={70} align="center">
                    <HeaderCell>#</HeaderCell>
                    <ExpandCell
                        dataKey="id"
                        expandedRowKeys={expandedRowKeys}
                        onChange={this.handleExpanded}
                    />
                </Column>
                <Column width={130} colSpan={2}>
                    <HeaderCell className="header-cell-group">
                    <div className="header-cell-group-title">Group Size</div>
                    <div className="header-cell-group-subtitle">
                        <span style={{ width: 130 }}>Group</span>
                        <span style={{ width: 130 }}>Number</span>
                    </div>
                    </HeaderCell>
                    {/* <Group dataName="group" dataKey="id" dataType="group_size" /> */}
                    <Cell dataKey="group_name" />
                </Column>
                <Column width={130}>
                    <HeaderCell />
                    <Cell dataKey="group_number" />
                    {/* <Group dataName="number" dataKey="id" dataType="group_size" /> */}
                </Column>
                <Column width={130} colSpan={3}>
                    <HeaderCell className="header-cell-group">
                    <div className="header-cell-group-title">Travel Dates</div>
                    <div className="header-cell-group-subtitle">
                        <span style={{ width: 130 }}>Date</span>
                        <span style={{ width: 130 }}>Type</span>
                        <span style={{ width: 130 }}>Duration</span>
                    </div>
                    </HeaderCell>
                    <Cell dataKey="trip_date" />
                    {/* <Group dataName="date" dataKey="id" dataType="travel_dates" /> */}
                </Column>
                <Column width={180}>
                    <HeaderCell />
                    <Cell dataKey="trip_date_type" resizable />
                    {/* <Group dataName="type" dataKey="id" dataType="travel_dates" /> */}
                </Column>
                <Column width={130}>
                    <HeaderCell />
                    <Cell dataKey="trip_duration" />
                    {/* <Group dataName="duration" dataKey="id" dataType="travel_dates" /> */}
                </Column>
                <Column width={130} colSpan={4}>
                    <HeaderCell className="header-cell-group">
                    <div className="header-cell-group-title">Contact Details</div>
                    <div className="header-cell-group-subtitle">
                        <span style={{ width: 130 }}>Name</span>
                        <span style={{ width: 130 }}>Email</span>
                        <span style={{ width: 130 }}>Phone</span>
                        <span style={{ width: 130 }}>Country</span>
                    </div>
                    </HeaderCell>
                    <Cell dataKey="customer_name" />
                    {/* <Group dataName="name" dataKey="id" dataType="contact_details" /> */}
                </Column>
                <Column width={130}>
                    <HeaderCell />
                    <Cell dataKey="customer_email" />
                    {/* <Group dataName="email" dataKey="id" dataType="contact_details" /> */}
                </Column>
                <Column width={130}>
                    <HeaderCell />
                    <Cell dataKey="customer_phone" />
                    {/* <Group dataName="phone" dataKey="id" dataType="contact_details" /> */}
                </Column>
                <Column width={130}>
                    <HeaderCell />
                    <Cell dataKey="customer_country" />
                    {/* <Group dataName="country" dataKey="id" dataType="contact_details" /> */}
                </Column>
                <Column width={100}>
                  <HeaderCell>Delete</HeaderCell>
                  <ActionCell dataKey="id" onTripDelete={this.onTripDelete.bind(this)} />
                </Column>
              </Table>
              {
                    isEmpty(tripplans)?null:
                    <CustomPagination 
                            page={page}
                            getFunc={(pages)=>this.props.getTripPlans(pages)}
                    />
            }
              
            </div>
          </div>
        )
    }
}

const mapDispatchToProps = {
    getTripPlans,
    deleteTripPlan
  };
  
  const mapStateToProps = state => ({
    tripplans: state.tripplanReducer.tripplans,
    page: state.tripplanReducer.page,
    loading: state.tripplanReducer.loading
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(TripPlan);
  