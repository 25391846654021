import axios from "axios";
import { Alert } from "rsuite";
import isEmpty from "../validations/isEmpty";
import {getError} from '../helpers/getUnauth'

import {
	GET_TRIP_PLANS,
	TRIP_PLAN_LOADING,
	DELETE_TRIP_PLAN,
	CLEAR_ERRORS,
	GET_ERRORS
} from "./types";
import {getPages} from '../helpers/getPages'

// Get all contacts
export const getTripPlans = (pages) => async dispatch => {
    dispatch(setLoading());
    let page = getPages(pages);
	await axios
		.get("/api/v1/tripplan",{ params:page})
		.then(res =>{
			dispatch({
				type: GET_TRIP_PLANS,
                payload: res.data.data,
                page:res.data.page
			})
		}
		)
		.catch(err =>{
            getError(err);
            dispatch({
				type: GET_TRIP_PLANS,
				payload: []
			})
        });
};

// Delete Contact
export const deleteTripPlan = id => dispatch => {
	axios
		.delete(`/api/v1/tripplan/${id}`)
		.then(res => {
			if (res.data.success) {
				dispatch({
					type: DELETE_TRIP_PLAN,
					payload: id
				});
				Alert.success(res.data.message, 5000);
			} else {
				Alert.error("Something went wrong", 5000);
			}
		})
		.catch(err => {
			getError(err);
		});
};


// User loading
export const setLoading = () => {
	return {
		type: TRIP_PLAN_LOADING
	};
};

// Clear errors
export const clearErrors = () => {
	return {
		type: CLEAR_ERRORS
	};
};
