import dayjs from 'dayjs';
import React from 'react'
import './bookings.styles.scss';


function ExpandData({data}) {
    console.log({data})
    return (
        <div className='book-expand'>
            <UserExpand 
                title="Captain"
                passport_img={data?.passport_img}
                customer_img={data?.customer_img}
                name={data?.first_name}
                expiration_date={data?.expiration_date}
            />
            {/* {
                data?.additional_details && data?.additional_details.map((item,i)=>{
                    return <UserExpand 
                        title={"Guest - " + i}
                        passport_img={item?.passport_img}
                        name={item?.name}
                        expiration_date={item?.expiration_date}
                    />
                })
            } */}
        </div>
    )
}

const UserExpand = (props) =>{
    const {title,passport_img,customer_img,name,expiration_date,passport_no} = props;
    return(
        <div className='book-expand__container'>
            <div className='book_expand__title'>{title}</div>
            <div className='book_expand__flex'>
                <div
                    className='book-expand__passport'
                >
                    <img src={passport_img} />
                </div>
                <div
                    className='book-expand__passport'
                >
                    <img src={customer_img} />
                </div>
                <div className='book_expand__details'>
                    <div className='book_expand__name'>
                        {name}
                    </div>
                    <div className='book_expand__passport'>
                        {passport_no}
                    </div>
                    <div className='book_expand__date'>
                        {dayjs(expiration_date).format("YYYY-MM-DD")}
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default ExpandData
