import { GET_SCAN,CLEAR_SCAN,SCAN_LOADING} from '../actions/types';

const initialState ={
    scan:{isScan:false,scanData:''},
    loading: false
}

export default function(state= initialState,action){
    switch(action.type){
        case SCAN_LOADING:
        return {
            ...state,
            loading: true
        };
        case GET_SCAN:
            return {
                ...state,
                scan:{isScan:action.payload.isScanned,scanData:action.payload.data},
                loading:false,
            }
        case CLEAR_SCAN:
            return{
                ...state,
                scan:{isScan:false,scanData:''},
                loading:false
            };
        default:
        return state;
    }
}