import {
	GET_SETTINGS,
	SETTINGS_LOADING,
	EDIT_SETTINGS,
	GET_TREK_REGIONS,
	ADD_TREK_REGION,
	EDIT_TREK_REGION,
	DELETE_TREK_REGION,
	TREK_REGION_LOADING,
	GET_SERVICES,
	DELETE_SERVICE,
	ADD_SERVICE,
	EDIT_SERVICE,
	GET_SERVICE_TYPES,
	DELETE_SERVICE_TYPE,
	ADD_SERVICE_TYPE,
	EDIT_SERVICE_TYPE,
	ADD_BLOG,
	EDIT_BLOG,
	DELETE_BLOG,
	GET_BLOGS,

	UPDATE_ALP_SETTINGS,
	UPDATE_ALP_SETTINGS_IMAGES,
	GET_ALP_SETTINGS,
	ADD_THINGS_TO,
	EDIT_THINGS_TO,
	GET_THINGS_TOS,
	DELETE_THINGS_TO,
} from "../actions/types";

const initialState = {
	settings: {},
	alpSettings: {},
	things:[],
	trekRegions:[],
	services:[],
	serviceTypes:[],
	blogs:[],
	loading: false
};
export default function(state = initialState, action) {
	switch (action.type) {
		case SETTINGS_LOADING:
			return {
				...state,
				loading: action.payload
			};
		case GET_ALP_SETTINGS:
			return {
				...state,
				alpSettings: action.payload,
				loading: false
			};
		case UPDATE_ALP_SETTINGS:
			return {
				...state,
				alpSettings: action.payload,
				loading: false
			};
		case UPDATE_ALP_SETTINGS_IMAGES:
			return {
				...state,
				alpSettings: action.payload,
				loading: false
			};

		case GET_SETTINGS:
			return {
				...state,
				settings: action.payload,
				loading: false
			};
		case EDIT_SETTINGS:
			return {
				...state,
				settings: action.payload
			};
		case TREK_REGION_LOADING:
			return {
				...state,
				loading: true
			};
		case GET_TREK_REGIONS:
			return {
				...state,
				trekRegions: action.payload,
				loading: false
			};
		case ADD_TREK_REGION:
			return {
				...state,
				trekRegions: [action.payload, ...state.trekRegions]
			};
		case EDIT_TREK_REGION:
			return {
				...state,
				trekRegions: state.trekRegions.map(room =>
					room.id === action.id ? action.payload : room
				),
			};
		case DELETE_TREK_REGION:
			return {
				...state,
				trekRegions: state.trekRegions.filter(
					room => room.id !== action.payload
				)
			};
		case GET_BLOGS:
			return {
				...state,
				blogs: action.payload,
				loading: false
			};
		case ADD_BLOG:
			return {
				...state,
				blogs: [action.payload, ...state.blogs]
			};
		case EDIT_BLOG:
			return {
				...state,
				blogs: state.blogs.map(room =>
					room.id === action.payload.id ? action.payload : room
				),
			};
		case DELETE_BLOG:
			return {
				...state,
				blogs: state.blogs.filter(
					room => room.id !== action.payload
				)
			};
		case GET_SERVICES:
			return {
				...state,
				services: action.payload,
				loading: false
			};
		case ADD_SERVICE:
			return {
				...state,
				services: [action.payload, ...state.services]
			};
		case EDIT_SERVICE:
			return {
				...state,
				services: state.services.map(room =>
					room.id === action.payload[0].id ? action.payload[0] : room
				),
			};
		case DELETE_SERVICE:
			return {
				...state,
				services: state.services.filter(
					room => room.id !== action.payload
				)
			};
		case GET_SERVICE_TYPES:
			return {
				...state,
				serviceTypes: action.payload,
				loading: false
			};
		case ADD_SERVICE_TYPE:
			return {
				...state,
				serviceTypes: [action.payload, ...state.serviceTypes]
			};
		case EDIT_SERVICE_TYPE:
			return {
				...state,
				serviceTypes: state.serviceTypes.map(room =>
					room.id === action.payload[0].id ? action.payload[0] : room
				),
			};
		case DELETE_SERVICE_TYPE:
			return {
				...state,
				serviceTypes: state.serviceTypes.filter(
					room => room.id !== action.payload
				)
			};
		case GET_THINGS_TOS:
			return {
				...state,
				things: action.payload,
				loading: false
			};
		case ADD_THINGS_TO:
			return {
				...state,
				things: [action.payload, ...state.things]
			};
		case EDIT_THINGS_TO:
			return {
				...state,
				things: state.things.map(room =>
					room.id === action.payload[0].id ? action.payload[0] : room
				),
			};
		case DELETE_THINGS_TO:
			return {
				...state,
				things: state.things.filter(
					room => room.id !== action.payload
				)
			};
		default:
			return state;
	}
}
