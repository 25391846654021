import React, { useState } from "react";
import { Button, Modal, Icon, ButtonToolbar } from "rsuite";
import Formed from "./form";
import { Animation } from "rsuite";
import renderHTML from "react-render-html";
import { deleteTourPackageIncludes } from "../../../../../actions/tourActions";
import { useDispatch } from "react-redux";

const { Fade, Collapse, Transition } = Animation;

function Includes(props) {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState(null);
  const onModalOpen = () => setOpen(true);
  const onModalClose = () => setOpen(false);
  const { errorReducer, editCustomer, auth, statuses, tourPackage } = props;
  const onData = (dt) => {
    setDatas(dt);
    setOpen(true);
  };
  const onClear = (dt) => {
    setDatas(null);
    setOpen(false);
  };
  return (
    <div className="">
      <Button onClick={onModalOpen}>Add Includes</Button>
      <Modal full backdrop={true} show={open} onHide={onModalClose}>
        <Modal.Header>
          <Modal.Title>Trek Includes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formed
            errorReducer={errorReducer}
            close={onClear}
            auth={auth}
            datas={datas}
            isEdit={datas !=null}
            editCustomer={editCustomer}
            statuses={statuses}
            tourPackage={tourPackage}
            settings={props.settings}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onModalClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="includes">
        <div className="includes-main">Includes</div>
        <div className="includes-content">
          {tourPackage?.includes &&
            tourPackage?.includes.filter(item=>item.included).map((it) => {
              return <Iti includes={it} onData={onData} />;
            })}
        </div>
      </div>
      <div className="includes">
        <div className="includes-main">Excludes</div>
        <div className="includes-content">
          {tourPackage?.includes &&
            tourPackage?.includes.filter(item=>!item.included).map((it) => {
              return <Iti includes={it} onData={onData} />;
            })}
        </div>
      </div>
    </div>
  );
}

const Iti = (props) => {
  const { includes, onData } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <div className="includes-single">
        <div className="includes-container">
          <div className="includes-title">{includes.content}</div>
          <div>
            <div
              onClick={() => onData(includes)}
              className="includes__edit text-center"
            >
              <Icon icon="edit" size="lg" color="red" />
            </div>
            <div
              onClick={() => setOpen(!open)}
              className="includes__delete text-center"
            >
              <Icon icon="minus-square" size="lg" color="blue" />
            </div>
            <Modal
              backdrop="static"
              show={open}
              onHide={() => setOpen(false)}
              size="xs"
            >
              <Modal.Body>
                <Icon
                  icon="remind"
                  style={{
                    color: "#ffb300",
                    fontSize: 24,
                  }}
                />
                {"  "}
                Are you sure, You want to delete this includes ?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    dispatch(deleteTourPackageIncludes(includes.id));
                    setOpen(false);
                  }}
                  appearance="primary"
                >
                  Ok
                </Button>
                <Button onClick={() => setOpen(false)} appearance="subtle">
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Includes;
