export default function validateAuth(values) {
    let errors = {};
    // Email Errors
    if (!values.email) {
      errors.email = "Email Field is Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    // Password Errors
    if (!values.password) {
      errors.password = "Password Field is Required";
    } else if (values.password.length < 5) {
      errors.password = "Password must be at least 5 characters";
    }
    return errors;
  }
  