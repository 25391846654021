import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  CheckPicker,
  Radio,
  Input,
} from "rsuite";
import { Button, Modal, Loader, ButtonToolbar } from "rsuite";
import useFormValidation from "../../../validations/formValidations";
import {validateGroup,} from "../../../validations/validateItinerary";
import isEmpty from "../../../validations/isEmpty";
import Tiny from "../../../components/common/TinyEmc";
import { addTrekPriceGroupSize } from "../../../actions/trekActions";
import { useDispatch, useSelector } from "react-redux";

function ItineraryForm(props) {
  const {
    errorReducer,
    editCustomer,
    auth,
    datas = {},
    isEdit = false,
    statuses,
    close,
    trek,
  } = props;
  const dispatch = useDispatch();
  const settings = useSelector(state=>state.settingReducer.settings)
  const INITIAL_STATE = {
    group_size: !isEmpty(datas?.group_size) ? datas.group_size : '',
    price: !isEmpty(datas?.price) ? datas.price : "",
  };
  const {
    handleSubmit,
    handleChange2,
    handleChange3,
    handleSelect,
    checkErrors,
    handleBlur,
    handleDateChange,
    values,
    errors,
    isSubmitting,
    clearValues,
  } = useFormValidation(INITIAL_STATE, validateGroup);
  const handleSubmits = async (e) => {
    e.preventDefault();
    const { isValid } = checkErrors();
    
    if (isValid) {
      
      if (isEmpty(errors)) {
        values.price = parseInt(values.price)
        if (isEdit) {
          // dispatch(editTeam(values.id,values));
          // clearValues();
          // onEdit({})
          await dispatch(addTrekPriceGroupSize(trek.id, {...values,id:datas.id,trekPriceId:trek.id}));
          clearValues();
          close()
          return false;
        }
        await dispatch(addTrekPriceGroupSize(trek.id, {...values,trekPriceId:trek.id}));
        clearValues();
        close()
      }
    }
  };
  const [errs, setErrs] = React.useState({});
  useEffect(() => {
    if (errorReducer) {
      if (!isEmpty(errorReducer.error)) {
        setErrs(errorReducer.error);
      }
    }
  }, [errorReducer]);
  const getError = (type) => {
    let errClass =
      !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
    return errClass;
  };
  const getErrorMessage = (type) => {
    if (!isEmpty(errors[type])) {
      return errors[type];
    } else if (!isEmpty(errs[type])) {
      return errs[type];
    }
    return "";
  };
  return (
    <Form>
      <FormGroup className={getError("group_size")}>
        <ControlLabel>Group Size</ControlLabel>
        <FormControl
          name="group_size"
          type=""
          value={values.group_size}
          errorMessage={getErrorMessage("group_size")}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={getError("price")}>
        <ControlLabel>Price</ControlLabel>
        <FormControl
          name="price"
          type="number"
          errorMessage={getErrorMessage("price")}
          value={values.price}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>

      <FormGroup>
        <ButtonToolbar>
          <Button
            appearance="primary"
            disabled={isSubmitting}
            onClick={handleSubmits}
          >
            {isSubmitting ? <Loader /> : null}
            Submit
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
}

export default ItineraryForm;
