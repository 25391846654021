import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  RadioGroup,
  Radio,
  Input,
} from "rsuite";
import { Button, Modal, Loader, ButtonToolbar } from "rsuite";
import useFormValidation from "../../../validations/formValidations";
import {validateincludes} from "../../../validations/validateItinerary";
import isEmpty from "../../../validations/isEmpty";
import Tiny from "../../../components/common/TinyEmc";
import { addTrekIncludes } from "../../../actions/trekActions";
import { useDispatch } from "react-redux";

function ItineraryForm(props) {
  const {
    errorReducer,
    editCustomer,
    auth,
    datas = {},
    isEdit = false,
    statuses,
    close,
    trek,
  } = props;
  const dispatch = useDispatch();
  const INITIAL_STATE = {
    included: !isEmpty(datas?.included) ? datas.included : false,
    content: !isEmpty(datas?.content) ? datas.content : "",
  };
  console.log(isEdit);
  const {
    handleSubmit,
    handleChange2,
    handleChange3,
    handleSelect,
    checkErrors,
    handleBlur,
    handleDateChange,
    values,
    errors,
    isSubmitting,
    clearValues,
  } = useFormValidation(INITIAL_STATE, validateincludes);
  const handleSubmits = async (e) => {
    e.preventDefault();
    const { isValid } = checkErrors();
    
    if (isValid) {
      
      if (isEmpty(errors)) {
        if (isEdit) {
          // dispatch(editTeam(values.id,values));
          // clearValues();
          // onEdit({})
          await dispatch(addTrekIncludes(trek.id, {...values,id:datas.id}));
          clearValues();
          close()
          return false;
        }
        await dispatch(addTrekIncludes(trek.id, values));
        clearValues();
        close()
      }
    }
  };
  const [errs, setErrs] = React.useState({});
  useEffect(() => {
    if (errorReducer) {
      console.log(errorReducer);
      if (!isEmpty(errorReducer.error)) {
        setErrs(errorReducer.error);
      }
    }
  }, [errorReducer]);
  const getError = (type) => {
    let errClass =
      !isEmpty(errors[type]) && !isEmpty(errs[type]) ? "has-error" : "";
    return errClass;
  };
  const getErrorMessage = (type) => {
    if (!isEmpty(errors[type])) {
      return errors[type];
    } else if (!isEmpty(errs[type])) {
      return errs[type];
    }
    return "";
  };
  return (
    <Form>
      {/* <FormGroup className={getError("included")}>
        <ControlLabel>Included</ControlLabel>
        <FormControl
          name="included"
          type=""
          value={values.included}
          errorMessage={getErrorMessage("included")}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup> */}
      <FormGroup className={getError("content")}>
        <ControlLabel>Content</ControlLabel>
        <FormControl
          name="content"
          type="text"
          errorMessage={getErrorMessage("content")}
          value={values.content}
          onChange={handleChange2}
        />
        <HelpBlock tooltip>Required</HelpBlock>
      </FormGroup>
      <RadioGroup
        className="radio-container"
        onChange={(value) => handleChange3("included", value)}
        name="included"
        inline
        value={values.included}
        appearance="picker"
        defaultValue="Easy"
      >
        <span className="radio-header">Difficulty: </span>
        <Radio value={true}>Included</Radio>
        <Radio value={false}>Excluded</Radio>
      </RadioGroup>

      <FormGroup>
        <ButtonToolbar>
          <Button
            appearance="primary"
            disabled={isSubmitting}
            onClick={handleSubmits}
          >
            {isSubmitting ? <Loader /> : null}
            Submit
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
}

export default ItineraryForm;
