import React, { Component } from 'react'
import PropTypes from 'prop-types';
import AdventureItem from './AdventureItem';

export class AdventureFeed extends Component {
    render() {
        const {adventures,onEdit} = this.props;
        return (
            <div className="adventures-list__block">
                <div className="adventures-list__search">

                </div>
                <div className="adventures-list__header adventure-grid">
                    <span className="name">Adventure Name</span>
                    <span className="price text-center">Price</span>
                    <span className="duration text-center">Duration</span>
                    <span className="destination text-center">Destination</span>
                    <span className="edit text-center">Edit</span>
                    <span className="delete text-center">Delete</span>
                </div>
                 <div className="adventures-list__items">
                    {
                        adventures && adventures.map(adventure =>{
                            return(
                                <AdventureItem 
                                    onEdit={onEdit} 
                                    key={adventure.id}
                                    adventure={adventure}
                                />
                            )
                        })
                    }
                 </div>
            </div>
        )
    }
}

export default AdventureFeed
