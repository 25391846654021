import React, { Component,useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import useFormValidation from "../../validations/formValidations";
import validateAuth from "../../validations/validateAuth";
import isEmpty from '../../validations/isEmpty'
import {Link} from 'react-router-dom'

const INITIAL_STATE = {
  email: "",
  password: ""
};

function Login(props) {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting
  } = useFormValidation(INITIAL_STATE, validateAuth);
  // const [email, setEmail] = React.useState("");
  const [errs, setErrs] = React.useState({});
  
  const {auth}=props;
  const {errorReducer}=props;
  useEffect(() => {
    if(auth.isAuthenticated){
      props.history.push('/dashboard');
    }
  }, [auth])
  useEffect(() => {
    if(isSubmitting){
      props.loginUser(values)
    }
  }, [isSubmitting])
  useEffect(() => {
    if(props.errorReducer){
      setErrs(props.errorReducer);
    }
  }, [errorReducer])
  const getErrorMessage=(type)=>{
    if(!isEmpty(errors[type])){
        return errors[type]
    }else if (!isEmpty(errs[type])){
        return errs[type]
    }
    return ''
}
  return (
      <section className="section-login login">
        <div className="login-left">
          {/* <div className="login-left__header">

          </div> */}
          <div className="login-left__content">
            <h2 className="secondary-heading">Welcome to Flock</h2>
            <p className="text-para mt-md">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio perspiciatis culpa sapiente officia, soluta quis blanditiis?</p>
          </div>
        </div>
        <div className="login-right">
          <div className="login-form">
            <h1 className="tertiary-heading">Sign !n</h1>
            <form onSubmit={handleSubmit}>
                  <TextFieldGroup 
                      placeholder="Email Address" 
                      name="email"
                      type="email" 
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      error={getErrorMessage('email')}
                      />
                  <TextFieldGroup 
                      placeholder="Password" 
                      name="password"
                      type="password" 
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      error={getErrorMessage("password")}
                      />
                      <Link className="forgot-password-link" to="forgot-password">
                        <span>Forgot Password ?</span>
                      </Link>
                      <button 
                        type="submit"
                        disabled={auth.loading}
                        className="login-button">
                          {
                            auth.loading?
                            <div class="lds-hourglass"></div>
                            :null
                          }
                            <span>
                              Login
                            </span>
                      </button>
                    {/* <input type="submit" disabled={auth.loading} value="Login" className="login-button" /> */}

            </form>
          </div>
        </div>
      </section>
  );
}

 
Login.propTypes ={
  loginUser: PropTypes.func.isRequired,
  auth:PropTypes.object.isRequired,
  errorReducer: PropTypes.object.isRequired 
}

const mapStateToProps = (state) => ({
  auth:state.auth,
  errorReducer:state.errorReducer
})


export default connect(mapStateToProps, {loginUser})(Login);